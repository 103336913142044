<template>
	<div ref="post" class="post-container d-flex flex-column">
		<template v-for="(page, i) in pages">
			<!-- Text post (sticky note style) -->
			<div
				v-if="page.type == 'text'"
				:key="i"
				:style="{
					'height': `${page.i == 0 ? page.y : page.y + 10}px`
				}"
				class="sticky-container"
			>
				<div
					class="sticky-note-bg"
					:class="{
						'bg-red': post.color == 0,
						'bg-blue': post.color == 1,
						'bg-yellow': post.color == 2,
						'bg-green': post.color == 3,
						'sticky-note-bg-last': page.i + 1 == page.n,
					}"
					:style="{
						'transform': `rotate(${page.reverse ? -1 * page.r - 2 : page.r + 2}deg)`,
						'margin-top': `${page.i > 0 ? -10 - Math.sin(page.r / 180 * Math.PI) * 195 : 8}px`,
						'height': `${page.i == 0 ? page.y : page.y + 10 + Math.sin(page.r / 180 * Math.PI) * 195}px`,
						'z-index': page.n - page.i
					}"
				>
					<div v-if="page.i + 1 == page.n" class="post-details d-flex flex-row">
						<div v-if="post.translation" class="post-details-button" @click="toggleTranslation">{{ post.showTranslated ? $t("labels.tl_on") : $t("labels.tl_off") }}</div>
						<div class="ml-auto">{{ DateTime.fromISO(post.created_at).toLocaleString(DateTime.DATETIME_FULL) }}</div>
					</div>
				</div>
				<div
					class="sticky-note-fg d-flex flex-row"
					:class="{'flex-direction-reverse': page.reverse}"
					:style="{
						'margin-top': `${page.i > 0 ? -10 - Math.sin(page.r / 180 * Math.PI) * 195 : 0}px`,
						'padding-top': `${page.i > 0 ? 10 + Math.sin(page.r / 180 * Math.PI) * 195 : 8}px`,
						'transform': `rotate(${page.reverse ? page.r : -1 * page.r}deg)`,
						'z-index': 2 * page.n - page.i
					}"
				>
					<img class="sticky-note-decoration" src="@/assets/images/birthday/card_decoration.png">
					<img v-if="post.cat == 0" class="cat-watermark" src="@/assets/images/birthday/cat1.png">
					<img v-if="post.cat == 1" class="cat-watermark" src="@/assets/images/birthday/cat2.png">
					<img v-if="post.cat == 2" class="cat-watermark" src="@/assets/images/birthday/cat3.png">
					<img v-if="post.cat == 3" class="cat-watermark" src="@/assets/images/birthday/cat4.png">
					<div
						class="sticky-note-text"
						:class="{'chinese-font': post.language == 'ZH' && !post.showTranslated}"
						:style="{
							'width': `${page.n * page.x}px`,
							'height': page.y,
							'column-width': `${page.x}px`,
							'column-fill': 'balance',
							'clip-path': `inset(0px ${(page.n - page.i - 1) * page.x}px 0px ${page.i * page.x}px)`,
							'transform': `translateX(-${page.i * page.x}px)`
						}"
						v-html="post.showTranslated ? page.textTL : page.text"
					>
					</div>
				</div>
			</div>

			<!-- Image/video embed post (polaroid style) -->
			<div v-if="page.type == 'image' || page.type == 'video'" :key="i" class="polaroid-container" :style="{'margin-bottom': `${pages.length > 1 ? -30 : 0}px`}">
				<img height="420" src="@/assets/images/birthday/polaroid_frame.svg">

				<!-- Image -->
				<div v-if="page.type == 'image'" class="polaroid-viewport">
					<div
						class="post-img-container expandable"
						:style="{
							'padding-top': `${100 * post.image_height / post.image_width}%`,
							'margin-top': `${post.image_width > post.image_height ? 50 - (50 * post.image_height / post.image_width) : 0}%`
						}"
						@click="showImageLightbox(post)"
					>
						<div class="loading-container d-flex flex-column justify-content-center align-items-center">
							<div class="text-muted" style="font-size: 18px">Loading...</div>
						</div>
						<img :src="post.thumb_url || post.image_url" :id="`img-${post._id}`" class="post-img" />
					</div>
					<!-- <div class="polaroid-developing-message">{{ $t('labels.still_developing') }}</div> -->
				</div>

				<!-- Video -->
				<div v-if="page.type == 'video'" class="polaroid-viewport">
					<div
						class="post-img-container expandable"
						style="padding-top: 75%; top: 42px;"
						@click="showVideoLightbox(getEmbedURL(post.video_url))"
					>
						<div class="loading-container d-flex flex-column justify-content-center align-items-center">
							<div class="text-muted" style="font-size: 18px">Loading...</div>
						</div>
						<img :src="getThumbnailURL(post.video_url)" class="post-img" />
					</div>
					<!-- <div class="polaroid-developing-message">{{ $t('labels.still_developing') }}</div> -->
				</div>

				<div v-if="page.signature" class="polaroid-bottom-text" v-html="page.signature">
				</div>
				
				<img v-if="page.topTapeType == 1" class="tape-top" src="@/assets/images/birthday/tape1.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 2" class="tape-top" src="@/assets/images/birthday/tape2.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 3" class="tape-top" src="@/assets/images/birthday/tape3.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 4" class="tape-top" src="@/assets/images/birthday/tape4.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 5" class="tape-top" src="@/assets/images/birthday/tape5.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 6" class="tape-top" src="@/assets/images/birthday/tape6.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 7" class="tape-top" src="@/assets/images/birthday/tape7.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 8" class="tape-top" src="@/assets/images/birthday/tape8.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				<img v-if="page.topTapeType == 9" class="tape-top" src="@/assets/images/birthday/tape9.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">

				<template v-if="pages.length > 1">
					<img v-if="page.bottomTapeType == 1" class="tape-bottom" src="@/assets/images/birthday/tape1.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 2" class="tape-bottom" src="@/assets/images/birthday/tape2.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 3" class="tape-bottom" src="@/assets/images/birthday/tape3.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 4" class="tape-bottom" src="@/assets/images/birthday/tape4.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 5" class="tape-bottom" src="@/assets/images/birthday/tape5.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 6" class="tape-bottom" src="@/assets/images/birthday/tape6.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 7" class="tape-bottom" src="@/assets/images/birthday/tape7.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 8" class="tape-bottom" src="@/assets/images/birthday/tape8.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
					<img v-if="page.bottomTapeType == 9" class="tape-bottom" src="@/assets/images/birthday/tape9.png" :style="{transform: `translate(-50%) rotate(${Math.random() * 12 - 6}deg)`}">
				</template>
			</div>
		</template>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";
// import Store from "@/utils/Store";
import Img from "@/utils/Img";
import { DateTime } from "luxon";
// import * as basicLightbox from "basiclightbox";
import $ from "jquery";
// import LR from "@/utils/LinkRedirect"

export default {
	name: "BirthdayPost",

	props: ["post", "pages"],

	data() {
		return {
			DateTime: DateTime,
			rotation: Math.random(),
		};
	},
	created() {
		this.$set(this.post, "showTranslated", false);
	},

	mounted() {
		// LR.redirectLinks(this.$refs.post, this.openLinkRedirectModal)
	},

	computed: {
	},

	methods: {
		openLinkRedirectModal(url) {
			console.log("Open redirect modal", url)
			this.$emit("redirect", url)
		},

		renderName(post) {
			switch(post.user_type) {
				case "pixiv":
					return `${post.user_name}<svg
		class="fab pixiv-icon"
		role="img"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		style="height: 20px; margin-left: 6px;"
	>
		<title>pixiv icon</title>
		<path
			d="M4.935 0A4.924 4.924 0 0 0 0 4.935v14.13A4.924 4.924 0 0 0 4.935 24h14.13A4.924 4.924 0 0 0 24 19.065V4.935A4.924 4.924 0 0 0 19.065 0zm7.81 4.547c2.181 0 4.058.676 5.399 1.847a6.118 6.118 0 0 1 2.116 4.66c.005 1.854-.88 3.476-2.257 4.563-1.375 1.092-3.225 1.697-5.258 1.697-2.314 0-4.46-.842-4.46-.842v2.718c.397.116 1.048.365.635.779H5.79c-.41-.41.19-.65.644-.779V7.666c-1.053.81-1.593 1.51-1.868 2.031.32 1.02-.284.969-.284.969l-1.09-1.73s3.868-4.39 9.553-4.39zm-.19.971c-1.423-.003-3.184.473-4.27 1.244v8.646c.988.487 2.484.832 4.26.832h.01c1.596 0 2.98-.593 3.93-1.533.952-.948 1.486-2.183 1.492-3.683-.005-1.54-.504-2.864-1.42-3.86-.918-.992-2.274-1.645-4.002-1.646Z"
		></path>
	</svg>`
				case "discord":
					return `${post.user_name}#${post.ext}<i class="fab fa-fw fa-discord ml-1"></i>`
				case "twitter":
					return `@${post.user_name}<i class="fab fa-fw fa-twitter ml-1"></i>`
				case "youtube":
					return `${post.user_name}<i class="fab fa-fw fa-youtube ml-1"></i>`

			}
			return post.user_name
		},

		logout() {
			AuthService.logout(this);
		},

		toggleTranslation() {
			this.post.showTranslated = !this.post.showTranslated;
		},

		getEmbedURL(url) {
			let youtubeID = this.getYoutubeID(url);
			if (youtubeID) {
				return `https://www.youtube.com/embed/${youtubeID}`;
			}
		},

		getThumbnailURL(url) {
			let youtubeID = this.getYoutubeID(url);
			if (youtubeID) {
				return `https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`;
			}
		},

		getYoutubeID(url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			let match = url.match(regExp);

			return match && match[2].length === 11 ? match[2] : null;
		},

		showImageLightbox(post) {
			this.$emit("clickImage", post.image_url)
		},

		// Experiment with cloning images into base64, didn't work
		getURL(post) {
			if (post.image_url.substring(0, 10) == "data:image") {
				return post.image_url;
			}
			if (!Img.isSameDomain(post.image_url)) {
				return post.image_url;
			}
			let img = document.getElementById(`img-${post._id}`);
			if (!img) {
				return post.image_url;
			}
			let dataURL = Img.getBase64Image(img);
			if (!dataURL) {
				return post.image_url;
			}

			return dataURL;
		},

		showVideoLightbox(url) {
			console.log(url)
			this.$emit("clickVideo", url)
		},

		goToSignIn() {
			// Store.set(this, "returnTo", "wall");
			// this.$router.push("/login");

			$("#userDropdownButton").dropdown("toggle");
		},

		TLTooltip(post) {
			let origLanguage = "Unknown";
			if (post.language) {
				origLanguage = this.$i18n.t("languages." + post.language);
			}

			let t_origLang = this.$i18n.t("labels.original_language");
			let t_tlBy = this.$i18n.t("labels.translation_by");
			let t_toggle = this.$i18n.t("labels.click_to_toggle_translation");

			if (!post.translation) {
				return `<div>${t_origLang}: <span class="text-primary">${origLanguage}</span></div>`;
			}
			if (post.showTranslated) {
				let mtlBy = post.translation.mtl_by;
				if (post.translation && post.translation.is_mtl) {
					return `<div class="text-left"><div>${t_tlBy}: <span class="text-primary">${mtlBy}</span></div><small class="text-muted">${t_toggle}</small></div>`;
				} else {
					return `<div class="text-left"><div>${t_tlBy}: ${post.user_name}</div><small class="text-muted">${t_toggle}</small></div>`;
				}
			} else {
				return `<div class="text-left"><div>${t_origLang}: <span class="text-primary">${origLanguage}</span></div><small class="text-muted">${t_toggle}</small></div>`;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
.hamburger-menu {
	border: 4px solid black;
	border-radius: 0.5rem;
	opacity: 0.75;
}
.hamburger-icon {
	height: 40px;
}
.logo {
	margin-top: -8px;
	margin-bottom: -2px;
}
.logo-img {
	height: 80px;
}
.title {
	font-size: 72px;
	font-family: "Blender Pro";
	color: black;
	margin-top: -16px;
	margin-bottom: -16px;
	line-height: 0.8;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}
.transition-color {
	transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.message-container {
	max-width: 358px;
	overflow-x: hidden;
	margin-right: -358px;
	width: 100%;
}

.message-inner-container {
	width: 200%;
	display: flex;
	flex-direction: row;
}

.message-inner-container > div {
	width: 100%;
	transition: opacity 0.5s ease-in-out;
}

.show-message {
	order: 1;
	opacity: 1;
}

.hide-message {
	order: 2;
	opacity: 0;
}

.post-img-container {
	height: 0;
	position: relative;
	width: 100%;
	background-color: rgba(255, 0, 255, 0.02);
	overflow: hidden;
	/* padding-top should be applied manually to make this work */
}

.post-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	clip: rect(0px, 334px, 334px, 0px);
}

.half-tl-icon {
	margin-left: 2px;
	margin-bottom: -10px;
	outline: none;
	width: 19px;
	overflow: hidden;
	border-radius: 50%;
	height: 25px;
	margin-top: -3px;
}

.half-tl-icon > i {
	margin-top: -1px;
	margin-left: -17px;
}

.sticky-container {
	position: relative;
	width: 390px;
	height: 292px;
}

.sticky-note-bg {
	position: absolute;
	top: 0;
	left: -5px;
	width: 400px;
	transition: box-shadow 0.25s ease-in-out, transform 0.25s ease-in-out;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	z-index: 1;
}
.sticky-note-fg {
	position: absolute;
	top: 0;
	left: 0;
	width: 390px;
	background: linear-gradient(to right, rgb(253,242,222), rgb(253,242,222) 60px, rgb(243,232,212) 100%);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	z-index: 2;
	align-items: center;
}
.sticky-note-text {
	margin: 20px;
	position: absolute;
	left: 36px;
	column-gap: 0;
	font-size: 18px;
	font-family: seto;
	font-weight: 800;
	opacity: 0.9;
	text-shadow: 2px 2px 2px rgb(253 242 222), -2px 2px 2px rgb(253 242 222), -2px -2px 2px rgb(253 242 222), 2px -2px 2px rgb(253 242 222);
}
.sticky-note-pad-top {
	margin-top: -10px;
	padding-top: 18px;
}

.cat-watermark {
	position: absolute;
	right: 10px;
	bottom: 10px;
	opacity: 0.5;
	height: 80px;
}

.tape-top {
	height: 40px;
	position: absolute;
	top: -15px;
	left: 50%;
	transform: translate(-50%);
}

.tape-bottom {
	height: 40px;
	position: absolute;
	top: 370px;
	left: 50%;
	transform: translate(-50%);
	z-index: 100;
}

.polaroid-viewport {
	position: absolute;
	top: 19px;
    left: 28px;
    right: 28px;
    height: 334px;
	background: rgb(0,0,0);
	background: linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(60,60,60,1) 100%);
}

.polaroid-bottom-text {
	position: absolute;
    bottom: 24px;
    right: 30px;
    font-family: 'seto';
    font-weight: 800;
    font-size: 20px;
    transform: rotate(-1deg);
}

.post-details {
	position: absolute;
	text-align: left;
	width: 100%;
	left: 0;
	bottom: 0;
	padding: 10px 16px 6px 16px;
	font-size: 14px;
	font-weight: 800;
	font-family: seto;
	transition: opacity 0.25s ease-in-out;
	opacity: 0;
}
.post-details-button {
	width: 100px;
	text-align: center;
	border: 2px solid rgb(44, 62, 80);
	border-radius: 6px;
	cursor: pointer;
	user-select: none;
	padding: 0px 6px;
	margin-top: -4px;
}
.post-details-button:hover {
	box-shadow: inset 0px 0px 40px rgba(0, 0, 0, 0.2);
}

.polaroid-developing img {
	filter: blur(30px) saturate(0.5) brightness(0.75);
}
.polaroid-developing-message {
	font-family: seto;
	font-size: 18px;
	position: absolute;
	bottom: 4px;
	right: 8px;
	color: rgba(255, 255, 255, 0.5);
}
.polaroid-developing .expandable {
	cursor: not-allowed;
}

.title-text {
	color: #fff;
	text-align: center;
	font-family: gloria;
	font-weight: 800;
	font-size: 60px;
	letter-spacing: 10px;
	padding-left: 10px;

	background: -webkit-linear-gradient(white, #016922);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}

.chinese-font {
	font-family: hanyiSenty;
	font-size: 20px;
	line-height: 27px;
}

@media only screen and (max-width: 420px) {
	.polaroid-viewport {
		right: 0;
	}
}
</style>

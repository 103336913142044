<template>
	<div class="container justify-content-center pb-5">
		<div class="delu-text-large title-text">{{ $t("labels.ERROR") }}: 404</div>
		<!-- <img src="@/assets/triangle.png" class="triangle-image" /> -->
		<div class="profile-card mt-3 p-5" style="width: initial; max-width: 60%">
			<h1><i class="fas fa-exclamation mr-3" />Page not found</h1>
			<pre class="mt-3 error-block">If something should be here,<br />please contact the webmaster</pre>
			<a class="btn btn-secondary mt-3" @click="goToPath('/')">{{ $t("labels.back") }}</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "Error",

	props: [],

	components: {},

	data() {
		return {
			platformDisplay: "",
			platform: "",
			errorMsg: "",
		};
	},

	created() {},

	methods: {
		goToPath(path) {
			this.$router.push(path);
		},
	},
};
</script>

<style scoped>
.title-text {
	color: #fff;
	text-align: center;
	font-family: "lato", sans-serif;
	font-weight: 300;
	font-size: 50px;
	letter-spacing: 10px;
	padding-left: 10px;

	background: -webkit-linear-gradient(white, #016922);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}
.error-block {
	overflow-x: auto;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}
</style>
const genshinHeavy = require("@/assets/fonts/subset-GenShinGothic-Heavy.ttf")
const gloria = require("@/assets/fonts/GloriaHallelujah-Regular.ttf")
// const kiwimaru = require("@/assets/fonts/KiwiMaru-Regular.ttf")
const seto = require("@/assets/fonts/sjis_sp_setofont.ttf")
const ambrogio = require("@/assets/fonts/Ambrogio.otf")
const hanyiSenty = require("@/assets/fonts/HanyiSentyBubbleTea_Regular.ttf")

const purpleBackground = require("@/assets/images/background.png")
const diagmonds = require("@/assets/images/diagmonds-light.png")

// const birthdayBackground = require("@/assets/images/birthday/background.jpg")
const birthdaySplash = require("@/assets/images/birthday/splash.png")
const birthdayGreeting = require("@/assets/images/birthday/welcome.png")

const header = require("@/assets/images/birthday/header.png")

const triangleConfetti = require("@/assets/images/birthday/triangle_confetti.png")
const gold = require("@/assets/images/birthday/gold.jpg")

// const paperBG = require("@/assets/images/birthday/paper-bg.jpg")
const ricePaper = require("@/assets/images/birthday/rice-paper.jpg")

var themes = {
	"birthday2022": {
		key: "birthday2022",
		name: "Birthday2022",
		css: `
		@font-face {
			font-family: genshinHeavy;
			src: url(${genshinHeavy});
		}
		`
	},

	"birthday2022.old": {
		key: "birthday2022.old",
		name: "Birthday2022.old",
		css: `
		@font-face {
			font-family: genshinHeavy;
			src: url(${genshinHeavy});
		}
		`
	},
	
	"birthday2023_wall": {
		key: "birthday",
		name: "Birthday",
		splash: birthdaySplash,
		greeting: birthdayGreeting,
		css: `
		.paper {
			background-image : url(${ricePaper});
			background-repeat: tile;
			background-attachment: scroll;
		}

		.paper-bg {
			background-image : url(${ricePaper});
		}

		.note-paper-bg {
			background-image: url(${gold});
			background-repeat: tile;
		}

		.triangle-confetti-bg {
			background-repeat: tile;
		}

		.post-card-container {
			transition: transform 0.25s ease-in-out;
			z-index: 1;
		}
		.post-card-container:hover {
			transform: scale(1.05) translateY(-20px);
			z-index: 2;
		}
		.post-card-container:hover .sticky-note-bg-last {
			transform: translateY(40px) !important;
		}
		.post-card-container:hover .post-details {
			opacity: 1;
		}

		.post-card-container:hover .sticky-note-bg {
			box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 25px;
		}

		.bg-red {
			background-color: rgb(245,144,134);
		}
		.bg-blue {
			background-color: rgb(140,194,202);
		}
		.bg-yellow {
			background-color: rgb(245,227,134);
		}
		.bg-green {
			background-color: rgb(166, 217, 124);
		}

		.section-header {
			position: -webkit-sticky; /* Safari */
			position: sticky;
			top: 20px;
			z-index: 10;
			background-image: url(${header});
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			width: 77.03vw;
			height: 8vw;
			margin: 20px auto;
			max-height: 160px;
    		max-width: 1540px;
		}
		.section-header > img {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			left: 15%;
			height: 8vw;
			width: 8vw;
			max-height: 160px;
		}

		.text-center {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-family: seto;
			font-weight: 500;
			font-size: 5vw;
		}

		.video-box-section {
			width: 72vw;
			height: 40.5vw;
			margin: auto auto;
		}

		.background {
			height: 100%;
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			filter: blur(1px);
			-webkit-filter: blur(1px);
			z-index: 1;
			background: rgb(102,113,173);
			background: linear-gradient(45deg, rgb(102, 113, 173) 0%, rgb(160, 83, 166) 100%);
		}

		.fill-background {
		}

		@font-face {
			font-family: genshinHeavy;
			src: url(${genshinHeavy});
		}

		@font-face {
			font-family: gloria;
			src: url(${gloria});
		}

		@font-face {
			font-family: ambrogio;
			src: url(${ambrogio});
		}

		@font-face {
			font-family: seto;
			src: url(${seto});
		}

		@font-face {
			font-family: hanyiSenty;
			src: url(${hanyiSenty});
		}

		.wall-card {
			box-shadow: 0 0 8px 1px rgb(255 255 255 / 20%);
			background-color: rgba(200, 200, 200, 0.65);
		}

		.wall-card .card {
			border-radius: 10px;
			font-size: 1.125rem;
			background-color: rgba(255, 255, 255, 0.9);
			color: rgb(0, 0, 0);
		}
`
	},

	"birthday": {
		key: "birthday",
		name: "Birthday",
		splash: birthdaySplash,
		greeting: birthdayGreeting,
		css: `
		.paper {
			background-image : url(${ricePaper});
			background-repeat: tile;
			background-attachment: scroll;
		}

		.paper-bg {
			background-image : url(${ricePaper});
		}

		.note-paper-bg {
			background-image: url(${gold});
			background-repeat: tile;
		}

		.triangle-confetti-bg {
			background-image: url(${triangleConfetti});
			background-repeat: tile;
		}

		.post-card-container {
			transition: transform 0.25s ease-in-out;
			z-index: 1;
		}
		.post-card-container:hover {
			transform: scale(1.05) translateY(-20px);
			z-index: 2;
		}
		.post-card-container:hover .sticky-note-bg-last {
			transform: translateY(40px) !important;
		}
		.post-card-container:hover .post-details {
			opacity: 1;
		}

		.post-card-container:hover .sticky-note-bg {
			box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 25px;
		}

		.bg-red {
			background-color: rgb(245,144,134);
		}
		.bg-blue {
			background-color: rgb(140,194,202);
		}
		.bg-yellow {
			background-color: rgb(245,227,134);
		}
		.bg-green {
			background-color: rgb(166, 217, 124);
		}

		.section-header {
			position: -webkit-sticky; /* Safari */
			position: sticky;
			top: 20px;
			z-index: 10;
			background-image: url(${header});
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			width: 77.03vw;
			height: 8vw;
			margin: 20px auto;
			max-height: 160px;
    		max-width: 1540px;
		}
		.section-header > img {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			left: 15%;
			height: 8vw;
			width: 8vw;
			max-height: 160px;
		}

		.text-center {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-family: seto;
			font-weight: 500;
			font-size: 5vw;
		}

		.video-box-section {
			width: 72vw;
			height: 40.5vw;
			margin: auto auto;
		}

		.background {
			height: 100%;
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			filter: blur(1px);
			-webkit-filter: blur(1px);
			z-index: 1;
		}

		.fill-background {
		}

		@font-face {
			font-family: genshinHeavy;
			src: url(${genshinHeavy});
		}

		@font-face {
			font-family: gloria;
			src: url(${gloria});
		}

		@font-face {
			font-family: ambrogio;
			src: url(${ambrogio});
		}

		@font-face {
			font-family: seto;
			src: url(${seto});
		}

		@font-face {
			font-family: hanyiSenty;
			src: url(${hanyiSenty});
		}

		.wall-card {
			box-shadow: 0 0 8px 1px rgb(255 255 255 / 20%);
			background-color: rgba(200, 200, 200, 0.65);
		}

		.wall-card .card {
			border-radius: 10px;
			font-size: 1.125rem;
			background-color: rgba(255, 255, 255, 0.9);
			color: rgb(0, 0, 0);
		}
`
	},
	"purple": {
		key: "purple",
		name: "Purple (Sosaika)",
		header: require("@/assets/images/100k_2560.png"),
		headerWide: require("@/assets/images/100k_2560.png"),
		headerText: `
		<span class="header-10mannin">
			<span class="header-text-lg mr-2">10</span>
			<span class="header-mannin header-text-sm header-text-upper-baseline">万人</span>
		</span>
		<span class="header-omedetou-gozaimasu header-text-upper-baseline">
			<span class="header-text-sm">おめでとう</span><span class="header-text-sm header-gozaimasu">ございます</span><span class="header-text-sm header-exclamation">!</span>
		</span>
`,
		css: `
		.post-card {
			background-color: #5c008a;
			background-image: url(${diagmonds});
		}

		.fill-background {
			background: black url(${purpleBackground});
			background-size: 100% 100%;
		}

		@font-face {
			font-family: genshinHeavy;
			src: url(${genshinHeavy});
		}

		.wall-card {
			box-shadow: 0 0 8px 1px rgb(255 200 255 / 20%);
			background-color: rgba(139, 130, 164, 0.65);
		}

		.wall-card .card {
			border-radius: 10px;
			font-size: 1.125rem;
			background-color: rgba(255, 255, 255, 0.9);
			color: rgb(84, 102, 120);
		}
`,
	},
	"purplelight": {
		key: "purpledark",
		name: "Purple Light (Sosaika/Mirrored)",
		header: require("@/assets/images/t1_header.png"),
		headerText: `
		<span class="header-10mannin">
			<span class="header-text-lg mr-2">10</span>
			<span class="header-mannin header-text-sm header-text-upper-baseline">万人</span>
		</span>
		<span class="header-omedetou-gozaimasu header-text-upper-baseline">
			<span class="header-text-sm">おめでとう</span><span class="header-text-sm header-gozaimasu">ございます</span><span class="header-text-sm header-exclamation">!</span>
		</span>
`,
		css: `
		.fill-background {
			background: black url(${purpleBackground});
			background-size: 100% 100%;
		}

		@font-face {
			font-family: genshinHeavy;
			src: url(${genshinHeavy});
		}

		.wall-card {
			box-shadow: 0 0 8px 1px rgb(255 200 255 / 20%);
			background-color: rgba(139, 130, 164, 0.75);
		}

		.wall-card .card {
			border-radius: 10px;
			font-size: 1.125rem;
			background-color: rgba(255, 255, 255, 0.9);
			color: rgb(84, 102, 120);
		}
`,
	},
	"green": {
		key: "green",
		name: "Green (original)",
		css: `
			.theme-login {
				background-color: rgb(0, 150, 136);
				color: rgba(255, 255, 255, 0.85);
			}

			.theme-login-text {
				color: rgb(0, 150, 136);
			}

			.banner {
				background-color: rgb(38, 166, 154) !important;
				box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
					0 2px 10px 0 rgba(0, 0, 0, 0.12);
				height: 300px;
				position: absolute;
				width: 100%;
			}

			.logo-mono {
				height: 150px;
				opacity: 0.4;
			}
			
			/* Midbar height + header height */
			.viewer-container {
				top: calc(56px + 64px);
			}

			.assessment-details-btn {
				height: 120px;
				width: 42.2px;
				margin-bottom: -1px;
				border-radius: 0.25rem;
			}`,
	}
};

export default {
	currentTheme: null,

	getCurrent() {
		if (!this.currentTheme) {
			// Load default theme
			let theme = themes["purple"]
			return theme
		}

		return this.currentTheme
	},

	setCurrentTheme(themeName) {
		this.currentTheme = themes[themeName]
		console.log("set theme to", this.currentTheme)
	},

	getHost() {
		return window.location.host;
	},

	load(themeSettings) {
		console.log("LOAD THEME", themeSettings)
		console.log("currentTheme", this.currentTheme)
		let themeName = themeSettings.theme_name
		if (!themeName) {
			themeName = "purple"
		}
		if (this.currentTheme && this.currentTheme.key == themeName) {
			// No need to re-load the CSS
			return
		}

		let theme = themes[themeName]
		if (!theme) {
			// Load default theme
			theme = themes["purple"]
		}

		this.loadCSS(theme);
	},

	loadCSS(theme) {
		var styleElement = document.getElementById("theme-css");
		if (!styleElement) {
			var head =
				document.head || document.getElementsByTagName("head")[0];
			styleElement = document.createElement("style");
			styleElement.id = "theme-css";
			styleElement.type = "text/css";
			head.appendChild(styleElement);
		}

		var existingTextNode = styleElement.childNodes[0];
		if (existingTextNode) {
			styleElement.replaceChild(
				document.createTextNode(theme.css),
				existingTextNode
			);
		} else {
			styleElement.appendChild(document.createTextNode(theme.css));
		}

		this.current = theme
	},

	setTitle(brand) {
		console.log("Set title", brand.title);
		document.title = brand.title;
	}
};

import axios from "axios";

//FileService
export default {
	uploadFile(file, fileType, progress) {
		let url = "/v1/file"
		if (fileType) {
			url += "/" + fileType
		}

		let config = {
			headers: { "Content-Type": undefined }
		}
		if (progress) {
			config.onUploadProgress = progress
		}

		let form = new FormData();
		form.set("file", file);
		return axios.post(url, form, config);
	},

	uploadFileWithPath(file, path, progress) {
		let config = {
			headers: { "Content-Type": undefined }
		}
		if (progress) {
			config.onUploadProgress = progress
		}

		let form = new FormData();
		form.set("file", file);
		form.set("path", path);
		return axios.post(`/v1/file`, form, config);
	},

	getFileLinks() {
		return axios.get(`/v1/file_links`)
	},

	saveFileLink(fileLink) {
		return axios.post(`/v1/file_link`, fileLink)
	},

	deleteFileLink(id) {
		return axios.delete(`/v1/file_link/${id}`)
	},

	accessFileLink(path) {
		let ctx = { path: path }
		return axios.post(`/v1/file_link/access`, ctx)
	},

	accessFileLinkMember(path) {
		let ctx = { path: path }
		return axios.post(`/v1/file_link/member/access`, ctx)
	},

	getSignedURL(id, user) {
		if (user) {
			return axios.get(`/v1/file_link/${id}/member/signed_url`)
		} else {
			return axios.get(`/v1/file_link/${id}/signed_url`)
		}
	},

	downloadLink(fileLink, user) {
		let url = fileLink.url
		let doDownload = () => {
			let a = document.createElement('a')
			a.href = url
			a.download = fileLink.filename
			// a.target = "_blank"
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		}

		if (url == "restricted") {
			this.getSignedURL(fileLink.id, user).then(r => {
				console.log("SIGNED URL", r.data)
				url = r.data.signed_url
				doDownload()
			}).catch(e => {
				console.error(e)
			})
		} else {
			doDownload()
		}
	}
};
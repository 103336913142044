<template>
	<main-page hideHeader>
		<div class="container" style="min-height: 100vh">
			<div class="mb-auto"></div>
			<div class="delu-text-large title-text mt-4">DELUTAYA.CLUB</div>
			<!-- <img src="@/assets/triangle.png" class="triangle-image" /> -->
			<div class="big-triangle title-text">△</div>
			<div class="delu-text title-text mb-3">△ {{ $t("labels.under_construction") }} △</div>
			<a
				id="discord-button"
				href="/v1/auth/discord?provider=discord"
				class="btn btn-block btn-lg btn-social btn-discord mt-3"
				style="width: 300px"
			>
				<i class="fab fa-discord"></i> {{ $t("labels.sign_in_with_discord") }}
			</a>
			<a
				id="twitter-button"
				href="/v1/auth/twitter?provider=twitter"
				class="btn btn-block btn-lg btn-social btn-twitter mt-3"
				style="width: 300px"
			>
				<i class="fab fa-twitter"></i> {{ $t("labels.sign_in_with_twitter") }}
			</a>
			<!-- <a
			id="youtube-button"
			href="/v1/auth/google?provider=google"
			class="btn btn-block btn-lg btn-social btn-youtube mt-3"
			style="width: 300px"
		>
			<i class="fab fa-youtube"></i> {{ $t("labels.sign_in_with_youtube") }}
		</a> -->
			<a
				id="youtube-button"
				class="btn btn-block btn-lg btn-social btn-youtube mt-3"
				style="width: 300px"
				@click="Toast.info($t('messages.not_yet_implemented'), $t('messages.check_again'))"
			>
				<i class="fab fa-youtube"></i> {{ $t("labels.sign_in_with_youtube") }}
			</a>
			<!-- <a
			id="steam-button"
			class="btn btn-block btn-lg btn-social btn-steam mt-3"
			style="width: 300px"
			@click="Toast.info($t('messages.not_yet_implemented'), $t('messages.check_again'))"
		>
			<i class="fab fa-steam"></i> {{ $t("labels.sign_in_with_steam") }}
		</a> -->
			<div v-if="returnTo == 'submit'" class="return-card profile-card clickable mt-4" @click="goToSubmit">
				{{ $t("messages.return_to_submission_page") }}
				<i class="fas fa-undo-alt float-right mt-1" style="opacity: 0.8" />
			</div>
			<div v-if="returnTo == 'wall'" class="return-card profile-card clickable mt-4" @click="goToWall">
				{{ $t("messages.return_to_celebration_wall") }}
				<i class="fas fa-undo-alt float-right mt-1" style="opacity: 0.8" />
			</div>
			<div class="mt-4" />
			<div class="d-flex flex-row justify-content mt-auto mb-4">
				<a href="/privacy_policy" class="bare-link">{{ $t("labels.privacy_policy") }}</a>
				<a href="/tos" class="ml-5 bare-link">{{ $t("labels.terms_of_service") }}</a>
			</div>
		</div>
	</main-page>
</template>

<script>
import AuthService from "@/services/AuthService";
import Toast from "@/utils/Toast";
import Store from "@/utils/Store";

export default {
	name: "Login",

	props: [],

	components: {},

	data() {
		return {
			Toast: Toast,

			returnTo: null,
		};
	},

	created() {
		this.returnTo = Store.getDefault(this, "returnTo", null);

		AuthService.getAuth()
			.then(() => {
				this.$router.push("/members");
			})
			.catch((e) => {
				console.log("Not logged in", e);
				// Not authenticated, do nothing
			});
	},

	methods: {
		goToSubmit() {
			Store.set(this, "returnTo", null);
			this.$router.push("/submit");
		},

		goToWall() {
			Store.set(this, "returnTo", null);
			this.$router.push("/");
		},
	},
};
</script>

<style scoped>
.title-text {
	color: #fff;
	text-align: center;
	font-family: "lato", sans-serif;
	font-weight: 300;
	font-size: 50px;
	letter-spacing: 10px;
	padding-left: 10px;

	background: -webkit-linear-gradient(white, #016922);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}
.delu-text {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 24px;
	font-weight: 500;
}
.big-triangle {
	color: rgb(185, 219, 188);
	text-shadow: 0 0 20px rgb(79, 211, 89);
	font-size: 400px;
	line-height: 400px;
	opacity: 1;
	user-select: none;
	cursor: default;
	font-weight: 100;
}

@media only screen and (max-width: 550px) {
	.title-text {
		letter-spacing: 5px;
	}
	.delu-text-large {
		font-size: 36px;
	}
	.delu-text {
		letter-spacing: 2px;
	}
	.big-triangle {
		font-size: 300px;
		line-height: 300px;
	}
}
</style>
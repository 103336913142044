import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import "@/assets/css/app.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/fontawesome-all.min.css'
import "@/assets/css/wall.css";
import "@/assets/css/dossier.css"

import MainPage from "@/components/MainPage";
Vue.component("main-page", MainPage);
import BirthdayPage from "@/components/Birthday/BirthdayPage";
Vue.component("birthday-page", BirthdayPage);
import PixivIcon from "@/components/PixivIcon";
Vue.component("pixiv-icon", PixivIcon)

require("bootstrap")
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import VueToastr from "vue-toastr";
Vue.use(VueToastr);

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

import 'basiclightbox/dist/basicLightbox.min.css';

import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy, {
	arrow: true
});
Vue.component("tippy", TippyComponent);

import VueLocalStorage from "vue-localstorage";
Vue.use(VueLocalStorage, { name: "ls", namespace: "mzd" });

// require("bootstrap-social")

import en from "@/i18n/lang/en.json";
import ja from "@/i18n/lang/ja.json";
import zh from "@/i18n/lang/zh.json";

let lang = window.navigator.language;
//Cover all "en" settings so we don't get log spam if you are English (United States)
if (lang == "en-US") {
	lang = "en";
}

console.log("LANGUAGE", lang)

// lang = "ja"

const languages = {
	en: en,
	ja: ja,
	zh: zh
};
const messages = Object.assign(languages);

const dateTimeFormats = {
	'en': {
		short: {
			year: 'numeric', month: 'short', day: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		}
	},
	'ja': {
		short: {
			year: 'numeric', month: 'short', day: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
		}
	}
}


const i18n = new VueI18n({
	locale: lang,
	fallbackLocale: "en",
	silentTranslationWarn: true,
	dateTimeFormats,
	messages
});

let instance = new Vue({
	router,
	i18n,
	render: h => h(App)
}).$mount('#app')

import AuthService from "@/services/AuthService"
AuthService.init(instance)

import Toast from "@/utils/Toast"
Toast.init(instance)

import ThisVue from "@/utils/ThisVue"
ThisVue.init(instance)
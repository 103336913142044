import axios from "axios"

var mockupBaseURL = "https://delu.party"

export default {
	getPostsAnon(event) {
		if(window.mockupMode) {
			return axios.get(mockupBaseURL + `/v1/anon/posts`)
		}

		return axios.get(`/v1/anon/posts`, { params: { event: event } })
	},

	getPostsUser(event) {
		if(window.mockupMode) {
			return axios.get(mockupBaseURL + `/v1/user/posts`)
		}

		return axios.get(`/v1/user/posts`, { params: { event: event } })
	},

	getPostsSwitch(user, event) {
		if (user) {
			return this.getPostsUser(event)
		} else {
			return this.getPostsAnon(event)
		}
	},

	listPostsMod(ctx, event) {
		ctx.event = event
		if(window.mockupMode) {
			return axios.get(mockupBaseURL + `/v1/mod/posts`)
		}

		return axios.get(`/v1/mod/posts`, { params: ctx })
	},

	getPost(id) {
		if(window.mockupMode) {
			return axios.get(mockupBaseURL + `/v1/mod/post/${id}`)
		}

		return axios.get(`/v1/mod/post/${id}`)
	},

	submitPostAnon(post) {
		return axios.post(`/v1/anon/post`, post)
	},

	submitPostUser(post) {
		return axios.post(`/v1/user/post`, post)
	},

	submitPostSwitch(user, post) {
		if (user) {
			return this.submitPostUser(post)
		} else {
			return this.submitPostAnon(post)
		}
	},

	savePost(post) {
		return axios.post(`/v1/mod/post`, post)
	},

	approvePost(id) {
		return axios.post(`/v1/mod/post/${id}/approve`)
	},

	banPost(id) {
		return axios.post(`/v1/mod/post/${id}/ban`)
	},

	unbanPost(id) {
		return axios.post(`/v1/mod/post/${id}/unban`)
	},

	addTranslation(id, tl) {
		return axios.post(`/v1/mod/post/${id}/translation`, tl)
	},

	getPostCount() {
		if(window.mockupMode) {
			return axios.get(mockupBaseURL + `/v1/mod/posts/count`)
		}

		return axios.get(`/v1/mod/posts/count`)
	}
}
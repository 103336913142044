<template>
	<main-page :user="user" :refreshUser="fullRefresh">
		<div class="container d-flex flex-column h-100">
			<h1 v-if="loading"><i class="fas fa-spinner fa-pulse text-very-muted" /></h1>
			<div v-if="status" class="general-card p-0 text-lg" style="max-width: 640px">
				<template>
					<div class="d-flex flex-row">
						<div
							class="d-flex flex-row align-items-center justify-content-center b-r p-5"
							style="width: 40%; background-color: rgb(0 0 0 / 20%)"
						>
							<i
								v-if="fileLink"
								class="fas fa-4x"
								:class="{ [Format.mimeTypeIcon(fileLink.mime_type)]: true }"
							/>
							<div v-else class="error-code">
								{{ status.link_status }}
							</div>
						</div>
						<div class="py-3 px-4" style="width: 60%">
							<div v-if="fileLink" class="row">
								<div class="col-12 form-group">
									<label class="text-muted">{{ $t("labels.filename") }}</label>
									<div class="text-left" style="word-break: break-word">{{ fileLink.filename }}</div>
								</div>
								<div class="col-12 form-group">
									<label class="text-muted">{{ $t("labels.size") }}</label>
									<div class="text-left" style="word-break: break-word">
										{{ Format.fileSize(fileLink.size) }}
									</div>
								</div>
								<div class="col-12">
									<button
										v-if="status.link_status == 200"
										class="btn btn-success mt-3 mb-3"
										style="border-radius: 12px"
										@click="download"
									>
										<h5 class="mb-0">{{ $t("labels.download") }}</h5>
									</button>
									<button
										v-if="status.link_status != 200"
										class="btn btn-secondary mt-3 mb-3"
										style="border-radius: 12px"
										disabled
									>
										<h5 class="mb-0">{{ $t("labels.no_download") }}</h5>
									</button>
								</div>
							</div>
							<div
								v-if="status.link_status == 404"
								class="d-flex flex-column justify-content-center h-100 py-1"
							>
								<div>
									{{ $t("messages.link_not_found") }}
								</div>
							</div>
							<div
								v-else-if="status.link_status != 200"
								class="d-flex flex-column justify-content-center py-1 mt-3"
								style="font-size: 0.85rem"
							>
								<div v-if="(status.link_status == 403 || status.link_status == 401) && status.reason == 'members_only'">
									<p>{{ $t("messages.link_members_only_1") }}</p>
									<p>{{ $t("messages.link_members_only_2") }}</p>
									<p>{{ $t("messages.link_members_only_3") }}</p>
								</div>
								<div v-if="(status.link_status == 403 || status.link_status == 401) && status.reason == 'discord_only'">
									<p>{{ $t("messages.discord_members_only") }}</p>
									<template v-if="!user || user.discord_user_id == ''">
										<p v-if="fileLink.discord_only_snapshot">
											{{
												$t("messages.discord_members_only_snapshot_logged_out", {
													time: $d(new Date(fileLink.discord_only_at), "long"),
												})
											}}
										</p>
										<p v-else>{{ $t("messages.discord_members_only_logged_out") }}</p>
									</template>
									<p v-else>
										{{
											$t("messages.discord_members_only_snapshot_logged_in", {
												time: $d(new Date(fileLink.discord_only_at), "long"),
											})
										}}
									</p>
								</div>
								<div v-if="status.link_status == 410 && status.reason == 'limit_downloads'">
									{{ $t("messages.link_limit_downloads") }}
								</div>
								<div v-if="status.link_status == 410 && status.reason == 'expire'">
									{{ $t("messages.link_expire") }}
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</main-page>
</template>


<script>
import Toast from "@/utils/Toast";
import Format from "@/utils/Format";

import FileService from "@/services/FileService";

export default {
	name: "FileDownload",

	props: ["user"],

	components: {},

	data() {
		return {
			Format: Format,

			loading: true,

			path: null,
			fileLink: null,
			status: null,
		};
	},

	created() {
		this.path = this.$route.params.path;

		this.loadLink();
	},

	methods: {
		loadLink() {
			let call = FileService.accessFileLink;
			if (this.user) {
				call = FileService.accessFileLinkMember;
			}
			call(this.path)
				.then((r) => {
					// r.data = {
					// 	link_status: 404,
					// };
					// r.data = {
					// 	link_status: 403,
					// 	reason: "members_only",
					// 	file_link: r.data.file_link,
					// };
					// r.data = {
					// 	link_status: 403,
					// 	reason: "discord_only",
					// 	file_link: r.data.file_link,
					// };
					// r.data = {
					// 	link_status: 410,
					// 	reason: "limit_downloads",
					// 	file_link: r.data.file_link,
					// };
					// r.data = {
					// 	link_status: 410,
					// 	reason: "expire",
					// 	file_link: r.data.file_link,
					// };

					this.loading = false;
					this.status = r.data;
					if (this.status.file_link) {
						this.fileLink = this.status.file_link;
					}
				})
				.catch((e) => {
					this.loading = false;
					console.log(e);
					Toast.error("Failed to check file link", e);
				});
		},

		download() {
			if (!this.fileLink) {
				console.error("No file link");
				return;
			}

			FileService.downloadLink(this.fileLink, this.user);
		},

		fullRefresh() {
			this.$router.go();
		},
	},
};
</script>

<style scoped>
.container {
	padding-top: 48px;
	justify-content: start;
}

.error-code {
	margin-top: -12px;
	margin-bottom: -12px;

	color: #fff;
	text-align: center;
	font-family: "lato", sans-serif;
	font-weight: 800;
	font-size: 50px;
	letter-spacing: 10px;
	padding-left: 10px;

	background: -webkit-linear-gradient(white, #016922);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
	opacity: 50%;
}
</style>
import axios from "axios"

export default {
	getGroups() {
		return axios.get(`/v1/soundboard/groups`)
	},

	saveAll(groups) {
		return axios.post(`/v1/soundboard/groups`, groups)
	}
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"large-scroll-bars",staticStyle:{"overflow":"auto"}},[_c('div',{ref:"container",staticClass:"canvas",style:({ top: (_vm.topOffset + "px"), left: ((_vm.leftOffset - 2) + "px") }),on:{"mousemove":_vm.heatmapHover,"click":_vm.heatmapClick}},[_c('img',{staticClass:"corkboard-bg",style:({
        top: ("-" + _vm.topOffset + "px"),
        left: ("-" + _vm.leftOffset + "px"),
      }),attrs:{"src":require("@/assets/images/birthday2023/bd2023-corkboard-bg.png")}}),_vm._l((_vm.defs),function(def,i){return [(!def.full_url)?_c('img',{key:i,staticClass:"canvas-element",style:({
          top: ((def.top) + "px"),
          left: ((def.left) + "px"),
          width: ((def.width) + "px"),
        }),attrs:{"src":def.img_url}}):_vm._e(),(def.full_url)?_c('img',{key:i,staticClass:"canvas-element",style:({
          top: ((def.top) + "px"),
          left: ((def.left) + "px"),
          width: ((def.width) + "px"),
        }),attrs:{"src":def.img_url}}):_vm._e()]}),_c('canvas',{ref:"heatmap",staticClass:"heatmap",staticStyle:{"z-index":"5"},attrs:{"width":"3840","height":"2160"}})],2),_c('div',{staticClass:"back-button-container"},[_c('button',{staticClass:"ui-button",on:{"click":_vm.goBack}},[_vm._v(" < "+_vm._s(_vm.$t("labels.back"))+" ")])]),_c('div',{staticClass:"lightbox-overlay",class:{ show: _vm.showLightbox },on:{"click":_vm.closeModals}}),_c('div',{staticClass:"lightbox",class:{ show: _vm.showLightbox }},[(_vm.showLightbox && !_vm.showLightboxContent)?_c('loading',{staticClass:"lightbox-loading",attrs:{"color":"rgba(255 255 255 / 100%)","font":"PixelMPlus10"}}):_vm._e(),(_vm.lightboxImage)?_c('img',{staticClass:"lightbox-image",class:{ show: _vm.showLightboxContent },attrs:{"src":_vm.lightboxImage},on:{"load":_vm.lightboxImageLoaded}}):_vm._e(),(_vm.lightboxDetails)?_c('div',{staticClass:"lightbox-details",class:{ show: _vm.showLightboxContent },domProps:{"innerHTML":_vm._s(_vm.lightboxDetails)}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
	getBase64Image(img) {
		img.setAttribute('crossorigin', 'anonymous');
		var canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);
		var dataURL = canvas.toDataURL("image/png");
		return dataURL;
	},

	isSameDomain(url) {
		const path = new URL('', url)
		let pathDomain = this.getDomain(path.hostname)
		let selfDomain = this.getDomain(window.location.hostname)
		console.log(pathDomain, "vs.", selfDomain)
		return pathDomain == selfDomain
	},

	getDomain(path) {
		console.log("GET DOMAIN", path)
		let pathParts = path.split(".")
		if (pathParts.length > 2) {
			pathParts = [pathParts[pathParts.length - 2], pathParts[pathParts.length - 1]]
		}
		return pathParts.join(".")
	}
}
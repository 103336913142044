<template>
	<main-page :user="user">
		<div class="container mt-3 pt-3" style="max-width: 100vw">
			<template v-if="!loading">
				<div
					v-for="(group, i) in groups"
					:key="group._id"
					class="general-card card-width mb-3 position-relative"
				>
					<template v-if="editing">
						<button class="btn btn-danger delete-button" style="right: -10px" @click="deleteGroup(group)">
							<i class="fas fa-times" />
						</button>
						<div class="d-flex flex-row my-2 align-items-center">
							<div class="mr-1" style="min-width: 25px">EN:</div>
							<input
								class="form-control light-placeholder"
								v-model="group.name_en"
								:placeholder="group.name_ja || group.name_zh"
							/>
						</div>
						<div class="d-flex flex-row mb-2 align-items-center">
							<div class="mr-1" style="min-width: 25px">JA:</div>
							<input
								class="form-control light-placeholder"
								v-model="group.name_ja"
								:placeholder="group.name_en || group.name_zh"
							/>
						</div>
						<div class="d-flex flex-row mb-2 align-items-center">
							<div class="mr-1" style="min-width: 25px">ZH:</div>
							<input
								class="form-control light-placeholder"
								v-model="group.name_zh"
								:placeholder="group.name_en || group.name_ja"
							/>
						</div>
					</template>
					<template v-else>
						<div class="delu-text-large title-text my-3">{{ group.name_en }}</div>
					</template>
					<div class="d-flex flex-row flex-wrap mb-3 justify-content-center">
						<div v-for="(sound, j) in group.sounds" :key="j" class="sound-card-container mt-2 ml-2">
							<div class="sound-card clickable mr-2 mb-2" @click="playSound(sound, i, j)">
								<div
									class="soundboard-bg-accent"
									:style="{
										transform: `rotate(${sound.rotation}turn)`,
									}"
								>
									△
								</div>
								<div v-if="editing" :style="{ opacity: sound.playing ? 0.0001 : 1 }">
									<div class="d-flex flex-row my-2 align-items-center">
										<div class="mr-1" style="min-width: 25px">EN:</div>
										<input
											class="form-control light-placeholder"
											v-model="sound.name_en"
											:placeholder="sound.name_ja || sound.name_zh"
										/>
									</div>
									<div class="d-flex flex-row mb-2 align-items-center">
										<div class="mr-1" style="min-width: 25px">JA:</div>
										<input
											class="form-control light-placeholder"
											v-model="sound.name_ja"
											:placeholder="sound.name_en || sound.name_zh"
										/>
									</div>
									<div class="d-flex flex-row mb-2 align-items-center">
										<div class="mr-1" style="min-width: 25px">ZH:</div>
										<input
											class="form-control light-placeholder"
											v-model="sound.name_zh"
											:placeholder="sound.name_en || sound.name_ja"
										/>
									</div>
								</div>
								<div v-else :style="{ opacity: sound.playing ? 0.0001 : 1 }">
									<div class="w-100 text-center" style="font-size: 20px">{{ sound.name_en }}</div>
								</div>
								<audio
									controls
									:id="`sound-${i}-${j}`"
									class="audio-player mt-3"
									v-show="editing"
									:src="sound.url"
									@timeupdate="updateSoundProgress(sound, i, j, $event)"
								/>
							</div>
							<div
								v-show="sound.playing"
								:class="{ 'd-flex': sound.playing }"
								class="progress-overlay flex-row justify-content-center align-items-center"
							>
								<div class="flex meter animate">
									<span :id="`progress-${i}-${j}`" :style="{ width: `${sound.progress}%` }"
										><span></span
									></span>
								</div>
							</div>
							<button
								v-if="editing && !sound.playing"
								class="btn btn-danger delete-button"
								@click="deleteSound(group, sound)"
							>
								<i class="fas fa-times" />
							</button>
							<button
								v-if="sound.playing"
								class="btn btn-danger delete-button"
								@click="stopSound(sound, i, j)"
							>
								<i class="fas fa-stop" />
							</button>
						</div>
						<div v-if="!(group.sounds && group.sounds.length > 0)" class="w-100">
							<div class="text-very-muted text-center" style="font-size: 24px">No sounds :(</div>
						</div>
					</div>

					<input
						v-if="editing"
						ref="sound-select"
						class="d-none"
						type="file"
						accept="audio/*"
						@change="selectSound"
					/>
					<button
						v-if="editing && !group.uploading"
						class="btn btn-success my-2"
						@click="clickSoundSelect(group)"
					>
						<i class="fas fa-plus mr-2" />{{ $t("labels.add_sound") }}
					</button>
					<button v-if="editing && group.uploading" class="btn btn-success my-2" disabled>
						<i class="fas fa-spinner fa-pulse mr-2" />{{ $t("labels.uploading") }}
					</button>
				</div>

				<div v-if="!(groups && groups.length > 0)" class="w-100 mt-5">
					<div class="text-extra-muted text-center" style="font-size: 36px">No sounds :(</div>
				</div>
				<div v-if="user && (user.is_admin || user.is_idol)" class="footer-spacer"></div>
			</template>
			<template v-else>
				<div
					style="min-height: calc(100vh - 348px)"
					class="d-flex flex-column justify-content-center align-items-center"
				>
					<div class="subtle-border">
						<h1>{{ $t("labels.loading") }}</h1>
						<h1><i class="fas fa-spinner fa-pulse" /></h1>
					</div>
				</div>
			</template>
		</div>

		<div v-if="user && (user.is_admin || user.is_idol)" class="sticky-footer general-card d-flex flex-row">
			<button v-if="!editing" class="ml-auto btn btn-success" @click="startEditing">
				{{ $t("labels.edit_sounds") }}
			</button>
			<button v-if="editing" class="btn btn-success" @click="addGroup">
				<i class="fas fa-plus mr-2" />{{ $t("labels.add_group") }}
			</button>
			<button v-if="editing" class="ml-auto btn btn-danger" @click="cancelEditing">
				{{ $t("labels.cancel") }}
			</button>
			<button v-if="editing" class="ml-2 btn btn-primary" @click="saveAll">{{ $t("labels.save") }}</button>
		</div>

		<b-modal id="submitModal" :title="$t('labels.post_anonymously')" header-bg-variant="primary">
			<p class="m-3" style="font-size: 18px" v-html="$t('messages.post_anonymously')"></p>
			<template #modal-footer="{ _, cancel }">
				<button class="btn btn-secondary" @click="cancel">{{ $t("labels.back") }}</button>
				<button class="btn btn-outline-primary ml-3" @click="submitItem(submittingSound)">
					{{ $t("labels.post_anonymously") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="confirmModal" :title="$t('labels.post_anonymously')" header-bg-variant="primary">
			<p class="m-3" style="font-size: 18px" v-html="$t('messages.post_anonymously')"></p>
			<template #modal-footer="{ _, cancel }">
				<button class="btn btn-secondary" @click="cancel">{{ $t("labels.back") }}</button>
				<button class="btn btn-outline-primary ml-3" @click="submitItem(submittingSound)">
					{{ $t("labels.post_anonymously") }}
				</button>
			</template>
		</b-modal>
	</main-page>
</template>

<script>
import SoundboardService from "@/services/SoundboardService";
import FileService from "@/services/FileService";

import f from "@/utils/Format";
import Toast from "@/utils/Toast";
import _ from "lodash";

export default {
	name: "Soundboard",

	props: ["user"],

	components: {},

	computed: {},

	data() {
		return {
			loading: true,
			editing: false,

			groups: null,
			submittingGroup: null,
		};
	},

	created() {
		this.loadData();
	},

	watch: {},

	methods: {
		clickSoundSelect(group) {
			this.submittingGroup = group;
			if (!this.submittingGroup.sounds) {
				this.submittingGroup.sounds = [];
			}
			this.$refs["sound-select"][0].click();
		},

		selectSound(event) {
			console.log("Selected file", event.target.files);
			if (event.target.files && event.target.files[0]) {
				if (event.target.files[0].size > 5242880) {
					Toast.error(this.$i18n.t("messages.invalid_file"), this.$i18n.t("messages.file_over_5mb"));
					return;
				}

				this.$set(this.submittingGroup, "uploading", true);
				return FileService.uploadFile(event.target.files[0], "audio")
					.then((r) => {
						this.addSound(this.submittingGroup, r.data, event.target.files[0].name);
						this.submittingGroup.uploading = false;
					})
					.catch((e) => {
						this.submittingGroup.uploading = false;
						console.log(e);
						Toast.error("Failed to upload sound file", e);
					});
			} else {
				Toast.error("Error processing file");
			}
		},

		loadData() {
			SoundboardService.getGroups()
				.then((r) => {
					this.groups = r.data;
					_.each(this.groups, (group) => {
						_.each(group.sounds, (sound) => {
							sound.rotation = Math.random();
							this.$set(sound, "playing", false);
							this.$set(sound, "progress", 0);
						});
					});
				})
				.catch((e) => {
					console.log(e);
					Toast.error("Failed to get soundboard items", e);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		openSubmitSoundModal(group) {
			this.submittingGroup = group;
			this.$bvModal.show("submitModal");
		},

		submitSound(sound) {
			SoundboardService.submitSound(sound).then(() => {
				Toast.info("Sound submitted");
			});
		},

		saveAll() {
			SoundboardService.saveAll(this.groups)
				.then(() => {
					console.log("All saved");
					this.editing = false;
					this.loadData();
				})
				.catch((e) => {
					console.log(e);
					Toast.error("Failed to save soundboard", e);
				});
		},

		startEditing() {
			this.editing = true;
		},

		cancelEditing() {
			this.editing = false;
			this.loadData();
		},

		addGroup() {
			this.groups.push({
				sequence: this.groups.length,
				name_en: "",
				name_ja: "",
				name_zh: "",
				sounds: [],
			});
		},

		addSound(group, url, filename) {
			console.log(group, url, filename);
			group.sounds.push({
				sequence: group.sounds.length,
				name_en: f.normalizeFilename(filename),
				name_ja: "",
				name_zh: "",
				url: url,
				file: null,
			});
		},

		deleteSound(group, sound) {
			group.sounds = _.without(group.sounds, sound);
		},

		deleteGroup(group) {
			this.groups = _.without(this.groups, group);
		},

		playSound(sound, i, j) {
			if (this.editing) {
				return;
			}

			let el = document.getElementById(`sound-${i}-${j}`);
			if (!el) {
				console.log("Failed to find sound element", i, j);
				return;
			}

			el.play();
			sound.playing = true;

			let progress = document.getElementById(`progress-${i}-${j}`);
			progress.style.transition = `width ${el.duration}s linear`;
			sound.progress = 0;
		},

		updateSoundProgress(sound, i, j, event) {
			let el = event.target;
			let progress = el.currentTime / el.duration;
			if (sound.progress == 0) {
				let progBar = document.getElementById(`progress-${i}-${j}`);
				progBar.style.transition = `width ${el.duration - el.currentTime}s linear`;
				sound.progress = 100;
			}
			if (progress >= 0.99) {
				sound.playing = false;
				sound.progress = 0;
			}
		},

		stopSound(sound, i, j) {
			let el = document.getElementById(`sound-${i}-${j}`);
			if (!el) {
				console.log("Failed to find sound element", i, j);
				return;
			}

			el.pause();
			el.currentTime = 0;
			sound.playing = false;
			sound.progress = 0;
		},
	},
};
</script>

<style scoped>
.sticky-footer {
	position: fixed;
	bottom: -10px;
	padding-bottom: 18px;
	left: -10px;
	padding-left: 26px;
	right: -10px;
	padding-right: 26px;
	height: 62px;
}
.footer-spacer {
	height: 62px;
}

.sound-card-container {
	width: 300px;
	position: relative;
}

.sound-card {
	position: relative;
	overflow: hidden;
	padding: 1rem;
	-webkit-box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
		0 7px 8px -4px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
		0 7px 8px -4px rgba(0, 0, 0, 0.2);
	box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
		0 7px 8px -4px rgba(0, 0, 0, 0.2);
	background-color: rgb(56, 56, 56);
	/* background: -webkit-linear-gradient(to right, #237a57, #093028);
	background: linear-gradient(
		to right,
		#237a57,
		#093028
	); */
	transition: box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
		background-color 0.25s ease-in-out;
	border-radius: 10px;
}
.sound-card:hover {
	-webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 0, 0, 0.2);
	background-color: rgb(61, 61, 61);
}
.sound-card:active {
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
	background-color: rgb(56, 56, 56);
}
.delete-button {
	border-radius: 50%;
	padding: 0;
	width: 25px;
	height: 25px;
	position: absolute;
	right: 0px;
	top: -7px;
}

.audio-player {
	width: 270px;
	height: 40px;
}

.title-text {
	font-weight: 500;
	letter-spacing: 4px;
}

.soundboard-bg-accent {
	font-size: 80px;
	line-height: 80px;
	font-weight: 800;
	color: rgba(255, 255, 255, 0.01);
	text-shadow: 0 0 5px rgba(200, 255, 200, 0.1);
	position: absolute;
	top: -10px;
	right: -20px;
	z-index: 0;
	transform: rotate(0.03turn);
	transform-origin: 40px 46px;
}

.progress-overlay {
	position: absolute;
	top: 0;
	bottom: 0.5rem;
	left: 0.5rem;
	right: 1rem;
}

.meter {
	box-sizing: content-box;
	height: 20px; /* Can be anything */
	position: relative;
	background: #555;
	border-radius: 25px;
	padding: 10px;
	box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter > span {
	display: block;
	height: 100%;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: rgb(43, 194, 83);
	background-image: linear-gradient(center bottom, rgb(43, 194, 83) 37%, rgb(84, 240, 84) 69%);
	box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
	position: relative;
	overflow: hidden;
}
.meter > span:after,
.animate > span > span {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-image: linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.2) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.2) 50%,
		rgba(255, 255, 255, 0.2) 75%,
		transparent 75%,
		transparent
	);
	z-index: 1;
	background-size: 50px 50px;
	animation: move 2s linear infinite;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	overflow: hidden;
}

.animate > span:after {
	display: none;
}

.subtle-border {
	border: 3px solid rgba(255, 255, 255);
	border-radius: 2rem;
	display: inline-block;
	padding: 1rem 2rem;
	opacity: 0.2;
}

@keyframes move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -50px -50px;
	}
}

.orange > span {
	background-image: linear-gradient(#f1a165, #f36d0a);
}

.red > span {
	background-image: linear-gradient(#f0a3a3, #f42323);
}

.card-width {
	width: 1060px;
}

@media only screen and (max-width: 1080px) and (min-width: 720px) {
	.card-width {
		width: 660px;
	}
}

@media only screen and (max-width: 720px) {
	.card-width {
		max-width: 400px;
	}
}

@media only screen and (max-width: 370px) {
	.card-width {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		padding-bottom: 0rem;
	}
	.sound-card-container {
		width: 280px;
	}
}
</style>
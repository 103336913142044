<template>
	<main-page socialsOnly>
		<div class="container" style="min-height: 100vh">
			<div class="dossier">
				<div class="d-flex flex-row dossier-top mt-3">
					<div class="flex dossier-outside">
						<!-- <button class="btn btn-success mr-2" disabled>🇬🇧</button>
						<button class="btn btn-secondary">🇯🇵</button> -->
					</div>
					<div class="d-flex flex-row dossier-tab">
						<div class="dossier-tab-left"></div>
						<div class="dossier-tab-center d-flex flex-column justify-content-center">
							<div>Reference</div>
						</div>
						<div class="dossier-tab-right"></div>
						<div class="dossier-tab-right-spacer"></div>
					</div>
				</div>
				<div class="dossier-body">
					<div class="dossier-profile d-flex">
						<div class="dossier-details">
							<div class="dossier-name">DELUTAYA Δ.</div>
							<hr />
							<div>
								<div class="dossier-label">{{ $t("dossier.illustrator") }}</div>
								<div class="dossier-text"><a href="https://twitter.com/umeko299">うめこ</a></div>
							</div>
							<hr />
							<div>
								<div class="dossier-label">{{ $t("dossier.aliases") }}</div>
								<div class="dossier-text">でるたや, Δ., DELUTΔYA, Deluta, Delta, Delu</div>
							</div>
							<hr />
							<div>
								<div class="dossier-label">{{ $t("dossier.personality") }}</div>
								<div class="dossier-text">{{ $t("dossier.delutaya_personality") }}</div>
							</div>
						</div>
						<div class="main-reference">
							<img :src="mainURL" class="dossier-main-img" />
						</div>
					</div>
					<hr class="dossier-hr" />
					<div>
						<div class="text-center dossier-header">{{ $t("dossier.official_art") }}</div>
						<div class="d-flex flex-row flex-wrap justify-content-center">
							<div v-for="art in officialArts" :key="art.url">
								<img :src="art.url" class="dossier-img" />
								<div class="d-flex flex-row justify-content-center">
									<button
										class="mr-2 btn btn-primary"
										style="width: 120px"
										@click="openLightbox(art)"
									>
										<i class="fa fa-expand mr-1" />
										{{ $t("labels.expand") }}
									</button>
									<a class="lightbox-btn btn btn-info" :href="art.url" target="_blank">
										<i class="fa fa-download mr-1" />
										{{ $t("labels.download") }}
									</a>
								</div>
							</div>
						</div>
					</div>
					<hr class="dossier-hr" />
					<div>
						<div class="text-center dossier-header">{{ $t("dossier.fan_art") }}</div>
						<div class="d-flex flex-row flex-wrap justify-content-center mb-3">
							<div v-for="art in fanArts" :key="art.url">
								<img :src="art.url" class="dossier-img" />
								<div class="d-flex flex-row justify-content-center">
									<button
										class="mr-2 btn btn-primary"
										style="width: 120px"
										@click="openLightbox(art)"
									>
										<i class="fa fa-expand mr-1" />
										{{ $t("labels.expand") }}
									</button>
									<a class="lightbox-btn btn btn-info" :href="art.url" target="_blank">
										<i class="fa fa-download mr-1" />
										{{ $t("labels.download") }}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="lightbox-overlay" :class="{ show: lightboxArt }" @click="closeLightbox"></div>

		<div class="lightbox" :class="{ show: lightboxArt }" @click="closeLightbox">
			<div
				v-if="lightboxArt"
				class="d-flex flex-row align-items-center justify-content-center mt-3 mb-3"
				style="color: white"
			>
				<div class="nowrap" style="font-size: 18px">
					<i class="fab fa-fw fa-discord mr-1" />{{ lightboxArt.artist }}
				</div>
			</div>
			<img v-if="lightboxArt" :src="lightboxArt.url" draggable="false" class="dossier-image-large" />
			<div v-if="lightboxArt" class="lightbox-details mb-3 mt-3">
				<a class="lightbox-btn btn btn-info" :href="lightboxArt.url" target="_blank">
					<i class="fa fa-download mr-1" />
					{{ $t("labels.download") }}
				</a>
			</div>
		</div>
	</main-page>
</template>


<script>
import Toast from "@/utils/Toast";
import $ from "jquery";

export default {
	name: "DelutayaProfile",

	props: [],

	components: {},

	data() {
		return {
			Toast: Toast,

			lightboxArt: null,

			mainURL: "https://files.delutaya.club/file/deluclub/b8fe7df9-0386-436d-a124-d785eefdd4f1.jpeg",
			officialArts: [
				{
					url: "https://files.delutaya.club/file/deluclub/dbb65dfb-8740-4d15-a850-d6f410ed8646.jpeg",
					artist: "うめこ",
					downloadName: "delutaya-reference-うめこ-1.jpeg",
					description: "",
				},
				{
					url: "https://files.delutaya.club/file/deluclub/b8fe7df9-0386-436d-a124-d785eefdd4f1.jpeg",
					artist: "うめこ",
					downloadName: "delutaya-reference-うめこ-2.jpeg",
					description: "",
				},
			],
			fanArts: [
				{
					url: "https://files.delutaya.club/file/deluclub/6f8d1449-244d-4e19-83c8-01ba491deb9c.jpeg",
					artist: "June",
					downloadName: "delutaya-reference-june.jpeg",
					description: "",
				},
				{
					url: "https://files.delutaya.club/file/deluclub/8b1c221d-a975-4c7a-ab0e-6195f21fc8a1.jpeg",
					artist: "Tsukinonono",
					downloadName: "delutaya-reference-tsukinonono.jpeg",
					description: "",
				},
				{
					url: "https://files.delutaya.club/file/deluclub/43d607dc-18f0-4cea-8c39-3fbc807b3966.jpeg",
					artist: "Shiba Syoji",
					downloadName: "delutaya-reference-shiba_syoji.jpeg",
					description: "",
				},
				{
					url: "https://files.delutaya.club/file/deluclub/35233173-5df7-42ef-979c-1f6eeec0348a.jpeg",
					artist: "Nonca16",
					downloadName: "delutaya-reference-nonca16.jpeg",
					description: "",
				},
				{
					url: "https://files.delutaya.club/file/deluclub/a4e786fd-8d0a-4056-adfd-3aa97e8aa5c8.jpg",
					artist: "𝒊𝒄𝒆𝒘𝒊𝒏𝒆",
					downloadName: "delutaya-reference-icewine-1.jpg",
					description: "",
				},
				{
					url: "https://files.delutaya.club/file/deluclub/8b66fe58-af46-47a2-82be-50924c4379ad.jpg",
					artist: "𝒊𝒄𝒆𝒘𝒊𝒏𝒆",
					downloadName: "delutaya-reference-icewine-2.jpg",
					description: "",
				},
			],
		};
	},

	created() {},

	methods: {
		openLightbox(art) {
			console.log("openLightbox", art);
			this.lightboxArt = art;
		},

		closeLightbox() {
			this.lightboxArt = null;
		},

		download(art) {
			console.log("download", art);
			var a = document.createElement("a");
			a.href = art.url;
			a.download = art.downloadName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},

		downloadDoesntWork(art) {
			console.log("download", art);

			$.ajax({
				url: art.url,
				xhrFields: { responseType: "blob" },
				success: (data) => {
					var url = window.url || window.webkitURL;
					var path = url.createObjectURL(data);
					var a = document.createElement("a");
					a.href = path;
					a.download = art.downloadName;
					a.target = "_blank";
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				},
				error: (e) => {
					console.log(e);
					Toast.error(e);
				},
			});
		},
	},
};
</script>

<style scoped>
</style>
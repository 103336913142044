<template>
  <div
    class="d-flex flex-column h-100 fadeable"
    ref="page"
    style="overflow: hidden"
  >
    <div
      id="background"
      class="background-scene"
      style="
        z-index: -1;
        transition: transform 1s ease-out, scale 1s ease-in-out;
      "
    >
      <img
        @click="clickBackground"
        src="https://files.delutaya.club/file/deluparty/bd2023-scene2.webp"
        class="bar-bg"
        :class="{ focused: focalPoint }"
        ref="scene"
        :style="{
          left: `0px`,
          top: `0px`,
          height: `2735px`,
        }"
      />
      <div
        class="bar-button tv-skew"
        ref="tv"
        :class="{ focused: focalPoint && focalPoint.name == 'tv' }"
        @click="chooseFocus('tv')"
        :style="{
          left: `860px`,
          top: `266px`,
          height: `449px`,
        }"
      >
        <img src="https://files.delutaya.club/file/deluparty/bd2023-tv.webp" />
        <div class="tv-hitbox">
          <img
            width="100%"
            height="100%"
            :src="`https://i.ytimg.com/vi/${tvVideoId}/hq720.jpg`"
          />
        </div>
      </div>
      <div
        class="bar-button"
        ref="starmap"
        :class="{ focused: focalPoint && focalPoint.name == 'starmap' }"
        @click="chooseFocus('starmap')"
        :style="{
          left: `1564px`,
          top: `610px`,
          height: `417px`,
        }"
      >
        <img
          src="https://files.delutaya.club/file/deluparty/bd2023-starmap.webp"
        />
      </div>
      <div
        class="bar-button"
        ref="pinboard"
        :class="{
          focused: focalPoint && focalPoint.name == 'pinboard',
        }"
        @click="chooseFocus('pinboard')"
        :style="{
          left: `4693px`,
          top: `35px`,
          height: `476px`,
        }"
      >
        <img
          src="https://files.delutaya.club/file/deluparty/bd2023-pinboard-filled.webp"
        />
      </div>
      <div
        class="bar-button arcade-skew"
        ref="arcade"
        :class="{ focused: focalPoint && focalPoint.name == 'arcade' }"
        @click="chooseFocus('arcade')"
        :style="{
          left: `4721px`,
          top: `560px`,
          height: `2175px`,
        }"
      >
        <img
          src="https://files.delutaya.club/file/deluparty/bd2023-arcade.webp"
        />
        <div class="arcade-hitbox text-center">
          <!-- <img width="100%" height="100%" src="https://i.ytimg.com/vi/ZML5upxuLgU/hq720.jpg"> -->
          <div class="arcade-image">
            <img src="@/assets/images/dot_taya.png" />
          </div>
          <div class="game-link" @click="openDeltaDash">
            <i class="game-link-selector fas fa-caret-right" />Delta Dash
          </div>
          <div class="game-credit">By Kiiro</div>
          <div class="game-link mt-2" @click="openDrive">
            <i class="game-link-selector fas fa-caret-right" />Δ Drive
          </div>
          <div class="game-credit">By RatzKlaw</div>
        </div>
        <img
          class="arcade-joystick"
          src="https://files.delutaya.club/file/deluparty/bd2023-joystick.webp"
        />
      </div>
      <img
        src="https://files.delutaya.club/file/deluparty/bd2023-delu.webp"
        class="bar-fg"
        ref="delu"
        :style="{
          left: `287px`,
          top: `595px`,
          height: `1337px`,
        }"
      />
      <img
        src="https://files.delutaya.club/file/deluparty/bd2023-twinkle.webp"
        class="bar-bg twinkle"
        ref="twinkle"
        :style="{
          left: `749px`,
          top: `1003px`,
          height: `166px`,
        }"
      />
    </div>
    <div v-if="debug">
      <div
        v-for="(point, i) in focalPoints"
        :key="`inner-${i}`"
        class="focal-point"
        :style="{
          left: `${
            (point.left + point.width / 2 - point.innerRadius) * squishX
          }px`,
          top: `${
            (point.top + point.height / 2 - point.innerRadius) * squishY
          }px`,
          width: `${point.innerRadius * 2 * squishX}px`,
          height: `${point.innerRadius * 2 * squishY}px`,
          borderColor: `red`,
        }"
      ></div>
      <div
        v-for="(point, i) in focalPoints"
        :key="`outer-${i}`"
        class="focal-point"
        :style="{
          left: `${
            (point.left + point.width / 2 - point.outerRadius) * squishX
          }px`,
          top: `${
            (point.top + point.height / 2 - point.outerRadius) * squishY
          }px`,
          width: `${point.outerRadius * 2 * squishX}px`,
          height: `${point.outerRadius * 2 * squishY}px`,
          borderColor: `purple`,
        }"
      ></div>
      <div
        v-for="(point, i) in focalPoints"
        :key="`hitbox-${i}`"
        class="focal-hitbox"
        :style="{
          left: `${point.left * squishX}px`,
          top: `${point.top * squishY}px`,
          width: `${point.width * squishX}px`,
          height: `${point.height * squishY}px`,
          borderColor: `green`,
        }"
      ></div>
    </div>
    <!-- Content -->
    <div
      v-if="overlay"
      @click="clickZoomedOverlay"
      class="flex d-flex flex-column justify-content-end align-items-end text-white"
    >
      <!-- <div class="buttons-container">
				<button class="ui-button" @click="loadLightboxVideo('https://www.youtube.com/embed/L5p2OwFCObw')">
					{{ $t("labels.video") }}
				</button>
				<div class="button-spacer"></div>
				<button class="ui-button" @click="goToCanvas">{{ $t("labels.canvas") }}</button>
				<div class="button-spacer"></div>
				<button
					class="ui-button"
					@click="
						loadLightboxImage(
							'https://files.delutaya.club/file/deluparty/magicaldraw_20220807_162957.webp '
						)
					"
				>
					{{ $t("labels.community_canvas") }}
				</button>
				<div class="button-spacer"></div>
				<button class="ui-button" @click="goToTelescope">{{ $t("labels.telescope") }}</button>
			</div>

			<div class="credits-container">
				<div>Art: June (@junwei661)</div>
				<div>Website: Mirrored (@Mirrormn)</div>
			</div> -->
      <!-- <div v-if="focalPoint">Focus: {{focalPoint.name}}</div>
            <div>Distance: {{distance}}</div>
            <div>Closeness: {{closeness}}</div> -->
    </div>

    <div
      class="lightbox-overlay"
      :class="{ show: showLightbox }"
      @click="closeModals"
    ></div>

    <div class="lightbox" :class="{ show: showLightbox }">
      <loading
        v-if="showLightbox && !showLightboxContent"
        class="lightbox-loading"
        color="rgba(255 255 255 / 100%)"
        font="PixelMPlus10"
      />
      <div v-if="lightboxImage" class="lightbox-image-container">
        <img
          v-if="lightboxImage"
          :src="lightboxImage"
          class="lightbox-image zoomable"
          :class="{ show: showLightboxContent }"
          @load="lightboxImageLoaded"
          @click="zoom"
        />
      </div>
      <iframe
        v-if="lightboxVideo"
        :src="lightboxVideo"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="lightbox-video"
        :class="{ show: showLightboxContent }"
        @load="lightboxVideoLoaded"
        width="1120"
        height="730"
      ></iframe>
      <div
        v-if="lightboxDetails"
        class="lightbox-details"
        :class="{ show: showLightboxContent }"
        v-html="lightboxDetails"
      ></div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Birthday/Loading";
import _ from "lodash";

const sceneWidth = 5750;
const sceneHeight = 2735;
const sceneAspect = sceneWidth / sceneHeight;

const tvVideoId = "JqAjtgOSOfE";

const focalPoints = [
  {
    name: "tv",
    left: 860,
    top: 266,
    width: 674,
    height: 449,
    innerRadius: 400,
    outerRadius: 1200,
  },
  {
    name: "starmap",
    left: 1564,
    top: 610,
    width: 733,
    height: 417,
    innerRadius: 500,
    outerRadius: 1200,
  },
  {
    name: "pinboard",
    left: 4693,
    top: 35,
    width: 844,
    height: 476,
    innerRadius: 600,
    outerRadius: 1500,
  },
  {
    name: "arcade",
    left: 4873,
    top: 665,
    width: 640,
    height: 930,
    innerRadius: 550,
    outerRadius: 1500,
  },
];

import Theme from "@/utils/Theme";

export default {
  name: "BirthdayHome",

  props: [],

  components: { Loading },

  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },

  beforeRouteEnter(to, from, next) {
    console.log("BEFORE ENTER ROUTE", to, from);
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  data() {
    return {
      prevRoute: null,
      tvVideoId: tvVideoId,

      focalPoints: focalPoints,
      theme: Theme.getCurrent(),
      user: null,

      debug: false,
      overlay: false,

      refreshBG: null,

      redirectingTo: "",

      showLightbox: false,
      showMenu: false,
      showCredits: false,
      lightboxImage: null,
      lightboxVideo: null,
      lightboxDetails: null,
      showLightboxContent: false,

      squishX: 1,
      squishY: 1,

      focalPoint: null,
      distance: 0,
      closeness: 0,
      currentMouseX: 0,
      currentMouseY: 0,
    };
  },

  created() {
    this.loadData();
    window.addEventListener("resize", this.calcSceneView);
    window.addEventListener("mousemove", this.mouseMoved);
    window.addEventListener("mousedown", this.mouseDown);
    document.documentElement.style.overflow = "hidden";

    console.log("focalPoints", focalPoints);
  },

  destroyed() {
    window.removeEventListener("resize", this.calcBg);
    window.removeEventListener("mousemove", this.mouseMoved);
    window.removeEventListener("mousedown", this.mouseDown);
    document.documentElement.style.overflow = "auto";
  },

  mounted() {
    console.log("FROM", this.prevRoute);
    if (this.prevRoute) {
      if (this.prevRoute.path == "/pinboard") {
        this.focalPoint = _.find(focalPoints, { name: "pinboard" });
        setTimeout(() => {
          this.clickZoomedOverlay();
        }, 100);
      }
      if (this.prevRoute.path == "/telescope") {
        this.focalPoint = _.find(focalPoints, { name: "starmap" });
        setTimeout(() => {
          this.clickZoomedOverlay();
        }, 100);
      }
    }
    this.calcSceneView();
  },

  unmounted() {},

  watch: {},

  methods: {
    loadData() {
      // do nothing
    },

    mouseMoved(event) {
      if (this.focalPoint) {
        return;
      }
      this.calcSceneView(event.clientX, event.clientY);
    },

    clickBackground() {
      if (this.focalPoint) {
        this.clickZoomedOverlay();
      }
    },

    clickZoomedOverlay() {
      this.overlay = false;
      this.focalPoint = null;
      this.calcSceneView(this.currentMouseX, this.currentMouseY);
    },

    chooseFocus(name) {
      let chosenFocus = null;
      for (let fp of focalPoints) {
        if (fp.name == name) {
          chosenFocus = fp;
        }
      }

      if (!chosenFocus) {
        return;
      }
      if (this.focalPoint && this.focalPoint.name == chosenFocus.name) {
        // already on/moving to the chosen focus, so do nothing
        return;
      }

      this.focalPoint = chosenFocus;
      this.overlay = true;

      this.calcSceneView(this.currentMouseX, this.currentMouseY);
      let el = this.$refs[name];
      if (el) {
        el.focus();
      } else {
        console.error(`No element found for "${name}"`);
        return;
      }

      let animTime = 800;
      switch (name) {
        case "tv":
          setTimeout(() => {
            this.loadLightboxYoutubeEmbed(this.tvVideoId);
          }, animTime);
          break;
        case "starmap":
          setTimeout(() => {
            this.$refs.page.classList.add("fade-out");
          }, 400);
          setTimeout(() => {
            this.$router.push("/telescope");
          }, 1400);
          break;
        case "pinboard":
          setTimeout(() => {
            this.$refs.page.classList.add("fade-out");
          }, 400);
          setTimeout(() => {
            this.$router.push("/pinboard");
          }, 1400);
          break;
        case "arcade":
          setTimeout(() => {
            this.overlay = false;
          }, animTime);
          break;
      }
    },

    calcSceneView(mouseX, mouseY) {
      this.currentMouseX = mouseX;
      this.currentMouseY = mouseY;
      let w = window.innerWidth;
      let h = window.innerHeight;
      this.squishX = w / sceneWidth;
      this.squishY = h / sceneHeight;
      let a = w / h;

      // Adjust scale for focus zoom
      let maxScaleFactor = 0;
      if (a > sceneAspect) {
        // Horizontal is the constraining dimension
        maxScaleFactor = w / sceneWidth;
      } else {
        // Vertical is the constraining dimension
        maxScaleFactor = h / sceneHeight;
      }
      let { focusZoom, focusX, focusY } = this.getFocusZoom(
        maxScaleFactor,
        w,
        h
      );
      console.log("focusZoom", focusZoom, "focusX", focusX, "focusY", focusY);
      this.sceneScale = focusZoom;

      let translateX = 0;
      let translateY = 0;
      if (focusX && focusY) {
        translateX = focusX - (0.5 * w) / this.sceneScale;
        translateY = focusY - (0.5 * h) / this.sceneScale;
        if (translateX < 0) {
          translateX = 1;
        }
        if (translateY < 0) {
          translateY = 1;
        }
        if (translateX + w / this.sceneScale > sceneWidth) {
          translateX = sceneWidth - w / this.sceneScale;
        }
        if (translateY + h / this.sceneScale > sceneHeight) {
          translateY = sceneHeight - h / this.sceneScale;
        }
        console.log("TRANSLATE X", translateX);
        console.log("TRANSLATE Y", translateY);
      } else {
        if (mouseX == undefined) {
          mouseX = w / 2;
        }
        if (mouseY == undefined) {
          mouseY = h / 2;
        }

        mouseX = mouseX * 1.35;
        mouseX = mouseX - w * 0.1;
        if (mouseX > w) {
          mouseX = w;
        }
        if (mouseX < 0) {
          mouseX = 0;
        }

        mouseY = mouseY * 1.5;
        mouseY = mouseY - h * 0.35;
        if (mouseY > h) {
          mouseY = h;
        }
        if (mouseY < 0) {
          mouseY = 0;
        }

        // Slide scene to fit in viewport
        let ratioX = mouseX / w;
        let ratioY = mouseY / h;

        translateX = sceneWidth - w / this.sceneScale;
        translateY = sceneHeight - h / this.sceneScale;
        translateX = translateX * ratioX;
        translateY = translateY * ratioY;
      }

      let el = document.getElementById("background");
      if (!el) {
        return;
      }
      el.style.scale = `${this.sceneScale}`;
      el.style.transform = `translate(-${translateX}px, -${translateY}px)`;
    },

    getFocusZoom(maxScaleFactor, w, h) {
      if (!this.focalPoint) {
        return { focusZoom: maxScaleFactor };
      }
      let a = w / h;
      let focalAspect = this.focalPoint.width / this.focalPoint.height;
      let focusX = this.focalPoint.left + this.focalPoint.width / 2;
      let focusY = this.focalPoint.top + this.focalPoint.height / 2;

      let minScaleFactor = 0;
      if (a > focalAspect) {
        // Viewport is wider than focal area, so fit height within height
        minScaleFactor = h / 930;
      } else {
        // Viewport is taller than focal area, so fit width within width
        minScaleFactor = w / 844;
      }

      if (maxScaleFactor < minScaleFactor) {
        // let zoomedScaleFactor = maxScaleFactor + ((minScaleFactor - maxScaleFactor) * (closeness * closeness * 0.5))
        return {
          focusZoom: minScaleFactor,
          focusX: focusX,
          focusY: focusY,
        };
      }

      return {
        focusZoom: maxScaleFactor,
        focusX: focusX,
        focusY: focusY,
      };
    },

    getClosestFocalPoint(x, y) {
      x = x / this.squishX;
      y = y / this.squishY;
      let closeness = 0;
      let leastDistance = 1000000;
      let point = null;
      let maxWidth = 0;
      let maxHeight = 0;
      for (let i in focalPoints) {
        let fp = focalPoints[i];
        let centerX = fp.left + fp.width / 2;
        let centerY = fp.top + fp.height / 2;
        let distance = this.pointDistance(x, y, centerX, centerY);
        if (distance < leastDistance) {
          leastDistance = distance;
          point = focalPoints[i];
        }
        if (fp.width > maxWidth) {
          maxWidth = fp.width;
        }
        if (fp.height > maxHeight) {
          maxHeight = fp.height;
        }
      }

      if (!point) {
        return {};
      }

      if (leastDistance < point.innerRadius) {
        closeness = 1;
      } else if (leastDistance < point.outerRadius) {
        closeness =
          (point.outerRadius - leastDistance) /
          (point.outerRadius - point.innerRadius);
      }

      this.focalPoint = point;
      this.distance = leastDistance;
      this.closeness = closeness;

      return {
        focalPoint: point,
        distance: leastDistance,
        closeness: closeness,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
      };
    },

    pointDistance(x1, y1, x2, y2) {
      return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    },

    loadLightboxImage(url, details) {
      this.lightboxImage = url;
      this.lightboxDetails = details;
      this.lightboxVideo = null;
      this.showLightboxContent = false;
      this.$nextTick(() => {
        this.showLightbox = true;
      });
    },

    loadLightboxVideo(url) {
      this.lightboxImage = null;
      this.lightboxDetails = null;
      this.lightboxVideo = url;
      this.showLightboxContent = false;
      this.$nextTick(() => {
        this.showLightbox = true;
      });
    },

    loadLightboxYoutubeEmbed(videoID) {
      let url = `https://www.youtube.com/embed/${videoID}`;
      this.loadLightboxVideo(url);
    },

    lightboxImageLoaded(e) {
      console.log("LOADED IMAGE", e);
      setTimeout(() => {
        this.showLightboxContent = true;
      }, 100);
    },

    lightboxVideoLoaded(e) {
      console.log("LOADED IMAGE", e);
      setTimeout(() => {
        this.showLightboxContent = true;
      }, 100);
    },

    closeModals() {
      this.showLightbox = false;
      this.lightboxImage = null;
      this.lightboxVideo = null;
      this.showLightboxContent = false;
      if (!(this.focalPoint && this.focalPoint.name == "arcade")) {
        this.clickZoomedOverlay();
      }
    },

    zoom(event) {
      console.log("click event", event);
      let el = event.target;

      let x = event.offsetX;
      let y = event.offsetY;
      let w = el.offsetWidth;
      let h = el.offsetHeight;
      let rx = x / w;
      let ry = y / h;

      this.$nextTick(() => {
        this.centerAfterZoom(rx, ry, el);
      });

      el.classList.toggle("zoomed");
    },

    centerAfterZoom(rx, ry, el) {
      let w = el.parentNode.offsetWidth;
      let h = el.parentNode.offsetHeight;
      console.log("inputs", w, h, rx, ry, el);
      console.log("scrollwh", el.scrollWidth, el.scrollHeight);
      let cx = w / 2;
      let cy = h / 2;
      let tx = el.scrollWidth * rx;
      let ty = el.scrollHeight * ry;
      let dx = 0;
      let dy = 0;
      if (tx > cx) {
        dx = tx - cx;
      }
      if (ty > cy) {
        dy = ty - cy;
      }
      console.log("dx dy", dx, dy);
      el.parentNode.scroll(dx, dy);
    },

    goToCanvas() {
      this.$router.push("/canvas2022");
    },

    goToTelescope() {
      this.$refs.splashBack.classList.add("bg-zoom");
      this.$refs.splashFront.classList.add("fg-zoom");
      this.$refs.page.classList.add("fade-out");
      setTimeout(() => {
        this.$router.push("/telescope");
      }, 1000);
    },

    openDeltaDash() {
      this.loadLightboxVideo("2023games/delta_dash/Delta Dash.html");
    },

    openDrive() {
      this.loadLightboxVideo("2023games/drive/Drive.html");
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "PixelMPlus10";
  src: url("/fonts/PixelMplus10-Regular.ttf");
}

.bar-bg {
  position: absolute;
  bottom: 0;
  transition: transform 1s ease-in, scale 2s ease-in-out;
}
.bg-zoom {
  transform: scale(1.2);
}
.fg-zoom {
  transform: scale(2);
}
.fadeable {
  opacity: 1;
  transition: opacity 1s ease-in;
}
.fadeable.fade-out {
  opacity: 0;
}

.content-box {
  background-color: black;
  position: relative;
  margin-top: 2rem;
  width: 80%;
  max-width: 800px;
  padding: 1rem;
  overflow: hidden;
}
.content-box-bg {
  position: absolute;
  width: 400px;
  top: -80px;
  left: -80px;
  opacity: 0.15;
}

.green-border {
  border-top: 3px solid rgb(10, 208, 10);
}

.header-center {
  width: 80%;
  max-width: 800px;
  display: flex;
}

.header-icon > img {
  height: 64px;
  width: 64px;
  border-radius: 50%;
}
.header-icon {
  padding: 6px;
  border: 3px solid rgb(10, 208, 10);
  border-radius: 50%;
}
.header-top {
  position: relative;
  border-bottom: 3px solid rgb(10, 208, 10);
  font-weight: 900;
  font-size: 40px;
  font-family: tahoma;
  text-transform: capitalize;
  line-height: 48px;
}
.header-bottom {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.75;
}

.left-header {
  text-align: left;
  align-self: left;
  padding-left: 4px;
}
.left-header .header-icon {
  position: absolute;
  right: calc(100% - 2px);
}
.left-header .header-top {
  margin-left: -20px;
  padding-left: 20px;
}
.left-header .header-bottom {
  padding-left: 2px;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.25s;
  backdrop-filter: blur(6px);
  pointer-events: none;
  z-index: 30;
}

.lightbox-overlay.show {
  opacity: 1;
  pointer-events: all;
  cursor: zoom-out;
}

.bar-bg.focused {
  cursor: zoom-out;
}

.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 31;
  pointer-events: none;
  padding: 60px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lightbox.show {
  display: flex;
  pointer-events: none;
}

.lightbox-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.lightbox-image-container {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  pointer-events: all;
}
.lightbox-image-container::-webkit-scrollbar-corner {
  background: transparent;
}
.lightbox-image {
  opacity: 0;
  transition: opacity 0.45s;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
  background-color: #000;
}
.lightbox-image.show {
  opacity: 1;
  pointer-events: all;
}
.lightbox-image.zoomed {
  max-width: initial;
  max-height: initial;
  height: initial;
}

.lightbox-video {
  opacity: 0;
  transition: opacity 0.45s;
  max-width: 100%;
  max-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
  background-color: #000;
}
.lightbox-video.show {
  opacity: 1;
  pointer-events: all;
}

.lightbox-details {
  opacity: 0;
  transition: opacity 0.45s;
  background: linear-gradient(
    to right,
    rgb(253, 242, 222),
    rgb(253, 242, 222) 60px,
    rgb(223, 212, 192) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
  padding: 10px 16px;
  font-size: 24px;
  font-family: seto;
  font-weight: 800;
  margin-top: 20px;
}
.lightbox-details.show {
  opacity: 1;
  pointer-events: all;
}

.buttons-container {
  margin-top: 30px;
  margin-right: 30px;
  font-family: PixelMPlus10;
  display: flex;
  flex-direction: column;
}

.ui-button {
  padding: 0rem 1rem;
  font-size: 24px;
  border: 2px solid rgba(255 255 255 / 75%);
  color: rgba(255 255 255 / 75%);
  background-color: transparent;
  transition: all 0.15s;
  filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
    drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
  text-shadow: 0px 0px 1px rgba(255 255 255 / 75%),
    0px 0px 2px rgba(255 255 255 / 50%);
  backdrop-filter: brightness(0.65);
}
.ui-button:hover {
  filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 50%))
    drop-shadow(0px 0px 6px rgba(255 255 255 / 20%));
  border: 2px solid rgba(255 255 255 / 40%);
}
.button-spacer {
  border-left: 2px solid rgba(255 255 255 / 75%);
  filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
    drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
  width: 2px;
  height: 16px;
  align-self: center;
}

.credits-container {
  text-align: right;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: rgba(255 255 255 / 75%);
  font-family: PixelMPlus10;
  filter: drop-shadow(1px 1px 2px rgba(0 0 0 / 100%))
    drop-shadow(0px 0px 3px rgba(255 255 255 / 55%))
    drop-shadow(0px 0px 4px rgba(255 255 255 / 50%));
}

.focal-point {
  position: absolute;
  border: 2px solid;
  border-radius: 50%;
}
.focal-hitbox {
  position: absolute;
  border: 2px solid;
  background-color: rgba(0 255 0 / 10%);
}

.bar-button {
  z-index: 1;
  position: absolute;
  transition: scale 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  cursor: pointer;
}
.bar-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  box-shadow: inset 0 0 50px #fff, /* inner white */ inset 20px 0 80px #f0f,
    /* inner left magenta short */ inset -20px 0 80px #0ff,
    /* inner right cyan short */ inset 20px 0 300px #f0f,
    /* inner left magenta broad */ inset -20px 0 300px #0ff,
    /* inner right cyan broad */ 0 0 50px #fff,
    /* outer white */ -10px 0 80px #f0f,
    /* outer left magenta */ 10px 0 80px #0ff; /* outer right cyan */
  transition: opacity 0.3s ease-in-out;
}
.bar-button::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0.5;
  box-shadow: inset 0 0 25px #fff, /* inner white */ inset 10px 0 20px #f0f,
    /* inner left magenta short */ inset -10px 0 20px #0f0,
    /* inner right cyan short */ inset 10px 0 150px #f0f,
    /* inner left magenta broad */ inset -10px 0 150px #0f0,
    /* inner right cyan broad */ 0 0 25px #fff,
    /* outer white */ -5px 0 40px #f0f, /* outer left magenta */ 5px 0 40px #0f0; /* outer right cyan */
  transition: opacity 0.3s ease-in-out;
  animation: light-scale-pulse 1.8s ease-in-out infinite;
}
@keyframes light-opacity-pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes light-scale-pulse {
  0% {
    scale: 0.99;
  }
  50% {
    scale: 1.02;
  }
  100% {
    scale: 0.99;
  }
}
.bar-button:hover,
.bar-button.focused {
  scale: 1.05;
}
.bar-button:hover::before,
.bar-button.focused::before {
  opacity: 0.75;
}
.bar-button:hover::after,
.bar-button.focused::after {
  opacity: 0;
}
.bar-fg {
  position: absolute;
  pointer-events: none;
  z-index: 3;
}

.bar-button * {
  pointer-events: none;
}

.green-hitbox {
  background-color: rgba(0 255 0 / 20%);
}
.tv-skew::before,
.tv-skew::after {
  left: 21px;
  top: 22px;
  width: 630px;
  height: 404px;
  transform: skew(-6.6deg, 4.2deg);
}
.tv-hitbox {
  position: absolute;
  left: 61px;
  top: 65px;
  width: 558px;
  height: 324px;
  transform: skew(-6.6deg, 4.2deg);
  border-radius: 7px;
}

.arcade-skew {
  perspective: 1000px;
}
.arcade-skew::before,
.arcade-skew::after {
  z-index: 2;
  position: absolute;
  left: 276px;
  top: 300px;
  width: 397px;
  height: 506px;
  perspective: 1000px;
  transform: skew(-19deg, 4.5deg) rotateX(11.5deg) rotateY(-6deg);
  transform-style: preserve-3d;
}
.arcade-skew.focused {
  cursor: initial;
  pointer-events: none;
}
.arcade-hitbox {
  z-index: 3;
  position: absolute;
  left: 276px;
  top: 300px;
  width: 397px;
  height: 506px;
  perspective: 1000px;
  transform: skew(-19deg, 4.5deg) rotateX(11.5deg) rotateY(-6deg);
  transform-style: preserve-3d;
  background-color: rgba(0 0 0 / 75%);
  backdrop-filter: blur(2px);
  border-radius: 4px;
  pointer-events: none;
}
.arcade-skew.focused .arcade-hitbox {
  pointer-events: all;
}
.arcade-hitbox div {
  color: rgba(255 255 255 / 50%);
  text-shadow: 0px 0px 6px rgba(255 255 255 / 75%),
    0px 0px 12px rgba(255 255 255 / 50%);
  font-family: PixelMPlus10;
}
.arcade-skew.focused .arcade-hitbox .game-link {
  pointer-events: all;
  cursor: pointer;
}
.game-link {
  font-size: 40px;
}
.game-link-selector {
  margin-left: -20px;
  margin-right: 8px;
  opacity: 0;
}
.game-link:hover {
  background: linear-gradient(
    45deg,
    rgb(44, 199, 1) 0%,
    rgb(140, 73, 145) 100%
  );
  background-size: 25% 50%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-animation: TextGradient 10s infinite;
  -moz-animation: TextGradient 10s infinite;
  animation: TextGradient 10s infinite;
}

@-webkit-keyframes TextGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes TextGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes TextGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.game-link:hover .game-link-selector {
  opacity: 1;
}
.game-credit {
  margin-top: -10px;
  font-size: 24px;
}

.bar-button:hover .arcade-hitbox,
.bar-button.focused .arcade-hitbox {
  box-shadow: inset 0 0 50px rgba(255 255 255 / 50%); /* inner white */
}
.arcade-joystick {
  position: absolute;
  top: 778px;
  left: 154px;
  z-index: 3;
}
.arcade-image {
  margin-top: 30px;
  margin-bottom: 10px;
}
.arcade-image > img {
  height: 200px;
  filter: drop-shadow(0px 0px 6px rgba(255 255 255 / 75%));
  margin-top: 20px;
  margin-bottom: -20px;
}

.twinkle {
  z-index: 3;
}
.twinkle:hover {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

@keyframes opacity-pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.1;
  }
}
</style>

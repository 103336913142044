<template>
	<div>
        <!-- Streams -->
        <div v-for="(stream, i) in schedule.streams" class="row position-relative" :key="i">
            <hr class="w-100 my-3" v-if="i != 0" />
            <div class="col-12 d-flex flex-row align-items-center">
                <h5 class="mr-2 mb-0">{{ i + 1 }}.</h5>
                <div class="flex row">
                    <div class="col-7" style="margin-top: 1px; margin-bottom: 1px; padding-right: 1px;">
                        <b-form-datepicker
                            :class="{'is-invalid': !stream.date}"
                            v-model="stream.date"
                            locale="ja"
                            placeholder="Date"
                            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                        ></b-form-datepicker>
                    </div>
                    <div class="col-5" style="margin-bottom: 1px; margin-top: 1px; padding-left: 1px;">
                        <b-form-timepicker
                            :class="{'is-invalid': !stream.time}"
                            v-model="stream.time"
                            locale="ja"
                            placeholder="Time"
                            hourCycle="h23"
                        ></b-form-timepicker>
                        <button
                            class="btn btn-danger delete-button"
                            @click="removeStream(stream)"
                        >
                            <i class="fas fa-times" />
                        </button>
                    </div>
                    
                    <div class="col-5 col-md-7" style="margin-bottom: 1px; margin-top: 1px; padding-right: 1px;">
                        <input :placeholder="$t('labels.archive_link')" class="form-control" v-model="stream.archive_link">
                    </div>
                    <div class="col-7 col-md-5 d-flex flex-row" style="margin-bottom: 1px; margin-top: 1px; padding-left: 1px;">
                        <v-select
                            class="flex"
                            v-model="stream.platform"
                            :options="platforms"
                            :reduce="(o) => o.value"
                            :clearable="false"
                            :searchable="false"
                        >
                            <template #option="{ label, value }">
                                <span><i v-if="getPlatformIcon(value)" class="fa-fw mr-1" :class="{[getPlatformIcon(value)]: true}" />{{ label }}</span>
                            </template>
                            <template #selected-option="{ label, value }">
                                <span><i v-if="getPlatformIcon(value)" class="fa-fw mr-1" :class="{[getPlatformIcon(value)]: true}" />{{ label }}</span>
                            </template>
                        </v-select>
						<b-form-checkbox
                            class="ml-1"
							v-model="stream.fanbox_only"
							name="check-button"
							button
							:button-variant="stream.fanbox_only ? 'primary' : ''"
						>
							<div
								class="d-flex flex-row align-items-center"
								style="margin-left: -4px; margin-right: -4px"
                                v-tippy
                                title="Fanbox Only"
							>
								🔒<i
									class="ml-1 fa"
									:class="{
										'fa-check': stream.fanbox_only,
										'fa-times-circle': !stream.fanbox_only,
										'text-very-muted': !stream.fanbox_only,
									}"
									style="min-width: 15px"
								/>
							</div>
						</b-form-checkbox>
                    </div>
                </div>
            </div>

            <table class="w-100" style="margin-left: 15px; margin-right: 15px;">
                <tr>
                    <td style="width: 100px;">Name (JP)</td>
                    <td><input :class="{'is-invalid': !stream.date}" :placeholder="$t('labels.stream_title')" class="form-control" v-model="stream.name"></td>
                </tr>
                <tr>
                    <td style="width: 100px;">Name (EN)</td>
                    <td><input :placeholder="$t('labels.stream_title_en')" class="form-control" v-model="stream.name_en"></td>
                </tr>
                <tr>
                    <td style="width: 100px;">Name (ZH)</td>
                    <td><input :placeholder="$t('labels.stream_title_zh')" class="form-control" v-model="stream.name_zh"></td>
                </tr>
            </table>
        </div>

        
        <div class="row">
            <div class="col-12 d-flex flex-row">
                <div class="flex">
                    <div class="container text-white">
                        <label class="mt-3">{{ $t("labels.upload_image") }}</label>
                        <pickable-image
                            class="side-img card p-2 shadow-sm"
                            v-model="schedule.image_url"
                            :thumbUrl.sync="thumb_url"
                            :width.sync="image_width"
                            :height.sync="image_height"
                            style="min-height: 150px; width: 200px"
                            :commit.sync="saveFile"
                            imgID="upload-img"
                            fileType="image"
                        />
                    </div>
                </div>
                <div>
                    <div class="align-self-end h-100 d-flex flex-column">
                        <button
                            class="btn btn-success mt-3"
                            @click="addStream"
                        >
                            <i class="fas fa-plus mr-2" />{{ $t("labels.add_stream") }}
                        </button>
                        <button
                            v-if="saving"
                            class="btn btn-secondary mt-auto mb-2"
                            disabled
                        >
                            {{ $t("labels.saving") }}
                        </button>
                        <button
                            v-else-if="dirty || (!dirty && !canSave)"
                            class="btn btn-primary mt-auto mb-2"
                            @click="save"
                            :disabled="!canSave"
                        >
                            {{ $t("labels.save") }}
                        </button>
                        <button
                            v-else
                            class="btn btn-secondary mt-auto mb-2"
                            disabled
                        >
                            {{ $t("labels.saved") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScheduleService from "@/services/ScheduleService"
import Toast from "@/utils/Toast";
import _ from "lodash";
import PickableImage from "@/components/PickableImage";

export default {
	name: "ScheduleEdit",

	props: ["schedule"],

    components: {PickableImage},

	data() {
        let platforms = [
            { label: this.$i18n.t("platforms.youtube"), value: "youtube" },
            { label: this.$i18n.t("platforms.twitch"), value: "twitch" },
            { label: this.$i18n.t("platforms.twitcast"), value: "twitcast" },
            { label: this.$i18n.t("platforms.twitter"), value: "twitter" },
            { label: this.$i18n.t("platforms.unknown"), value: "unknown" },
        ]

		return {
            saving: false,

            initialSchedule: null,

            platforms: platforms,

			image_url: null,
			thumb_url: null,
			image_width: null,
			image_height: null,
			saveFile: null,
        };
	},

	created() {
        this.initialSchedule = _.omit(_.cloneDeep(this.schedule), "meta")
    },

	computed: {
        dirty() {
            return !_.isEqual(_.omit(this.schedule, "meta"), this.initialSchedule)
        },

        canSave() {
            if(!this.schedule.streams || this.schedule.streams.length == 0) return false

            for(let stream of this.schedule.streams) {
                if(!stream.date) return false
                if(!stream.time) return false
                if(!stream.name) return false
            }

            return true
        }
    },

	methods: {
		uploadImage() {
			this.uploading = true;
			this.saveFile()
				.then(() => {
					this.uploading = false;
					this.uploaded = true;
				})
				.catch((e) => {
					console.error(e);
					Toast.info("Failed to save file", e);
				});
		},

        addStream() {
            let newStream = {
                platform: "youtube",
                time: "20:00:00"
            }
            this.schedule.streams.push(newStream)
        },

        removeStream(stream) {
            let index = this.schedule.streams.indexOf(stream)
            if (index >= 0) {
                this.$delete(this.schedule.streams, index)
            }
        },

        save() {
			this.saving = true;
			if (this.saveFile) {
				this.uploading = true;
				this.saveFile()
					.then(() => {
						this.doSave();
					})
					.catch((e) => {
						this.saving = false;
						console.error(e);
						Toast.info("Failed to save image", e);
					});
			} else {
				this.doSave();
			}
        },

        doSave() {
            ScheduleService.saveSchedule(this.schedule).then(r => {
                this.schedule._id = r.data._id
                this.schedule.created_at = r.data.created_at
                this.schedule.streams = r.data.streams
                this.initialSchedule = _.omit(_.cloneDeep(this.schedule), "meta")
                this.saving = false
            }).catch(e => {
                console.error(e)
                Toast.error("Failed to save schedule", e)
            })
        },

        getPlatformIcon(value) {
            switch(value) {
                case "youtube":
                    return "fab fa-youtube"
                case "twitch":
                    return "fab fa-twitch"
                case "twitter":
                    return "fab fa-twitter"
                case "twitcast":
                    return "fas fa-phone"
                case "unknown":
                    return "fas fa-question"
            }
        }
    },
};
</script>

<style scoped>
input::placeholder {
    opacity: 0.35;
}
.delete-button {
	border-radius: 50%;
	padding: 0;
	width: 25px;
	height: 25px;
	position: absolute;
	right: 0px;
	top: -7px;
}
</style>

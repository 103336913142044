<template>
	<div class="d-flex flex-column h-100">
		<div
			id="background"
			class="background triangle-confetti-bg"
			:class="{ 'fill-background': fillBackground }"
			style="z-index: -1"
		>
			<!-- <canvas ref="canvas" id="bg-canvas" width="1200" height="1200"></canvas> -->
		</div>
		<!-- Header -->

		<!-- <nav-bar v-if="!hideHeader" ref="subheader" :user="user" :refreshBG="loadData" :refreshUser="refreshUser" /> -->

		<!-- Content -->
		<div
			class="flex d-flex flex-column main-page-scroll"
			:class="{ 'justify-content-center': vCenter, animate: !themeSettings.stars_off }"
		>
			<div id="main-page-scroll" :class="{'scroll-y': !noScroll, 'overflow-y': noScroll}">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";

export default {
	name: "BirthdayPage",

	props: {
		user: {},
		color: {},
		fillBackground: {
			type: Boolean,
			default: false,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		vCenter: {
			type: Boolean,
			default: false,
		},
		refreshUser: {
			type: Function,
			default: null,
		},
		noScroll: {
			type: Boolean,
			default: false
		}
	},

	components: {},

	data() {
		return {
			themeSettings: null,
		};
	},
	created() {
		this.loadData();
		this.$emit("update:refresh", () => {
			this.loadData();
		});
	},

	mounted() {
		// let canvas = this.$refs.canvas;
		// let ctx = canvas.getContext("2d");
		// for(let x = 100; x < 1059; x += 60) {
		// 	for(let y = 100; y < 1059; y += 60) {
		// 		this.drawRandomTriangle(ctx, x, y, 60)
		// 	}
		// }
		// ctx.strokeStyle = `rgba(0, 0, 0, 1)`
		// ctx.lineWidth = 10
		// ctx.beginPath();
		// ctx.rect(93, 93, 974, 974);
		// ctx.stroke()
	},

	computed: {},

	methods: {
		loadData() {
			this.themeSettings = AuthService.getThemeSettings(this.user);
		},

		drawRandomTriangle(ctx, sx, sy, offset) {
			let prim = Math.floor(Math.random() * 2);

			let r = Math.floor(prim == 0 ? Math.random() * 128 + 128 : Math.random() * 256);
			let g = Math.floor(Math.random() * 192 + 64);
			let b = Math.floor(prim == 2 ? Math.random() * 128 + 128 : Math.random() * 256);

			let x = Math.random() * offset + sx;
			let y = Math.random() * offset + sy;
			let t = Math.random() * 2 * Math.PI;

			ctx.fillStyle = `rgba(${r}, ${g}, ${b}, 0.35)`;
			ctx.strokeStyle = `rgba(${r}, ${g}, ${b}, 0.55)`;
			let points2 = this.getTriangle(x, y, 20, t);
			this.roundedPolygon(ctx, points2, 8);
			ctx.fill();
			ctx.stroke();

			let points3 = this.getTriangle(x - 960, y, 20, t);
			this.roundedPolygon(ctx, points3, 8);
			ctx.fill();
			ctx.stroke();

			let points4 = this.getTriangle(x, y - 960, 20, t);
			this.roundedPolygon(ctx, points4, 8);
			ctx.fill();
			ctx.stroke();

			let points5 = this.getTriangle(x + 960, y, 20, t);
			this.roundedPolygon(ctx, points5, 8);
			ctx.fill();
			ctx.stroke();

			let points6 = this.getTriangle(x, y + 960, 20, t);
			this.roundedPolygon(ctx, points6, 8);
			ctx.fill();
			ctx.stroke();
		},

		// Get an array of points for an equilateral triangle with center x,y and radius r, rotated t degrees
		getTriangle(x, y, r, t) {
			let x1 = x + Math.sin(t) * r;
			let y1 = y + Math.cos(t) * r;

			t += (2 * Math.PI) / 3;
			let x2 = x + Math.sin(t) * r;
			let y2 = y + Math.cos(t) * r;

			t += (2 * Math.PI) / 3;
			let x3 = x + Math.sin(t) * r;
			let y3 = y + Math.cos(t) * r;

			return [
				{ x: x1, y: y1 },
				{ x: x2, y: y2 },
				{ x: x3, y: y3 },
			];
		},

		/**
		 * Draws a polygon with rounded corners
		 * @param {CanvasRenderingContext2D} ctx The canvas context
		 * @param {Array} points A list of `{x, y}` points
		 * @radius {number} how much to round the corners
		 */
		roundedPolygon(ctx, points, radius) {
			ctx.beginPath();
			const distance = (p1, p2) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

			const lerp = (a, b, x) => a + (b - a) * x;

			const lerp2D = (p1, p2, t) => ({
				x: lerp(p1.x, p2.x, t),
				y: lerp(p1.y, p2.y, t),
			});

			const numPoints = points.length;

			let corners = [];
			for (let i = 0; i < numPoints; i++) {
				let lastPoint = points[i];
				let thisPoint = points[(i + 1) % numPoints];
				let nextPoint = points[(i + 2) % numPoints];

				let lastEdgeLength = distance(lastPoint, thisPoint);
				let lastOffsetDistance = Math.min(lastEdgeLength / 2, radius);
				let start = lerp2D(thisPoint, lastPoint, lastOffsetDistance / lastEdgeLength);

				let nextEdgeLength = distance(nextPoint, thisPoint);
				let nextOffsetDistance = Math.min(nextEdgeLength / 2, radius);
				let end = lerp2D(thisPoint, nextPoint, nextOffsetDistance / nextEdgeLength);

				corners.push([start, thisPoint, end]);
			}

			ctx.moveTo(corners[0][0].x, corners[0][0].y);
			for (let [start, ctrl, end] of corners) {
				ctx.lineTo(start.x, start.y);
				ctx.quadraticCurveTo(ctrl.x, ctrl.y, end.x, end.y);
			}

			ctx.closePath();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}

.main-page-scroll {
	overflow: hidden;
}

.hamburger-menu {
	border: 3px solid #adafae;
	border-radius: 0.5rem;
	opacity: 0.75;
}
.hamburger-icon {
	height: 40px;
}

.logo-img {
	height: 50px;
	width: 50px;
	margin-bottom: -10px;
}
.title {
	font-size: 72px;
	font-family: "Blender Pro";
	color: black;
	margin-top: -16px;
	margin-bottom: -16px;
	line-height: 0.8;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}
.transition-color {
	transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

.navbar-bg {
	box-shadow: 0 0 10px rgb(79, 211, 89, 0.3);
	background-color: rgba(64, 64, 64, 0.5);
}

.large-details {
	padding: 1rem;
	font-size: 1.5rem;
}
.details-link-container {
	width: 100%;
	text-align: center;
}
.details-link {
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-align: center;
}
.large-icon-modal-header {
	width: 100%;
	text-align: center;
	color: #fff;
}
.large-icon-modal-header > div {
	font-size: 36px;
}

.overflow-y {
	overflow-y: hidden;
}
</style>

<template>
	<div class="d-flex flex-column h-100 fadeable" ref="page" style="overflow: hidden">
		<div id="background" class="background" style="z-index: -1; overflow: hidden">
			<img
				src="https://files.delutaya.club/file/deluparty/starbg-bg2.webp"
				class="starry-bg"
				ref="splashBack"
				:style="{
					left: `${bgLeft}px`,
					bottom: `${bgBottom}px`,
					height: `${bgHeight}px`,
				}"
			/>
			<div
				class="meteor-field"
				ref="meteorField"
				:style="{
					left: `${bgLeft}px`,
					bottom: `${bgBottom}px`,
					transform: `scale(${bgScale})`,
				}"
			></div>
			<img
				src="https://files.delutaya.club/file/deluparty/starbg-fg2.webp"
				class="starry-bg"
				ref="splashFront"
				:style="{
					left: `${bgLeft}px`,
					bottom: `${bgBottom}px`,
					height: `${bgHeight}px`,
				}"
			/>
		</div>
		<!-- Content -->
		<div class="flex d-flex flex-column justify-content-start align-items-end">
			<div class="buttons-container">
				<button class="ui-button" @click="loadLightboxVideo('https://www.youtube.com/embed/L5p2OwFCObw')">
					{{ $t("labels.video") }}
				</button>
				<div class="button-spacer"></div>
				<button class="ui-button" @click="goToCanvas">{{ $t("labels.canvas") }}</button>
				<div class="button-spacer"></div>
				<button
					class="ui-button"
					@click="
						loadLightboxImage(
							'https://files.delutaya.club/file/deluparty/magicaldraw_20220807_162957.webp '
						)
					"
				>
					{{ $t("labels.community_canvas") }}
				</button>
				<div class="button-spacer"></div>
				<button class="ui-button" @click="goToTelescope">{{ $t("labels.telescope") }}</button>
			</div>

			<div class="credits-container">
				<div>Art: June (@junwei661)</div>
				<div>Website: Mirrored (@Mirrormn)</div>
			</div>
		</div>

		<div class="lightbox-overlay" :class="{ show: showLightbox }" @click="closeModals"></div>

		<div class="lightbox" :class="{ show: showLightbox }">
			<loading
				v-if="showLightbox && !showLightboxContent"
				class="lightbox-loading"
				color="rgba(255 255 255 / 100%)"
				font="PixelMPlus10"
			/>
			<div v-if="lightboxImage" class="lightbox-image-container">
				<img
					v-if="lightboxImage"
					:src="lightboxImage"
					class="lightbox-image zoomable"
					:class="{ show: showLightboxContent }"
					@load="lightboxImageLoaded"
					@click="zoom"
				/>
			</div>
			<iframe
				v-if="lightboxVideo"
				:src="lightboxVideo"
				frameborder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
				class="lightbox-video"
				:class="{ show: showLightboxContent }"
				@load="lightboxVideoLoaded"
				width="1120"
				height="730"
			></iframe>
			<div
				v-if="lightboxDetails"
				class="lightbox-details"
				:class="{ show: showLightboxContent }"
				v-html="lightboxDetails"
			></div>
		</div>
	</div>
</template>

<script>
import Loading from "@/components/Birthday/Loading";

const splashWidth = 2875;
const splashHeight = 1368;
const splashHeightLimit = 1000;
const splashWidthLimit = 1900;
const splashAspect = splashWidth / splashHeight;
const splashHotdogAspect = splashWidth / splashHeightLimit;
const splashHamburgerAspect = splashWidthLimit / splashHeight;

import Theme from "@/utils/Theme";

export default {
	name: "BirthdayHome",

	props: [],

	components: { Loading },

	computed: {
		lang() {
			return this.$i18n.locale;
		},
	},

	data() {
		return {
			splashAspect: splashAspect,
			theme: Theme.getCurrent(),
			user: null,

			refreshBG: null,

			redirectingTo: "",

			showLightbox: false,
			showMenu: false,
			showCredits: false,
			lightboxImage: null,
			lightboxVideo: null,
			lightboxDetails: null,
			showLightboxContent: false,

			bgHeight: 0,
			bgLeft: 0,
			bgBottom: 0,
			bgScale: 0,
		};
	},

	created() {
		this.loadData();
		window.addEventListener("resize", this.calcBg);
		this.calcBg();
		document.documentElement.style.overflow = "hidden";
	},

	destroyed() {
		window.removeEventListener("resize", this.calcBg);
		document.documentElement.style.overflow = "auto";
	},

	mounted() {
		setTimeout(this.generateMeteor, Math.random() * 6000);
		this.shootMeteor(Math.random() * 18000);
		this.shootMeteor(Math.random() * 18000);
		this.shootMeteor(Math.random() * 18000);
		this.shootMeteor(Math.random() * 18000);
		this.shootMeteor(Math.random() * 12000);
		this.shootMeteor(Math.random() * 12000);
		this.shootMeteor(Math.random() * 12000);
		this.shootMeteor(Math.random() * 6000);
	},

	unmounted() {},

	watch: {},

	methods: {
		loadData() {
			// do nothing
		},

		calcBg() {
			let w = window.innerWidth;
			let h = window.innerHeight;
			let a = w / h;

			if (a > splashHotdogAspect) {
				// Viewport is wider than vertically cut-off bg aspect ratio

				// Calculate the height that will make the splash fill viewport width, and set left to 0
				this.bgScale = w / splashWidth;
				this.bgHeight = splashHeight * this.bgScale;
				this.bgLeft = 0;

				// Vertically center the splash on the focus zone
				let focusHeight = splashHeightLimit * this.bgScale;
				let overflow = focusHeight - h;
				this.bgBottom = overflow / -2;
			} else if (a > splashAspect) {
				// Viewport is wider than the splash image

				// Calculate the height that will make the splash fill viewport width, and set left to 0
				this.bgScale = w / splashWidth;
				this.bgHeight = splashHeight * this.bgScale;
				this.bgLeft = 0;

				// Since the viewport height should be greater than the focus limit, but less than the splash image,
				// we can just rest the bottom of the splash image on the bottom of the viewport
				this.bgBottom = 0;
			} else if (a > splashHamburgerAspect) {
				// Viewport is not as wide as the splash image, but not narrow enough that we need to start
				// constraining it horizontally

				// Fill the viewport vertically and align splash image to the left
				this.bgHeight = h;
				this.bgLeft = 0;
				this.bgBottom = 0;
				this.bgScale = h / splashHeight;
			} else {
				// Viewport is so narrow that we need to constrain it horizontally

				// Fill the viewport vertically
				this.bgHeight = h;
				this.bgBottom = 0;

				// Horizontally center the splash image on the focus zone
				this.bgScale = h / splashHeight;
				let focusWidth = splashWidthLimit * this.bgScale;
				let overflow = focusWidth - w;
				this.bgLeft = overflow / -2;
			}
		},

		loadLightboxImage(url, details) {
			this.lightboxImage = url;
			this.lightboxDetails = details;
			this.lightboxVideo = null;
			this.showLightboxContent = false;
			this.$nextTick(() => {
				this.showLightbox = true;
			});
		},

		loadLightboxVideo(url) {
			this.lightboxImage = null;
			this.lightboxDetails = null;
			this.lightboxVideo = url;
			this.showLightboxContent = false;
			this.$nextTick(() => {
				this.showLightbox = true;
			});
		},

		lightboxImageLoaded(e) {
			console.log("LOADED IMAGE", e);
			setTimeout(() => {
				this.showLightboxContent = true;
			}, 100);
		},

		lightboxVideoLoaded(e) {
			console.log("LOADED IMAGE", e);
			setTimeout(() => {
				this.showLightboxContent = true;
			}, 100);
		},

		closeModals() {
			this.showLightbox = false;
			this.lightboxImage = null;
			this.lightboxVideo = null;
			this.showLightboxContent = false;
		},

		generateMeteor() {
			console.log("GENERATE METEOR");
			this.shootMeteor();
			setTimeout(this.generateMeteor, Math.random() * 6000);
		},

		shootMeteor(progressMilliseconds) {
			let top = -146;
			let left = -246;
			let startFactor = Math.random() * 3875 - 1000;
			if (startFactor < 0) {
				top = top - startFactor;
			} else {
				left = left + startFactor;
			}

			let speedFactor = Math.random() * 0.5 + 1;
			let dx = 3022 * speedFactor;
			let dy = 1764 * speedFactor;

			if (progressMilliseconds) {
				let progressFactor = progressMilliseconds / 30000;
				left = left + dx * progressFactor;
				top = top + dy * progressFactor;
			}

			let scaleFactor = Math.random() * 0.5 + 0.5;

			let meteorField = this.$refs.meteorField;
			if (!meteorField) {
				console.log("METEOR FIELD NOT FOUND");
			}
			let meteor = document.createElement("div");
			meteor.classList.add("meteor");
			meteor.style.top = `${top}px`;
			meteor.style.left = `${left}px`;
			meteor.style.transform = `rotate(30deg) scale(${scaleFactor})`;
			meteorField.appendChild(meteor);
			setTimeout(() => {
				meteor.style.top = `${top + dy}px`;
				meteor.style.left = `${left + dx}px`;
			}, 500);
			setTimeout(() => {
				meteorField.removeChild(meteor);
			}, 30500);
		},

		zoom(event) {
			console.log("click event", event);
			let el = event.target;

			let x = event.offsetX;
			let y = event.offsetY;
			let w = el.offsetWidth;
			let h = el.offsetHeight;
			let rx = x / w;
			let ry = y / h;

			this.$nextTick(() => {
				this.centerAfterZoom(rx, ry, el);
			});

			el.classList.toggle("zoomed");
		},

		centerAfterZoom(rx, ry, el) {
			let w = el.parentNode.offsetWidth;
			let h = el.parentNode.offsetHeight;
			console.log("inputs", w, h, rx, ry, el);
			console.log("scrollwh", el.scrollWidth, el.scrollHeight);
			let cx = w / 2;
			let cy = h / 2;
			let tx = el.scrollWidth * rx;
			let ty = el.scrollHeight * ry;
			let dx = 0;
			let dy = 0;
			if (tx > cx) {
				dx = tx - cx;
			}
			if (ty > cy) {
				dy = ty - cy;
			}
			console.log("dx dy", dx, dy);
			el.parentNode.scroll(dx, dy);
		},

		goToCanvas() {
			this.$router.push("/canvas2022");
		},

		goToTelescope() {
			this.$refs.splashBack.classList.add("bg-zoom");
			this.$refs.splashFront.classList.add("fg-zoom");
			this.$refs.page.classList.add("fade-out");
			setTimeout(() => {
				this.$router.push("/telescope");
			}, 1000);
		},
	},
};
</script>

<style scoped>
@font-face {
	font-family: "PixelMPlus10";
	src: url("/fonts/PixelMplus10-Regular.ttf");
}

.starry-bg {
	position: absolute;
	bottom: 0;
	transform-origin: 34% 62%;
	transition: transform 1s ease-in;
}
.bg-zoom {
	transform: scale(1.2);
}
.fg-zoom {
	transform: scale(2);
}
.fadeable {
	opacity: 1;
	transition: opacity 1s ease-in;
}
.fadeable.fade-out {
	opacity: 0;
}

.content-box {
	background-color: black;
	position: relative;
	margin-top: 2rem;
	width: 80%;
	max-width: 800px;
	padding: 1rem;
	overflow: hidden;
}
.content-box-bg {
	position: absolute;
	width: 400px;
	top: -80px;
	left: -80px;
	opacity: 0.15;
}

.green-border {
	border-top: 3px solid rgb(10, 208, 10);
}

.header-center {
	width: 80%;
	max-width: 800px;
	display: flex;
}

.header-icon > img {
	height: 64px;
	width: 64px;
	border-radius: 50%;
}
.header-icon {
	padding: 6px;
	border: 3px solid rgb(10, 208, 10);
	border-radius: 50%;
}
.header-top {
	position: relative;
	border-bottom: 3px solid rgb(10, 208, 10);
	font-weight: 900;
	font-size: 40px;
	font-family: tahoma;
	text-transform: capitalize;
	line-height: 48px;
}
.header-bottom {
	font-size: 16px;
	font-weight: 500;
	opacity: 0.75;
}

.left-header {
	text-align: left;
	align-self: left;
	padding-left: 4px;
}
.left-header .header-icon {
	position: absolute;
	right: calc(100% - 2px);
}
.left-header .header-top {
	margin-left: -20px;
	padding-left: 20px;
}
.left-header .header-bottom {
	padding-left: 2px;
}

.lightbox-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.25s;
	backdrop-filter: blur(6px);
	pointer-events: none;
	z-index: 30;
}

.lightbox-overlay.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	z-index: 31;
	pointer-events: none;
	padding: 60px 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.lightbox.show {
	display: flex;
	pointer-events: none;
}

.lightbox-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.5;
}

.lightbox-image-container {
	max-width: 100%;
	max-height: 100%;
	overflow: auto;
	pointer-events: all;
}
.lightbox-image-container::-webkit-scrollbar-corner {
	background: transparent;
}
.lightbox-image {
	opacity: 0;
	transition: opacity 0.45s;
	max-width: 100%;
	max-height: 100%;
	height: auto;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	background-color: #000;
}
.lightbox-image.show {
	opacity: 1;
	pointer-events: all;
}
.lightbox-image.zoomed {
	max-width: initial;
	max-height: initial;
	height: initial;
}

.lightbox-video {
	opacity: 0;
	transition: opacity 0.45s;
	max-width: 100%;
	max-height: 100%;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	background-color: #000;
}
.lightbox-video.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox-details {
	opacity: 0;
	transition: opacity 0.45s;
	background: linear-gradient(to right, rgb(253, 242, 222), rgb(253, 242, 222) 60px, rgb(223, 212, 192) 100%);
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	padding: 10px 16px;
	font-size: 24px;
	font-family: seto;
	font-weight: 800;
	margin-top: 20px;
}
.lightbox-details.show {
	opacity: 1;
	pointer-events: all;
}

.buttons-container {
	margin-top: 30px;
	margin-right: 30px;
	font-family: PixelMPlus10;
	display: flex;
	flex-direction: column;
}

.ui-button {
	padding: 0rem 1rem;
	font-size: 24px;
	border: 2px solid rgba(255 255 255 / 75%);
	color: rgba(255 255 255 / 75%);
	background-color: transparent;
	transition: all 0.15s;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%)) drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
	text-shadow: 0px 0px 1px rgba(255 255 255 / 75%), 0px 0px 2px rgba(255 255 255 / 50%);
	backdrop-filter: brightness(0.65);
}
.ui-button:hover {
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 50%)) drop-shadow(0px 0px 6px rgba(255 255 255 / 20%));
	border: 2px solid rgba(255 255 255 / 40%);
}
.button-spacer {
	border-left: 2px solid rgba(255 255 255 / 75%);
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%)) drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
	width: 2px;
	height: 16px;
	align-self: center;
}

.credits-container {
	text-align: right;
	position: fixed;
	bottom: 10px;
	right: 10px;
	color: rgba(255 255 255 / 75%);
	font-family: PixelMPlus10;
	filter: drop-shadow(1px 1px 2px rgba(0 0 0 / 100%)) drop-shadow(0px 0px 3px rgba(255 255 255 / 55%))
		drop-shadow(0px 0px 4px rgba(255 255 255 / 50%));
}

.meteor-field {
	width: 2875px;
	height: 1368px;
	position: absolute;
	transform-origin: bottom left;
}
</style>
import axios from "axios"

export default {
	getSchedules() {
		return axios.get(`/v1/schedules`)
	},

	saveSchedule(schedule) {
		return axios.post(`/v1/schedule`, schedule)
	}
}
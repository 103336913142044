<template>
	<div
		ref="page"
		class="fadeable"
		style="overflow: hidden; position: relative"
	>
		<div class="spacebox-container" ref="spaceboxContainer">
			<!-- <div v-for="(star, i) in stars" :key="i">
			<input type="number" v-model="star.position.x" />
			<input type="number" v-model="star.position.y" />
			<input type="number" v-model="star.position.z" />
		</div>
		<div>
			<input v-if="camera" type="number" v-model="camera.position.x" />
			<input v-if="camera" type="number" v-model="camera.position.y" />
			<input v-if="camera" type="number" v-model="camera.position.z" />
		</div>
		<div>
			<input v-if="camera" type="number" v-model="camera.fov" />
		</div> -->
		</div>

		<div v-if="ui">
			<div
				class="highlighter"
				:style="{
					transform: `translate(${ui.highlight.x}px, ${ui.highlight.y}px)`,
				}"
			></div>
			<div
				v-if="ui.connector"
				class="connector-line"
				:style="{
					transform: `translate(${ui.connector.x}px, ${ui.connector.y}px) rotate(${ui.connector.angle}rad)`,
					width: `${ui.connector.length}px`,
				}"
			></div>
			<div
				class="ui-container"
				ref="uiContainer"
				:class="{ 'right-side': ui.flip }"
			>
				<div class="ui-content">
					<div class="d-flex flex-row" :class="{'mb-4': ui.post.post_year != 2023}">
						<div class="flex text-left">
							<div class="label">
								{{ $t("labels.entity_name") }}:
							</div>
							<div>{{ ui.post.user_name }}</div>
						</div>
						<div v-if="ui.post.post_type == 1" class="ml-4">
							<div class="label">{{ $t("labels.image") }}</div>
							<i class="fas fa-file-image" />
						</div>
						<div v-else-if="ui.post.post_type == 2" class="ml-4">
							<div class="label">{{ $t("labels.video") }}</div>
							<i class="fas fa-file-video" />
						</div>
						<div v-else-if="ui.post.post_type == 3" class="ml-4">
							<div class="label">{{ $t("labels.audio") }}</div>
							<i class="fas fa-file-audio" />
						</div>
						<div v-else class="ml-4">
							<div class="label">{{ $t("labels.message") }}</div>
							<i class="fas fa-envelope-square" />
						</div>
					</div>
					<div v-if="ui.post.post_year == 2023" class="new-discovery mb-4">
						{{ $t("labels.discovered_in_2023") }}
					</div>
					<div v-if="ui.post.message" class="post-message mb-4">
						{{ ui.post.message }}
					</div>
					<!-- Image -->
					<template v-if="ui.post.post_type == 1">
						<div
							class="post-img-container expandable"
							:style="{
								'padding-top': `${getUiPaddingTop(ui)}%`,
								'margin-top': `${getUiMarginTop(ui)}%`,
							}"
							@click="showImageLightbox(ui.post)"
						>
							<div
								class="loading-container d-flex flex-column justify-content-center align-items-center"
							>
								<div class="text-muted" style="font-size: 18px">
									Loading...
								</div>
							</div>
							<img
								:src="ui.post.thumb_url || ui.post.image_url"
								:id="`img-${ui.post._id}`"
								class="post-img"
								@load="updateUIDrawnElements"
							/>
						</div>
					</template>
					<!-- Video -->
					<template v-if="ui.post.post_type == 2">
						<div
							class="post-img-container expandable"
							style="padding-top: 75%"
							@click="
								showVideoLightbox(
									getEmbedURL(ui.post.video_url)
								)
							"
						>
							<div
								class="loading-container d-flex flex-column justify-content-center align-items-center"
							>
								<div class="text-muted" style="font-size: 18px">
									Loading...
								</div>
							</div>
							<img
								:src="getThumbnailURL(ui.post.video_url)"
								class="post-img"
								@load="updateUIDrawnElements"
							/>
						</div>
					</template>
					<!-- Audio -->
					<template v-if="ui.post.post_type == 3">
						<skybox-audio-player :src="ui.post.audio_url" />
					</template>
				</div>
			</div>
		</div>
		<div v-if="editMode" class="edit-toolbar">
			<button
				@click="clickStarButton"
				class="edit-button"
				:class="{ down: interactMode == 'star' }"
			>
				⭐️
			</button>
			<button
				@click="clickLineButton"
				class="edit-button"
				:class="{ down: interactMode == 'line' }"
			>
				/
			</button>
		</div>
		<div v-if="selectedStarPost">
			<div class="edit-ui-container" ref="editUIContainer">
				<div class="row">
					<div class="col-12 form-group">
						<label>{{ $t("labels.name") }}</label>
						<input
							class="form-control"
							v-model.trim="selectedStarPost.user_name"
						/>
					</div>
					<div class="col-6 form-group">
						<label>{{ $t("labels.media_type") }}</label>

						<v-select
							v-model="selectedStarPost.post_type"
							:options="postTypes"
							:reduce="(o) => o.id"
							:clearable="false"
						></v-select>
					</div>
					<div class="col-6 form-group">
						<label>{{ $t("labels.post_year") }}</label>

						<v-select
							v-model="selectedStarPost.post_year"
							:options="postYears"
							:reduce="(o) => o.id"
							:clearable="false"
						></v-select>
					</div>
					<div class="col-12 form-group">
						<label>{{ $t("labels.message") }}</label>
						<textarea
							rows="5"
							class="form-control"
							v-model="selectedStarPost.message"
						/>
					</div>
					<div class="col-12 form-group">
						<label>{{ $t("labels.original_message") }}</label>
						<textarea
							rows="5"
							class="form-control"
							v-model="selectedStarPost.orig_message"
						/>
					</div>
					<!-- Image -->
					<template v-if="selectedStarPost.post_type == 1">
						<div class="col-12 form-group">
							<label>{{ $t("labels.upload_image") }}</label>
							<pickable-image
								class="side-img card p-2 shadow-sm"
								v-model="selectedStarPost.image_url"
								:thumbUrl.sync="selectedStarPost.thumb_url"
								:width.sync="selectedStarPost.image_width"
								:height.sync="selectedStarPost.image_height"
								style="height: 400px"
								:commit.sync="saveFile"
								imgID="upload-img"
								fileType="image"
							/>
						</div>
					</template>
					<!-- Video -->
					<template v-if="selectedStarPost.post_type == 2">
						<div class="col-12 form-group">
							<label>{{ $t("labels.video_url") }}</label>
							<input
								class="form-control"
								v-model="selectedStarPost.video_url"
								:class="{
									'is-invalid':
										selectedStarPost.video_url &&
										!youtubeID(selectedStarPost),
									'is-valid':
										selectedStarPost.video_url &&
										youtubeID(selectedStarPost),
								}"
							/>
						</div>
						<div
							v-if="youtubeID(selectedStarPost)"
							class="col-12 form-group d-flex flex-row justify-content-center"
						>
							<div class="video-box">
								<iframe
									class="youtube-embed"
									:src="
										`https://www.youtube.com/embed/${youtubeID(
											selectedStarPost
										)}`
									"
									frameborder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>
					</template>
					<!-- Audio -->
					<template v-if="selectedStarPost.post_type == 3">
						<div class="col-12 form-group">
							<label>{{ $t("labels.audio_file") }}</label>
							<div>
								<input
									type="file"
									accept="audio/*,application/ogg"
									@change="selectAudio"
									class="w-100"
								/>
							</div>
						</div>
						<div
							v-if="selectedStarPost.audio_url"
							class="col-12 form-group"
						>
							<skybox-audio-player
								:src="selectedStarPost.audio_url"
							/>
						</div>
					</template>
					<div class="col-12 d-flex flex-row justify-content-end">
						<button
							@click="deleteSelectedStar"
							class="ui-button text-danger"
						>
							{{ $t("labels.delete") }}
						</button>
						<button
							@click="
								clickSaveStarPost(
									selectedStarPost,
									selectedStar
								)
							"
							class="ml-3 ui-button text-success"
							:disabled="savingStar"
						>
							<template v-if="savingStar">{{
								$t("labels.saving")
							}}</template>
							<template v-else>{{ $t("labels.save") }}</template>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="editMode && selectedStar"
			class="highlighter-small"
			:style="{
				transform: `translate(${starHighlightX}px, ${starHighlightY}px)`,
			}"
		></div>
	</div>
</template>

<style scoped>
@font-face {
	font-family: "PixelMPlus10";
	src: url("/fonts/PixelMplus10-Regular.ttf");
}
.spacebox-container {
	width: 100%;
	height: 100%;
}
.highlighter {
	position: absolute;
	width: 100px;
	height: 100px;
	left: -50px;
	top: -50px;
	border: 3px solid white;
	opacity: 0.75;
	border-radius: 50%;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
		drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
}
.highlighter-small {
	position: absolute;
	width: 50px;
	height: 50px;
	left: -25px;
	top: -25px;
	border: 3px solid white;
	opacity: 0.75;
	border-radius: 50%;
	pointer-events: none;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
		drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
}
.edit-ui-container {
	position: absolute;
	bottom: 80px;
	right: 80px;
	border: 4px solid white;
	opacity: 1;
	padding: 1.5rem;
	font-family: PixelMPlus10;
	color: white;
	/* background-color: rgba(0 0 0 / 25%); */
	max-width: 50%;
	max-height: 75%;
	overflow-y: auto;
	z-index: 2;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
		drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
}
.ui-container {
	position: absolute;
	bottom: 60px;
	font-family: PixelMPlus10;
	font-size: 36px;
	color: rgba(255 255 255 / 75%);
	background-color: rgba(0 0 30 / 75%);
	max-width: 390px;
	max-height: 50%;
	z-index: 2;
	display: flex;
	flex-direction: column;
}
.ui-container:not(.right-side) {
	left: 80px;
}
.ui-container.right-side {
	right: 80px;
}
.ui-content {
	height: 100%;
	overflow-y: auto;
	border: 4px solid rgba(255 255 255 / 75%);
	padding: 1.5rem;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 62%))
		drop-shadow(0px 0px 6px rgba(255 255 255 / 37%));
}

.connector-line {
	position: absolute;
	top: -2.5px;
	left: 0;
	border-top: 4px solid white;
	opacity: 0.75;
	height: 5px;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
		drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
}
.edit-toolbar {
	position: absolute;
	bottom: 8px;
}
.edit-button {
	margin-left: 8px;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	background-color: rgba(0 0 0 / 25%);
	border: 2px solid white;
	color: white;
	font-weight: 800;
}
.edit-button.down {
	background-color: rgba(255 255 255 / 25%);
}
.ui-button {
	/* background-color: rgba(0 0 0 / 25%); */
	border: 2px solid white;
	color: white;
}
.label {
	font-size: 22px;
}

.post-img-container {
	height: 0;
	position: relative;
	width: 100%;
	background-color: rgba(255, 0, 255, 0.02);
	overflow: hidden;
	/* padding-top should be applied manually to make this work */
}

.post-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	clip: rect(0px, 334px, 334px, 0px);
}

.post-message {
	background-color: rgba(0 0 30 / 90%);
	font-size: 18px;
	text-align: left;
	padding: 0.5rem 1rem;
	text-shadow: 0px 0px 3px rgba(255 255 255 / 75%),
		0px 0px 6px rgba(255 255 255 / 50%);
}

.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.fadeable {
	opacity: 0;
	transition: opacity 1s ease-in;
}
.fadeable.fade-in {
	opacity: 1;
}

.new-discovery {
	color: #00ff00;
	font-size: 16px;
	text-align: left;
}
</style>

<script>
import SkyboxService from "@/services/SkyboxService";
import FileService from "@/services/FileService";
import Toast from "@/utils/Toast";

import PickableImage from "@/components/PickableImage";
import SkyboxAudioPlayer from "@/components/SkyboxAudioPlayer";

import * as Three from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
const Tween = require("@tweenjs/tween.js");

// const skybox_ft = require("@/assets/images/skybox/nebula/skybox_front.png");
// const skybox_bk = require("@/assets/images/skybox/nebula/skybox_back.png");
// const skybox_up = require("@/assets/images/skybox/nebula/skybox_up.png");
// const skybox_dn = require("@/assets/images/skybox/nebula/skybox_down.png");
// const skybox_rt = require("@/assets/images/skybox/nebula/skybox_left.png");
// const skybox_lf = require("@/assets/images/skybox/nebula/skybox_right.png");

// const skybox_ft = require("@/assets/images/skybox/galaxy/pz.png");
// const skybox_bk = require("@/assets/images/skybox/galaxy/nz.png");
// const skybox_up = require("@/assets/images/skybox/galaxy/py.png");
// const skybox_dn = require("@/assets/images/skybox/galaxy/ny.png");
// const skybox_rt = require("@/assets/images/skybox/galaxy/px.png");
// const skybox_lf = require("@/assets/images/skybox/galaxy/nx.png");

// const skybox_ft = require("@/assets/images/skybox/guide/pz.png");
// const skybox_bk = require("@/assets/images/skybox/guide/nz.png");
// const skybox_up = require("@/assets/images/skybox/guide/py.png");
// const skybox_dn = require("@/assets/images/skybox/guide/ny.png");
// const skybox_rt = require("@/assets/images/skybox/guide/px.png");
// const skybox_lf = require("@/assets/images/skybox/guide/nx.png");

const skybox_ft = require("@/assets/images/skybox/colornebula/pz.webp");
const skybox_bk = require("@/assets/images/skybox/colornebula/nz.webp");
const skybox_up = require("@/assets/images/skybox/colornebula/py.webp");
const skybox_dn = require("@/assets/images/skybox/colornebula/ny.webp");
const skybox_rt = require("@/assets/images/skybox/colornebula/px.webp");
const skybox_lf = require("@/assets/images/skybox/colornebula/nx.webp");
var skyboxPiecesLoaded = 0;

const starAura = require("@/assets/images/skybox/star_aura.png");
const starAuraTexture = new Three.TextureLoader().load(starAura);

// import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";

export default {
	name: "Spacebox",

	props: [],

	components: { PickableImage, SkyboxAudioPlayer },

	computed: {},

	data() {
		let postTypes = [
			{ label: this.$i18n.t("labels.image"), id: 1 },
			{ label: this.$i18n.t("labels.video"), id: 2 },
			{ label: this.$i18n.t("labels.audio"), id: 3 },
		];

		let postYears = [
			{ label: "2023", id: 2023 },
			{ label: "2022", id: 2022 },
		];

		return {
			starPosts: [],

			clock: null,
			animations: [],
			scene: null,
			camera: null,
			renderer: null,
			skyboxGeo: null,
			skybox: null,
			controls: null,
			stars: [],
			lines: [],
			collisionBox: null,

			centerPos: new Three.Vector3(0, 0, 0),
			cornerPos: null,
			cornerDistance: 1,

			raycaster: new Three.Raycaster(),
			pointer: new Three.Vector2(),

			maxZoom: 20,
			zoomed: false,
			zoomTween: null,
			currentStarScale: 1,
			currentAuraCloseScale: 300,
			currentAuraFarScale: 450,
			currentLineOpacity: 1,
			prevX: 0,
			prevY: 0,
			prevZ: 0,

			ui: null,

			editMode: false,
			interactMode: "",
			draggingStar: null,
			starStartingLine: null,
			drawingLine: null,
			didCameraMove: false,

			selectedStar: null,
			selectedStarPost: null,
			starHighlightX: 0,
			starHighlightY: 0,

			postTypes: postTypes,
			postYears: postYears,
			saveFile: null,
			savingStar: false,
		};
	},

	created() {
		this.editMode = this.$route.query.edit;
		window.addEventListener("resize", this.onWindowResize);
	},

	destroyed() {
		window.removeEventListener("resize", this.onWindowResize);
	},

	mounted() {
		this.initScene();
		this.loadStars();
	},

	methods: {
		loadStars() {
			SkyboxService.getStars()
				.then((r) => {
					console.log("GETSTARS", r.data);
					// first pass to create the stars
					const starsByID = {};
					for (let starPost of r.data) {
						this.starPosts[starPost._id] = starPost;
						const star = this.drawStarPost(starPost);
						starsByID[starPost._id] = star;
					}

					// second pass to create lines
					for (let starPost of r.data) {
						if (
							!(starPost.lines_to && starPost.lines_to.length > 0)
						)
							continue;
						let star1 = starsByID[starPost._id];
						for (let lineTo of starPost.lines_to) {
							let star2 = starsByID[lineTo];
							if (!star2) {
								console.error(
									"line to star did not exist",
									lineTo
								);
							} else {
								this.drawLineBetweenStars(star1, star2);
							}
						}
					}

					this.$nextTick(() => {
						this.starsLoaded = true;
						if (this.skyboxLoaded && this.starsLoaded) {
							this.$emit("starsLoaded", true);
						}
					});
				})
				.catch((e) => {
					console.error(e);
					Toast.error("Failed to get stars", e);
				});
		},

		updateStarPost(star, starPost) {
			starPost.star_x = star.position.x;
			starPost.star_y = star.position.y;
			starPost.star_z = star.position.z;
		},

		clickSaveStarPost(starPost, star, onSave) {
			this.savingStar = true;
			this.saveStarPost(starPost, star, onSave);
		},

		saveStarPost(starPost, star, onSave) {
			if (this.saveFile) {
				this.savingStar = true;
				this.saveFile().then(() => {
					this.doSaveStarPost(starPost, star, onSave);
				});
			} else {
				this.doSaveStarPost(starPost, star, onSave);
			}
		},

		doSaveStarPost(starPost, star, onSave) {
			return SkyboxService.saveStar(starPost)
				.then((r) => {
					console.log("Saved star");
					if (onSave) {
						onSave(r.data);
					}
					if (this.savingStar) {
						Toast.success("Star saved", "");
					}
					this.savingStar = false;
					this.updateStarColor(starPost, star);
				})
				.catch((e) => {
					console.error(e);
					Toast.error("Failed to save star", e);
					this.savingStar = false;
				});
		},

		deleteSelectedStar() {
			if (!(this.selectedStar && this.selectedStarPost)) {
				console.error(
					"Cannot delete selected star, no star is selected",
					this.selectedStar,
					this.selectedStarPost
				);
				return;
			}

			SkyboxService.deleteStar(this.selectedStarPost._id)
				.then(() => {
					// Star has been delete from the DB, but our current scene needs clean-up

					// Delete any lines
					for (let line of this.selectedStar.connectedLines) {
						// Remove references from other stars
						for (let otherStar of this.stars) {
							if (otherStar == this.selectedStar) continue;
							otherStar.connectedLines = otherStar.connectedLines.filter(
								(o) => o !== line
							);
						}

						this.deleteLine(line);
					}

					// Delete star itself
					this.stars = this.stars.filter(
						(o) => o !== this.selectedStar
					);
					this.deleteStar(this.selectedStar);

					this.deselectStar();
				})
				.catch((e) => {
					console.error(e);
					Toast.error("Failed to delete star", e);
				});
		},

		initScene() {
			// Get container element
			let el = this.$refs.spaceboxContainer;
			if (!el) {
				console.error("Failed to find spacebox render container");
				return;
			}

			// Create scene and camera
			let w = el.offsetWidth;
			let h = el.offsetHeight;
			let aspect = w / h;
			let fov = 55;
			let near = 45;
			let far = 30000;
			this.scene = new Three.Scene();
			this.camera = new Three.PerspectiveCamera(fov, aspect, near, far);
			console.log("CAMERA", this.camera);

			// Set camera position
			let cameraX = 1200;
			let cameraY = -250;
			let cameraZ = 2000;
			this.camera.position.set(cameraX, cameraY, cameraZ);

			// Add lights?
			const ambientLight = new Three.AmbientLight(0xcccccc, 0.4);
			this.scene.add(ambientLight);

			const pointLight = new Three.PointLight(0xffffff, 0.8);
			this.camera.add(pointLight);
			this.scene.add(this.camera);

			// Create renderer
			this.renderer = new Three.WebGLRenderer({ antialias: true });
			this.renderer.setSize(w, h);

			// Create dom element and append it to the container element
			this.renderer.domElement.id = "spacebox-canvas";
			el.appendChild(this.renderer.domElement);

			// Init controls
			this.controls = new OrbitControls(
				this.camera,
				this.renderer.domElement
			);
			this.controls.enabled = true;
			this.controls.minDistance = 700;
			this.controls.maxDistance = 1500;

			this.controls.addEventListener("change", () => {
				this.$nextTick(this.onCameraChange);
			});

			// Check pointer moves, to intersect objects
			el.addEventListener("pointermove", this.pointerMoveInField);
			el.addEventListener("click", this.clickInField);
			el.addEventListener("pointerdown", this.editPointerDown);
			el.addEventListener("pointerup", this.editPointerUp);
			this.onWindowResize();

			this.drawSkybox();

			this.addCollisionBox(4600);

			if (this.editMode) {
				const geometry = new Three.SphereGeometry(5000, 24, 12);
				const material = new Three.MeshBasicMaterial({
					color: 0x7777ff,
					wireframe: true,
					transparent: true,
					opacity: 0.1,
				});
				let latlong = new Three.Mesh(geometry, material);
				this.scene.add(latlong);
			}

			this.clock = new Three.Clock();
			this.animate();
		},

		onCameraChange() {
			if (this.selectedStar) {
				this.updateSelectedStarHighlight(this.selectedStar);
			}
			this.didCameraMove = true;
		},

		onWindowResize() {
			if (!this.camera && this.renderer) return;

			this.camera.aspect = window.innerWidth / window.innerHeight;
			this.camera.updateProjectionMatrix();

			this.renderer.setSize(window.innerWidth, window.innerHeight);
		},

		toggleEditMode() {
			if (this.editMode) {
				this.editMode = false;
				this.editDraggingStar = false;
				this.interactMode = "";
			} else {
				if (this.zoomed) {
					this.zoomOut(100);
				}
				this.editMode = true;
			}
		},

		clickStarButton() {
			if (this.interactMode != "star") {
				this.interactMode = "star";
			} else {
				this.interactMode = "";
			}
		},

		clickLineButton() {
			if (this.interactMode != "line") {
				this.interactMode = "line";
			} else {
				this.interactMode = "";
			}
		},

		clickInField() {
			if (this.editMode) return;

			if (this.zoomed) {
				this.zoomOut(1000);
			} else {
				let star = this.getHoveredStar();
				if (star) {
					this.zoomIn(star, 1000);
				}
			}
		},

		editPointerDown(event) {
			if (!this.editMode) return;

			this.updatePointer(event);
			let star = this.getHoveredStar();
			console.log("EDIT POINTER DOWN", star);
			this.didCameraMove = false;

			if (this.interactMode == "star") {
				this.addStarFromPointer();
			} else if (this.interactMode == "line") {
				if (!this.drawingLine) {
					if (star) {
						this.startDrawingLineFromStar(star);
					}
				} else {
					if (star) {
						this.endDrawingLineToStar(star);
					} else {
						this.endDrawingLine();
					}
				}
			} else {
				if (star) {
					this.startDraggingStar(star);
				}
			}
		},

		selectStar(star) {
			if (this.savingStar) return;

			let starPost = this.starPosts[star.postID];
			if (!starPost) {
				console.error("failed to find post for star", star.postID);
				return;
			}

			this.selectedStar = star;
			this.selectedStarPost = starPost;

			this.updateSelectedStarHighlight(this.selectedStar);
		},

		deselectStar() {
			if (this.savingStar) return;

			this.selectedStar = null;
			this.selectedStarPost = null;
			this.starHighlightX = 0;
			this.starHighlightY = 0;
		},

		editPointerUp(event) {
			if (!this.editMode) return;

			console.log("editPointerUp", event);
			if (this.draggingStar) {
				this.selectStar(this.draggingStar);
				this.endDraggingStar();
			} else if (!this.didCameraMove) {
				this.deselectStar();
			}
		},

		addStarFromPointer() {
			// Calculate pointer position
			let pos = this.getPointerPosOnBox();
			if (!pos) {
				console.error("NO POS");
				return;
			}

			// Add new star at position
			let star = this.drawStar(pos.x, pos.y, pos.z);

			// let prevStar = this.stars[this.stars.length - 1];
			// this.drawLineBetweenStars(prevStar, star);

			this.stars.push(star);
			star.material.color = 0xff0000;

			let newStarPost = {
				user_name: "test",
				post_year: 2023,
			};
			this.updateStarPost(star, newStarPost);
			this.saveStarPost(newStarPost, star, (starPost) => {
				this.starPosts[starPost._id] = starPost;
				star.postID = starPost._id;
			});

			this.interactMode = "";
		},

		startDrawingLineFromStar(star) {
			// Calculate pointer position
			let pos = this.getPointerPosOnBox();
			if (!pos) {
				console.error("NO POS");
				return;
			}

			// Add new line from star to pointer
			let line = this.drawLine(
				star.position.x,
				star.position.y,
				star.position.z,
				pos.x,
				pos.y,
				pos.z
			);
			this.drawingLine = line;
			this.starStartingLine = star;
		},

		endDrawingLineToStar(star) {
			let starPost = this.starPosts[this.starStartingLine.postID];
			if (!starPost) {
				console.error(
					"star without matching starpost",
					this.starStartingLine.postID,
					"not saved"
				);
				return;
			} else {
				if (!starPost.lines_to) {
					starPost.lines_to = [];
				}
				starPost.lines_to.push(star.postID);
				this.saveStarPost(starPost, star);
			}

			this.drawLineBetweenStars(this.starStartingLine, star);
			this.endDrawingLine();
		},

		endDrawingLine() {
			this.drawingLine.geometry.dispose();
			this.drawingLine.material.dispose();
			this.drawingLine.removeFromParent();
			this.drawingLine = null;
			this.starStartingLine = null;
			this.interactMode = "";
		},

		startDraggingStar(star) {
			this.draggingStar = star;
		},

		updateDraggingStar(star) {
			// Calculate pointer position
			let pos = this.getPointerPosOnBox();
			if (!pos) {
				console.error("NO POS");
				return;
			}

			// Update star position
			star.position.copy(pos);
			star.auraClose.position.copy(pos);
			star.auraClose.auraFar.position.copy(pos);
			let baseScale = this.getBaseScale(pos);
			star.baseScale = baseScale;
			star.scale.set(baseScale, baseScale, baseScale);
			star.auraClose.scale.set(baseScale * 400, baseScale * 400, 1);
			star.auraClose.auraFar.scale.set(
				baseScale * 600,
				baseScale * 600,
				1
			);

			this.updateLinesFromStar(star);
		},

		endDraggingStar() {
			let starPost = this.starPosts[this.draggingStar.postID];
			if (!starPost) {
				console.error(
					"star without matching starpost",
					this.draggingStar.postID,
					"not saved"
				);
				return;
			}
			this.updateStarPost(this.draggingStar, starPost);
			this.saveStarPost(starPost, this.draggingStar);

			this.draggingStar = null;
		},

		pointerMoveInField(event) {
			this.updatePointer(event);

			if (this.editMode && this.draggingStar) {
				this.updateDraggingStar(this.draggingStar);
			} else {
				if (this.editMode && this.drawingLine) {
					this.updateDrawingLine();
				}
				this.updateHoveredStars();
			}
		},

		updateHoveredStars() {
			if (this.zoomed) {
				// Don't hover and unhover stars while zoomed
				return;
			}

			// Cast a ray to check if any stars are hovered
			this.raycaster.setFromCamera(this.pointer, this.camera);

			// calculate objects intersecting the picking ray
			const intersects = this.raycaster.intersectObjects(
				this.scene.children
			);

			const intersected = {};
			for (let intersect of intersects) {
				if (intersect.object && intersect.object.hoverable) {
					intersected[intersect.object.uuid] = true;
					// console.log("HOVERABLE FOUND", intersect.object);
					if (!intersect.object.hovered) {
						this.fadeIn(intersect.object.auraFar, 200, 0.5);
						intersect.object.hovered = true;
					}
				}
			}

			for (let star of this.stars) {
				if (
					star.auraClose &&
					star.auraClose.hovered &&
					!intersected[star.auraClose.uuid]
				) {
					this.fadeOut(star.auraClose.auraFar, 200, 0.5);
					star.auraClose.hovered = false;
				}
			}
		},

		updateDrawingLine() {
			// Calculate pointer position
			let pos = this.getPointerPosOnBox();
			if (!pos) {
				console.error("NO POS");
				return;
			}

			// Update end of line
			this.drawingLine.geometry.dispose();

			const points = [];
			points.push(this.starStartingLine.position);
			points.push(pos);
			const geometry = new Three.BufferGeometry().setFromPoints(points);
			this.drawingLine.geometry = geometry;
			this.drawingLine.computeLineDistances();
		},

		updateLinesFromStar(star) {
			for (let connectedLine of star.connectedLines) {
				let otherStar = null;
				if (connectedLine.toStar === star) {
					otherStar = connectedLine.fromStar;
				} else {
					otherStar = connectedLine.toStar;
				}

				// Update end of line
				connectedLine.geometry.dispose();

				const points = [];
				points.push(star.position);
				points.push(otherStar.position);
				const geometry = new Three.BufferGeometry().setFromPoints(
					points
				);
				connectedLine.geometry = geometry;
				connectedLine.computeLineDistances();
			}
		},

		fadeIn(mesh, dur, max) {
			if (mesh.currentTween) {
				mesh.currentTween.stop();
			}
			const current = mesh.material.opacity;
			const delta = 1 - current / max;

			mesh.currentTween = new Tween.Tween(mesh.material)
				.to({ opacity: max }, dur * delta)
				.start();
		},

		fadeOut(mesh, dur, max) {
			if (mesh.currentTween) {
				mesh.currentTween.stop();
			}
			const current = mesh.material.opacity;
			const delta = current / max;

			mesh.currentTween = new Tween.Tween(mesh.material)
				.to({ opacity: 0 }, dur * delta)
				.start();
		},

		zoomIn(star, dur) {
			if (this.zoomTween) {
				this.zoomTween.stop();
			}

			let flip = false;
			let proj = star.position.clone().project(this.camera);
			if (proj.x > 0) {
				flip = true;
			}

			this.controls.enabled = false;

			this.prevX = this.camera.position.x;
			this.prevY = this.camera.position.y;
			this.prevZ = this.camera.position.z;

			// Calculate camera position that puts star in upper portion of screen
			// Get the component of the position vector on the horizontal plane (no y value)
			let horiz = new Three.Vector3(
				-1 * star.position.x,
				0,
				-1 * star.position.z
			);
			// Rotate this vector 90 degrees around the vertical axis
			let vertAxis = new Three.Vector3(0, -1, 0);
			let perpAxis = horiz.applyAxisAngle(
				vertAxis,
				Three.MathUtils.degToRad(90)
			);
			perpAxis = perpAxis.normalize();
			// We now have an axis that is perpendicular to the original position vector, on the horizontal plane
			// We can use this to adjust the "vertical rotation" of the position
			// (Since the camera looks through the origin, positions are mirrored, and later normalized)
			let behind = new Three.Vector3(
				-1 * star.position.x,
				-1 * star.position.y,
				-1 * star.position.z
			);
			behind = behind.applyAxisAngle(
				perpAxis,
				Three.MathUtils.degToRad(5)
			);
			// Finally, offset the position left or right horizontally to give compositional room for the UI
			behind = behind.applyAxisAngle(
				vertAxis,
				Three.MathUtils.degToRad(flip ? 3 : -3)
			);

			// let behind = new Three.Vector3(-1 * star.position.x, -1 * star.position.y, -1 * star.position.z);
			// let rot = new Three.Euler(Three.MathUtils.degToRad(-15 / 2), 0, 0);
			// behind.applyEuler(rot);

			var from = {
				x: this.camera.position.x,
				y: this.camera.position.y,
				z: this.camera.position.z,
				fov: this.camera.fov,
				starScale: this.currentStarScale,
				auraCloseScale: this.currentAuraCloseScale,
				auraFarScale: this.currentAuraFarScale,
			};
			var to = {
				x: behind.x,
				y: behind.y,
				z: behind.z,
				fov: 15,
				starScale: 0.25,
				auraCloseScale: 100,
				auraFarScale: 150,
			};
			let r2 = to.x * to.x + to.y * to.y + to.z * to.z; // radius^2 of sphere the star is on
			let ratio = Math.sqrt(r2 / (1500 * 1500)); // ratio that the star radius is compared to the camera position radius

			to.x = to.x / ratio;
			to.y = to.y / ratio;
			to.z = to.z / ratio;

			console.log("to", to);

			let camera = this.camera;
			let stars = this.stars;
			let lines = this.lines;
			for (let line of lines) {
				line.material.visible = false;
			}
			let _this = this;
			this.zoomTween = new Tween.Tween(from)
				.to(to, dur)
				.easing(Tween.Easing.Cubic.InOut)
				.onUpdate(function() {
					camera.position.set(
						this._object.x,
						this._object.y,
						this._object.z
					);
					camera.fov = this._object.fov;
					camera.updateProjectionMatrix();

					let ss = this._object.starScale;
					_this.currentStarScale = ss;

					let acs = this._object.auraCloseScale;
					_this.currentAuraCloseScale = acs;

					let afs = this._object.auraFarScale;
					_this.currentAuraFarScale = afs;

					for (let otherStar of stars) {
						if (otherStar != star) {
							let r = otherStar.baseScale;
							otherStar.scale.set(r * ss, r * ss, r * ss);
							otherStar.auraClose.scale.set(r * acs, r * acs, 1);
							otherStar.auraClose.auraFar.scale.set(
								r * afs,
								r * afs,
								1
							);
						}
					}
				})
				.onComplete(() => {
					this.setupUI(star, flip);
				})
				.start();
			this.zoomed = true;
		},

		zoomOut(dur) {
			if (this.zoomTween) {
				this.zoomTween.stop();
			}

			let star = this.ui.star;
			console.log("WAS STAR", star);
			this.ui = null;

			var from = {
				x: this.camera.position.x,
				y: this.camera.position.y,
				z: this.camera.position.z,
				fov: this.camera.fov,
				starScale: this.currentStarScale,
				auraCloseScale: this.currentAuraCloseScale,
				auraFarScale: this.currentAuraFarScale,
			};
			var to = {
				x: this.prevX,
				y: this.prevY,
				z: this.prevZ,
				fov: 55,
				starScale: 1,
				auraCloseScale: 400,
				auraFarScale: 600,
			};

			let camera = this.camera;
			let stars = this.stars;
			let lines = this.lines;
			let _this = this;
			this.zoomTween = new Tween.Tween(from)
				.to(to, dur)
				.easing(Tween.Easing.Cubic.InOut)
				.onUpdate(function() {
					camera.position.set(
						this._object.x,
						this._object.y,
						this._object.z
					);
					camera.fov = this._object.fov;
					camera.updateProjectionMatrix();

					let ss = this._object.starScale;
					_this.currentStarScale = ss;

					let acs = this._object.auraCloseScale;
					_this.currentAuraCloseScale = acs;

					let afs = this._object.auraFarScale;
					_this.currentAuraFarScale = afs;

					for (let otherStar of stars) {
						if (otherStar.scale.x != otherStar.baseScale) {
							let r = otherStar.baseScale;
							otherStar.scale.set(r * ss, r * ss, r * ss);
							otherStar.auraClose.scale.set(r * acs, r * acs, 1);
							otherStar.auraClose.auraFar.scale.set(
								r * afs,
								r * afs,
								1
							);
						}
					}
				})
				.onComplete(() => {
					for (let line of lines) {
						line.material.visible = true;
					}
					this.zoomed = false;
					this.updateHoveredStars();
					this.controls.enabled = true;
					console.log("IS COLOR", star);
					star.material.color.setHex(0x77ff77);
				})
				.start();
		},

		setupUI(star, flip) {
			let el = this.$refs.spaceboxContainer;
			let highlightPos = this.getObjXY(el, star);

			let starPost = this.starPosts[star.postID];
			if (!starPost) {
				console.error(
					"Zoomed to star with no matching post",
					star.postID
				);
				return;
			}

			this.ui = {
				highlight: highlightPos,
				flip: flip,
				post: starPost,
				star: star,
			};
			// if (highlightPos.x < el.offsetWidth / 2) {
			// 	this.ui.flip = true;
			// }

			this.$nextTick(this.updateUIDrawnElements);
		},

		updateUIDrawnElements() {
			let el = this.$refs.uiContainer;
			let boxtopRect = el.getBoundingClientRect();
			let boxtopX = (boxtopRect.left + boxtopRect.right) / 2;
			let boxtopY = boxtopRect.top + 2;

			// Get length and angle from boxtop to middle of star highlight
			let deltaX = this.ui.highlight.x - boxtopX;
			let deltaY = this.ui.highlight.y - boxtopY;
			let length = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
			let angle = Math.atan2(deltaY, deltaX);

			// Get a point that hits the edge of the highlight circle instead
			let highlightRadius = 50;
			length = length - highlightRadius;
			let edgeDeltaX = length * Math.cos(angle);
			let edgeDeltaY = length * Math.sin(angle);
			this.ui.connector = {
				x: boxtopX + edgeDeltaX / 2 - length / 2,
				y: boxtopY + edgeDeltaY / 2,
				length: length,
				angle: angle,
			};
			this.$forceUpdate();
		},

		drawSkybox() {
			this.skyboxGeo = new Three.BoxGeometry(10000, 10000, 10000);

			let materialArray = this.getCubeMaterialArray(
				skybox_ft,
				skybox_bk,
				skybox_up,
				skybox_dn,
				skybox_rt,
				skybox_lf
			);

			this.skybox = new Three.Mesh(this.skyboxGeo, materialArray);
			this.scene.add(this.skybox);
		},

		animate() {
			// const delta = this.clock.getDelta();

			// this.skybox.rotation.y += 0.005;
			this.controls.update();

			if (Tween) {
				Tween.update();
			} else {
				console.log("no tween!");
			}

			this.renderer.render(this.scene, this.camera);
			requestAnimationFrame(this.animate);
		},

		updateSelectedStarHighlight(star) {
			let el = this.$refs.spaceboxContainer;
			let pos = this.getObjXY(el, star);

			this.starHighlightX = pos.x;
			this.starHighlightY = pos.y;
		},

		getCubeMaterialArray(ft, bk, up, dn, rt, lf) {
			return [
				this.loadMaterialFromImage(ft),
				this.loadMaterialFromImage(bk),
				this.loadMaterialFromImage(up),
				this.loadMaterialFromImage(dn),
				this.loadMaterialFromImage(rt),
				this.loadMaterialFromImage(lf),
			];
		},

		skyboxLoad() {
			skyboxPiecesLoaded++;
			if (skyboxPiecesLoaded >= 6) {
				this.$refs.page.classList.add("fade-in");
				this.skyboxLoaded = true;
				if (this.skyboxLoaded && this.starsLoaded) {
					this.$emit("starsLoaded", true);
				}
			}
		},

		loadMaterialFromImage(img) {
			console.log("LOAD IMAGE", img);
			console.log("url", img.default);
			let texture = new Three.TextureLoader().load(img, this.skyboxLoad);
			console.log("TEXTURE", texture);
			let material = new Three.MeshBasicMaterial({
				map: texture,
				// color: 0xffffff,
				side: Three.BackSide,
			});
			console.log("MATERIAL", material);
			return material;
		},

		addCollisionBox(bound) {
			this.cornerPos = new Three.Vector3(bound, bound, bound);
			this.cornerDistance = this.centerPos.distanceTo(this.cornerPos);
			const geometry = new Three.BoxGeometry(
				bound * 2,
				bound * 2,
				bound * 2
			);
			const material = new Three.MeshBasicMaterial({
				color: 0xffffff,
				transparent: true,
				opacity: 0.01,
				side: Three.BackSide,
				visible: false,
			});
			let box = new Three.Mesh(geometry, material);
			box.position.set(0, 0, 0);
			this.scene.add(box);
			this.collisionBox = box;
		},

		drawStarPost(starPost) {
			let star = null;
			if (this.editMode) {
				star = this.drawStar(
					starPost.star_x,
					starPost.star_y,
					starPost.star_z
				);
				star.postID = starPost._id;
				this.stars.push(star);
				this.updateStarColor(starPost, star);
			} else {
				let unclickable = this.isUnclickableStar(starPost);
				star = this.drawStar(
					starPost.star_x,
					starPost.star_y,
					starPost.star_z,
					unclickable
				);
				if (starPost.post_year == 2023) {
					star.material.color.setHex(0x22ff00);
					star.auraClose.material.color.setHex(0x00ff00);
					star.auraClose.auraFar.material.color.setHex(0x00ff00);
				} else {
					star.material.color.setHex(0xffffcc);
				}
				star.postID = starPost._id;
				if (!unclickable) {
					this.stars.push(star);
				}
			}
			return star;
		},

		drawStar(x, y, z, unclickable) {
			let geometry = null;
			let material = null;
			if (unclickable) {
				geometry = new Three.SphereGeometry(30, 16, 8);
				material = new Three.MeshBasicMaterial({ color: 0xaaaaaa });
			} else {
				geometry = new Three.SphereGeometry(45, 16, 8);
				material = new Three.MeshBasicMaterial({ color: 0xffffcc });
			}
			let star = new Three.Mesh(geometry, material);
			star.position.set(x, y, z);
			let baseScale = this.getBaseScale(star.position);
			star.scale.set(baseScale, baseScale, baseScale);
			star.baseScale = baseScale;
			star.connectedLines = [];
			this.scene.add(star);

			const spriteMaterial = new Three.SpriteMaterial({
				map: starAuraTexture,
				color: 0xffffff,
				transparent: true,
				opacity: 0.5,
				blending: Three.AdditiveBlending,
			});
			var sprite = new Three.Sprite(spriteMaterial);
			if (!unclickable) {
				sprite.scale.set(baseScale * 400, baseScale * 400, 1.0);
			}
			sprite.position.set(x, y, z);
			sprite.hoverable = true;
			this.scene.add(sprite);
			star.auraClose = sprite;

			if (!unclickable) {
				const spriteMaterial2 = new Three.SpriteMaterial({
					map: starAuraTexture,
					color: 0xffffff,
					transparent: true,
					opacity: 0,
					blending: Three.AdditiveBlending,
				});
				var sprite2 = new Three.Sprite(spriteMaterial2);
				sprite2.scale.set(baseScale * 600, baseScale * 600, 1.0);
				sprite2.position.set(x, y, z);
				this.scene.add(sprite2);
				star.auraClose.auraFar = sprite2;
			}

			return star;
		},

		updateStarColor(starPost, star) {
			let noName = starPost.user_name == "test";
			let noMedia = !(
				starPost.message ||
				starPost.audio_url ||
				starPost.image_url ||
				starPost.video_url
			);
			if (noName && noMedia) {
				star.material.color.setHex(0x0000ff);
			} else if (noName || noMedia) {
				star.material.color.setHex(0xff0000);
			} else {
				if (starPost.post_year == 2023) {
					star.material.color.setHex(0x77ff55);
					if (star.auraClose) {
						star.auraClose.material.color.setHex(0x00ff00);
						star.auraClose.auraFar.material.color.setHex(0x00ff00);
					}
				} else {
					star.material.color.setHex(0xffffcc);
				}
			}
		},

		isUnclickableStar(starPost) {
			let noName = starPost.user_name == "test";
			let noMedia = !(
				starPost.message ||
				starPost.audio_url ||
				starPost.image_url ||
				starPost.video_url
			);
			return noName && noMedia;
		},

		deleteStar(star) {
			star.auraClose.auraFar.material.dispose();
			star.auraClose.auraFar.removeFromParent();

			star.auraClose.material.dispose();
			star.auraClose.removeFromParent();

			star.geometry.dispose();
			star.material.dispose();
			star.removeFromParent();
		},

		drawLineBetweenStars(s1, s2) {
			let line = this.drawLine(
				s1.position.x,
				s1.position.y,
				s1.position.z,
				s2.position.x,
				s2.position.y,
				s2.position.z
			);
			if (!s1.connectedLines) {
				s1.connectedLines = [];
			}
			if (!s2.connectedLines) {
				s2.connectedLines = [];
			}
			s1.connectedLines.push(line);
			s2.connectedLines.push(line);
			line.fromStar = s1;
			line.toStar = s2;
		},

		drawLine(x1, y1, z1, x2, y2, z2) {
			const material = new Three.LineDashedMaterial({
				color: 0xffffff,
				linewidth: 40,
				scale: 1,
				dashSize: 40,
				gapSize: 40,
				// transparent: true,
			});
			const points = [];
			let from = new Three.Vector3(x1, y1, z1);
			let to = new Three.Vector3(x2, y2, z2);
			points.push(from);
			points.push(to);

			const geometry = new Three.BufferGeometry().setFromPoints(points);
			const line = new Three.Line(geometry, material);
			line.renderOrder = 1;
			line.computeLineDistances();
			this.scene.add(line);
			return line;
		},

		deleteLine(line) {
			line.geometry.dispose();
			line.material.dispose();
			line.removeFromParent();
		},

		// loadCar() {
		// 	const loadModel = () => {
		// 		// this.car.position.x = 1000;
		// 		this.car.position.y = -95;
		// 		this.scene.add(this.car);
		// 	};

		// 	const manager = new Three.LoadingManager(loadModel);

		// 	const progress = (xhr) => {
		// 		console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
		// 	};

		// 	const error = (error) => {
		// 		console.log("An error happened", error);
		// 	};

		// 	const finished = (obj) => {
		// 		this.car = obj;
		// 		this.car.scale.set(100, 100, 100);
		// 	};

		// 	const loader = new OBJLoader(manager);
		// 	loader.load("/car/car.obj", finished, progress, error);
		// },
		getObjXY(el, obj) {
			let pos = obj.position.clone();
			let proj = pos.project(this.camera);

			console.log("PROJ", proj);

			// Projected value ranges from -1 to 1, we want to normal that to 0 to 1
			// Also, y value is ascending from bottom of screen, so we need to flip that
			let normalX = (proj.x + 1) / 2;
			let normalY = (-proj.y + 1) / 2;

			let x = normalX * el.offsetWidth;
			let y = normalY * el.offsetHeight;

			console.log("PROJECTION", x, y);
			return { x, y };
		},

		getHoveredStar() {
			for (let star of this.stars) {
				if (star.auraClose.hovered) {
					return star;
				}
			}
		},

		updatePointer(event) {
			// Get container element
			let el = this.$refs.spaceboxContainer;
			if (!el) {
				console.error("Failed to find spacebox render container");
				return;
			}

			let w = el.offsetWidth;
			let h = el.offsetHeight;
			if (event) {
				this.pointer.x = (event.offsetX / w) * 2 - 1;
				this.pointer.y = -(event.offsetY / h) * 2 + 1;
			}
		},

		getPointerPosOnBox() {
			this.raycaster.setFromCamera(this.pointer, this.camera);
			const intersects = this.raycaster.intersectObject(
				this.collisionBox
			);
			if (intersects.length <= 0) {
				return null;
			}
			console.log("GETPOINTERPOSONBOX", intersects[0].point);
			return intersects[0].point;
		},

		getBaseScale(pos) {
			const distance = this.centerPos.distanceTo(pos);
			return distance / this.cornerDistance;
		},

		youtubeID(starPost) {
			if (!(starPost && starPost.video_url)) return "";
			let youtubeID = this.getYoutubeID(starPost.video_url);
			return youtubeID ? youtubeID : "";
		},

		getYoutubeID(url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			let match = url.match(regExp);

			return match && match[2].length === 11 ? match[2] : null;
		},

		getEmbedURL(url) {
			let youtubeID = this.getYoutubeID(url);
			if (youtubeID) {
				return `https://www.youtube.com/embed/${youtubeID}`;
			}
		},

		getThumbnailURL(url) {
			let youtubeID = this.getYoutubeID(url);
			if (youtubeID) {
				return `https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`;
			}
		},

		selectAudio(event) {
			if (
				!(
					event &&
					event.target &&
					event.target.files &&
					event.target.files.length > 0
				)
			) {
				console.error("Invalid selectAudio event", event);
			}
			this.selectedStarPost.audio_url = URL.createObjectURL(
				event.target.files[0]
			);
			console.log("set audio_url");
			this.saveFile = () => {
				return FileService.uploadFile(event.target.files[0], "audio")
					.then((r) => {
						URL.revokeObjectURL(this.selectedStar.audio_url);
						this.selectedStarPost.audio_url = r.data;
						this.saveFile = null;
					})
					.catch((e) => {
						console.error(e);
						Toast.error("Failed to upload audio file", e);
					});
			};
		},

		showImageLightbox(post) {
			this.$emit("clickImage", post.image_url);
		},

		showVideoLightbox(url) {
			console.log(url);
			this.$emit("clickVideo", url);
		},

		getUiPaddingTop(ui) {
			return ui.post.image_height > ui.post.image_width
				? 100
				: (100 * ui.post.image_height) / ui.post.image_width;
		},

		getUiMarginTop(ui) {
			return ui.post.image_width > ui.post.image_height
				? 50 - (50 * ui.post.image_height) / ui.post.image_width
				: 0;
		},
	},
};
</script>

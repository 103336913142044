<template>
	<main-page :user="user">
		<div class="d-flex flex-column align-items-center pb-3">
			<div class="icon-header mt-5 mb-4">
				<img :src="getProfileIcon()" />
			</div>
			<members-card v-if="user.is_admin || user.is_idol" :user="user" class="mt-3"></members-card>
			<fanbox-card :user="user" class="mt-3" @icon="changeIcon"></fanbox-card>
			<twitter-card :user="user" class="mt-3" @icon="changeIcon"></twitter-card>
			<discord-card :user="user" class="mt-3" @icon="changeIcon"></discord-card>
			<youtube-card :user="user" class="mt-3" @icon="changeIcon"></youtube-card>
			<!-- <steam-card :user="user" class="mt-3" @icon="changeIcon"></steam-card> -->
			<div v-if="returnTo == 'submit'" class="return-card profile-card clickable mt-3" @click="goToSubmit">
				{{ $t("messages.return_to_submission_page") }}
				<i class="fas fa-undo-alt float-right mt-1" style="opacity: 0.8" />
			</div>
			<div v-if="returnTo == 'wall'" class="return-card profile-card clickable mt-3" @click="goToWall">
				{{ $t("messages.return_to_celebration_wall") }}
				<i class="fas fa-undo-alt float-right mt-1" style="opacity: 0.8" />
			</div>
		</div>
	</main-page>
</template>

<script>
import Members from "@/components/ProfileCards/Members";
import Fanbox from "@/components/ProfileCards/Fanbox";
import Twitter from "@/components/ProfileCards/Twitter";
import Discord from "@/components/ProfileCards/Discord";
import Youtube from "@/components/ProfileCards/Youtube";
// import Steam from "@/components/ProfileCards/Steam";

import Store from "@/utils/Store";

export default {
	name: "Profile",

	props: ["user"],

	components: {
		"members-card": Members,
		"fanbox-card": Fanbox,
		"twitter-card": Twitter,
		"discord-card": Discord,
		"youtube-card": Youtube,
		// "steam-card": Steam,
	},

	data() {
		return {
			assertedIcon: null,

			returnTo: null,
		};
	},

	created() {
		this.returnTo = Store.getDefault(this, "returnTo", null);
	},

	methods: {
		getProfileIcon() {
			// console.log("user", this.user);
			return (
				this.assertedIcon ||
				this.user.twitter_icon_url ||
				this.user.pixiv_icon_url ||
				this.user.discord_icon_url ||
				this.user.youtube_icon_url ||
				this.user.steam_icon_url
			);
		},

		changeIcon(iconURL) {
			this.assertedIcon = iconURL;
		},

		goToSubmit() {
			Store.set(this, "returnTo", null);
			this.$router.push("/submit");
		},

		goToWall() {
			Store.set(this, "returnTo", null);
			this.$router.push("/");
		},
	},
};
</script>

<style scoped>
.icon-header {
	align-self: center;
	height: 200px;
	width: 200px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.5);
	box-shadow: 0 0 20px rgb(79, 211, 89, 0.5);
}

.icon-header > img {
	height: 200px;
	width: 200px;
	border-radius: 50%;
}
</style>
<template>
	<birthday-page :user="user" fillBackground hideHeader :refresh.sync="refreshBG" class="purple-scrollbar">
		<div class="fade-out"></div>
		<img class="streamer-left" :src="birthdayStreamer">
		<img class="streamer-right" :src="birthdayStreamer">

		<div class="welcome-section">
			<div class="section-header">
				<img :src="gift">
				<span class="text-center">{{ $t("labels.welcome") }}</span>
			</div>

			<div class="mx-auto limit-width mb-5 d-flex align-items-center justify-content-center welcome-content" style="margin-top: 4vw;">
				<div class="note-paper-container mr-5 welcome-note">
					<div class="note-paper-bg"></div>
					<div class="note-paper-fg d-flex flex-row align-items-center py-4">	
						<div class="d-flex flex-column">
							<img class="sticky-note-decoration" src="@/assets/images/birthday/card_decoration.png">
							<img class="sticky-note-decoration" src="@/assets/images/birthday/card_decoration.png">
						</div>
						<div class="mx-5">
							<p class="greeting-text">
								{{ $t('messages.birthday_greeting_1') }}
							</p>
							<p class="mt-5 greeting-text">
								{{ $t('messages.birthday_greeting_2') }}
							</p>
						</div>
					</div>
					
				<img class="note-paper-tape-tl" src="@/assets/images/birthday/tape4.png">
				<img class="note-paper-tape-tr" src="@/assets/images/birthday/tape5.png">
				</div>
				<img :src="theme.greeting" class="welcome-delu">
			</div>
		</div>

		<div id="video-section">
			<div class="section-header video-section-header">
				<img :src="cake">
				<span class="text-center">{{ $t('labels.video') }}</span>
			</div>

			<div class="video-box-section">
				<div class="video-box" style="width:100%; height:100%;">
					<!-- Real content -->
					<iframe width="100%" height="100%" src="https://www.youtube.com/embed/A-J2sgQ6VH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

					<!-- Placeholder -->
					<!-- <div style="width: 100%; height: 100%; background-color: black;" class="d-flex flex-column justify-content-center">
						<div class="delu-large-text title-text">
							{{ $t('labels.coming_aug11_1') }}
						</div>
						<div class="delu-large-text title-text">
							{{ $t('labels.coming_aug11_2') }}
						</div>
					</div> -->
				</div>
			</div>
		</div>

		<div id="canvas-section">
			<div class="section-header canvas-section-header">
				<img :src="cake">
				<span class="text-center">{{ $t('labels.canvas') }}</span>
			</div>

			<div class="canvas-box-section d-flex flex-row justify-content-center">
				<div class="canvas-container">
					<delu-canvas @clickImage="loadLightboxImage" :user="user" />
					<!-- <div class="d-flex flex-column justify-content-center align-items-center" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">
						<div class="delu-large-text title-text">
							{{ $t('labels.coming_aug11_1') }}
						</div>
						<div class="delu-large-text title-text">
							{{ $t('labels.coming_aug11_2') }}
						</div>
					</div> -->
				</div>
			</div>
		</div>
		
		<div id="messages-section">
			<div class="section-header messages-section-header">
				<img :src="gift">
				<span class="text-center">{{ $t('labels.messages') }}</span>
			</div>

			<div class="mx-auto limit-width mb-4" :style="{ 'margin-top': contentMargin + 'px' }">
				<loading v-if="loading" color="rgba(0, 0, 0, 0.7)" />
				<div v-if="!loading" ref="postContainer" class="masonry">
					<div v-for="post in posts" :key="post.id" class="post-card-container">
						<div class="post-card fade-in" style="margin-bottom: 48px;">
							<birthday-post
								:post="post"
								:pages="post.pages"
								@redirect="redirect"
								@clickImage="loadLightboxImage"
								@clickVideo="loadLightboxVideo"
							></birthday-post>
							<div v-if="post.newPost" class="new-badge badge badge-info">{{ $t("labels.new!") }}</div>
						</div>
					</div>
				</div>
				<div
					v-else
					style="min-height: calc(100vh - 448px)"
					class="d-flex flex-column justify-content-center align-items-center"
				>
					<div class="subtle-border">
						<h1>{{ $t("labels.loading") }}</h1>
						<h1><i class="fas fa-spinner fa-pulse" /></h1>
					</div>
				</div>
			</div>
		</div>

		<div class="fullscreen-overlay" :class="{show: (showMenu)}" @click="closeModals">
		</div>

		<div class="menu-button-container">
			<button class="menu-button" @click="toggleMenu">
				<img width="72" height="72" style="margin-top: -8px;" src="@/assets/images/birthday/deluparty.png" />
			</button>
		</div>

		<div class="menu-options">
			<div @click="goToSubmit" class="menu-option bg-red" style="transition: transform 0.15s ease-out" :style="{transform: `rotate(${showMenu ? 0 : -15}deg)`}">
				<div class="menu-option-content">{{ $t('labels.submit_a_post') }}</div>
			</div>
			<div @click="toggleTranslate" class="menu-option bg-green" style="transition: transform 0.23s ease-out" :style="{transform: `rotate(${showMenu ? 7.5 : -15}deg)`}">
				<div class="menu-option-content">{{ themeSettings && themeSettings.do_translate ? $t('labels.japanese_tl_on') : $t('labels.japanese_tl_off') }}</div>
			</div>
			<div class="menu-option bg-blue" style="transition: transform 0.31s ease-out" :style="{transform: `rotate(${showMenu ? 15 : -15}deg)`}">
				<div class="menu-option-content d-flex flex-row">
					{{ $t('labels.site:(language)') }}
					<span class="ml-2" @click="setLanguage('ja')">{{ lang == 'ja' ? `[JA]` : `JA` }}</span>
					<span class="ml-2" @click="setLanguage('en')">{{ lang == 'en' ? `[EN]` : `EN` }}</span>
					<span class="ml-2" @click="setLanguage('zh')">{{ lang == 'zh' ? `[ZH]` : `ZH` }}</span>
				</div>
			</div>
			<div @click="toggleCredits" class="menu-option bg-yellow" style="transition: transform 0.39s ease-out" :style="{transform: `rotate(${showMenu ? 22.5 : -15}deg)`}">
				<div class="menu-option-content">{{ $t('labels.credits') }}</div>
			</div>
		</div>

		<div class="lightbox-overlay" :class="{show: showLightbox || showCredits}" @click="closeModals">
		</div>

		<div class="lightbox" :class="{show: showLightbox}">
			<loading v-if="showLightbox && !showLightboxContent" class="lightbox-loading" />
			<img v-if="lightboxImage" :src="lightboxImage" class="lightbox-image" :class="{show: showLightboxContent}" @load="lightboxImageLoaded">
			<iframe v-if="lightboxVideo" :src="lightboxVideo" class="lightbox-video" :class="{show: showLightboxContent}" @load="lightboxVideoLoaded" width="1120" height="730" frameborder="0"></iframe>
			<div v-if="lightboxDetails" class="lightbox-details" :class="{show: showLightboxContent}" v-html="lightboxDetails"></div>
		</div>

		<div class="credits" :class="{show: showCredits}">
			<div class="paper-container">
				<div class="paper">
					<div class="credits-header">Delu.party Website</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.website_by') }}</div>
						<div class="credits-name">Mirrored</div>
					</div>
					<hr>
					<div class="d-flex flex-row flex-wrap justify-content-center">
						<div class="credits-line mx-3">
							<div class="credits-role">{{ $t('credits.featured_illustrations') }}</div>
							<div class="credits-name">June</div>
						</div>
						<div class="credits-line mx-3">
							<div class="credits-role">{{ $t('credits.birthday_page_design') }}</div>
							<div class="credits-name">蔬菜果 (SOSAIKA)</div>
						</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.production_management') }}</div>
						<div class="credits-name">Bakarina</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.additional_programming') }}</div>
						<div class="credits-name mx-3">Solit</div>
						<div class="credits-name mx-3">Vorador</div>
						<div class="credits-name mx-3">ChouNagi</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.testing') }}</div>
						<div class="credits-name mx-3">mas1904</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.additional_illustrations') }}</div>
						<div class="credits-name mx-3">Naya Wolf</div>
						<div class="credits-name mx-3">Akashibag</div>
						<div class="credits-name mx-3">きのみ</div>
					</div>
					
					<hr>
					<div class="credits-header">Blessings MV</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.main_mv_editor') }}</div>
						<div class="credits-name">Yagami</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.lits_thing') }}</div>
						<div class="credits-name">Lit Fiana</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.asset_creators') }}</div>
						<div class="credits-name">赤柴</div>
						<div class="credits-name">mas1904</div>
						<div class="credits-name">Kuro Hayakawa™</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.character_illustrators') }}</div>
						<div class="credits-name">Uchimasui</div>
						<div class="credits-name">Jeroz</div>
						<div class="credits-name">4S</div>
						<div class="credits-name">Taiyu</div>
						<div class="credits-name">【 町貓 】DINCAT</div>
						<div class="credits-name">Rikure</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.mv_audio_mixer') }}</div>
						<div class="credits-name">wAA_</div>
					</div>
					<div class="d-flex flex-row flex-wrap justify-content-center">
						<div class="credits-line mx-3">
							<div class="credits-role">{{ $t('credits.end_credit_audio_mixer') }}</div>
							<div class="credits-name">咲Saki</div>
						</div>
						<div class="credits-line mx-3">
							<div class="credits-role">{{ $t('credits.end_credit_music_composer') }}</div>
							<div class="credits-name">LACP</div>
						</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.singers_audition_jury') }}</div>
						<div class="credits-name">黒血</div>
						<div class="credits-name">Yagami</div>
						<div class="credits-name">黒赤焔</div>
						<div class="credits-name">アカギ</div>
						<div class="credits-name">木狐</div>
						<div class="credits-name">ノリ爺</div>
						<div class="credits-name">Kuro Hayakawa™</div>
						<div class="credits-name">あめぽん</div>
						<div class="credits-name">にや</div>
						<div class="credits-name">default</div>
						<div class="credits-name">🌈Rinisei🌈</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.singers_solo') }}</div>
						<div class="credits-name">Vee</div>
						<div class="credits-name">Kuro Hayakawa™</div>
						<div class="credits-name">アカギ</div>
						<div class="credits-name">にや</div>
						<div class="credits-name">ひらねーさん</div>
						<div class="credits-name">ノリ爺</div>
						<div class="credits-name">あめぽん</div>
						<div class="credits-name">木狐</div>
						<div class="credits-name">チーズ</div>
						<div class="credits-name">Jeroz</div>
						<div class="credits-name">Shirogane Ryouko</div>
						<div class="credits-name">4S</div>
						<div class="credits-name">黒赤焔</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.singers_chorus') }}</div>
						<div class="credits-name">黒血</div>
						<div class="credits-name">白</div>
						<div class="credits-name">wAA_</div>
						<div class="credits-name">だるま</div>
						<div class="credits-name">MEAの小銭</div>
						<div class="credits-name">18歲大叔QC</div>
						<div class="credits-name">アリマーンのAngra Mainyu</div>
						<div class="credits-name">Rightnya-右魔ライト</div>
						<div class="credits-name">XeroKimo</div>
						<div class="credits-name">坂本キリト</div>
						<div class="credits-name">萬惡</div>
						<div class="credits-name">Rexyne</div>
					</div>
					
					<hr>
					<div class="credits-header">Delutaya Birthday Canvas</div>
					<div class="d-flex flex-row flex-wrap justify-content-center">
						<div class="credits-line mx-3">
							<div class="credits-role">{{ $t('credits.project_management') }}</div>
							<div class="credits-name">Kuro Hayakawa™</div>
						</div>
						<div class="credits-line mx-3">
							<div class="credits-role">{{ $t('credits.project_management') }}</div>
							<div class="credits-name">木狐 (Kitsu)</div>
						</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.artists') }}</div>
						<div class="credits-name mx-3">Hyde</div>
						<div class="credits-name mx-3">Lily is asleep</div>
						<div class="credits-name mx-3">はつ乃 </div>
						<div class="credits-name mx-3">Maru</div>
						<div class="credits-name mx-3">Homo Erectus</div>
						<div class="credits-name mx-3">icewine</div>
						<div class="credits-name mx-3">赤柴</div>
						<div class="credits-name mx-3">Shirokami</div>
						<div class="credits-name mx-3">DY Haku</div>
						<div class="credits-name mx-3">EarthenSpire</div>
						<div class="credits-name mx-3">✨Usagi</div>
						<div class="credits-name mx-3">Potatoseed</div>
						<div class="credits-name mx-3">BaKamen</div>
						<div class="credits-name mx-3">Taiyu</div>
						<div class="credits-name mx-3">Cielu</div>
						<div class="credits-name mx-3">ちまるΔ</div>
						<div class="credits-name mx-3">gudanco</div>
						<div class="credits-name mx-3">Uchimasui</div>
						<div class="credits-name mx-3">Naya Wolf</div>
						<div class="credits-name mx-3">mas1904</div>
						<div class="credits-name mx-3">ドクロ 𝗗𝗲𝗹𝘂𝘅𝗲✨</div>
						<div class="credits-name mx-3">Shiinarei ❀ 🐙</div>
						<div class="credits-name mx-3">Artpatient✍️</div>
						<div class="credits-name mx-3">Klaius</div>
						<div class="credits-name mx-3">ポテト🐾お絵描き </div>
						<div class="credits-name mx-3">Laddernumber12</div>
						<div class="credits-name mx-3">ChouNagi🔺</div>
						<div class="credits-name mx-3">🌈Rinisei🌈</div>
						<div class="credits-name mx-3">Reagan_Ren</div>
						<div class="credits-name mx-3">Kuro Hayakawa™</div>
						<div class="credits-name mx-3">ムムム</div>
						<div class="credits-name mx-3">Kiiro</div>
						<div class="credits-name mx-3">【 町貓 】DINCAT</div>
						<div class="credits-name mx-3">しぐまの</div>
						<div class="credits-name mx-3">Jeroz</div>
						<div class="credits-name mx-3">Ivan Sutikno</div>
						<div class="credits-name mx-3">にや</div>
						<div class="credits-name mx-3">Dane🔺♦️</div>
						<div class="credits-name mx-3">wAA</div>
						<div class="credits-name mx-3">appleseed</div>
						<div class="credits-name mx-3">RaRa</div>
						<div class="credits-name mx-3">Poppy MechΔ </div>
						<div class="credits-name mx-3">LevenΔ</div>
						<div class="credits-name mx-3">キルノルキ</div>
						<div class="credits-name mx-3">Vee</div>
						<div class="credits-name mx-3">Kazami Yui</div>
						<div class="credits-name mx-3">リヅカ</div>
					</div>
						
					<hr>
					<div class="credits-header">Delutaya Birthday Project Management</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.project_management_en') }}</div>
						<div class="credits-name">Kuro Hayakawa™</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.project_management_jp') }}</div>
						<div class="credits-name">ノリ爺</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.project_management_cn') }}</div>
						<div class="credits-name">黒血</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.project_management_cnjp') }}</div>
						<div class="credits-name">アカギ</div>
					</div>
					<hr>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.enjp_translators') }}</div>
						<div class="credits-name">default</div>
						<div class="credits-name">Vee</div>
						<div class="credits-name">木狐</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.encn_translators') }}</div>
						<div class="credits-name">Hiei</div>
						<div class="credits-name">4S</div>
						<div class="credits-name">stupidlamer</div>
						<div class="credits-name">Kuro Hayakawa™</div>
					</div>
					<div class="credits-line">
						<div class="credits-role">{{ $t('credits.cnjp_translator') }}</div>
						<div class="credits-name">アカギ</div>
					</div>
				</div>
				<div class="paper-bg" style="transform: rotate(2deg);"></div>
				<div class="paper-bg" style="transform: rotate(-2deg);"></div>
			</div>
		</div>

		<b-modal id="redirectModal" header-bg-variant="secondary" hideFooter @hide="redirectingTo = ''">
			<template #modal-header>
				<div class="large-icon-modal-header">
					<div class="d-flex flex-column">
						<i class="fas fa-link fa-2x my-3"></i>
						<div>{{ $t("labels.link_redirect") }}</div>
					</div>
				</div>
			</template>
			<div class="large-details">
				<p>{{ $t("labels.you_are_being_redirected") }}</p>
				<div class="details-link-container">
					<a class="btn btn-lg btn-secondary details-link" target="_blank" :href="redirectingTo">{{ redirectingTo }}</a>
				</div>
				<p class="mt-4">{{ $t("labels.redirect_warning") }}</p>
			</div>
		</b-modal>
	</birthday-page>
</template>

<script>

var cake = require("@/assets/images/birthday/cake.png")
var gift = require("@/assets/images/birthday/gift.png")

import AuthService from "@/services/AuthService";
import PostService from "@/services/PostService";
import Masonry from "masonry-layout";
import Toast from "@/utils/Toast";
import Theme from "@/utils/Theme";
import { DateTime } from "luxon";
import _ from "lodash";
import Layout from "@/utils/Layout"

import BirthdayPost from "@/components/Birthday/BirthdayPost";
import DeluCanvas from "@/components/Birthday/Canvas"
import Loading from "@/components/Birthday/Loading"

const birthdayStreamer = require("@/assets/images/birthday/streamer.png")

var lastScrollPos = null;
var lastScrollTime = null;

export default {
	name: "BirthdayWall",

	props: [],

	components: { BirthdayPost, DeluCanvas, Loading },

	computed: {
		lang() {
			return this.$i18n.locale
		}
	},

	data() {
		return {
			birthdayStreamer: birthdayStreamer,
			cake: cake,
			gift: gift,

			loading: true,

			theme: Theme.getCurrent(),
			user: null,
			posts: [],
			masonry: null,

			subheaderY: 0,
			subheaderHeight: 0,
			contentMargin: 32,

			refreshBG: null,
			themeSettings: null,

			cardElements: null,
			scrollEl: null,
			lastScrollPos: null,
			lastScrollTime: null,

			showLightbox: false,
			showMenu: false,
			showCredits: false,
			lightboxImage: null,
			lightboxVideo: null,
			lightboxDetails: null,
			showLightboxContent: false,

			redirectingTo: "",
		};
	},

	created() {
		this.loadData();
		this.redirect = (e) => {
			if(!e) return
			if (e.target.tagName === 'A') {
				let href = e.target.href;
				if(!href) return
				if(this.redirectingTo) return

				this.redirectingTo = href
				this.$bvModal.show("redirectModal")
				e.preventDefault();
			}
		}
		document.addEventListener("click", this.redirect)
	},

	destroyed() {
		document.removeEventListener("click", this.redirect)
	},

	mounted() {
		let scroll = document.getElementById("main-page-scroll");
		if (!scroll) {
			console.error("Failed to find scroll container");
			return;
		}
		this.scrollEl = scroll;

		// let subheader = this.$refs.subheader && this.$refs.subheader.$el;
		// if (!subheader) {
		// 	console.error("Failed to find subheader");
		// 	return;
		// }

		// this.subheaderY = subheader.offsetTop;
		// this.subheaderHeight = subheader.offsetHeight;
		// scroll.addEventListener("scroll", this.stickySubheader);
		scroll.addEventListener("scroll", this.doFadeIns);
		window.addEventListener("resize", this.doFadeIns);
	},

	unmounted() {
		let scroll = document.getElementById("main-page-scroll");
		if (!scroll) {
			console.error("Failed to find scroll container");
			return;
		}

		scroll.removeEventListener("scroll", this.stickySubheader);
		scroll.removeEventListener("scroll", this.doFadeIns);
		window.removeEventListener("resize", this.doFadeIns);
	},

	watch: {},

	methods: {
		loadData() {
			AuthService.getAuth()
				.then((resp) => {
					this.user = resp.data.base_user;
				})
				.catch((e) => {
					console.log("Not logged in", e);
					// Not authenticated, do nothing
				})
				.finally(() => {
					PostService.getPostsSwitch(this.user, 1)
						.then((r) => {
							_.each(r.data.rows, (r) => {
								r.message = r.message.replace(/\n/g, "<br>");
								if(r.translation) {
									r.translation.text = r.translation.text.replace(/\n/g, "<br>");
								}
							});

							let finalize = () => {
								this.posts = r.data.rows;

								this.posts = this.sortPosts(this.posts);
								this.loading = false;

								this.$nextTick(() => {
									Layout.cleanup()
									this.masonry = new Masonry(this.$refs.postContainer, {
										itemSelector: ".post-card-container",
										gutter: 36,
									});
									this.masonry.layout();
									this.loadTheme(this.user);
									this.cardElements = Array.from(document.getElementsByClassName("fade-in"));
									this.initFadeIns();
								});
							}

							let i = 0
							let processChunk = () => {
								let chunkStartTime = Date.now()
								console.log("Process chunk starting at", i)
								for (; i < r.data.rows.length; i++) {
									// process current row
									if(r.data.rows[i].message) {
										r.data.rows[i].message = r.data.rows[i].message + `<div style="padding-left: 20px; padding-top: 20px; width: 100%; text-align: left;">- ${Layout.renderName(r.data.rows[i])}</div>`
									}
									if(r.data.rows[i].translation) {
										r.data.rows[i].translation.text = r.data.rows[i].translation.text + `<div style="padding-left: 20px; padding-top: 20px; width: 100%; text-align: left;">- ${Layout.renderName(r.data.rows[i])}</div>`
									}

									r.data.rows[i].color = Math.floor(Math.random() * 4)
									r.data.rows[i].cat = Math.floor(Math.random() * 4)
									r.data.rows[i].pages = Layout.splitIntoPages(r.data.rows[i], "tape")

									// defer if necessary
									if(Date.now() - chunkStartTime > 8) {
										i++
										setTimeout(processChunk, 20)
										break
									}
								}

								if (i >= r.data.rows.length) {
									finalize()
								}
							}
							processChunk()
						})
						.catch((e) => {
							this.loading = false;
							console.log(e);
							Toast.error("Failed to load posts", e);
						});
				});
		},

		stickySubheader() {
			let scroll = document.getElementById("main-page-scroll");
			if (!scroll) {
				console.error("Failed to find scroll container");
				return;
			}

			let subheader = this.$refs.subheader && this.$refs.subheader.$el;
			if (!subheader) {
				console.error("Failed to find subheader");
				return;
			}

			if (scroll.scrollTop >= this.subheaderY) {
				subheader.classList.add("sticky");
				this.contentMargin = this.subheaderHeight + 32;
			} else {
				subheader.classList.remove("sticky");
				this.contentMargin = 32;
			}
		},

		loadTheme() {
			this.themeSettings = AuthService.getThemeSettings(this.user);
			_.each(this.posts, (post) => {
				if (post.translation) {
					post.showTranslated = this.themeSettings.do_translate;
				}
			});
		},

		refreshOrder() {
			this.posts = this.sortPosts(this.posts);
			this.$nextTick(() => {
				this.masonry.layout();
			});
		},

		sortPosts(posts) {
			this.themeSettings = AuthService.getThemeSettings(this.user);
			if (this.themeSettings && this.themeSettings.posts_in_order) {
				return _.orderBy(posts, "created_at", "desc");
			} else if (this.user && this.user.last_viewed_wall) {
				let newPosts = [];
				let oldPosts = [];

				let viewedTime = DateTime.fromISO(this.user.last_viewed_wall);

				_.each(posts, (post) => {
					if (DateTime.fromISO(post.created_at) > viewedTime) {
						post.newPost = true;
						newPosts.push(post);
					} else {
						oldPosts.push(post);
					}
				});

				newPosts = _.orderBy(newPosts, "created_at", "desc");
				oldPosts = _.shuffle(oldPosts);

				return newPosts.concat(oldPosts);
			} else {
				return _.shuffle(posts);
			}
		},

		initFadeIns() {
			for (let i = 0; i < this.cardElements.length; i++) {
				let card = this.cardElements[i];
				if (this.isCardVisible(card)) {
					card.style.opacity = "1";
					card.style.transform = "scale(1)";
					this.cardElements.splice(i, 1); // only allow it to run once
					i--; // Replay the current index since we deleted the element from that index
				} else {
					card.classList.add("fade-in-trans");
				}
			}
		},

		doFadeIns() {
			if(!this.cardElements) {
				console.log("Card elements aren't ready yet")
				return
			}

			let scrollVelocity = 200;
			let now = Date.now();
			if (lastScrollTime && now - lastScrollTime < 200) {
				scrollVelocity = (1000 * (this.scrollEl.scrollTop - lastScrollPos)) / (now - lastScrollTime);
				scrollVelocity = Math.abs(scrollVelocity);
			}
			lastScrollPos = this.scrollEl.scrollTop;
			lastScrollTime = now;

			let viewHeight = this.scrollEl.getBoundingClientRect().height;
			let animTotal = viewHeight / scrollVelocity; // The expected amount of second for the element to be on the page, at the current speed
			animTotal = Math.min(1, animTotal); // Don't make it more than a second

			let animDelay = 0.3;
			let animDur = 0.5;
			if (animTotal > 0.5) {
				animDelay = animTotal - animDur;
			} else {
				animDelay = 0;
				animDur = animTotal;
			}

			// let misses = 0;
			for (let i = 0; i < this.cardElements.length; i++) {
				let card = this.cardElements[i];
				if (this.isCardVisible(card, animDur < 0.2)) {
					card.classList.add("unfade");
					card.style.transitionDelay = `${animDelay}s`;
					card.style.transitionDuration = `${animDur}s`;
					// this.cardElements.splice(i, 1); // only allow it to run once
					// i--; // Replay the current index since we deleted the element from that index
				} else {
					// misses++;
					card.classList.remove("unfade");
				}
				// if (misses > 3) {
				// 	// Stop calculating rects if we've already seen three that are out of range
				// 	break;
				// }
			}
		},

		isCardVisible(card, noBuffer) {
			let rect = card.getBoundingClientRect();
			let elemTop = rect.top;
			if (!noBuffer) {
				let buffer = Math.min(rect.height / 2, 100);
				elemTop += buffer - 200;
			}
			let elemBottom = rect.bottom;
			return elemTop < window.innerHeight && elemBottom >= 0;
		},

		loadLightboxImage(url, details) {
			this.lightboxImage = url
			this.lightboxDetails = details
			this.lightboxVideo = null
			this.showLightboxContent = false
			this.$nextTick(() => {
				this.showLightbox = true
			})
		},

		loadLightboxVideo(url) {
			this.lightboxImage = null
			this.lightboxDetails = null
			this.lightboxVideo = url
			this.showLightboxContent = false
			this.$nextTick(() => {
				this.showLightbox = true
			})
		},

		lightboxImageLoaded(e) {
			console.log("LOADED IMAGE", e)
			setTimeout(() => {
				this.showLightboxContent = true;
			}, 100)
		},

		lightboxVideoLoaded(e) {
			console.log("LOADED IMAGE", e)
			setTimeout(() => {
				this.showLightboxContent = true;
			}, 100)
		},

		toggleMenu() {
			this.showMenu = !this.showMenu
		},

		toggleCredits() {
			this.showCredits = !this.showCredits
		},

		closeModals() {
			this.showLightbox = false
			this.lightboxImage = null
			this.lightboxVideo = null
			this.lightboxDetails = null
			this.showLightboxContent = false
			this.showMenu = false
			this.showCredits = false
		},
		
		toggleTranslate() {
			this.themeSettings.do_translate = !this.themeSettings.do_translate;
			AuthService.setThemeSettings(this.user, this.themeSettings);
			this.loadTheme();
		},

		goToSubmit() {
			this.$router.push("/submit");
		},

		setLanguage(lang) {
			this.themeSettings.language = lang;
			AuthService.setThemeSettings(this.user, this.themeSettings);
			if (lang == "") {
				lang = window.navigator.language;
				if (lang == "en-US") {
					lang = "en";
				}
			}
			this.$i18n.locale = lang;
		}
	},
};
</script>

<style scoped>
.video-box {
	border-color: #dbd7ba;
	border-width: 6px;
	border-style:dashed;
	padding: 30px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.fade-in {
	opacity: 0;
	transform: scale(0.8) translateY(200px);
}
.fade-in-trans {
	transition: 0.5s all cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.unfade {
	opacity: 1;
	transform: scale(1);
}

.splash-header {
	width: 100%;
	overflow: hidden;
}

.post-card-container {
	width: 33%;
	max-width: 390px;
}

.post-card {
	box-shadow: 0 0 8px 1px rgb(255 255 255 / 40%);
	border-radius: 10px;
}

.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}

.limit-width {
	max-width: 1296px;
	padding-left: 15px;
	padding-right: 15px;
}

.subtle-border {
	border: 3px solid rgba(255, 255, 255);
	border-radius: 2rem;
	display: inline-block;
	padding: 1rem 2rem;
	opacity: 0.2;
}

.menu-button-container {
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 21;
}

.menu-button {
    display:block;
    width:80px;
    height:80px;
    line-height:80px;
    border: 2px solid #f5f5f5;
    border-radius: 50%;
    color:#f5f5f5;
    text-align:center;
    text-decoration:none;
    background: #555777;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-size:20px;
    font-weight:bold;
	padding: 0;
}
.menu-button:hover {
    background: #777555;
}
.menu-button > img {
	border-radius: 50%
}
.menu-button:focus {
	outline: none;
}
.menu-button:active {
	outline: none;
	border-color: #333333;
}

.fullscreen-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transition: opacity 0.25s;
	backdrop-filter: blur(6px);
	pointer-events: none;
	z-index: 20;
}

.fullscreen-overlay.show {
	opacity: 1;
	pointer-events: all;
}

.menu-option {
	position: fixed;
	width: 300px;
	height: 50px;
	bottom: 26px;
	right: 150px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	transform-origin: 800px;
	transform: rotate(-3deg);
	transition: transform 0.25s ease-out;
	z-index: 22;
}

.menu-option-content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	font-family: gloria;
	font-size: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.menu-option-content:hover {
	box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.1);
}
.menu-option-content:active {
	box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
}
/* .menu-option:after{
	content:" ";
	display:block;
	height: 45px;
	background-image:url('https://ecorelos.com/wp-content/uploads/2020/04/torn-border2.png');
	position:absolute;
	bottom:-15px;
	width:300%;
	left:-500px;    
} */

.streamer-left {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	position: fixed;
	top: -10px;
	left: -10px;
	height: 12vw;
	max-height: 240px;
	z-index: 4;
}
.streamer-right {
	position: fixed;
	top: -10px;
	right: -10px;
	height: 12vw;
	max-height: 240px;
	z-index: 4;
}

.note-paper-container {
	position: relative;
	width: 600px;
}

.note-paper-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transform: rotate(-4deg);
	box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 30px;
}

.note-paper-fg {
	background: linear-gradient(to right, rgb(253,242,222), rgb(253,242,222) 60px, rgb(243,232,212) 100%);
	transform: rotate(2deg);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 16px;
	font-family: gloria;
	font-weight: 800;
	font-size: 40px;
	color: rgba(0, 0, 0, 0.7);
}

.note-paper-tape-tl {
	position: absolute;
    top: -20px;
    left: -40px;
	height: 3.1vw;
	min-height: 10px;
    transform: rotate(-8deg);
}

.note-paper-tape-tr {
	position: absolute;
    top: -20px;
    right: -40px;
	height: 2.9vw;
	min-height: 10px;
    transform: rotate(15deg);
}

.canvas-container {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 30px;
	position: relative;
	width: 1125px;
    max-width: calc(100vw - 10px);
	padding-top: min(calc(177.7777%), 2000px);
	margin-bottom: 20px;
}

.title-text {
	color: #fff;
	text-align: center;
	font-family: gloria;
	font-weight: 800;
	font-size: 60px;
	letter-spacing: 10px;
	padding-left: 10px;

	background: -webkit-linear-gradient(white, #016922);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}

.fade-out {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 16vw;
	max-height: 320px;
	background: linear-gradient(to bottom, rgba(255,253,239,1) 70%, rgba(255,253,239,0) 100%);
	z-index: 3;
	pointer-events: none;
}

.greeting-text {
	font-size: 32px;
}

.sticky-note-decoration {
	height: 292px;
}

.lightbox-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.25s;
	backdrop-filter: blur(6px);
	pointer-events: none;
	z-index: 30;
}

.lightbox-overlay.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	z-index: 31;
	pointer-events: none;
	padding: 60px 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.lightbox.show {
	display: flex;
}

.lightbox-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.5;
}

.lightbox-image {
	opacity: 0;
	transition: opacity 0.45s;
	max-width: 100%;
	max-height: 100%;
	height: auto;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	background-color: #000;
}
.lightbox-image.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox-video {
	opacity: 0;
	transition: opacity 0.45s;
	max-width: 100%;
	max-height: 100%;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	background-color: #000;
}
.lightbox-video.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox-details {
	opacity: 0;
	transition: opacity 0.45s;
	background: linear-gradient(to right, rgb(253,242,222), rgb(253,242,222) 60px, rgb(223,212,192) 100%);
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	padding: 10px 16px;
	font-size: 24px;
	font-family: seto;
	font-weight: 800;
	margin-top: 20px;
}
.lightbox-details.show {
	opacity: 1;
	pointer-events: all;
}

.welcome-content {
	flex-direction: row;
}

.welcome-delu {
	height: 800px;
}

.video-section-header {
	margin-bottom: 80px;
}

.canvas-section-header {
	margin-top: 60px;
	margin-bottom: 80px;
}

.messages-section-header {
	margin-top: 60px;
	margin-bottom: 80px;
}

.delu-large-text {
	height: 120px;
}

.credits {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	z-index: 31;
	pointer-events: none;
	padding: 60px 40px;
}
.credits.show {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.paper-container {
	padding: 60px;
	position: relative;
}

.paper {
	padding: 0 60px 40px 60px;
	background-color: #eee;
	box-shadow: rgba(0, 0, 0, 0.6) 0px 6px 15px;
	border: 1px solid #bbb;
	color: rgba(0, 0, 0, 0.8);
	font-size: 16px;
	font-weight: 500;
	font-family: Georgia;
	pointer-events: all;
	max-height: 90vh;
	overflow-y: auto;
	
}
.paper::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(128, 128, 128, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.paper-bg {
	position: absolute;
	top: 60px;
	left: 60px;
	right: 60px;
	bottom: 60px;
	opacity: 1;
	z-index:-1; transform: rotate(2deg);
}

.credits-header {
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 60px;
	font-family: ambrogio;
	color: green;
	text-shadow: 0px 2px 2px rgba(0,0,0,.5), 0px -2px 2px rgba(255,255,255,.5);
}

.credits-line {
	margin-bottom: 12px;
}

.credits-role {
	margin-top: 10px;
	color: rgba(0, 0, 0, 0.4);
	text-shadow: 0px -1px 0px rgb(0 0 0 / 20%);
}
.credits-name {
	font-weight: 800;
	font-size: 22px;
	text-shadow: 0px 1px 1px rgba(0,0,0,.5), 0px -1px 0px rgb(0 0 0 / 20%);
}

@media only screen and (max-width: 1281px) and (min-width: 856px) {
	.limit-width {
		max-width: 874px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.post-card-container {
		width: 50%;
		flex: 0 0 50%;
	}
	.welcome-delu {
		height: 600px;
	}
	.greeting-text {
		font-size: 24px;
	}
	.sticky-note-decoration {
		height: 250px;
	}
}

@media only screen and (max-width: 856px)  and (min-width: 460px) {
	.welcome-content {
		max-width: 90%;
		flex-direction: column-reverse;
	}
	.limit-width {
		max-width: 420px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.welcome-content {
		max-width: 80%;
		margin-top: 50px;
	}
	.welcome-note {
		width: 100%;
		margin: 15px 48px 15px 48px;
	}
	.video-section-header {
		margin-top: 50px;
		margin-bottom: 50px;
	}
	.post-card-container {
		width: 100%;
		flex: 0 0 100%;
	}
	.note-paper-tape-tl {
		display: none;
	}
	.note-paper-tape-tr {
		display: none;
	}
	.welcome-delu {
		height: 500px;
	}
	.greeting-text {
		font-size: 24px;
	}
	.sticky-note-decoration {
		height: 220px;
	}
}

@media only screen and (max-width: 459px) {
	.welcome-content {
		max-width: 90%;
		flex-direction: column-reverse;
	}
	.welcome-delu {
		height: auto;
		width: 100%;
	}
	.welcome-note {
		width: 100%;
		margin: 15px 48px 15px 48px;
	}
	.video-box-section {
		width: 95vw;
		height: 53.4375vw;
	}
	.video-box {
		padding: 10px;
	}
	.delu-large-text {
		font-size: 40px;
		height: 70px;
	}
	.video-section-header {
		margin-top: 30px;
		margin-bottom: 40px;
	}
	.canvas-section-header {
		margin-top: 30px;
		margin-bottom: 40px;
	}
	.messages-section-header {
		margin-top: 30px;
		margin-bottom: 40px;
	}
	.header-limit {
		margin-bottom: 10px;
	}
	.post-card-container {
		width: 100%;
		flex: 0 0 100%;
	}
	.note-paper-tape-tl {
		display: none;
	}
	.note-paper-tape-tr {
		display: none;
	}
	.greeting-text {
		font-size: 20px;
	}
	.sticky-note-decoration {
		height: 160px;
	}
}

@media only screen and (max-width: 420px) {
	.masonry {
		transform: scale(0.7) translate(calc(-210px + 50vw));
		transform-origin: top;
	}
}


@media only screen and (max-width: 620px) {
	.streamer-left {
		display: none;
	}
	.streamer-right {
		display: none;
	}
	.section-header {
		width: 100vw;
		height: 11vw;
		top: 10px;
		margin: 10px auto;
	}
	.fade-out {
		height: 24vw;
	}
	.text-center {
		font-size: 7vw;
	}
}

@media only screen and (max-width: 462px) {
	.menu-option {
		width: 220px;
		right: calc(-35px + 40.54vw);
		height: 45px;
		transform-origin: 700px;
	}
	.menu-option-content {
		font-size: 18px;
	}
}
</style>
// Constant sizes for layout
var stickyWidth = 322
var stickyHeight = 292

export default {
    el: null,
    lineHeight: null,
    iterations: 0,

    initTestElement(width, fontSize, fontFamily, fontWeight) {
        if(this.el) return

        this.el = document.createElement("div")
        document.querySelector("body").appendChild(this.el)
        let el = this.el
        el.style.width = `${width}px`
        el.style.fontSize = `${fontSize}px`
        el.style.fontFamily = fontFamily
        el.style.fontWeight = fontWeight

        el.innerText = "Hello world"
        this.lineHeight = el.offsetHeight
    },

    splitText(text, height) {
        let pages = []

        for(let i = 0; text.length > 0 && i < 10; i++) {
            let [pageText, splitIndex] = this.popOnePage(text, height)
            text = text.substring(splitIndex)
            pages.push(pageText)
        }

        return pages
    },

	popOnePage(text, height) {
        let el = this.el
        el.innerText = text

        // Abort early if the text all fits within the height
        if(el.offsetHeight <= height) {
            return [text, text.length]
        }

        let totalLines = el.offsetHeight / this.lineHeight
        let avgLengthPerLine = text.length / totalLines
        let linesPerPage = Math.floor(height / this.lineHeight)
        let splitIndex = Math.floor(avgLengthPerLine * linesPerPage)
        el.innerText = text.substring(0, splitIndex)
        let jump = 32

        for(let i = 0; i < 10000; i++) {
            this.iterations++
            if(el.offsetHeight > height) {
                for(;jump > splitIndex && jump > 1;) {
                    jump /= 2
                }
                let newSplitIndex = splitIndex - jump
                el.innerText = text.substring(0, newSplitIndex)
                if(el.offsetHeight <= height) {
                    if(jump == 1) {
                        splitIndex = newSplitIndex
                        break;
                    } else {
                        jump /= 2
                    }
                }
                splitIndex = newSplitIndex
            } else {
                for(;splitIndex + jump > text.length && jump > 1;) {
                    jump /= 2
                }
                let newSplitIndex = splitIndex + jump
                el.innerText = text.substring(0, newSplitIndex)
                if(el.offsetHeight > height) {
                    if(jump == 1) {
                        break;
                    } else {
                        jump /= 2
                    }
                }
                splitIndex = newSplitIndex
            }
        }

        return [text.substring(0, splitIndex), splitIndex]
    },

    splitWordsSmart(text) {
        let spaceSplitWords = text.split(" ")
        if(this.averageWordLength(spaceSplitWords) > 10) {
            console.log("Split a different way")
        }
    },

    getNumColumns(text, x, y) {
        let el = this.el
        el.style.columnWidth = `${x}px`
        el.style.columnFill = "balanced"
        el.style.columnGap = "0"
        el.innerHTML = text

        let columnCount = 1
        for(; columnCount < 20; columnCount++) {
            el.style.width = `${x * columnCount}px`
            el.style.columnCount = columnCount
            if(el.offsetHeight <= y) {
                break
            }
        }

        return columnCount
    },

    splitIntoPages(post, decoration = "tape") {
        let pages = []
        this.initTestElement(stickyWidth, 18, "seto", 800)
        // console.log(post)
        if(post.image_url || post.video_url) {
            if(post.image_url) {
                let page = {
                    type: 'image',
                    image_url: post.image_url,
                }
                if(decoration == "tape") {
                    page.topTapeType = Math.ceil(Math.random() * 9)
                    page.bottomTapeType = Math.ceil(Math.random() * 9)
                }
                if(decoration == "pins") {
                    page.topPinType = Math.ceil(Math.random() * 3)
                    page.bottomPinType = Math.ceil(Math.random() * 3)
                }
                if(!post.message) {
                    page.signature = "- " + this.renderName(post)
                }
                pages.push(page)
            } else {
                let page = {
                    type: 'video',
                    video_url: post.image_url,
                }
                if(decoration == "tape") {
                    page.topTapeType = Math.ceil(Math.random() * 9)
                    page.bottomTapeType = Math.ceil(Math.random() * 9)
                }
                if(decoration == "pins") {
                    page.topPinType = Math.ceil(Math.random() * 3)
                    page.bottomPinType = Math.ceil(Math.random() * 3)
                }
                pages.push(page)
                if(!post.message) {
                    page.signature = "- " + this.renderName(post)
                }
            }
        }

        if(post.message) {
            let n = this.getNumColumns(post.message, stickyWidth, stickyHeight)
            if(post.translation) {
                let tn = this.getNumColumns(post.translation.text, stickyWidth, stickyHeight)
                if(tn > n) {
                    n = tn
                }
            }
            let startReverse = Math.random() > 0.5
            for(let i = 0; i < n; i++) {
                let page = {
                    type: 'text',
                    text: post.message,
                    x: stickyWidth,
                    y: stickyHeight,
                    i: i,
                    n: n,
                    r: i > 1 ? Math.random() * 3 + 1 : Math.random() * 3,
                    reverse: startReverse ? i % 2 == 0 : i % 2 == 1,
                }
                if(decoration == "pins") {
                    page.topPinType = Math.ceil(Math.random() * 3)
                    page.bottomPinType = Math.ceil(Math.random() * 3)
                }
                if(post.translation) {
                    page.textTL = post.translation.text
                }
                pages.push(page)
            }
        }

        return pages
    },

    renderName(post) {
        switch(post.user_type) {
            case "pixiv":
                return `${post.user_name}<svg
    class="fab pixiv-icon"
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style="height: 20px; margin-left: 6px;"
>
    <title>pixiv icon</title>
    <path
        d="M4.935 0A4.924 4.924 0 0 0 0 4.935v14.13A4.924 4.924 0 0 0 4.935 24h14.13A4.924 4.924 0 0 0 24 19.065V4.935A4.924 4.924 0 0 0 19.065 0zm7.81 4.547c2.181 0 4.058.676 5.399 1.847a6.118 6.118 0 0 1 2.116 4.66c.005 1.854-.88 3.476-2.257 4.563-1.375 1.092-3.225 1.697-5.258 1.697-2.314 0-4.46-.842-4.46-.842v2.718c.397.116 1.048.365.635.779H5.79c-.41-.41.19-.65.644-.779V7.666c-1.053.81-1.593 1.51-1.868 2.031.32 1.02-.284.969-.284.969l-1.09-1.73s3.868-4.39 9.553-4.39zm-.19.971c-1.423-.003-3.184.473-4.27 1.244v8.646c.988.487 2.484.832 4.26.832h.01c1.596 0 2.98-.593 3.93-1.533.952-.948 1.486-2.183 1.492-3.683-.005-1.54-.504-2.864-1.42-3.86-.918-.992-2.274-1.645-4.002-1.646Z"
    ></path>
</svg>`
            case "discord":
                return `${post.user_name}#${post.ext}<i class="fab fa-fw fa-discord ml-1"></i>`
            case "twitter":
                return `@${post.user_name}<i class="fab fa-fw fa-twitter ml-1"></i>`
            case "youtube":
                return `${post.user_name}<i class="fab fa-fw fa-youtube ml-1"></i>`

        }
        return post.user_name
    },

    cleanup() {
        if(this.el) {
           this.el.parentNode.removeChild(this.el);
        }
        this.el = null
    }
}
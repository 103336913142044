<template>
	<main-page :user="user" :refreshUser="fullRefresh">
		<div class="container mt-3 pt-3" style="max-width: 100vw">
			<template v-if="mobile">
				<div v-if="ornament" class="mobile-top-bar">
					<div v-if="ornament.left < 0" class="delu-text title-text">{{ $t('labels.tap_ornament') }}</div>
					<div v-if="ornament.left >= 0" class="d-flex flex-row">
						<button class="btn btn-danger mr-3" @click="cancelOrnament">{{ $t("labels.cancel") }}</button>
						<button class="btn btn-success" @click="finishOrnament">{{ $t("labels.done") }}</button>
					</div>
				</div>
				<div ref="tree" class="tree" :class="{condense: ornament}" @click="placeOrnament" @touchstart="placeOrnament" @touchmove="placeOrnament">
					<img src="@/assets/images/christmas/tree.png" />
					<div
						v-for="o in ornaments"
						:key="o.id"
						class="ornament"
						:class="{topper: o.ball == 10}"
						:style="{top: `${o.top}%`, left: `${o.left}%`}"
						@click="openLightbox(o)"
					>
						<template v-if="o.ball == 1">
							<img :src="o.image" draggable="false" class="ornament-1-icon">
							<img src="@/assets/images/christmas/ornament_1.png" draggable="false" class="ornament-1-bg">
						</template>
						<template v-if="o.ball == 2">
							<img :src="o.image" draggable="false" class="ornament-2-icon">
							<img src="@/assets/images/christmas/ornament_2.png" draggable="false" class="ornament-2-bg">
						</template>
						<template v-if="o.ball == 10">
							<img :src="o.image" draggable="false" class="ornament-top-icon">
						</template>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="d-flex flex-row w-100">
					<div style="flex: 2;" class="d-flex flex-row justify-content-center">
						<div ref="tree" class="tree">
							<img src="@/assets/images/christmas/tree.png" />
							<div
								v-for="o in ornaments"
								:key="o.id"
								class="ornament"
								:class="{topper: o.ball == 10}"
								:style="{top: `${o.top}%`, left: `${o.left}%`}"
								@click="openLightbox(o)"
							>
								<template v-if="o.ball == 1">
									<img :src="o.image" draggable="false" class="ornament-1-icon">
									<img src="@/assets/images/christmas/ornament_1.png" draggable="false" class="ornament-1-bg">
								</template>
								<template v-if="o.ball == 2">
									<img :src="o.image" draggable="false" class="ornament-2-icon">
									<img src="@/assets/images/christmas/ornament_2.png" draggable="false" class="ornament-2-bg">
								</template>
								<template v-if="o.ball == 10">
									<img :src="o.image" draggable="false" class="ornament-top-icon">
								</template>
							</div>
						</div>
					</div>
					<div v-if="ornament" style="flex: 1;" class="d-flex flex-row justify-content-start align-items-center">
						<div class="d-flex flex-column align-items-center">
							<div class="delu-text title-text">{{ $t('labels.drag_ornament') }}</div>
							<div class="ornament-box mt-4">
								<div v-if="!ornamentDrag.dragging && ornament.left < 0" class="new-ornament" @mousedown="startOrnamentDrag">
									<template v-if="ornament.ball == 1">
										<img :src="ornament.image" draggable="false" class="ornament-1-icon">
										<img src="@/assets/images/christmas/ornament_1.png" draggable="false" class="ornament-1-bg">
									</template>
									<template v-if="ornament.ball == 2">
										<img :src="ornament.image" draggable="false" class="ornament-2-icon">
										<img src="@/assets/images/christmas/ornament_2.png" draggable="false" class="ornament-2-bg">
									</template>
								</div>
							</div>
							<div class="d-flex flex-row mt-4">
								<button v-if="ornament.left < 0" class="btn btn-danger" @click="cancelOrnament">{{ $t("labels.cancel") }}</button>
								<button v-if="ornament.left >= 0" class="btn btn-info mr-3" @click="rehangOrnament">{{ $t("labels.rehang") }}</button>
								<button v-if="ornament.left >= 0" class="btn btn-success" @click="finishOrnament">{{ $t("labels.done") }}</button>
							</div>
						</div>
					</div>
				</div>
			</template>
			<button
				v-if="!ornament"
				class="new-button btn btn-success btn-icon btn-lg"
				v-tippy
				:title="$t('labels.add_a_new_ornament')"
				@click="openOrnamentModal"
			>
				<i class="fas fa-plus fa-3x" />
			</button>
			<div ref="ornament" v-if="ornamentDrag.dragging" class="dragging-ornament" :style="{left: `${ornamentDrag.X - 18}px`, top: `${ornamentDrag.Y - 10}px`}">
				<template v-if="ornament.ball == 1">
					<img :src="ornament.image" draggable="false" class="ornament-1-icon">
					<img src="@/assets/images/christmas/ornament_1.png" draggable="false" class="ornament-1-bg">
				</template>
				<template v-if="ornament.ball == 2">
					<img :src="ornament.image" draggable="false" class="ornament-2-icon">
					<img src="@/assets/images/christmas/ornament_2.png" draggable="false" class="ornament-2-bg">
				</template>
			</div>
		</div>

		<!-- <div v-if="user && (user.is_admin || user.is_idol)" class="sticky-footer general-card d-flex flex-row">
			<button v-if="!editing" class="ml-auto btn btn-success" @click="startEditing">
				{{ $t("labels.edit_sounds") }}
			</button>
			<button v-if="editing" class="btn btn-success" @click="addGroup">
				<i class="fas fa-plus mr-2" />{{ $t("labels.add_group") }}
			</button>
			<button v-if="editing" class="ml-auto btn btn-danger" @click="cancelEditing">
				{{ $t("labels.cancel") }}
			</button>
			<button v-if="editing" class="ml-2 btn btn-primary" @click="saveAll">{{ $t("labels.save") }}</button>
		</div> -->


		<div class="lightbox-overlay" :class="{show: lightboxOrnament}" @click="closeLightbox">
			<div v-if="lightboxOrnament && lightboxOrnament.id" class="d-flex flex-row align-items-center justify-content-center mt-3" style="color: white;">
				<template v-if="lightboxOrnament.user_type != 'text'">
					<div v-if="lightboxOrnament.user_type == 'pixiv'" class="nowrap" style="font-size: 18px">
						<pixiv-icon size="20" class="fa-fw mr-2" />{{ lightboxOrnament.user_name }}
					</div>
					<div v-if="lightboxOrnament.user_type == 'discord'" class="nowrap" style="font-size: 18px">
						<i class="fab fa-fw fa-discord mr-1" />{{ lightboxOrnament.user_name }}
						<span class="text-half-muted">#{{ lightboxOrnament.ext }}</span>
					</div>
					<div v-if="lightboxOrnament.user_type == 'twitter'" class="nowrap" style="font-size: 18px">
						<i class="fab fa-fw fa-twitter mr-1" />@{{ lightboxOrnament.user_name }}
					</div>
					<div v-if="lightboxOrnament.user_type == 'youtube'" class="nowrap" style="font-size: 18px">
						<i class="fab fa-fw fa-youtube mr-1" />{{ lightboxOrnament.user_name }}
					</div>
				</template>
				<template v-if="lightboxOrnament.user_type == 'text'">
					<div v-if="lightboxOrnament.user_name" style="font-size: 18px;">{{ lightboxOrnament.user_name }}</div>
					<div v-if="!lightboxOrnament.user_name" style="font-size: 18px; opacity: 0.5">{{ $t('labels.anonymous') }}</div>
				</template>
			</div>
			<div v-if="lightboxOrnament && lightboxOrnament.id && user && (user.is_idol || user.is_admin)" class="lightbox-details">
				<button v-if="!deletingOrnament" class="lightbox-btn btn btn-danger" @click.stop="deletingOrnament = lightboxOrnament">{{ $t('labels.delete') }}</button>
				<button v-if="deletingOrnament" class="lightbox-btn btn btn-danger" @click.stop="deleteOrnament">{{ $t('labels.really_delete') }}</button>
			</div>
		</div>
		
		<div class="lightbox" :class="{show: lightboxOrnament}">
			<div v-if="lightboxOrnament" class="lightbox-ornament">
				<template v-if="lightboxOrnament.ball == 1">
					<img :src="lightboxOrnament.image" draggable="false" class="ornament-1-icon">
					<img src="@/assets/images/christmas/ornament_1.png" draggable="false" class="ornament-1-bg">
				</template>
				<template v-if="lightboxOrnament.ball == 2">
					<img :src="lightboxOrnament.image" draggable="false" class="ornament-2-icon">
					<img src="@/assets/images/christmas/ornament_2.png" draggable="false" class="ornament-2-bg">
				</template>
				<template v-if="lightboxOrnament.ball == 10">
					<img :src="lightboxOrnament.image" draggable="false" class="ornament-top-icon">
				</template>
			</div>
		</div>

		<b-modal
			id="ornamentModal"
			:title="$t('labels.new_ornament')"
			header-bg-variant="success"
		>
			<div class="row">
				<div class="col-12 form-group">
					<label>{{ $t("labels.upload_image") }}</label>
					<pickable-image
						class="side-img card p-2 shadow-sm"
						v-model="image.url"
						:thumbUrl.sync="image.thumb_url"
						:width.sync="image.width"
						:height.sync="image.height"
						style="background-color: #ffffff; height: 150px; width: 150px;"
						:commit.sync="saveFile"
						imgID="upload-img"
						fileType="image"
					/>
				</div>
			</div>
			<template v-if="image.url">
				<hr />
				<div class="row">
					<div class="col-12 form-group">
						<label>{{ $t("labels.ornament_type") }}</label>
						<div class="d-flex flex-row justify-content-around">
							<div class="pick-box" :class="{picked: newOrnament.ball == 1}" @click="newOrnament.ball = 1">
								<div class="pick-ornament">
									<img :src="image.url" draggable="false" class="ornament-1-icon">
									<img src="@/assets/images/christmas/ornament_1.png" draggable="false" class="ornament-1-bg">
								</div>
							</div>
							<div class="pick-box" :class="{picked: newOrnament.ball == 2}" @click="newOrnament.ball = 2">
								<div class="pick-ornament">
									<img :src="image.url" draggable="false" class="ornament-2-icon">
									<img src="@/assets/images/christmas/ornament_2.png" draggable="false" class="ornament-2-bg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<hr />
			<template v-if="newOrnament && !user">
				<div class="col-12 form-group">
					<label>{{ $t("labels.name") }}</label>
					<div class="w-100 d-flex flex-row justify-content-space-between">
						<input class="form-control" style="border: 1px solid #ced4da" v-model.trim="newOrnament.user_name" />
						<button class="ml-3 btn btn-success" style="min-width: 90px" @click.stop="goToSignIn">
							{{ $t("labels.sign_in") }}
						</button>
					</div>
				</div>
			</template>
			<template v-if="newOrnament && user">
				<div class="col-12 form-group">
					<label>{{ $t("labels.name") }}</label>
					<v-select
						:options="usernameOptions"
						label="username"
						:reduce="(o) => o.type"
						v-model="newOrnament.preferred_handle"
						:searchable="false"
						:clearable="false"
					>
						<template v-slot:selected-option="option">
							<template v-if="option.type == 'pixiv'">
								<pixiv-icon size="1em" class="mr-1 fa-fw" />
								{{ option.username }}
							</template>
							<template v-if="option.type == 'discord'">
								<i class="fab fa-fw fa-discord mr-1" />
								{{ option.username }}
								<span class="text-muted">#{{ option.discriminator }}</span>
							</template>
							<template v-if="option.type == 'twitter'">
								<i class="fab fa-fw fa-twitter mr-1" />
								@{{ option.username }}
							</template>
							<template v-if="option.type == 'youtube'">
								<i class="fab fa-fw fa-youtube mr-1" />
								{{ option.username }}
							</template>
						</template>
						<template v-slot:option="option">
							<template v-if="option.type == 'pixiv'">
								<pixiv-icon size="1em" class="mr-1 fa-fw" />
								{{ option.username }}
							</template>
							<template v-if="option.type == 'discord'">
								<i class="fab fa-fw fa-discord mr-1" />
								{{ option.username }}
								<span class="text-muted">#{{ option.discriminator }}</span>
							</template>
							<template v-if="option.type == 'twitter'">
								<i class="fab fa-fw fa-twitter mr-1" />
								@{{ option.username }}
							</template>
							<template v-if="option.type == 'youtube'">
								<i class="fab fa-fw fa-youtube mr-1" />
								{{ option.username }}
							</template>
						</template>
					</v-select>
				</div>
			</template>
			<template #modal-footer="{ _, cancel }">
				<button class="btn btn-secondary" @click="cancel">{{ $t("labels.back") }}</button>
				<button
					class="btn ml-3"
					:class="{[!image.url || !newOrnament.ball || creating ? 'btn-secondary' : 'btn-primary']: true}"
					@click="createOrnament"
					:disabled="!image.url || !newOrnament.ball || creating"
				>
					{{ $t("labels.create_ornament") }}
					<i v-if="creating" class="ml-2 fas fa-spinner fa-spin" />
				</button>
			</template>
		</b-modal>
	</main-page>
</template>

<script>
import ChristmasService from "@/services/ChristmasService";
import PickableImage from "@/components/PickableImage"
// import FileService from "@/services/FileService";

import Toast from "@/utils/Toast";
import _ from "lodash";
import $ from "jquery"

export default {
	name: "ChristmasTree",

	props: ["user"],

	components: { PickableImage },

	data() {
		return {
			mobile: false,
			scale: 0.5,
			loading: true,
			decorating: false,
			editing: false,
			creating: false,
			saveFile: null,

			ornaments: null,
			newOrnament: null,
			ornament: null,

			lightboxOrnament: null,
			deletingOrnament: null,

			image: {
				url: null,
				thumb_url: null,
				width: null,
				height: null,
			},

			ornamentDrag: {
				dragging: false,
				X: 0,
				Y: 0
			}
		};
	},

	created() {
		this.loadData();

		this.calcOrientation()
		window.addEventListener("resize", this.calcOrientation)
	},

	destroyed() {
		window.removeEventListener("resize", this.calcOrientation)
		window.removeEventListener("mousemove", this.calcDrag)
	},

	watch: {},

	computed: {
		usernameOptions() {
			let opts = [];
			if (!this.user) {
				return opts;
			}

			if (this.user.pixiv_user_id) {
				opts.push({ type: "pixiv", username: this.user.pixiv_user_name });
			}
			if (this.user.discord_user_id) {
				opts.push({
					type: "discord",
					username: this.user.discord_handle,
					discriminator: this.user.discord_discriminator,
				});
			}
			if (this.user.twitter_user_id) {
				opts.push({ type: "twitter", username: this.user.twitter_handle });
			}
			if (this.user.google_user_id) {
				opts.push({ type: "youtube", username: this.user.youtube_name });
			}

			return opts;
		},
	},

	methods: {
		calcOrientation() {
			let x = window.innerWidth
			let y = window.innerHeight

			if (x < y) {
				this.mobile = true
			} else {
				this.mobile = false
			}
		},

		loadData() {
			return ChristmasService.getOrnaments().then(r => {
				this.ornaments = r.data
			})
		},

		openOrnamentModal() {
			if(!this.ornament) {
				this.newOrnament = {
					left: -1,
					top: -1,
					ball: 1,
					image: ""
				}
			} else {
				this.newOrnament = _.clone(this.ornament)
			}
			
			this.$bvModal.show("ornamentModal");
		},

		createOrnament() {
			this.creating = true
			this.saveFile().then(r => {
				this.ornament = this.newOrnament
				this.ornament.image = r.data.thumb_url
				this.creating = false
				this.$bvModal.hide("ornamentModal");

				this.image.url = null
				this.image.thumb_url = null
				this.image.width = null
				this.image.height = null
			}).catch(e => {
				console.error(e)
				Toast.error("Failed to save image", e)
			})
		},

		saveAll() {
			ChristmasService.saveAll(this.ornaments)
				.then(() => {
					console.log("All saved");
					this.editing = false;
					this.loadData();
				})
				.catch((e) => {
					console.log(e);
					Toast.error("Failed to save ornaments", e);
				});
		},

		startOrnamentDrag(event) {
			this.ornamentDrag.X = event.clientX
			this.ornamentDrag.Y = event.clientY
			this.ornamentDrag.dragging = true

			window.addEventListener("mousemove", this.calcOrnamentDrag)
			window.addEventListener("mouseup", this.endOrnamentDrag)
		},

		calcOrnamentDrag(event) {
			this.ornamentDrag.X = event.clientX
			this.ornamentDrag.Y = event.clientY
		},

		endOrnamentDrag() {
			let [left, top] = this.getOrnamentOffset()
			if(!this.canEndOrnamentDrag()) {
				this.cancelOrnamentDrag()
				return
			}

			if(!this.ornament) {
				debugger;
			}

			this.ornamentDrag.dragging = false
			this.ornament.left = left
			this.ornament.top = top
			this.cancelOrnamentDrag()

			this.ornaments.push(this.ornament)
		},

		canEndOrnamentDrag() {
			// implement tree mask on canvas method of where ornaments can be dropped
			return true
		},

		getOrnamentOffset() {
			let ornamentEl = this.$refs.ornament
			console.log("REFS", this.$refs, this.$refs.ornament, ornamentEl)
			if(!ornamentEl) {
				console.error("Failed to get ornament")
				return [0, 0]
			}
			let treeEl = this.$refs.tree
			if(!treeEl) {
				console.error("Failed to get tree")
				return [0, 0]
			}

			let oRect = ornamentEl.getBoundingClientRect()
			let oX = oRect.left + (oRect.width / 2)
			let oY = oRect.top

			let tRect = treeEl.getBoundingClientRect()
			let tX = tRect.left
			let tY = tRect.top
			let tWidth = tRect.width
			let tHeight = tRect.height

			let x = oX - tX
			let y = oY - tY
			let left = (100 * x / tWidth) - 3.2727
			let top = 100 * y / tHeight
			return [left, top]
		},

		placeOrnament(event) {
			if(event.touches) {
				if(event.touches.length == 1) {
					this.doPlaceOrnament(event.touches[0].clientX, event.touches[0].clientY)
				}
				// else ignore
			} else {
				this.doPlaceOrnament(event.clientX, event.clientY)
			}
		},

		doPlaceOrnament(oX, oY) {
			if(!this.ornament) return

			console.log("DO PLACE", oX, oY)
			let treeEl = this.$refs.tree
			if(!treeEl) {
				console.error("Failed to get tree")
				return
			}

			let tRect = treeEl.getBoundingClientRect()
			let tX = tRect.left
			let tY = tRect.top
			let tWidth = tRect.width
			let tHeight = tRect.height

			let x = oX - tX
			let y = oY - tY
			let left = (100 * x / tWidth) - 3.2727
			let top = 100 * y / tHeight

			this.ornament.left = left
			this.ornament.top = top

			let i = this.ornaments.indexOf(this.ornament)
			if(i < 0) {
				this.ornaments.push(this.ornament)
			}
		},

		cancelOrnamentDrag() {
			window.removeEventListener("mousemove", this.calcOrnamentDrag)
			window.removeEventListener("mouseup", this.endOrnamentDrag)
			this.ornamentDrag.dragging = false
			this.ornamentDrag.X = 0
			this.ornamentDrag.Y = 0
		},

		cancelOrnament() {
			this.ornaments = _.without(this.ornaments, this.ornament)
			this.ornament = null
		},

		rehangOrnament() {
			this.ornaments = _.without(this.ornaments, this.ornament)
			this.ornament.left = -1
			this.ornament.top = -1
		},

		finishOrnament() {
			this.saving = true
			ChristmasService.saveOrnament(this.ornament, this.user).then(() => {
				console.log("Ornament saved")
				Toast.success(this.$i18n.t('labels.ornament_saved'), this.$i18n.t('labels.merry_christmas'))
				this.loadData().then(() => {
					this.cancelOrnament()
				})
			}).catch(e => {
				console.log(e)
				Toast.error("Failed to save ornament", e)
			});
		},

		openLightbox(ornament) {
			if(this.mobile && this.ornament) return
			this.lightboxOrnament = ornament
		},

		closeLightbox() {
			this.lightboxOrnament = null
		},

		deleteOrnament() {
			if(!this.deletingOrnament) return

			ChristmasService.deleteOrnament(this.deletingOrnament.id).then(() => {
				Toast.info(this.$i18n.t('labels.ornament_deleted'))
				this.loadData().then(() => {
					this.closeLightbox()
				})
			}).catch(e => {
				console.error(e)
				Toast.error("Failed to delete ornament", e)
			})
		},

		goToSignIn() {
			this.$bvModal.hide("ornamentModal");
			this.$nextTick(() => {
				$("#userDropdownButton").dropdown("toggle");
			});
		},

		fullRefresh() {
			this.$router.go()
		}
	},
};
</script>

<style scoped>
.title-text {
	color: #fff;
	text-align: center;
	font-family: "lato", sans-serif;
	font-weight: 300;
	font-size: 50px;
	letter-spacing: 10px;
	padding-left: 10px;

	background: -webkit-linear-gradient(white, #016922);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}
.delu-text {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 24px;
	font-weight: 500;
}
.sticky-footer {
	position: fixed;
	bottom: -10px;
	padding-bottom: 18px;
	left: -10px;
	padding-left: 26px;
	right: -10px;
	padding-right: 26px;
	height: 62px;
}
.footer-spacer {
	height: 62px;
}
.tree {
	position: relative;
}
.tree:not(.condense) > img {
	max-height: calc(100vh - 150px);
}
.tree.condense > img {
	max-height: calc(100vh - 200px);
}
.ornament {
	position: absolute;
	width: 6.5454%;
	height: 6.1893%;
	transform-origin: center top;
	transition: transform 0.25s;
	cursor: pointer;
}
.ornament:hover {
	transform: scale(1.1);
}
.ornament.topper {
	transform-origin: center bottom;
}
.ornament-1-bg {
	left: 0;
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
}
.ornament-1-icon {
	position: absolute;
	top: 29.4118%;
	left: 0;
	border-radius: 50%;
	width: 100%;
	height: 70.5882%;
}
.ornament-2-bg {
	left: 0;
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
}
.ornament-2-icon {
	position: absolute;
	top: 29.4118%;
	left: 0;
	border-radius: 50%;
	width: 100%;
	height: 70.5882%;
}
.ornament-top-icon {
	position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 200%;
    height: 141.2%;
    margin-left: -50%;
}
.ornament-box {
	user-select: none;
	border: 2px solid green;
	border-radius: 10px;
	width: 177px;
	height: 177px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.new-ornament {
	user-select: none;
	width: 108px;
	height: 153px;
	position: relative;
}
.lightbox-ornament {
	user-select: none;
	width: 144px;
	height: 204px;
	position: relative;
}
.dragging-ornament {
	user-select: none;
	position: fixed;
	width: 36px;
	height: 51px;
}

.pick-box {
	padding: 8px;
	height: 169px;
	width: 169px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pick-box:hover {
	background-color: #eeeeee;
}
.pick-box:active {
	background-color: #dddddd;
}
.pick-box.picked {
	background-color: #eeeeee;
	border: 2px solid grey;
}
.pick-ornament {
	user-select: none;
	width: 108px;
	height: 153px;
	position: relative;
}

.new-button {
	border-radius: 50%;
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: 80px;
	height: 80px;
}

.lightbox-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.25s;
	backdrop-filter: blur(6px);
	pointer-events: none;
	z-index: 30;
}

.lightbox-overlay.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	z-index: 31;
	pointer-events: none;
	padding: 60px 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.lightbox.show {
	display: flex;
}

.lightbox-btn {
	position: absolute;
	right: 50px;
	bottom: 50px;
}

.mobile-top-bar {
	margin-left: -1rem;
	margin-right: -1rem;
	margin-top: -2rem;
	height: 50px;
	border-bottom: 2px solid green;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100% + 2rem);
	margin-bottom: 2rem;
}
</style>
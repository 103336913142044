<template>
	<div class="row">
		<div class="col-12 form-group">
			<label>{{ $t("labels.event") }}</label>
			<v-select
				v-model="value.event_id"
				:options="events"
				label="text"
				:reduce="(o) => o.value"
				disabled
				class="disabled"
				:clearable="false"
			></v-select>
		</div>
		<template v-if="!user && !value.doAnon">
			<div class="col-12 form-group">
				<label>{{ $t("labels.name") }}</label>
				<div class="w-100 d-flex flex-row justify-content-space-between">
					<button class="flex btn btn-success" @click.stop="goToSignIn">
						{{ $t("labels.sign_in") }}
					</button>
					<button class="flex btn btn-outline-primary ml-3" @click="openAnonModal">
						{{ $t("labels.post_anonymously") }}
					</button>
				</div>
			</div>
		</template>
		<template v-if="value.doAnon">
			<div class="col-12 form-group">
				<label>{{ $t("labels.name") }}</label>
				<div class="w-100 d-flex flex-row justify-content-space-between">
					<input class="form-control" v-model.trim="value.user_name" />
					<button class="ml-3 btn btn-success" style="min-width: 90px" @click.stop="goToSignIn">
						{{ $t("labels.sign_in") }}
					</button>
				</div>
				<div class="w-100 text-muted mt-1">
					{{ $t('labels.sign_in_warning') }}
				</div>
			</div>
		</template>
		<template v-if="user">
			<div class="col-12 form-group">
				<label>{{ $t("labels.name") }}</label>
				<v-select
					:options="usernameOptions"
					label="username"
					:reduce="(o) => o.type"
					v-model="value.preferred_handle"
					:searchable="false"
					:clearable="false"
				>
					<template v-slot:selected-option="option">
						<template v-if="option.type == 'pixiv'">
							<pixiv-icon size="1em" class="mr-1 fa-fw" />
							{{ option.username }}
						</template>
						<template v-if="option.type == 'discord'">
							<i class="fab fa-fw fa-discord mr-1" />
							{{ option.username }}
							<span class="text-muted">#{{ option.discriminator }}</span>
						</template>
						<template v-if="option.type == 'twitter'">
							<i class="fab fa-fw fa-twitter mr-1" />
							@{{ option.username }}
						</template>
						<template v-if="option.type == 'youtube'">
							<i class="fab fa-fw fa-youtube mr-1" />
							{{ option.username }}
						</template>
					</template>
					<template v-slot:option="option">
						<template v-if="option.type == 'pixiv'">
							<pixiv-icon size="1em" class="mr-1 fa-fw" />
							{{ option.username }}
						</template>
						<template v-if="option.type == 'discord'">
							<i class="fab fa-fw fa-discord mr-1" />
							{{ option.username }}
							<span class="text-muted">#{{ option.discriminator }}</span>
						</template>
						<template v-if="option.type == 'twitter'">
							<i class="fab fa-fw fa-twitter mr-1" />
							@{{ option.username }}
						</template>
						<template v-if="option.type == 'youtube'">
							<i class="fab fa-fw fa-youtube mr-1" />
							{{ option.username }}
						</template>
					</template>
				</v-select>
			</div>
		</template>

		<template v-if="type == 'text'">
			<div class="col-12 form-group">
				<div class="d-flex flex-row mr-1">
					<label class="flex">
						{{ $t("labels.message") }}
						<character-count :value="value.message" :limit="1120" style="font-size: 12px" />
					</label>
					<language-picker v-model="value.language" />
					<div v-if="!value.language" class="language-reminder">🠕</div>
				</div>
				<textarea rows="11" class="form-control" v-model="value.message" />
				<!-- <emote-text-area rows="11" v-model="value.message" class="mt-3" /> -->
			</div>
			<div v-if="value.language && value.language != 'JA'" class="col-12 form-group">
				<div class="d-flex flex-row mr-1">
					<label class="flex">
						{{ $t("labels.translation") }}
						<character-count
							v-if="!value.request_mtl"
							:value="value.translation"
							:limit="1120"
							style="font-size: 12px"
						/>
					</label>
					<language-picker :value="'JA'" noPick />
				</div>
				<textarea v-if="!value.request_mtl" rows="11" class="form-control" v-model="value.translation" />
				<div v-if="value.request_mtl" class="card p-3 text-muted">
					{{ $t("messages.translation_provided_by_deepl") }}
				</div>
			</div>
		</template>

		<template v-if="type == 'image' || type == 'video'">
			<template v-if="type == 'image'">
				<div class="col-12 form-group">
					<label>{{ $t("labels.upload_image") }}</label>
					<pickable-image
						class="side-img card p-2 shadow-sm"
						v-model="value.image_url"
						:thumbUrl.sync="value.thumb_url"
						:width.sync="value.image_width"
						:height.sync="value.image_height"
						style="min-height: 150px"
						:commit.sync="saveFile"
						imgID="upload-img"
						fileType="image"
					/>
					<div class="w-100 text-muted mt-1">
						{{ $t('labels.image_warning') }}
					</div>
				</div>
			</template>

			<template v-if="type == 'video'">
				<div class="col-12 form-group">
					<label>{{ $t("labels.video_url") }}</label>
					<input
						class="form-control"
						v-model="value.video_url"
						:class="{
							'is-invalid': value.video_url && !value.youtubeID,
							'is-valid': value.video_url && value.youtubeID,
						}"
					/>
					<div class="w-100 text-muted mt-1">
						{{ $t('labels.video_warning') }}
					</div>
				</div>
				<div v-if="value.youtubeID" class="col-12 form-group d-flex flex-row justify-content-center">
					<div class="video-box">
						<iframe
							class="youtube-embed"
							:src="`https://www.youtube.com/embed/${value.youtubeID}`"
							frameborder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</div>
			</template>

			<template v-if="value.includeMessage">
				<div class="col-12 form-group">
					<div class="d-flex flex-row">
						<label class="flex">
							{{ $t("labels.message") }}
							<character-count :value="value.message" :limit="280" style="font-size: 12px" />
						</label>
						<language-picker v-model="value.language" class="mr-2" />
						<div v-if="!value.language" class="language-reminder">🠕</div>
						<button
							class="btn btn-sm btn-icon btn-danger btn-inline-rounded mr-1"
							@click="value.includeMessage = false"
						>
							<i class="fas fa-times" />
						</button>
					</div>
					<textarea rows="4" class="form-control" v-model="value.message" />
				</div>
				<div v-if="value.language && value.language != 'JA'" class="col-12 form-group">
					<div class="d-flex flex-row mr-1">
						<label class="flex">
							{{ $t("labels.translation") }}
							<character-count
								v-if="!value.request_mtl"
								:value="value.translation"
								:limit="280"
								style="font-size: 12px"
							/>
						</label>
						<language-picker :value="'JA'" noPick />
					</div>
					<textarea v-if="!value.request_mtl" rows="4" class="form-control" v-model="value.translation" />
					<div v-if="value.request_mtl" class="card p-3 text-muted">
						{{ $t("messages.translation_provided_by_deepl") }}
					</div>
				</div>
			</template>

			<template v-if="value.language && value.language != 'JP'"> </template>
		</template>

		<div class="col-12 d-flex flex-row flex-wrap">
			<button
				v-if="type != 'text' && !value.includeMessage"
				class="btn btn-secondary mr-3 mb-2"
				@click="value.includeMessage = true"
			>
				<i class="fas fa-plus fa-fw mr-2" />{{ $t("labels.add_message") }}
			</button>
			<template v-if="type == 'text' || value.includeMessage">
				<b-form-checkbox
					v-if="value.language && value.language != 'JA'"
					v-model="value.request_mtl"
					name="check-button"
					button
					:button-variant="value.request_mtl ? 'primary' : ''"
					class="mr-3 mb-2"
				>
					<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -8px">
						<i
							class="fa"
							:class="{
								'fa-check': value.request_mtl,
								'fa-times-circle': !value.request_mtl,
								'text-very-muted': !value.request_mtl,
							}"
							style="min-width: 15px"
						/>
						<span class="ml-2 text-left">{{ $t("labels.deepl_translation") }}</span>
					</div>
				</b-form-checkbox>
				<b-form-checkbox
					v-if="value.language && value.language != 'JA'"
					v-model="personalTL"
					name="check-button"
					button
					:button-variant="personalTL ? 'primary' : ''"
					class="mr-3 mb-2"
				>
					<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
						<i
							class="fa"
							:class="{
								'fa-check': personalTL,
								'fa-times-circle': !personalTL,
								'text-very-muted': !personalTL,
							}"
							style="min-width: 15px"
						/>
						<span class="ml-2 text-left">{{ $t("labels.personal_translation") }}</span>
					</div>
				</b-form-checkbox>
			</template>
			<!-- <b-form-checkbox
				v-if="user && user.pixiv_user_id"
				v-model="value.members_only"
				name="check-button"
				button
				:button-variant="value.members_only ? 'primary' : ''"
				class="mb-2"
			>
				<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
					<i
						class="fa"
						:class="{
							'fa-check': value.members_only,
							'fa-times-circle': !value.members_only,
							'text-very-muted': !value.members_only,
						}"
						style="min-width: 15px"
					/>
					<span class="ml-2 text-left">{{ $t("labels.members_only") }}</span>
				</div>
			</b-form-checkbox> -->
		</div>
		<div v-if="value.members_only" class="col-12 d-flex flex-row flex-wrap align-items-center">
			<i class="fas fa-exclamation-triangle mx-1" />
			<span>{{ $t("messages.members_only_post") }}</span>
		</div>
	</div>
</template>

<script>
import LanguagePicker from "@/components/LanguagePicker";
import PickableImage from "@/components/PickableImage";
import CharacterCount from "@/components/CharacterCount";
// import EmoteTextArea from "@/components/EmoteTextArea";
// import Store from "@/utils/Store";
// import _ from "lodash";
import $ from "jquery";

export default {
	name: "PostSubmissionForm",

	props: ["value", "type", "user", "events"],

	components: {
		LanguagePicker,
		PickableImage,
		CharacterCount,
		// EmoteTextArea
	},

	data() {
		return {
			saveFile: null,
			personalTL: false,
		};
	},

	created() {},

	watch: {
		"value.request_mtl"() {
			if (this.value.request_mtl && this.personalTL) {
				this.personalTL = false;
			}
			if (!this.value.request_mtl && !this.personalTL) {
				this.personalTL = true;
			}
		},

		personalTL() {
			if (this.value.request_mtl && this.personalTL) {
				this.value.request_mtl = false;
			}
			if (!this.value.request_mtl && !this.personalTL) {
				this.value.request_mtl = true;
			}
		},

		saveFile() {
			this.$emit("update:commit", this.saveFile);
		},

		"value.video_url"() {
			if (!this.value.video_url) {
				return null;
			}
			this.value.youtubeID = this.getYoutubeID(this.value.video_url);
		},
	},

	computed: {
		usernameOptions() {
			let opts = [];
			if (!this.user) {
				return opts;
			}

			if (this.user.pixiv_user_id) {
				opts.push({ type: "pixiv", username: this.user.pixiv_user_name });
			}
			if (this.user.discord_user_id) {
				opts.push({
					type: "discord",
					username: this.user.discord_handle,
					discriminator: this.user.discord_discriminator,
				});
			}
			if (this.user.twitter_user_id) {
				opts.push({ type: "twitter", username: this.user.twitter_handle });
			}
			if (this.user.google_user_id) {
				opts.push({ type: "youtube", username: this.user.youtube_name });
			}

			return opts;
		},
	},

	methods: {
		openAnonModal() {
			this.$bvModal.show("anonModal");
		},

		getYoutubeID(url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			console.log("URL", url);
			let match = url.match(regExp);
			console.log("MATCH", match);

			return match && match[2].length === 11 ? match[2] : null;
		},

		goToSignIn() {
			// Store.set(this, "returnTo", "submit");
			// this.$router.push("/login");

			$("#userDropdownButton").dropdown("toggle");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-inline-rounded {
	margin-bottom: 0.15rem;
	margin-top: -0.15rem;
	border-radius: 50%;
	opacity: 0.9;
	width: 25px;
	height: 25px;
	padding: 0.2rem 0.25rem;
}

.language-reminder {
	position: absolute;
	right: 65px;
	top: 10px;
	font-size: 60px;
	color: #ff0000;
	opacity: 0.5;
}

@media only screen and (min-width: 620px) {
	.youtube-embed {
		width: 560px;
		height: 315px;
	}
}

@media only screen and (max-width: 400px) {
}
</style>

<template>
	<main-page socialsOnly>
		<div class="container" style="min-height: 100vh">
			<label>{{ $t("labels.upload_image") }}</label>
			<pickable-image
				class="side-img card p-2 shadow-sm"
				v-model="image_url"
				:thumbUrl.sync="thumb_url"
				:width.sync="image_width"
				:height.sync="image_height"
				style="min-height: 150px"
				:commit.sync="saveFile"
				imgID="upload-img"
				fileType="image"
			/>
			<div class="w-100 text-muted mt-1">
				{{ $t("labels.image_warning") }}
			</div>
		</div>
	</main-page>
</template>


<script>
import Toast from "@/utils/Toast";

export default {
	name: "SigmanoProfile",

	props: [],

	components: {},

	data() {
		return {
			Toast: Toast,

			image_url: null,
			thumb_url: null,
			image_width: null,
			image_height: null,
		};
	},

	created() {},

	methods: {
		saveFile(url) {
			console.log("URL", url);
		},
	},
};
</script>

<style scoped>
</style>
export default {
	vue: null,

	init(vue) {
		this.vue = vue;
		this.vue.$toastr.defaultPosition = "toast-bottom-right";
	},

	info(msg, submsg) {
		msg = `<span class="text-md">` + msg + `</span>`;
		if (!submsg) {
			submsg = ""
		}
		this.vue.$toastr.i(submsg, msg);
	},

	infoSticky(msg, submsg) {
		msg = `<span class="text-md">` + msg + `</span>`;
		if (!submsg) {
			submsg = ""
		}
		this.vue.$toastr.Add({
			title: msg, // Toast Title
			msg: submsg, // Toast Message
			timeout: 0, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
			//progressBarValue: 50, // Manually update progress bar value later; null (not 0) is default
			type: "info", // Toast type,
		});
	},

	success(msg, submsg) {
		msg = `<span class="text-md">` + msg + `</span>`;
		this.vue.$toastr.s(submsg, msg);
	},

	extractErrorMessage(err) {
		if (typeof err == "string") {
			return err;
		} else if (
			err &&
			err.response &&
			err.response.data &&
			err.response.data.data &&
			err.response.data.data.Err
		) {
			return err.response.data.data.Err;
		} else if (
			err &&
			err.response &&
			err.response.data &&
			err.response.data.detail
		) {
			return err.response.data.detail;
		} else if (
			err &&
			err.response &&
			err.response.data &&
			err.response.data.message
		) {
			return err.response.data.message;
		} else if (
			err &&
			err.response &&
			err.response.data &&
			err.response.data.error &&
			err.response.data.error.detail
		) {
			return err.response.data.error.detail;
		} else if (
			err &&
			err.response &&
			err.response.data &&
			err.response.data.error &&
			err.response.data.error.message
		) {
			return err.response.data.error.message;
		} else if (
			err &&
			err.response &&
			err.response.data &&
			typeof err.response.data == "string"
		) {
			return err.response.data;
		} else if (err.message) {
			return `Client error: ${err.message}`;
		}
	},

	extractDupError(errMsg) {
		let regex = /index: (\w+)_1 dup key: { : "(.+)" }}]}/g;
		let match = regex.exec(errMsg);
		if (match) {
			return `The ${match[1]} '${match[2]}' is already in use`;
		}
		return errMsg;
	},

	error(msg, err) {
		// eslint-disable-next-line no-console
		if (err && err.suppress) {
			return;
		}

		msg = `<span class="text-md">` + msg + `</span>`;

		var errMsg;
		if (err) {
			errMsg = this.extractErrorMessage(err);
		}
		// if (errMsg) {
		//   msg =
		//     msg + `<br/><div class="text-muted text-white text-xs">${errMsg}</div>`;
		// }

		if (errMsg && errMsg.includes("duplicate key error")) {
			console.log("Duplicate index error:", errMsg);
			errMsg = this.extractDupError(errMsg);
		}

		this.vue.$toastr.e(errMsg, msg);
	},

	warning(msg, submsg) {
		this.vue.$toastr.w(submsg, msg);
	}
};

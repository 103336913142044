<template>
	<div>
		<div
			class="canvas"
			@mousemove="heatmapHover"
			@click="heatmapClick"
			ref="container"
			:style="{ transform: `scale(${this.scaleFactor}) translateX(-50%)` }"
		>
			<template v-for="(def, i) in defs">
				<img
					v-if="!def.full_url"
					:key="i"
					:src="def.img_url"
					class="canvas-element"
					:style="{
						top: `${def.top}px`,
						left: `${def.left}px`,
						width: `${def.width}px`,
					}"
				/>
				<img
					v-if="def.full_url"
					:key="i"
					:src="def.img_url"
					class="canvas-element"
					:style="{
						top: `${def.top}px`,
						left: `${def.left}px`,
						width: `${def.width}px`,
					}"
				/>
			</template>
			<canvas width="1410" height="2000" class="heatmap" ref="heatmap"></canvas>
		</div>

		<div class="back-button-container">
			<button class="ui-button" @click="goBack">&lt;&nbsp;{{ $t("labels.back") }}</button>
		</div>

		<div class="lightbox-overlay" :class="{ show: showLightbox }" @click="closeModals"></div>

		<div class="lightbox" :class="{ show: showLightbox }">
			<loading
				v-if="showLightbox && !showLightboxContent"
				class="lightbox-loading"
				color="rgba(255 255 255 / 100%)"
				font="PixelMPlus10"
			/>
			<img
				v-if="lightboxImage"
				:src="lightboxImage"
				class="lightbox-image"
				:class="{ show: showLightboxContent }"
				@load="lightboxImageLoaded"
			/>
			<div
				v-if="lightboxDetails"
				class="lightbox-details"
				:class="{ show: showLightboxContent }"
				v-html="lightboxDetails"
			></div>
		</div>
	</div>
</template>

<script>
import Loading from "@/components/Birthday/Loading";
var heatmap = require("@/assets/images/birthday/heatmap2022.png");

export default {
	name: "Canvas",

	props: ["shift", "src", "horizontal", "vertical", "user"],

	components: { Loading },

	data() {
		return {
			defs: [
				{
					artist: "",
					img_url: "https://files.delutaya.club/file/delucanvas/Balloon-Background.webp",
					full_url: "",
					artist_url: "",
					top: 0,
					left: 0,
					width: 1410,
				},
				{
					artist: "よーな",
					img_url: "https://files.delutaya.club/file/delucanvas/438.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/438_full.png",
					artist_url: "https://twitter.com/yo__na__",
					top: 123,
					left: 650,
					width: 509,
				},
				{
					artist: "月野",
					img_url: "https://files.delutaya.club/file/delucanvas/del.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/del_full.png",
					artist_url: "https://twitter.com/tsuki_nonono",
					top: 75,
					left: 149,
					width: 707,
				},
				{
					artist: "Hyde",
					img_url: "https://files.delutaya.club/file/delucanvas/delubday.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/delubday_full.png",
					artist_url: "https://twitter.com/tabakko",
					top: 629,
					left: 299,
					width: 756,
				},
				{
					artist: "Nyctea",
					img_url: "https://files.delutaya.club/file/delucanvas/delutaya.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/delutaya_full.png",
					artist_url: "https://twitter.com/NycteaSnow",
					top: 509,
					left: 836,
					width: 484,
				},
				{
					artist: "lyrinne",
					img_url: "https://files.delutaya.club/file/delucanvas/delu-lyrinne.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/delu-lyrinne_full.png",
					artist_url: "https://twitter.com/miannawzays",
					top: 1140,
					left: 855,
					width: 419,
				},
				{
					artist: "Misaネキ",
					img_url: "https://files.delutaya.club/file/delucanvas/misadelu.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/misadelu_full.png",
					artist_url: "https://twitter.com/Misamisatotomi",
					top: 1454,
					left: 429,
					width: 517,
				},
				{
					artist: "Maru",
					img_url: "https://files.delutaya.club/file/delucanvas/oj.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/oj_full.png",
					artist_url: "https://twitter.com/Maru_ccy",
					top: 291,
					left: 64,
					width: 478,
				},
				{
					artist: "ドクロちゃん",
					img_url: "https://files.delutaya.club/file/delucanvas/delubd.webp",
					full_url: "https://files.delutaya.club/file/delucanvas/delubd_full.png",
					artist_url: "https://twitter.com/DokuroDx",
					top: 870,
					left: 0,
					width: 531,
				},
				{
					artist: "",
					img_url: "https://files.delutaya.club/file/delucanvas/Frame.webp",
					full_url: "",
					artist_url: "",
					top: 0,
					left: 0,
					width: 1410,
				},
			],
			elements: [],
			mouseoverLayer: null,
			prevMouseoverLayer: null,
			scaleFactor: 1,

			showLightbox: false,
			lightboxImage: null,
			lightboxDetails: null,
			showLightboxContent: false,
		};
	},

	created() {
		window.addEventListener("resize", this.doScale);
		this.doScale();
	},

	destroyed() {
		window.removeEventListener("resize", this.doScale);
	},

	mounted() {
		this.loadHeatmap();
		this.mapElements();
	},

	methods: {
		loadHeatmap() {
			var ctx = this.$refs.heatmap.getContext("2d");
			var img = new Image();
			img.onload = function () {
				console.log("do load", ctx, img);
				ctx.drawImage(img, 0, 0);
			};
			img.src = heatmap;
			console.log("set src to", heatmap);
		},

		mapElements() {
			this.elements = this.$refs.container.querySelectorAll(".canvas-element");
		},

		heatmapHover(e) {
			let pixel = this.$refs.heatmap.getContext("2d").getImageData(e.offsetX, e.offsetY, 1, 1).data;
			if (pixel[3] == 0) {
				this.mouseoverLayer = null;
			} else {
				let layerNum = this.colorToLayer(pixel);
				this.mouseoverLayer = layerNum;
			}
			if (this.mouseoverLayer != this.prevMouseoverLayer) {
				this.changeHovers(this.prevMouseoverLayer, this.mouseoverLayer);
				this.prevMouseoverLayer = this.mouseoverLayer;
			}
		},

		changeHovers(outEl, inEl) {
			console.log("Change hover layer", outEl, inEl);
			if (outEl != null) {
				this.elements[outEl].classList.remove("hover");
			}
			if (inEl != null) {
				this.elements[inEl].classList.add("hover");
				this.$refs.container.classList.add("hovering");
			} else {
				this.$refs.container.classList.remove("hovering");
			}
		},

		heatmapClick(e) {
			let pixel = this.$refs.heatmap.getContext("2d").getImageData(e.offsetX, e.offsetY, 1, 1).data;
			if (pixel[3] == 0) {
				this.mouseoverLayer = null;
			} else {
				let layerNum = this.colorToLayer(pixel);
				this.expandLayer(layerNum);
			}
		},

		expandLayer(index) {
			let def = this.defs[index];
			console.log("Layer def", def);
			if (def.full_url) {
				let details = `<div>
    <div>Artist: ${def.artist}</div>
    <a href="${def.artist_url}">${def.artist_url}</a>
</div>`;
				this.loadLightboxImage(def.full_url, details);
			}
		},

		colorToLayer(pixel) {
			let r = pixel[0];
			r = (r + 1) / 64 - 1;
			let g = pixel[1];
			g = (g + 1) / 64 - 1;
			let b = pixel[2];
			b = (b + 1) / 64 - 1;
			return r * 16 + g * 4 + b;
		},

		doScale() {
			let vw = window.innerWidth - 10;
			if (vw < 1410) {
				this.scaleFactor = vw / 1410;
			} else {
				this.scaleFactor = 1;
			}
		},

		loadLightboxImage(url, details) {
			this.lightboxImage = url;
			this.lightboxDetails = details;
			this.showLightboxContent = false;
			this.$nextTick(() => {
				this.showLightbox = true;
			});
		},

		lightboxImageLoaded(e) {
			console.log("LOADED IMAGE", e);
			setTimeout(() => {
				this.showLightboxContent = true;
			}, 100);
		},

		closeModals() {
			this.showLightbox = false;
			this.lightboxImage = null;
			this.lightboxDetails = null;
			this.showLightboxContent = false;
		},

		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>

<style scoped>
@font-face {
	font-family: "PixelMPlus10";
	src: url("/fonts/PixelMplus10-Regular.ttf");
}

.canvas {
	position: absolute;
	top: 0px;
	left: 50%;
	transform-origin: left top;
}
.canvas-element {
	position: absolute;
	pointer-events: none;
	transition: filter 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.heatmap {
	width: 1410px;
	height: 2000px;
	/* display: none; */
}
.canvas.hovering {
	cursor: pointer;
}
.canvas.hovering:hover .canvas-element:not(.hover) {
	filter: brightness(50%);
}
.canvas-element.hover {
	/* filter: brightness(125%); */
}

.lightbox-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.25s;
	backdrop-filter: blur(6px);
	pointer-events: none;
	z-index: 30;
}

.lightbox-overlay.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	z-index: 31;
	pointer-events: none;
	padding: 60px 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.lightbox.show {
	display: flex;
	pointer-events: none;
}

.lightbox-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.5;
}

.lightbox-image {
	opacity: 0;
	transition: opacity 0.45s;
	max-width: 100%;
	max-height: 100%;
	height: auto;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	background-color: #000;
}
.lightbox-image.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox-details {
	opacity: 0;
	transition: opacity 0.45s;
	border: 4px solid rgba(255 255 255 / 75%);
	color: rgba(255 255 255 / 75%);
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	filter: drop-shadow(0px 0px 2px rgba(255 255 255 / 62%)) drop-shadow(0px 0px 4px rgba(255 255 255 / 37%));
	padding: 10px 16px;
	font-family: PixelMPlus10;
	font-size: 20px;
	margin-top: 20px;
}
.lightbox-details.show {
	opacity: 1;
	pointer-events: all;
}

.back-button-container {
	position: fixed;
	top: 30px;
	right: 30px;
}

.ui-button {
	padding: 0rem 1rem;
	font-family: PixelMPlus10;
	font-size: 24px;
	border: 2px solid rgba(255 255 255 / 75%);
	color: rgba(255 255 255 / 75%);
	background-color: transparent;
	transition: all 0.15s;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%)) drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
	text-shadow: 0px 0px 1px rgba(255 255 255 / 75%), 0px 0px 2px rgba(255 255 255 / 50%);
	backdrop-filter: brightness(0.65);
}
.ui-button:hover {
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 50%)) drop-shadow(0px 0px 6px rgba(255 255 255 / 20%));
	border: 2px solid rgba(255 255 255 / 40%);
}
</style>
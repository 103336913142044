import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthService from "@/services/AuthService"

import Login from "@/views/Login"
import Return from "@/views/Return"
import PrivacyPolicy from "@/views/PrivacyPolicy"
import TermsOfService from "@/views/TermsOfService"
import ErrorPage from "@/views/Error"
import Submit from "@/views/Submit"
import ModPostList from "@/views/ModPostList"
import PostWall from "@/views/PostWall"
import Profile from "@/views/Profile"
import MembersList from "@/views/MembersList"
import Soundboard from "@/views/Soundboard"
import SchedulesEdit from "@/views/SchedulesEdit"
import ChristmasTree from "@/views/ChristmasTree"

import Splash from "@/views/Birthday/Splash"
import BirthdayWall from "@/views/Birthday/BirthdayWall"

import Birthday2022 from "@/views/Birthday2022/BirthdayHome"
import Birthday2022Starbox from "@/views/Birthday2022/Starbox"
import Canvas2022 from "@/components/Birthday/Canvas2022"

import Birthday2023 from "@/views/Birthday2023/BirthdayHome"
import BirthdayWall2023 from "@/views/Birthday2023/BirthdayWall"
import Canvas2023 from "@/views/Birthday2023/Canvas"

import NotFound from "@/views/NotFound"

import Canvas from "@/components/Birthday/Canvas"

import ImageUpload from "@/views/ImageUpload"
import FileUpload from "@/views/FileUpload"
import FileDownload from "@/views/FileDownload"
import RepeatMessages from "@/views/RepeatMessages"

import DelutayaProfile from "@/views/profiles/DelutayaProfile"
import SigmanoProfile from "@/views/profiles/SigmanoProfile"

import Toast from '../utils/Toast'
import Theme from "../utils/Theme"

Vue.use(VueRouter)

const MEMBER = true
const ADMIN = true
const IDOL = true

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
		props: initProps,
	},
	{
		path: '/canvas',
		name: 'Canvas',
		component: Canvas,
		props: initProps,
	},
	{
		path: '/return',
		name: 'Return',
		component: Return,
		props: initProps,
	},
	{
		path: '/privacy_policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy,
		props: initProps,
	},
	{
		path: '/tos',
		name: 'TermsOfService',
		component: TermsOfService,
		props: initProps,
	},
	{
		path: '/error',
		name: 'Error',
		component: ErrorPage,
		props: initProps,
	},
	{
		path: '/submit',
		name: 'Submit',
		component: Submit,
		props: initProps,
	},
	{
		path: '/100k',
		name: 'PostWall',
		component: PostWall,
		props: initProps,
	},
	{
		path: '/mod_posts',
		name: 'ModPosts',
		component: ModPostList,
		props: initProps,
		meta: {
			access: { ADMIN, IDOL }
		},
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		props: initProps,
		meta: {
			access: { MEMBER, ADMIN, IDOL }
		},
	},
	{
		path: '/members',
		name: 'Members',
		component: MembersList,
		props: initProps,
		meta: {
			access: { ADMIN, IDOL }
		},
	},
	{
		path: '/soundboard',
		name: 'Soundboard',
		component: Soundboard,
		props: initProps,
	},
	{
		path: "/schedules",
		name: "SchedulesEdit",
		component: SchedulesEdit,
		props: initProps,
	},
	{
		path: '/splash',
		name: 'Splash',
		component: Splash,
		props: initProps,
		meta: {
			forceTheme: 'birthday'
		}
	},
	{
		path: '/birthday2021',
		name: 'BirthdayWall',
		component: BirthdayWall,
		props: initProps,
		meta: {
			forceTheme: 'birthday'
		}
	},
	{
		path: '/birthday2022',
		name: "Birthday2022",
		component: Birthday2022,
		props: initProps,
		meta: {
			forceTheme: "birthday2022"
		}
	},
	{
		path: "/telescope",
		name: "Birthday2022Starbox",
		component: Birthday2022Starbox,
		props: initProps,
		meta: {
			forceTheme: "birthday2022"
		}
	},
	{
		path: "/canvas2022",
		name: "Cnvas2022",
		component: Canvas2022,
		props: initProps,
		meta: {
			forceTheme: "birthday2022"
		}
	},
	{
		path: "/birthday2023",
		name: "Birthday2023",
		component: Birthday2023,
		props: initProps,
		meta: {
			forceTheme: "birthday2022"
		}
	},
	{
		path: "/messages2023",
		name: "BirthdayWall2023",
		component: BirthdayWall2023,
		props: initProps,
		meta: {
			forceTheme: "birthday2023_wall"
		}
	},
	{
		path: "/pinboard",
		name: Canvas2023,
		component: Canvas2023,
		props: initProps,
		meta: {
			forceTheme: "birthday2022"
		}
	},
	{
		path: "/tree",
		name: "ChristmasTree",
		component: ChristmasTree,
		props: initProps,
	},
	{
		path: "/image_upload",
		name: "ImageUpload",
		component: ImageUpload,
		props: initProps,
	},
	{
		path: "/files",
		name: "FileUpload",
		component: FileUpload,
		props: initProps,
	},
	{
		path: "/reference/delutaya",
		name: "DelutayaProfile",
		component: DelutayaProfile,
		props: initProps,
	},
	{
		path: "/reference/sigmano",
		name: "SigmanoProfile",
		component: SigmanoProfile,
		props: initProps,
	},
	{
		path: "/%CE%94/:path+",
		name: "FileDownload",
		component: FileDownload,
		props: initProps,
	},
	{
		path: "/repeat_messages",
		name: "RepeatMessages",
		component: RepeatMessages,
		props: initProps,
	},
	{
		path: '*',
		name: 'NotFound',
		component: NotFound
	}
]

function initProps(route) {
	let props = {
		params: route.params,
		query: route.query
	};

	if (!(route.meta && route.meta.props)) {
		return props
	}
	for (let key in route.meta.props) {
		let prop = route.meta.props[key]
		props[key] = prop
	}

	return props;
}

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

import ThisVue from "@/utils/ThisVue"

router.beforeEach((to, from, next) => {
	console.log("---- Routing '" + to.path + "' to " + to.name + " ----", to);

	// Main page redirect
	if (to.path == "/") {
		if (window.location.hostname == "delutaya.club") {
			next("/birthday2023")
			return
		} else {
			next("/birthday2023")
			return
		}
	}

	to.meta.props = {}

	console.log("Try to get auth")

	AuthService.getAuth(true)
		.then(r => {
			let user = r.data.base_user;
			to.meta.props.user = user;

			let theme = AuthService.getThemeSettings(user)
			if (to.meta.forceTheme) {
				theme.theme_name = to.meta.forceTheme
			}

			Theme.load(theme)
			Theme.setCurrentTheme(to.meta.forceTheme)
			if (theme.language) {
				ThisVue.vue.$i18n.locale = theme.language
			}

			if (!to.meta.access) {
				// External route, allow without getting/loading user
				next()
				return
			}

			if (!hasAccess(to, user)) {
				console.log("No access", to);
				Toast.info("You are not allowed here!", to.path)
				next(false);
				return
			}

			next()
		})
		.catch(e => {
			let theme = AuthService.getThemeSettings()
			if (to.meta.forceTheme) {
				theme.theme_name = to.meta.forceTheme
			}

			Theme.load(theme)
			Theme.setCurrentTheme(to.meta.forceTheme)
			if (theme.language) {
				ThisVue.vue.$i18n.locale = theme.language
			}

			if (window.location.hostname == "localhost" && e.response.status == 500 && e.response.data && e.response.data.includes("Could not proxy request")) {
				// Running on local environment without backend, so enable mockup mode
				window.mockupMode = true
				Toast.info("Mockup mode", "No backend found, running in mockup mode")
			}

			console.log({ "auth_resp": e })

			if (!to.meta.access) {
				// External route, allow without getting/loading user
				next()
				return
			}

			if (to.path != "/") {
				console.log("Unauthorized", e);
				Toast.info(ThisVue.vue.$i18n.t("messages.unauthorized"), ThisVue.vue.$i18n.t("messages.you_are_not_logged_in"));
				next("/");
			}

			next();
		});
});

function hasAccess(route, user) {
	console.log("HASACCESS", route, user)
	if (route.meta && route.meta.access) {
		if (user.is_idol && (route.meta.access.IDOL || route.meta.access.MEMBER)) return true
		if (user.is_admin && (route.meta.access.ADMIN || route.meta.access.MEMBER)) return true
		if (route.meta.access.MEMBER) return true
	}

	console.error(`Route ${route.path} has no defined access`);
	return false;
}

export default router

<template>
	<div class="profile-card hoverable d-flex flex-column align-items-center" @mouseenter="emitIcon">
		<div class="w-100 d-flex flex-row align-items-center">
			<h2 class="flex mb-0 nowrap"><pixiv-icon class="mr-2 fa-fw" size="1em" />{{ $t("platforms.fanbox") }}</h2>
			<div v-if="user.pixiv_user_id" class="text-right">
				<div class="text-success"><i class="fas fa-check mr-1" />{{ $t("labels.verified") }}</div>
				<div class="text-half-muted">{{ user.pixiv_user_name }}</div>
			</div>
			<div v-else>
				<div class="text-danger"><i class="fas fa-times mr-1" />{{ $t("labels.not_connected") }}</div>
			</div>
		</div>
		<div v-if="!user.twitter_user_id" class="sub-block p-2 mt-2">
			{{ $t("messages.fanbox_needs_twitter") }}
		</div>
		<div v-else-if="!user.pixiv_user_name" class="sub-block p-2 mt-2">
			{{ $t("messages.fanbox_instructions_1")
			}}<a href="https://deluta.fanbox.cc/posts/1595549" target="_blank">{{
				$t("messages.fanbox_instructions_link")
			}}</a
			>{{ $t("messages.fanbox_instructions_2") }}
		</div>
	</div>
</template>

<script>
export default {
	name: "FanboxCard",

	props: ["user"],

	components: {},

	data() {
		return {};
	},

	created() {},

	methods: {
		emitIcon() {
			if (this.user.pixiv_icon_url) {
				this.$emit("icon", this.user.pixiv_icon_url);
			} else {
				console.error("No Pixiv icon URL");
			}
		},
	},
};
</script>

<style scoped>
</style>
<template>
	<main-page :user="user" :refreshUser="fullRefresh">
		<div class="container d-flex flex-column">
			<h1 v-if="loading" class="mt-5"><i class="fas fa-spinner fa-pulse text-very-muted" /></h1>
			<div v-else-if="noAccess" class="mt-5">
				<h1 class="mt-5">{{ $t("labels.no_access") }}</h1>
				<h5 class="mt-4 header-muted">{{ $t("messages.admin_page_1") }}</h5>
				<h5 class="header-muted">{{ $t("messages.admin_page_2") }}</h5>
			</div>
			<template v-else>
				<h3 class="mb-3">{{ $t("labels.repeat_messages") }}</h3>
				<b-table
					ref="table"
					striped
					stacked="md"
					:show-empty="true"
					:items="repeatMessages"
					:fields="fields"
					class="text-left flex"
					style="font-size: 1rem"
					:empty-text="$t('messages.there_are_no_records_to_display')"
				>
					<template #cell(active)="{ item: row }">
						<b-form-checkbox
							v-model="row.active"
							name="check-button"
							button
							:button-variant="row.active ? 'primary' : ''"
							@change="saveRow(row)"
						>
							<div
								class="d-flex flex-row align-items-center"
								style="margin-left: -4px; margin-right: -4px"
							>
								<i
									class="fa"
									:class="{
										'fa-check': row.active,
										'fa-times-circle': !row.active,
										'text-very-muted': !row.active,
									}"
									style="min-width: 15px"
								/>
							</div>
						</b-form-checkbox>
					</template>
					<template #cell(channel)="{ item: row }">
						<template v-if="row.channel_name">
							<div class="_600">#{{ row.channel_name }}</div>
							<small class="text-muted">{{ row.channel_id }}</small>
						</template>
						<template v-else>
							<div>{{ row.channel_id }}</div>
						</template>
					</template>
					<template #cell(actions)="{ item: row }">
						<div class="d-flex flex-row">
							<button
								v-tippy
								:title="$t('labels.edit')"
								@click="editRow(row)"
								class="ml-auto btn btn-icon btn-success"
							>
								<i class="fa fa-edit" />
							</button>
							<button
								v-tippy
								:title="$t('labels.delete')"
								@click="confirmDeleteRow(row)"
								class="ml-2 btn btn-icon btn-danger"
							>
								<i class="fa fa-trash" />
							</button>
						</div>
					</template>
					<template #cell(interval)="{ item: row }">
						<span v-tippy :title="timesTooltip(row.last_message_time, row.next_message_time, row.active)">{{
							intervalString(row.interval, row.interval_dur)
						}}</span>
					</template>
					<template #cell(last_message)="{ item: row }">
						<a
							v-if="row.last_message_id"
							:href="`https://canary.discord.com/channels/${row.guild_id}/${row.channel_id}/${row.last_message_id}`"
						>
							<i v-tippy :title="$t('labels.go_to_last_message')" class="fas fa-comment-alt-dots" />
						</a>
					</template>
				</b-table>
				<div class="below-table d-flex flex-row align-items-center mb-3">
					<button @click="newRow" class="ml-3 btn btn-success">
						<i class="fa fa-plus mr-2" />{{ $t("labels.new_repeat_message") }}
					</button>
				</div>
			</template>
		</div>

		<b-modal
			id="editModal"
			:title="$t('labels.message_details')"
			header-bg-variant="primary"
			body-bg-variant="dark"
			footer-bg-variant="dark"
			no-close-on-esc
			no-close-on-backdrop
			hide-header-close
		>
			<div v-if="editingRow" class="px-3 pt-3 row" style="font-size: 1rem">
				<div class="col-12 form-group">
					<label class="text-muted">{{ $t("labels.discord_channel") }}</label>
					<input class="form-control dark-control" v-model="editingRow.channel_id" />
					<i v-if="editingChannelName == true" class="fas fa-spinner fa-pulse mr-1" />
					<div v-else-if="editingChannelName == false" class="text-muted">
						{{ $t("labels.channel_not_found") }}
					</div>
					<div v-else-if="editingChannelName">#{{ editingChannelName }}</div>
				</div>
				<div class="col-12 form-group">
					<label class="text-muted">{{ $t("labels.embed_title") }}</label>
					<input class="form-control dark-control" v-model="editingRow.title" />
				</div>
				<div class="col-12 form-group">
					<label class="text-muted">{{ $t("labels.embed_body") }}</label>
					<textarea rows="4" class="form-control dark-control" v-model="editingRow.body" />
				</div>
				<div class="col-6 form-group">
					<label class="text-muted">{{ $t("labels.interval") }}</label>
					<input class="form-control dark-control" v-model.number="editingRow.interval" />
				</div>
				<div class="col-6 form-group d-flex flex-column">
					<v-select
						class="mt-auto"
						:options="intervalTypes"
						:reduce="(o) => o.id"
						v-model="editingRow.interval_dur"
					/>
				</div>
				<div class="col-12">
					<b-form-checkbox
						v-model="editingRow.sign"
						name="check-button"
						button
						:button-variant="editingRow.sign ? 'primary' : ''"
						class="mr-3 mb-3"
					>
						<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
							<i
								class="fa"
								:class="{
									'fa-check': editingRow.sign,
									'fa-times-circle': !editingRow.sign,
									'text-very-muted': !editingRow.sign,
								}"
								style="min-width: 15px"
							/>
							<span class="ml-2 text-left nowrap">{{ $t("labels.user_signature") }}</span>
						</div>
					</b-form-checkbox>
				</div>
				<div class="col-12">
					<b-form-checkbox
						v-model="editingRow.active"
						name="check-button"
						button
						:button-variant="editingRow.active ? 'primary' : ''"
					>
						<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
							<i
								class="fa"
								:class="{
									'fa-check': editingRow.active,
									'fa-times-circle': !editingRow.active,
									'text-very-muted': !editingRow.active,
								}"
								style="min-width: 15px"
							/>
							<span class="ml-2 text-left nowrap">{{ $t("labels.active") }}</span>
						</div>
					</b-form-checkbox>
				</div>
			</div>
			<template v-if="editingRow" #modal-footer="{ _, cancel }">
				<button v-if="!saving" class="btn btn-secondary" @click="cancel">
					{{ $t("labels.cancel") }}
				</button>
				<button
					v-if="!saving"
					@click="saveRow(editingRow)"
					class="ml-auto btn"
					:class="{ 'btn-success': canSaveRow(editingRow), 'btn-secondary': !canSaveRow(editingRow) }"
					:disabled="!canSaveRow(editingRow)"
				>
					{{ $t("labels.save") }}
				</button>
				<button v-if="saving" class="ml-auto btn btn-danger" disabled>
					<i class="fas fa-spinner fa-pulse mr-1" />{{ $t("labels.saving") }}
				</button>
			</template>
		</b-modal>

		<b-modal
			id="deleteModal"
			:title="$t('labels.delete_message')"
			header-bg-variant="danger"
			body-bg-variant="dark"
			footer-bg-variant="dark"
			no-close-on-esc
			no-close-on-backdrop
			hide-header-close
		>
			<div v-if="deletingRow" class="px-3 pt-3 row" style="font-size: 1rem">
				<div class="col-12 form-group">
					<h5>{{ $t("messages.confirm_delete_message") }}</h5>
				</div>
			</div>
			<template v-if="deletingRow" #modal-footer="{ _, cancel }">
				<button v-if="!deleting" class="btn btn-secondary" @click="cancel">{{ $t("labels.cancel") }}</button>
				<button v-if="!deleting" @click="deleteRow(deletingRow)" class="ml-auto btn btn-danger">
					{{ $t("labels.delete") }}
				</button>
				<button v-if="deleting" class="ml-auto btn btn-danger" disabled>
					<i class="fas fa-spinner fa-pulse mr-1" />{{ $t("labels.deleting") }}
				</button>
			</template>
		</b-modal>
	</main-page>
</template>


<script>
import Toast from "@/utils/Toast";
import Format from "@/utils/Format";
import _ from "lodash";

import RepeatMessageService from "@/services/RepeatMessageService";

export default {
	name: "RepeatMessages",

	props: ["user"],

	components: {},

	data() {
		return {
			Toast: Toast,
			Format: Format,

			loading: true,
			noAccess: false,
			fields: [
				{
					key: "active",
					label: this.$i18n.t("labels.active"),
					class: "v-mid text-center",
					thClass: "nowrap",
				},
				{
					key: "channel",
					label: this.$i18n.t("labels.discord_channel"),
					class: "v-mid",
					thClass: "nowrap",
				},
				{
					key: "title",
					label: this.$i18n.t("labels.embed_title"),
					class: "v-mid",
					thClass: "nowrap",
				},
				{
					key: "body",
					label: this.$i18n.t("labels.embed_body"),
					class: "v-mid",
					thClass: "nowrap",
				},
				{
					key: "sign",
					label: this.$i18n.t("labels.signed"),
					class: "v-mid",
					thClass: "nowrap",
				},
				{
					key: "interval",
					label: this.$i18n.t("labels.interval"),
					class: "v-mid",
					thClass: "nowrap",
				},
				{
					key: "last_message",
					label: this.$i18n.t("labels.go"),
					class: "v-mid",
					thClass: "nowrap",
				},
				{
					key: "actions",
					label: " ",
					class: "v-mid",
					thClass: "nowrap",
					sortable: true,
				},
			],
			files: [],

			host: "",
			editingRow: null,
			editingChannelName: null,
			deletingRow: null,
			saving: false,
			deleting: false,

			intervalTypes: [
				{ id: "second", label: this.$i18n.t("labels.second") },
				{ id: "minute", label: this.$i18n.t("labels.minute") },
				{ id: "hour", label: this.$i18n.t("labels.hour") },
				{ id: "day", label: this.$i18n.t("labels.day") },
			],
		};
	},

	created() {
		if (!this.user) {
			this.loading = false;
			this.noAccess = true;
			return;
		}

		this.loadRows();
	},

	watch: {
		"editingRow.channel_id"() {
			if (this.editingRow.channel_id == "") {
				this.editingChannelName = null;
			} else {
				this.editingChannelName = true;
				RepeatMessageService.getDiscordChannelName(this.editingRow.channel_id)
					.then((r) => {
						if (r.data.startsWith(`<!DOCTYPE html>`)) {
							this.editingChannelName = false;
						} else {
							this.editingChannelName = r.data;
						}
					})
					.catch((e) => {
						console.error(e);
						this.editingChannelName = false;
					});
			}
		},
	},

	methods: {
		loadRows() {
			this.loading = true;
			RepeatMessageService.getRepeatMessages()
				.then((r) => {
					this.loading = false;
					this.repeatMessages = r.data;
				})
				.catch((e) => {
					console.error(e);
					Toast.error("Failed to get repeat messages", e);
				});
		},

		newRow() {
			let newRow = {
				channel_id: "",
				channel_name: null,
				title: "",
				body: "",
				sign: false,
				active: true,
			};

			this.editRow(newRow);
		},

		editRow(row) {
			this.editingRow = _.cloneDeep(row);
			this.$bvModal.show("editModal");
		},

		saveRow(row) {
			this.saving = true;
			RepeatMessageService.saveRepeatMessage(row)
				.then(() => {
					this.saving = false;
					Toast.info(this.$i18n.t("messages.saved_message_details"));
					this.$bvModal.hide("editModal");
					this.loadRows();
				})
				.catch((e) => {
					this.saving = false;
					console.error(e);
					Toast.error("Failed to save repeating message", e);
				});
		},

		canSaveRow(row) {
			if (!row.channel_id) return false;
			if (!row.title) return false;
			if (!row.body) return false;
			if (!row.interval) return false;
			if (!row.interval_dur) return false;
			if (!(this.editingChannelName && this.editingChannelName != true)) return false;

			return true;
		},

		confirmDeleteRow(row) {
			this.deletingRow = row;
			this.$bvModal.show("deleteModal");
		},

		deleteRow(row) {
			this.deleting = true;
			RepeatMessageService.deleteRepeatMessage(row.id)
				.then(() => {
					Toast.info(this.$i18n.t("messages.message_deleted"));
					this.$bvModal.hide("deleteModal");
					this.deleting = false;
					this.loadRows();
				})
				.catch((e) => {
					console.error(e);
					Toast.error("Failed to delete repeating message", e);
					this.deleting = false;
				});
		},

		fullRefresh() {
			this.$router.go();
		},

		intervalString(interval, intervalDur) {
			switch (intervalDur) {
				case "second":
					return `${interval}s`;
				case "minute":
					return `${interval}m`;
				case "hour":
					return `${interval}h`;
				case "day":
					return `${interval}d`;
			}
		},

		timesTooltip(last, next, active) {
			console.log("DO TIMES", last, next);
			let tooltip = `
<table>
	<tr>
		<td class="text-muted">${this.$i18n.t("labels.last_message")}:</td>`;
			if (last) {
				tooltip += `
		<td>${this.$i18n.d(new Date(last), "long")}</td>`;
			} else {
				tooltip += `
		<td>${this.$i18n.t("labels.none")}<td>`;
			}

			tooltip += `
	</tr>
	<tr>
		<td class="text-muted">${this.$i18n.t("labels.next_message")}:</td>`;
			if (active && next) {
				tooltip += `
		<td>${this.$i18n.d(new Date(next), "long")}</td>`;
			} else if (!active) {
				tooltip += `
		<td>${this.$i18n.t("labels.inactive")}<td>`;
			} else {
				tooltip += `
		<td>${this.$i18n.t("labels.none")}<td>`;
			}

			tooltip += `
	</tr>
<table>`;
			return tooltip;
		},
	},
};
</script>

<style scoped>
.container {
	padding-top: 24px;
	justify-content: start;
}
.btn-icon {
	padding: 0.375rem 0.5rem;
	width: 35px;
}
.link-box {
	display: block;
	cursor: pointer;
	padding: 4px 8px;
	border-radius: 6px;
	border: 1px solid white;
	overflow-wrap: break-word;
}
.copied {
	border-color: #77b300;
	color: #77b300;
}
.fading {
	transition: border-color 1000ms, color 1000ms;
	border-color: white;
	color: white;
}
hr {
	border-color: rgb(255 255 255 / 20%);
}
.below-table {
	padding: 0.75rem 0.75rem;
	border-radius: 8px;
	color: white;
	background-color: rgb(255, 255, 255, 10%);
}

.header-muted {
	opacity: 0.5;
}
</style>
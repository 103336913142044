<template>
	<main-page :user="user" fillBackground hideHeader :refresh.sync="refreshBG" class="purple-scrollbar">
		<div class="splash-header">
			<div class="header-container">
				<img :src="theme.header" />
				<div class="header-edge-blur"></div>
			</div>
			<div class="header-layout">
				<div class="header-text" v-html="theme.headerText"></div>
				<div class="header-img-spacer"></div>
			</div>
		</div>

		<nav-bar
			ref="subheader"
			:user="user"
			:refreshBG="refreshBG"
			:refreshTL="loadTheme"
			:refreshUser="loadData"
			:refreshOrder="refreshOrder"
		/>

		<div class="mx-auto limit-width mb-4" :style="{ 'margin-top': contentMargin + 'px' }">
			<div v-if="!loading" ref="postContainer">
				<div v-for="post in posts" :key="post.id" class="post-card-container">
					<div class="post-card fade-in mb-4">
						<wall-post :post="post"></wall-post>
						<div v-if="post.newPost" class="new-badge badge badge-info">{{ $t("labels.new!") }}</div>
					</div>
				</div>
			</div>
			<div
				v-else
				style="min-height: calc(100vh - 448px)"
				class="d-flex flex-column justify-content-center align-items-center"
			>
				<div class="subtle-border">
					<h1>{{ $t("labels.loading") }}</h1>
					<h1><i class="fas fa-spinner fa-pulse" /></h1>
				</div>
			</div>
		</div>
	</main-page>
</template>

<script>
import AuthService from "@/services/AuthService";
import PostService from "@/services/PostService";
import Masonry from "masonry-layout";
import Toast from "@/utils/Toast";
import Theme from "@/utils/Theme";
var theme = Theme.getCurrent();
import { DateTime } from "luxon";
import _ from "lodash";

import NavBar from "@/components/NavBar";
import WallPost from "@/components/WallPost";

var lastScrollPos = null;
var lastScrollTime = null;

export default {
	name: "PostWall",

	props: [],

	components: { NavBar, WallPost },

	computed: {},

	data() {
		return {
			loading: true,

			theme: theme,
			user: null,
			posts: [],
			masonry: null,

			subheaderY: 0,
			subheaderHeight: 0,
			contentMargin: 32,

			refreshBG: null,
			themeSettings: null,

			cardElements: null,
			scrollEl: null,
			lastScrollPos: null,
			lastScrollTime: null,
		};
	},

	created() {
		this.loadData();
	},

	mounted() {
		let scroll = document.getElementById("main-page-scroll");
		if (!scroll) {
			console.error("Failed to find scroll container");
			return;
		}
		this.scrollEl = scroll;

		let subheader = this.$refs.subheader && this.$refs.subheader.$el;
		if (!subheader) {
			console.error("Failed to find subheader");
			return;
		}

		this.subheaderY = subheader.offsetTop;
		this.subheaderHeight = subheader.offsetHeight;
		scroll.addEventListener("scroll", this.stickySubheader);
		scroll.addEventListener("scroll", this.doFadeIns);
		window.addEventListener("resize", this.doFadeIns);
	},

	unmounted() {
		let scroll = document.getElementById("main-page-scroll");
		if (!scroll) {
			console.error("Failed to find scroll container");
			return;
		}

		scroll.removeEventListener("scroll", this.stickySubheader);
		scroll.removeEventListener("scroll", this.doFadeIns);
		window.removeEventListener("resize", this.doFadeIns);
	},

	watch: {},

	methods: {
		loadData() {
			AuthService.getAuth()
				.then((resp) => {
					this.user = resp.data.base_user;
				})
				.catch((e) => {
					console.log("Not logged in", e);
					// Not authenticated, do nothing
				})
				.finally(() => {
					PostService.getPostsSwitch(this.user, 0)
						.then((r) => {
							// _.each(r.data.rows, (r) => {
							// 	r.message = r.message.replace(/\n/g, "<br>");
							// });
							this.posts = r.data.rows;

							this.posts = this.sortPosts(this.posts);
							this.loading = false;

							this.$nextTick(() => {
								this.masonry = new Masonry(this.$refs.postContainer, {
									itemSelector: ".post-card-container",
									gutter: 24,
								});
								this.masonry.layout();
								this.loadTheme(this.user);
								this.cardElements = Array.from(document.getElementsByClassName("fade-in"));
								this.initFadeIns();
							});
						})
						.catch((e) => {
							this.loading = false;
							console.log(e);
							Toast.error("Failed to load posts", e);
						});
				});
		},

		stickySubheader() {
			let scroll = document.getElementById("main-page-scroll");
			if (!scroll) {
				console.error("Failed to find scroll container");
				return;
			}

			let subheader = this.$refs.subheader && this.$refs.subheader.$el;
			if (!subheader) {
				console.error("Failed to find subheader");
				return;
			}

			if (scroll.scrollTop >= this.subheaderY) {
				subheader.classList.add("sticky");
				this.contentMargin = this.subheaderHeight + 32;
			} else {
				subheader.classList.remove("sticky");
				this.contentMargin = 32;
			}
		},

		loadTheme() {
			this.themeSettings = AuthService.getThemeSettings(this.user);
			_.each(this.posts, (post) => {
				if (post.translation) {
					post.showTranslated = this.themeSettings.do_translate;
				}
			});
		},

		refreshOrder() {
			this.posts = this.sortPosts(this.posts);
			this.$nextTick(() => {
				this.masonry.layout();
			});
		},

		sortPosts(posts) {
			this.themeSettings = AuthService.getThemeSettings(this.user);
			if (this.themeSettings && this.themeSettings.posts_in_order) {
				return _.orderBy(posts, "created_at", "desc");
			} else if (this.user && this.user.last_viewed_wall) {
				let newPosts = [];
				let oldPosts = [];

				let viewedTime = DateTime.fromISO(this.user.last_viewed_wall);

				_.each(posts, (post) => {
					if (DateTime.fromISO(post.created_at) > viewedTime) {
						post.newPost = true;
						newPosts.push(post);
					} else {
						oldPosts.push(post);
					}
				});

				newPosts = _.orderBy(newPosts, "created_at", "desc");
				oldPosts = _.shuffle(oldPosts);

				return newPosts.concat(oldPosts);
			} else {
				return _.shuffle(posts);
			}
		},

		initFadeIns() {
			for (let i = 0; i < this.cardElements.length; i++) {
				let card = this.cardElements[i];
				if (this.isCardVisible(card)) {
					card.style.opacity = "1";
					card.style.transform = "scale(1)";
					this.cardElements.splice(i, 1); // only allow it to run once
					i--; // Replay the current index since we deleted the element from that index
				} else {
					card.classList.add("fade-in-trans");
				}
			}
		},

		doFadeIns() {
			let scrollVelocity = 200;
			let now = Date.now();
			if (lastScrollTime && now - lastScrollTime < 200) {
				scrollVelocity = (1000 * (this.scrollEl.scrollTop - lastScrollPos)) / (now - lastScrollTime);
				scrollVelocity = Math.abs(scrollVelocity);
			}
			lastScrollPos = this.scrollEl.scrollTop;
			lastScrollTime = now;

			let viewHeight = this.scrollEl.getBoundingClientRect().height;
			let animTotal = viewHeight / scrollVelocity; // The expected amount of second for the element to be on the page, at the current speed
			animTotal = Math.min(1, animTotal); // Don't make it more than a second

			let animDelay = 0.3;
			let animDur = 0.5;
			if (animTotal > 0.5) {
				animDelay = animTotal - animDur;
			} else {
				animDelay = 0;
				animDur = animTotal;
			}

			// let misses = 0;
			for (let i = 0; i < this.cardElements.length; i++) {
				let card = this.cardElements[i];
				if (this.isCardVisible(card, animDur < 0.2)) {
					card.classList.add("unfade");
					card.style.transitionDelay = `${animDelay}s`;
					card.style.transitionDuration = `${animDur}s`;
					// this.cardElements.splice(i, 1); // only allow it to run once
					// i--; // Replay the current index since we deleted the element from that index
				} else {
					// misses++;
					card.classList.remove("unfade");
				}
				// if (misses > 3) {
				// 	// Stop calculating rects if we've already seen three that are out of range
				// 	break;
				// }
			}
		},

		isCardVisible(card, noBuffer) {
			let rect = card.getBoundingClientRect();
			let elemTop = rect.top;
			if (!noBuffer) {
				let buffer = Math.min(rect.height / 2, 100);
				elemTop += buffer - 200;
			}
			let elemBottom = rect.bottom;
			return elemTop < window.innerHeight && elemBottom >= 0;
		},
	},
};
</script>

<style scoped>
.fade-in {
	opacity: 0;
	transform: scale(0.8) translateY(200px);
}
.fade-in-trans {
	transition: 0.5s all cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.unfade {
	opacity: 1;
	transform: scale(1);
}

.splash-header {
	width: 100%;
	overflow: hidden;
}

.post-card-container {
	width: 33%;
	max-width: 390px;
}

.post-card {
	box-shadow: 0 0 8px 1px rgb(255 255 255 / 40%);
	border-radius: 10px;
}

.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}

.limit-width {
	max-width: 1248px;
	padding-left: 15px;
	padding-right: 15px;
}

.subtle-border {
	border: 3px solid rgba(255, 255, 255);
	border-radius: 2rem;
	display: inline-block;
	padding: 1rem 2rem;
	opacity: 0.2;
}

@media only screen and (max-width: 1257px) and (min-width: 844px) {
	.limit-width {
		max-width: 834px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.post-card-container {
		width: 50%;
		flex: 0 0 50%;
	}
}

@media only screen and (max-width: 843px) {
	.limit-width {
		max-width: 420px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.post-card-container {
		width: 100%;
		flex: 0 0 100%;
	}
}
</style>
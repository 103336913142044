<template>
	<div>
		<img src="@/assets/images/birthday/delu-running.gif" />
		<div ref="text" class="loading-text" :style="{ color: color || 'lightgreen', 'font-family': font || 'seto' }">
			{{ text }}
		</div>
	</div>
</template>

<script>
export default {
	name: "Loading",

	props: ["color", "font"],

	components: {},

	data() {
		return {
			timer: null,
			prog: 0,
			text: this.$i18n.t("labels.loading").substring(0, this.$i18n.t("labels.loading").length - 3),
		};
	},

	created() {},

	mounted() {
		this.timer = setInterval(() => {
			this.prog = (this.prog + 1) % 3;
			this.text = this.$i18n
				.t("labels.loading")
				.substring(0, this.$i18n.t("labels.loading").length - (2 - this.prog));
		}, 500);
	},

	destroyed() {
		clearInterval(this.timer);
	},

	methods: {},
};
</script>

<style scoped>
.loading-text {
	font-size: 24px;
	font-weight: 800;
	margin-left: 20px;
}
</style>
<template>
	<div class="profile-card hoverable d-flex flex-row align-items-center" @mouseenter="emitIcon">
		<h2 class="flex mb-0"><i class="fab fa-fw fa-discord mr-2" />{{ $t("platforms.discord") }}</h2>
		<div v-if="user.discord_user_id" class="text-right">
			<div class="text-success"><i class="fas fa-check mr-1" />{{ $t("labels.verified") }}</div>
			<div class="text-half-muted">
				{{ user.discord_handle }}<span class="text-half-muted">#{{ user.discord_discriminator }}</span>
			</div>
		</div>
		<div v-else class="my-1">
			<a
				id="discord-button"
				href="/v1/auth/discord?provider=discord"
				class="btn-connect btn-lg btn-social btn-discord"
			>
				{{ $t("labels.connect") }}
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "DiscordCard",

	props: ["user"],

	components: {},

	data() {
		return {};
	},

	created() {},

	methods: {
		emitIcon() {
			if (this.user.discord_icon_url) {
				this.$emit("icon", this.user.discord_icon_url);
			} else {
				console.error("No Discord icon URL");
			}
		},
	},
};
</script>

<style scoped>
</style>
<template>
	<div class="d-flex flex-column h-100">
		<div id="background" class="background" :class="{ 'fill-background': fillBackground }" style="z-index: -1">
			<template v-if="!noStars">
				<div v-if="!themeSettings.stars_off" id="stars"></div>
				<div v-if="!themeSettings.stars_off" id="stars2"></div>
				<div v-if="!themeSettings.stars_off" id="stars3"></div>
			</template>
		</div>
		<!-- Header -->

		<nav-bar
			v-if="!hideHeader"
			ref="subheader"
			:user="user"
			:refreshBG="loadData"
			:refreshUser="refreshUser"
			:socialsOnly="socialsOnly"
		/>

		<!-- Content -->
		<div
			class="flex d-flex flex-column main-page-scroll"
			:class="{ 'justify-content-center': vCenter, animate: !themeSettings.stars_off }"
		>
			<div id="main-page-scroll" class="scroll-y">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";
import NavBar from "@/components/NavBar";

export default {
	name: "MainPage",

	props: {
		user: {},
		color: {},
		fillBackground: {
			type: Boolean,
			default: false,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		socialsOnly: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		vCenter: {
			type: Boolean,
			default: false,
		},
		noStars: {
			type: Boolean,
			default: false,
		},
		refreshUser: {
			type: Function,
			default: null,
		},
	},

	components: { NavBar },

	data() {
		return {
			themeSettings: null,
		};
	},
	created() {
		this.loadData();
		this.$emit("update:refresh", () => {
			this.loadData();
		});
	},
	computed: {},

	methods: {
		loadData() {
			this.themeSettings = AuthService.getThemeSettings(this.user);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}

.main-page-scroll {
	overflow: hidden;
}

.hamburger-menu {
	border: 3px solid #adafae;
	border-radius: 0.5rem;
	opacity: 0.75;
}
.hamburger-icon {
	height: 40px;
}
.logo {
}
.logo-img {
	height: 50px;
	width: 50px;
	margin-bottom: -10px;
}
.title {
	font-size: 72px;
	font-family: "Blender Pro";
	color: black;
	margin-top: -16px;
	margin-bottom: -16px;
	line-height: 0.8;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}
.transition-color {
	transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

.navbar-bg {
	box-shadow: 0 0 10px rgb(79, 211, 89, 0.3);
	background-color: rgba(64, 64, 64, 0.5);
}

.large-details {
	padding: 1rem;
	font-size: 1.5rem;
}
.details-link-container {
	width: 100%;
	text-align: center;
}
.details-link {
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-align: center;
}
.large-icon-modal-header {
	width: 100%;
	text-align: center;
	color: #fff;
}
.large-icon-modal-header > div {
	font-size: 36px;
}
</style>

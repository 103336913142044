import { render, staticRenderFns } from "./BirthdayPost2023.vue?vue&type=template&id=80fba20a&scoped=true&"
import script from "./BirthdayPost2023.vue?vue&type=script&lang=js&"
export * from "./BirthdayPost2023.vue?vue&type=script&lang=js&"
import style0 from "./BirthdayPost2023.vue?vue&type=style&index=0&id=80fba20a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80fba20a",
  null
  
)

export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"page",staticClass:"d-flex flex-column h-100 fadeable",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"background",staticStyle:{"z-index":"-1","overflow":"hidden"},attrs:{"id":"background"}},[_c('img',{ref:"splashBack",staticClass:"starry-bg",style:({
				left: (_vm.bgLeft + "px"),
				bottom: (_vm.bgBottom + "px"),
				height: (_vm.bgHeight + "px"),
			}),attrs:{"src":"https://files.delutaya.club/file/deluparty/starbg-bg2.webp"}}),_c('div',{ref:"meteorField",staticClass:"meteor-field",style:({
				left: (_vm.bgLeft + "px"),
				bottom: (_vm.bgBottom + "px"),
				transform: ("scale(" + _vm.bgScale + ")"),
			})}),_c('img',{ref:"splashFront",staticClass:"starry-bg",style:({
				left: (_vm.bgLeft + "px"),
				bottom: (_vm.bgBottom + "px"),
				height: (_vm.bgHeight + "px"),
			}),attrs:{"src":"https://files.delutaya.club/file/deluparty/starbg-fg2.webp"}})]),_c('div',{staticClass:"flex d-flex flex-column justify-content-start align-items-end"},[_c('div',{staticClass:"buttons-container"},[_c('button',{staticClass:"ui-button",on:{"click":function($event){return _vm.loadLightboxVideo('https://www.youtube.com/embed/L5p2OwFCObw')}}},[_vm._v(" "+_vm._s(_vm.$t("labels.video"))+" ")]),_c('div',{staticClass:"button-spacer"}),_c('button',{staticClass:"ui-button",on:{"click":_vm.goToCanvas}},[_vm._v(_vm._s(_vm.$t("labels.canvas")))]),_c('div',{staticClass:"button-spacer"}),_c('button',{staticClass:"ui-button",on:{"click":function($event){return _vm.loadLightboxImage(
						'https://files.delutaya.club/file/deluparty/magicaldraw_20220807_162957.webp '
					)}}},[_vm._v(" "+_vm._s(_vm.$t("labels.community_canvas"))+" ")]),_c('div',{staticClass:"button-spacer"}),_c('button',{staticClass:"ui-button",on:{"click":_vm.goToTelescope}},[_vm._v(_vm._s(_vm.$t("labels.telescope")))])]),_vm._m(0)]),_c('div',{staticClass:"lightbox-overlay",class:{ show: _vm.showLightbox },on:{"click":_vm.closeModals}}),_c('div',{staticClass:"lightbox",class:{ show: _vm.showLightbox }},[(_vm.showLightbox && !_vm.showLightboxContent)?_c('loading',{staticClass:"lightbox-loading",attrs:{"color":"rgba(255 255 255 / 100%)","font":"PixelMPlus10"}}):_vm._e(),(_vm.lightboxImage)?_c('div',{staticClass:"lightbox-image-container"},[(_vm.lightboxImage)?_c('img',{staticClass:"lightbox-image zoomable",class:{ show: _vm.showLightboxContent },attrs:{"src":_vm.lightboxImage},on:{"load":_vm.lightboxImageLoaded,"click":_vm.zoom}}):_vm._e()]):_vm._e(),(_vm.lightboxVideo)?_c('iframe',{staticClass:"lightbox-video",class:{ show: _vm.showLightboxContent },attrs:{"src":_vm.lightboxVideo,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":"","width":"1120","height":"730"},on:{"load":_vm.lightboxVideoLoaded}}):_vm._e(),(_vm.lightboxDetails)?_c('div',{staticClass:"lightbox-details",class:{ show: _vm.showLightboxContent },domProps:{"innerHTML":_vm._s(_vm.lightboxDetails)}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"credits-container"},[_c('div',[_vm._v("Art: June (@junwei661)")]),_c('div',[_vm._v("Website: Mirrored (@Mirrormn)")])])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"page",staticClass:"d-flex flex-column h-100 fadeable",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"background-scene",staticStyle:{"z-index":"-1","transition":"transform 1s ease-out, scale 1s ease-in-out"},attrs:{"id":"background"}},[_c('img',{ref:"scene",staticClass:"bar-bg",class:{ focused: _vm.focalPoint },style:({
          left: "0px",
          top: "0px",
          height: "2735px",
        }),attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-scene2.webp"},on:{"click":_vm.clickBackground}}),_c('div',{ref:"tv",staticClass:"bar-button tv-skew",class:{ focused: _vm.focalPoint && _vm.focalPoint.name == 'tv' },style:({
          left: "860px",
          top: "266px",
          height: "449px",
        }),on:{"click":function($event){return _vm.chooseFocus('tv')}}},[_c('img',{attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-tv.webp"}}),_c('div',{staticClass:"tv-hitbox"},[_c('img',{attrs:{"width":"100%","height":"100%","src":("https://i.ytimg.com/vi/" + _vm.tvVideoId + "/hq720.jpg")}})])]),_c('div',{ref:"starmap",staticClass:"bar-button",class:{ focused: _vm.focalPoint && _vm.focalPoint.name == 'starmap' },style:({
          left: "1564px",
          top: "610px",
          height: "417px",
        }),on:{"click":function($event){return _vm.chooseFocus('starmap')}}},[_c('img',{attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-starmap.webp"}})]),_c('div',{ref:"pinboard",staticClass:"bar-button",class:{
          focused: _vm.focalPoint && _vm.focalPoint.name == 'pinboard',
        },style:({
          left: "4693px",
          top: "35px",
          height: "476px",
        }),on:{"click":function($event){return _vm.chooseFocus('pinboard')}}},[_c('img',{attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-pinboard-filled.webp"}})]),_c('div',{ref:"arcade",staticClass:"bar-button arcade-skew",class:{ focused: _vm.focalPoint && _vm.focalPoint.name == 'arcade' },style:({
          left: "4721px",
          top: "560px",
          height: "2175px",
        }),on:{"click":function($event){return _vm.chooseFocus('arcade')}}},[_c('img',{attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-arcade.webp"}}),_c('div',{staticClass:"arcade-hitbox text-center"},[_vm._m(0),_c('div',{staticClass:"game-link",on:{"click":_vm.openDeltaDash}},[_c('i',{staticClass:"game-link-selector fas fa-caret-right"}),_vm._v("Delta Dash ")]),_c('div',{staticClass:"game-credit"},[_vm._v("By Kiiro")]),_c('div',{staticClass:"game-link mt-2",on:{"click":_vm.openDrive}},[_c('i',{staticClass:"game-link-selector fas fa-caret-right"}),_vm._v("Δ Drive ")]),_c('div',{staticClass:"game-credit"},[_vm._v("By RatzKlaw")])]),_c('img',{staticClass:"arcade-joystick",attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-joystick.webp"}})]),_c('img',{ref:"delu",staticClass:"bar-fg",style:({
          left: "287px",
          top: "595px",
          height: "1337px",
        }),attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-delu.webp"}}),_c('img',{ref:"twinkle",staticClass:"bar-bg twinkle",style:({
          left: "749px",
          top: "1003px",
          height: "166px",
        }),attrs:{"src":"https://files.delutaya.club/file/deluparty/bd2023-twinkle.webp"}})]),(_vm.debug)?_c('div',[_vm._l((_vm.focalPoints),function(point,i){return _c('div',{key:("inner-" + i),staticClass:"focal-point",style:({
          left: (((point.left + point.width / 2 - point.innerRadius) * _vm.squishX) + "px"),
          top: (((point.top + point.height / 2 - point.innerRadius) * _vm.squishY) + "px"),
          width: ((point.innerRadius * 2 * _vm.squishX) + "px"),
          height: ((point.innerRadius * 2 * _vm.squishY) + "px"),
          borderColor: "red",
        })})}),_vm._l((_vm.focalPoints),function(point,i){return _c('div',{key:("outer-" + i),staticClass:"focal-point",style:({
          left: (((point.left + point.width / 2 - point.outerRadius) * _vm.squishX) + "px"),
          top: (((point.top + point.height / 2 - point.outerRadius) * _vm.squishY) + "px"),
          width: ((point.outerRadius * 2 * _vm.squishX) + "px"),
          height: ((point.outerRadius * 2 * _vm.squishY) + "px"),
          borderColor: "purple",
        })})}),_vm._l((_vm.focalPoints),function(point,i){return _c('div',{key:("hitbox-" + i),staticClass:"focal-hitbox",style:({
          left: ((point.left * _vm.squishX) + "px"),
          top: ((point.top * _vm.squishY) + "px"),
          width: ((point.width * _vm.squishX) + "px"),
          height: ((point.height * _vm.squishY) + "px"),
          borderColor: "green",
        })})})],2):_vm._e(),(_vm.overlay)?_c('div',{staticClass:"flex d-flex flex-column justify-content-end align-items-end text-white",on:{"click":_vm.clickZoomedOverlay}}):_vm._e(),_c('div',{staticClass:"lightbox-overlay",class:{ show: _vm.showLightbox },on:{"click":_vm.closeModals}}),_c('div',{staticClass:"lightbox",class:{ show: _vm.showLightbox }},[(_vm.showLightbox && !_vm.showLightboxContent)?_c('loading',{staticClass:"lightbox-loading",attrs:{"color":"rgba(255 255 255 / 100%)","font":"PixelMPlus10"}}):_vm._e(),(_vm.lightboxImage)?_c('div',{staticClass:"lightbox-image-container"},[(_vm.lightboxImage)?_c('img',{staticClass:"lightbox-image zoomable",class:{ show: _vm.showLightboxContent },attrs:{"src":_vm.lightboxImage},on:{"load":_vm.lightboxImageLoaded,"click":_vm.zoom}}):_vm._e()]):_vm._e(),(_vm.lightboxVideo)?_c('iframe',{staticClass:"lightbox-video",class:{ show: _vm.showLightboxContent },attrs:{"src":_vm.lightboxVideo,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":"","width":"1120","height":"730"},on:{"load":_vm.lightboxVideoLoaded}}):_vm._e(),(_vm.lightboxDetails)?_c('div',{staticClass:"lightbox-details",class:{ show: _vm.showLightboxContent },domProps:{"innerHTML":_vm._s(_vm.lightboxDetails)}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arcade-image"},[_c('img',{attrs:{"src":require("@/assets/images/dot_taya.png")}})])}]

export { render, staticRenderFns }
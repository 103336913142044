/**
 * Selection Service
 *
 * Utility functions for resolving selections with local storage values.
 */
export default {
	//Wrappers for getting and setting from $ls since it doesn't handle raw JSON objects
	set(vue, key, value) {
		vue.$ls.set(key, JSON.stringify(value));
	},

	get(vue, key) {
		let val = vue.$ls.get(key);
		return val != "undefined" && JSON.parse(val);
	},

	getDefault(vue, key, defaultVal) {
		let val = this.get(vue, key);
		if (val == null || val == undefined) {
			return defaultVal;
		} else {
			return val;
		}
	},

	//2-way bind a value
	//Equivalent to calling 'getDefault' on the key and also setting a watch to 'set' it on change
	bind(vue, localKey, storeKey, defaultVal) {
		if (!storeKey) {
			vue.storagePrefix = vue.storagePrefix || "";
			storeKey = vue.storagePrefix + localKey;
		}

		vue.$watch(localKey, () => {
			this.set(vue, storeKey, vue[localKey]);
		});
		return this.getDefault(vue, storeKey, defaultVal);
	},
};

<template>
	<img ref="img" class="parallax-image" :src="src" :style="{transform: `translate(${(horizontal - 0.5) * shift}px, ${(vertical - 0.5) * 0.47 * shift}px)`}" @load="loadedImg">
</template>

<script>
export default {
	name: "ParallaxLayer",

	props: ["shift", "src", "horizontal", "vertical"],

	components: {},

	data() {
		return {
            loaded: false,
        };
	},

	created() {
        console.log("create", this.src)
    },

    mounted() {
    },

	methods: {
        loadedImg(e) {
            if(this.loaded) return
            console.log("loaded image internal", this.src, e)
            this.$emit("loaded")
            this.loaded = true
        }
	},
};
</script>

<style scoped>
    .parallax-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
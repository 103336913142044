<template>
	<div class="profile-card d-flex flex-row align-items-center">
		<h2 class="flex mb-0"><i class="fas fa-fw fa-list mr-2" />{{ $t("labels.admin") }}</h2>
		<div v-if="user.is_idol || user.is_admin" class="text-right">
			<div class="text-success"><i class="fas fa-check mr-1" />Access</div>
		</div>
		<div v-else>
			<div class="text-muted" style="font-size: 20px">NYI</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MembersCard",

	props: ["user"],

	components: {},

	data() {
		return {};
	},

	created() {},

	methods: {},
};
</script>

<style scoped>
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"canvas",style:({transform: ("scale(" + (this.scaleFactor) + ")")}),on:{"mousemove":_vm.heatmapHover,"click":_vm.heatmapClick}},[_vm._l((_vm.defs),function(def,i){return [(!def.full_url)?_c('img',{key:i,staticClass:"canvas-element",style:({
                    top: ((def.top) + "px"),
                    left: ((def.left) + "px"),
                    width: ((def.width) + "px")
                }),attrs:{"src":def.img_url}}):_vm._e(),(def.full_url)?_c('img',{key:i,staticClass:"canvas-element",style:({
                    top: ((def.top) + "px"),
                    left: ((def.left) + "px"),
                    width: ((def.width) + "px")
                }),attrs:{"src":def.img_url}}):_vm._e()]}),_c('canvas',{ref:"heatmap",staticClass:"heatmap",attrs:{"width":"1125","height":"2000"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from "axios"

export default {
	getOrnaments() {
		return axios.get(`/v1/christmas/ornaments`)
	},

	saveOrnament(ornament, user) {
		if(user) {
			return axios.post(`/v1/christmas/ornament`, ornament)
		} else {
			return axios.post(`/v1/anon/christmas/ornament`, ornament)
		}
	},

	deleteOrnament(id) {
		return axios.delete(`/v1/christmas/ornament/${id}`)
	},

	saveAll(ornaments) {
		return axios.post(`/v1/christmas/ornaments`, ornaments)
	}
}
<template>
	<div class="canvas" @mousemove="heatmapHover" @click="heatmapClick" ref="container" :style="{transform: `scale(${this.scaleFactor})`}">
        <template v-for="(def, i) in defs">
            <img
                v-if="!def.full_url"
                :key="i"
                :src="def.img_url"
                class="canvas-element"
                :style="{
                    top: `${def.top}px`,
                    left: `${def.left}px`,
                    width: `${def.width}px`
                }"
            >
            <img
                v-if="def.full_url"
                :key="i"
                :src="def.img_url"
                class="canvas-element"
                :style="{
                    top: `${def.top}px`,
                    left: `${def.left}px`,
                    width: `${def.width}px`
                }"
            >
        </template>
        <canvas width="1125" height="2000" class="heatmap" ref="heatmap"></canvas>
    </div>
</template>

<script>
var heatmap = require("@/assets/images/birthday/heatmap.png")

export default {
	name: "Canvas",

	props: ["shift", "src", "horizontal", "vertical", "user"],

	components: {},

	data() {
		return {
            defs: [
                {
                    "artist": "",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Background2.webp",
                    "full_url": "",
                    "artist_url": "",
                    "top": 0,
                    "left": 0,
                    "width": 1125
                },
                {
                    "artist": "リヅカ",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Riduka.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Riduka_full.png",
                    "artist_url": "https://twitter.com/_riduka",
                    "top": 324,
                    "left": 2,
                    "width": 181
                },
                {
                    "artist": "Kiiro",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Kiiro.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Kiiro_full.png",
                    "artist_url": "https://twitter.com/KiiroUsuario",
                    "top": 223,
                    "left": 808,
                    "width": 303
                },
                {
                    "artist": "Ivan Sutikno",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Ivan.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Ivan_full.png",
                    "artist_url": "https://twitter.com/ivan_sutikno",
                    "top": 237,
                    "left": 536,
                    "width": 130
                },
                {
                    "artist": "にや",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Niya.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Niya_full.jpg",
                    "artist_url": "https://twitter.com/NiyAcccc",
                    "top": 388,
                    "left": 500,
                    "width": 190
                },
                {
                    "artist": "Vee",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Vee.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Vee_full.png",
                    "artist_url": "https://twitter.com/DoctorEevee",
                    "top": 323,
                    "left": 155,
                    "width": 143
                },
                {
                    "artist": "LevenΔ",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Leven.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Leven_full.png",
                    "artist_url": "https://twitter.com/ctpggy",
                    "top": 558,
                    "left": 39,
                    "width": 143
                },
                {
                    "artist": "Kazami Yui",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Kazami.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Kazami_full.png",
                    "artist_url": "https://twitter.com/KazamiYui2",
                    "top": 260,
                    "left": 256,
                    "width": 136
                },
                {
                    "artist": "Poppy MechΔ ",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Poppy-Mecha.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Poppy Mecha_full.png",
                    "artist_url": "https://twitter.com/Hugooox3",
                    "top": 677,
                    "left": 141,
                    "width": 120
                },
                {
                    "artist": "キルノルキ",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Ruki.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Ruki_full.jpg",
                    "artist_url": "https://twitter.com/KIRUNO_RUKI",
                    "top": 294,
                    "left": 429,
                    "width": 157
                },
                {
                    "artist": "RaRa",
                    "img_url": "https://files.delutaya.club/file/delucanvas/rara.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/rara_full.png",
                    "artist_url": "https://twitter.com/rara086",
                    "top": 498,
                    "left": 144,
                    "width": 176
                },
                {
                    "artist": "Kuro Hayakawa™",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Kuro-Hayakawa.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Kuro Hayakawa_full.png",
                    "artist_url": "https://twitter.com/HayakawaKuro",
                    "top": 1514,
                    "left": 435,
                    "width": 254
                },
                {
                    "artist": "Naya Wolf",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Naya.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Naya_full.png",
                    "artist_url": "https://twitter.com/nayawolf621",
                    "top": 1170,
                    "left": 871,
                    "width": 187
                },
                {
                    "artist": "ドクロ 𝗗𝗲𝗹𝘂𝘅𝗲✨",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Dokuro.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Dokuro_full.png",
                    "artist_url": "https://twitter.com/DokuroDx",
                    "top": 1307,
                    "left": 732,
                    "width": 381
                },
                {
                    "artist": "PotΔtoseed",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Potatoseed.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Potatoseed_full.png",
                    "artist_url": "https://twitter.com/potatoseeed",
                    "top": 1589,
                    "left": 814,
                    "width": 311
                },
                {
                    "artist": "Laddernumber12",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Laddernumber12.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Laddernumber12_full.png",
                    "artist_url": "https://twitter.com/laddernumber12",
                    "top": 534,
                    "left": 905,
                    "width": 205
                },
                {
                    "artist": "Mas1904",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Mas1904.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Mas1904_full.png",
                    "artist_url": "https://twitter.com/mas19042",
                    "top": 1443,
                    "left": 625,
                    "width": 210
                },
                {
                    "artist": "ムムム",
                    "img_url": "https://files.delutaya.club/file/delucanvas/mumumu.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/mumumu_full.png",
                    "artist_url": "https://twitter.com/mmmnoaka",
                    "top": 1165,
                    "left": 147,
                    "width": 384
                },
                {
                    "artist": "【 町貓 】DINCAT",
                    "img_url": "https://files.delutaya.club/file/delucanvas/DINCAT.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/DINCAT_full.jpg",
                    "artist_url": "https://twitter.com/DINCAT_Pixiv",
                    "top": 1264,
                    "left": 250,
                    "width": 511
                },
                {
                    "artist": "ポテト🐾お絵描き",
                    "img_url": "https://files.delutaya.club/file/delucanvas/ポテトお絵描き.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/ポテトお絵描き_full.png",
                    "artist_url": "https://twitter.com/potato7192",
                    "top": 1377,
                    "left": 226,
                    "width": 325
                },
                {
                    "artist": "icewine",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Icewine.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Icewine_full.jpg",
                    "artist_url": "https://twitter.com/icwine",
                    "top": 272,
                    "left": 331,
                    "width": 150
                },
                {
                    "artist": "Reagan_Ren",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Reagan.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Reagan_full.png",
                    "artist_url": "https://twitter.com/Reagan_Ren",
                    "top": 443,
                    "left": 278,
                    "width": 189
                },
                {
                    "artist": "✨Usagi",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Usagi.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Usagi_full.png",
                    "artist_url": "https://twitter.com/hoshiusagi1",
                    "top": 400,
                    "left": 408,
                    "width": 168
                },
                {
                    "artist": "appleseed",
                    "img_url": "https://files.delutaya.club/file/delucanvas/appleseed.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/appleseed_full.png",
                    "artist_url": "https://twitter.com/appleseed_art",
                    "top": 654,
                    "left": 204,
                    "width": 217
                },
                {
                    "artist": "Jeroz",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Jeroz.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Jeroz_full.png",
                    "artist_url": "https://twitter.com/Jeroz",
                    "top": 815,
                    "left": 177,
                    "width": 133
                },
                {
                    "artist": "Maru",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Maru.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Maru_full.png",
                    "artist_url": "https://twitter.com/Maru_ccy",
                    "top": 1655,
                    "left": 256,
                    "width": 330
                },
                {
                    "artist": "Klaius",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Klaius.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Klaius_full.png",
                    "artist_url": "https://twitter.com/klaius",
                    "top": 1580,
                    "left": 567,
                    "width": 268
                },
                {
                    "artist": "Kukie",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Kukie.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Kukie_full.png",
                    "artist_url": "https://twitter.com/Kukie_nyan",
                    "top": 824,
                    "left": 267,
                    "width": 208
                },
                {
                    "artist": "Artpatient✍️",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Artpatient.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Artpatient_full.png",
                    "artist_url": "https://twitter.com/Artpatient",
                    "top": 764,
                    "left": 20,
                    "width": 178
                },
                {
                    "artist": "Shirokami",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Shirokami.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Shirokami_full.png",
                    "artist_url": "https://twitter.com/shirokami_shiki",
                    "top": 1756,
                    "left": 679,
                    "width": 251
                },
                {
                    "artist": "🌈Rinisei🌈",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Rinisei.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Rinisei_full.png",
                    "artist_url": "https://twitter.com/Rinisei___",
                    "top": 953,
                    "left": 899,
                    "width": 218
                },
                {
                    "artist": "はつ乃",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Hatsuno.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Hatsuno_full.png",
                    "artist_url": "https://twitter.com/Hatsuno_xxx",
                    "top": 986,
                    "left": 3,
                    "width": 340
                },
                {
                    "artist": "赤柴",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Akashiba.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Akashiba_full.png",
                    "artist_url": "https://twitter.com/akashibag",
                    "top": 1372,
                    "left": 76,
                    "width": 278
                },
                {
                    "artist": "Shiinarei ❀ 🐙",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Shiinarei.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Shiinarei_full.png",
                    "artist_url": "https://twitter.com/shiinareii",
                    "top": 1580,
                    "left": 138,
                    "width": 186
                },
                {
                    "artist": "Cielu",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Cielu.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Cielu_full.png",
                    "artist_url": "https://twitter.com/Cieluscian",
                    "top": 1796,
                    "left": 67,
                    "width": 320
                },
                {
                    "artist": "Dane🔺♦️",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Dane.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Dane_full.jpg",
                    "artist_url": "https://twitter.com/danemnm",
                    "top": 260,
                    "left": 613,
                    "width": 270
                },
                {
                    "artist": "Gudanco",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Gudanco.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Gudanco_full.png",
                    "artist_url": "https://twitter.com/gudanco",
                    "top": 528,
                    "left": 694,
                    "width": 297
                },
                {
                    "artist": "Hyde",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Hyde.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Hyde_full.png",
                    "artist_url": "https://twitter.com/tabakko",
                    "top": 535,
                    "left": 341,
                    "width": 462
                },
                {
                    "artist": "wAA_",
                    "img_url": "https://files.delutaya.club/file/delucanvas/wAA.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/wAA_full.png",
                    "artist_url": "https://twitter.com/wAA___________",
                    "top": 1399,
                    "left": 23,
                    "width": 116
                },
                {
                    "artist": "ChouNagi",
                    "img_url": "https://files.delutaya.club/file/delucanvas/ChouNagi.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/ChouNagi_full.png",
                    "artist_url": "https://twitter.com/ChouNagi",
                    "top": 836,
                    "left": 696,
                    "width": 150
                },
                {
                    "artist": "homo erectus",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Homo-Erectus.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Homo Erectus_full.png",
                    "artist_url": "https://twitter.com/monkeysarefunni",
                    "top": 1323,
                    "left": 142,
                    "width": 130
                },
                {
                    "artist": "しぐまの",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Shigu.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Shigu_full.png",
                    "artist_url": "https://twitter.com/Xx__sigma__xX",
                    "top": 943,
                    "left": 194,
                    "width": 169
                },
                {
                    "artist": "Earthenspire",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Earthenspire.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Earthenspire_full.png",
                    "artist_url": "https://twitter.com/EarthenSpire",
                    "top": 813,
                    "left": 914,
                    "width": 162
                },
                {
                    "artist": "BaKamen",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Bakamen.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Bakamen_full.png",
                    "artist_url": "https://twitter.com/yukimikamen",
                    "top": 848,
                    "left": 808,
                    "width": 112
                },
                {
                    "artist": "Taiyu",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Taiyu.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Taiyu_full.png",
                    "artist_url": "https://twitter.com/Yumme0216",
                    "top": 948,
                    "left": 733,
                    "width": 275
                },
                {
                    "artist": "ちまるΔ",
                    "img_url": "https://files.delutaya.club/file/delucanvas/ちまる.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/ちまる_full.png",
                    "artist_url": "https://twitter.com/myi123",
                    "top": 1201,
                    "left": 661,
                    "width": 160
                },
                {
                    "artist": "Uchimasui",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Uchimasui.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/Uchimasui_full.png",
                    "artist_url": "https://twitter.com/uchimasui",
                    "top": 1536,
                    "left": 6,
                    "width": 143
                },
                {
                    "artist": "DY Haku",
                    "img_url": "https://files.delutaya.club/file/delucanvas/DY-Haku.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/DY Haku_full.png",
                    "artist_url": "https://twitter.com/Oohk_Gnid_Nauy",
                    "top": 1165,
                    "left": 768,
                    "width": 161
                },
                {
                    "artist": "Anonymous",
                    "img_url": "https://files.delutaya.club/file/delucanvas/路易.webp",
                    "full_url": "https://files.delutaya.club/file/delucanvas/路易_full.png",
                    "artist_url": "",
                    "top": 1837,
                    "left": 493,
                    "width": 182
                },
                {
                    "artist": "",
                    "img_url": "https://files.delutaya.club/file/delucanvas/Foreground.webp",
                    "full_url": "",
                    "artist_url": "",
                    "top": 0,
                    "left": 0,
                    "width": 1125
                }
            ],
            elements: [],
            mouseoverLayer: null,
            prevMouseoverLayer: null,
            scaleFactor: 1,
        };
	},

	created() {
        window.addEventListener("resize", this.doScale)
        this.doScale()
    },

    destroyed() {
        window.removeEventListener("resize", this.doScale)
    },

    mounted() {
        this.loadHeatmap()
        this.mapElements()
    },

	methods: {
        loadHeatmap() {
            var ctx = this.$refs.heatmap.getContext('2d');
            var img = new Image();
            img.onload = function() {
                console.log("do load", ctx, img)
                ctx.drawImage(img, 0, 0);
            };
            img.src = heatmap;
            console.log("set src to", heatmap)
        },

        mapElements() {
            this.elements = this.$refs.container.querySelectorAll(".canvas-element")
        },

        heatmapHover(e) {
            let pixel = this.$refs.heatmap.getContext('2d').getImageData(e.offsetX, e.offsetY, 1, 1).data;
            if(pixel[3] == 0) {
                this.mouseoverLayer = null
            } else {
                let layerNum = this.colorToLayer(pixel)
                this.mouseoverLayer = layerNum
            }
            if(this.mouseoverLayer != this.prevMouseoverLayer) {
                this.changeHovers(this.prevMouseoverLayer, this.mouseoverLayer)
                this.prevMouseoverLayer = this.mouseoverLayer
            }
        },

        changeHovers(outEl, inEl) {
            console.log("Change hover layer", outEl, inEl)
            if(outEl != null) {
                this.elements[outEl].classList.remove("hover")
            }
            if(inEl != null) {
                this.elements[inEl].classList.add("hover")
                this.$refs.container.classList.add("hovering")
            } else {
                this.$refs.container.classList.remove("hovering")
            }
        },

        heatmapClick(e) {
            let pixel = this.$refs.heatmap.getContext('2d').getImageData(e.offsetX, e.offsetY, 1, 1).data;
            if(pixel[3] == 0) {
                this.mouseoverLayer = null
            } else {
                let layerNum = this.colorToLayer(pixel)
                this.expandLayer(layerNum)
            }
        },

        expandLayer(index) {
            let def = this.defs[index];
            console.log("Layer def", def)
            if(def.full_url) {
                let details = `<div>
    <div>Artist: ${def.artist}</div>
    <a href="${def.artist_url}">${def.artist_url}</a>
</div>`
                this.$emit("clickImage", def.full_url, details)

            }
        },

        colorToLayer(pixel) {
            let r = pixel[0]
            r = (r + 1) / 64 - 1
            let g = pixel[1]
            g = (g + 1) / 64 - 1
            let b = pixel[2]
            b = (b + 1) / 64 - 1
            return r * 16 + g * 4 + b
        },

        doScale() {
            let vw = window.innerWidth - 10
            if (vw < 1125) {
                this.scaleFactor = vw / 1125
            } else {
                this.scaleFactor = 1
            }
        }
	},
};
</script>

<style scoped>
    .canvas {
        position: absolute;
        top: 0px;
        left: 0px;
        transform-origin: left top;
    }
    .canvas-element {
        position: absolute;
        pointer-events: none;
        transition: filter 0.5s ease-in-out, transform 0.5s ease-in-out;
    }
    .heatmap {
        width: 1125px;
        height: 2000px;
        /* display: none; */
    }
    .canvas.hovering {
        cursor: pointer;
    }
    .canvas.hovering:hover .canvas-element:not(.hover) {
        filter: brightness(50%);
    }
    .canvas-element.hover {
        /* filter: brightness(125%); */
    }
</style>
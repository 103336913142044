<template>
	<main-page :user="user" :refreshUser="fullRefresh">
		<div class="container mt-3 d-flex flex-column">
			<h1 v-if="loading" class="mt-5"><i class="fas fa-spinner fa-pulse text-very-muted" /></h1>
			<div v-else-if="noAccess" class="mt-5">
				<h1 class="mt-5">{{ $t("labels.no_access") }}</h1>
				<h5 class="mt-4 header-muted">{{ $t("messages.admin_page_1") }}</h5>
				<h5 class="header-muted">{{ $t("messages.admin_page_2") }}</h5>
			</div>
			<template v-else>
				<button
					class="btn btn-success my-3"
					@click="addSchedule"
				>
					<i class="fas fa-plus mr-2" />{{ $t("labels.new_schedule") }}
				</button>

				<div
					v-for="(schedule) in schedules" :key="schedule.id"
					class="general-card card-width mb-3 pt-2 position-relative"
				>
					<div class="d-flex flex-row align-items-center">
						<div class="flex delu-text-small my-1 title-text">
							{{ getScheduleRange(schedule) }}
						</div>
						<i class="fas fa-caret-down delu-text-large title-text mx-2 clickable anim-rotate" :class="{'fa-rotate-90': !schedule.meta.expanded}" @click="schedule.meta.expanded = !schedule.meta.expanded" />
					</div>

					<schedule-edit v-if="schedule.meta.expanded" class="pt-2" :schedule="schedule" />
				</div>
			</template>
		</div>
	</main-page>
</template>


<script>
import Toast from "@/utils/Toast";
import { DateTime } from "luxon";
import ScheduleService from "@/services/ScheduleService"
import ScheduleEdit from "@/components/ScheduleEdit"

export default {
	name: "SchedulesEdit",

	props: ["user"],

	components: { ScheduleEdit },

	data() {
		return {
			DateTime: DateTime,
			Toast: Toast,

            loading: true,
			noAccess: false,
            schedules: null
		};
	},

	created() {
        if(!this.user) {
            this.loading = false
            this.noAccess = true
            return
        }
        this.loadSchedules()},

	methods: {
        loadSchedules() {
            ScheduleService.getSchedules().then(r => {
                this.schedules = r.data
                this.loading = false
				for(let i in this.schedules) {
					if(i == 0) {
						this.$set(this.schedules[i], "meta", {expanded: true})
					} else {
						this.$set(this.schedules[i], "meta", {expanded: false})
					}
				}
            }).catch(e => {
                console.error(e)
                Toast.error("Failed to get schedules")
            })
        },

        addSchedule() {
            let newSchedule = {
                streams: [{platform: "youtube", time: "20:00:00"}],
				meta: {expanded: true}
            }
            this.schedules.unshift(newSchedule)
        },

		fullRefresh() {
			this.$router.go();
		},

		getScheduleRange(schedule) {
			console.log("getScheduleRange", schedule)
			if(!schedule.streams || schedule.streams.length == 0) {
				return this.$i18n.t('labels.new_schedule')
			}
			
			let first = schedule.streams[0]
			let last = schedule.streams[schedule.streams.length - 1]

			if (!first.date && !last.date) {
				return this.$i18n.t('labels.new_schedule')
			}

			if (first.date == last.date) {
				return first.date
			} else if(!first.date) {
				return last.date
			} else if(!last.date) {
				return first.date
			} else {
				return `${first.date} - ${last.date}`
			}
		}
	},
};
</script>

<style scoped>
.card-width {
	width: 1060px;
}

@media only screen and (max-width: 1080px) and (min-width: 720px) {
	.card-width {
		width: 660px;
	}
}

@media only screen and (max-width: 720px) {
	.card-width {
		max-width: 400px;
	}
}
</style>
<template>
	<div>
		<audio controls class="d-none" :src="src" @timeupdate="timeUpdate" ref="audio" />
		<div :class="{ 'd-flex': playing }" class="progress-overlay flex-row justify-content-center align-items-center">
			<div class="flex meter animate" ref="meter">
				<span ref="progressBar" :style="{ width: playing ? `${progress}px` : `100%` }"><span></span></span>
			</div>
		</div>
		<div class="d-flex flex-row justify-content-between time-text">
			<template v-if="playing">
				<div class="ml-1">{{ $t("labels.playing") }}</div>
				<div class="mr-1">{{ formatTime(elapsed) }} / {{ formatTime(duration) }}</div>
			</template>
			<template v-else>
				<div class="ml-1">{{ $t("labels.stopped") }}</div>
				<div class="mr-1">0:00 / {{ formatTime(duration) }}</div>
			</template>
		</div>
		<button v-if="playing" class="mt-1 controls-button" @click="stopSound">
			<i class="fas fa-lg fa-stop" />
		</button>
		<button v-else class="mt-1 controls-button" @click="playSound">
			<i class="fas fa-lg fa-play" />
		</button>
	</div>
</template>

<script>
export default {
	name: "AudioPlayer",

	props: ["src"],

	components: {},

	data() {
		return {
			playing: false,
			progress: 0,
			startTime: null, // milliseconds
			duration: null, // seconds
			elapsed: null,
		};
	},

	created() {},

	mounted() {
		let el = this.$refs.audio;
		el.addEventListener("canplaythrough", () => {
			console.log("Mounted audio", el, el.duration);
			this.duration = el.duration;
		});
	},

	destroyed() {},

	methods: {
		playSound() {
			let el = this.$refs.audio;

			el.play();
			this.playing = true;
			this.startTime = new Date().getTime();
			this.duration = el.duration;

			this.progress = 0;
			requestAnimationFrame(this.progressUpdate);
		},

		progressUpdate() {
			let currentTime = new Date().getTime() - this.startTime;
			console.log("currentTime", currentTime);
			let progress = currentTime / (1000 * this.duration);
			console.log("progress", progress);

			let meter = this.$refs.meter;
			let meterWidth = meter.offsetWidth - 14;

			let progressWidth = progress * meterWidth;
			progressWidth = Math.round(progressWidth / 20) * 20;
			progressWidth = Math.min(progressWidth, meterWidth);
			this.progress = progressWidth;

			if (progress < 1) {
				requestAnimationFrame(this.progressUpdate);
			}
		},

		timeUpdate(event) {
			let el = event.target;
			let progress = el.currentTime / el.duration;
			this.elapsed = el.currentTime;
			console.log(progress);

			if (progress >= 1) {
				this.playing = false;
			}
		},

		stopSound() {
			let el = this.$refs.audio;

			el.pause();
			el.currentTime = 0;
			this.playing = false;
			this.progress = 0;
		},

		formatTime(seconds) {
			seconds = Math.round(seconds);
			let minutes = Math.floor(seconds / 60);
			let remSeconds = seconds - 60 * minutes;
			if (remSeconds >= 10) {
				return `${minutes}:${seconds}`;
			} else {
				return `${minutes}:0${seconds}`;
			}
		},
	},
};
</script>

<style scoped>
.meter {
	box-sizing: content-box;
	height: 20px; /* Can be anything */
	position: relative;
	border: 2px solid white;
	padding: 5px;
}
.meter > span {
	display: block;
	height: 100%;
	background-color: white;
	position: relative;
	overflow: hidden;
}
.meter > span:after,
.animate > span > span {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	overflow: hidden;
}

.time-text {
	font-size: 16px;
}

.animate > span:after {
	display: none;
}

.controls-button {
	background-color: rgba(0 0 0 / 0%);
	color: white;
	border: none;
}
.controls-button:hover {
	color: rgba(255 255 255 / 75%);
	border-color: rgba(255 255 255 / 75%);
}
.controls-button:focus {
	outline: none;
}
</style>
<template>
	<div class="dropdown-menu dropdown-menu-right mt-2 animate fadeIn text-md" :style="{ 'font-size': size + 'px' }">
		<a v-if="user" class="dropdown-item" @click="goToPath('/profile')">{{ $t("labels.profile") }}</a>
		<a class="dropdown-item" @click="goToPath('/submit')">{{ $t("labels.submit_post") }}</a>
		<a class="dropdown-item" @click="goToPath('/soundboard')">{{ $t("labels.soundboard") }}</a>
		<a class="dropdown-item" @click="goToPath('/')">{{ $t("labels.wall") }}</a>
		<a class="dropdown-item" @click="goToPath('/telescope?edit=true')">{{
			$t("labels.birthday_2022_edit_stars")
		}}</a>
		<template v-if="user && (user.is_idol || user.is_admin)">
			<div class="dropdown-divider"></div>
			<a class="dropdown-item" @click="goToPath('/members')">{{ $t("labels.members") }}</a>
			<a class="dropdown-item" @click="goToPath('/mod_posts')"
				><div v-if="awaitingPosts > 0" class="mr-1 badge badge-info" style="font-size: 14px">
					{{ awaitingPosts }}
				</div>
				{{ $t("labels.moderate_posts") }}
			</a>
			<a class="dropdown-item" @click="goToPath('/files')">{{ $t("labels.file_hosting") }}</a>
		</template>
	</div>
</template>

<script>
export default {
	name: "MemberNavDropdown",

	props: {
		user: {},
		size: {
			type: Number,
			default: 16,
		},
		awaitingPosts: {},
	},

	data() {
		return {};
	},
	created() {},
	computed: {},

	methods: {
		goToPath(path) {
			this.$router.push(path);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

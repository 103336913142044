<template>
	<main-page :user="user">
		<div class="container">
			<h3>{{ $t("labels.members") }}</h3>
			<form class="input-group my-2 ml-2 my-lg-0">
				<span class="input-group-btn">
					<button
						v-if="!filter"
						type="button"
						class="btn no-border no-bg no-shadow"
						style="cursor: default; padding-left: 5px; width: 35px"
					>
						<i class="fa fa-lg fa-search"></i>
					</button>
					<button
						v-if="filter"
						type="button"
						class="btn no-border no-bg no-shadow"
						style="padding-left: 5px; width: 35px"
						@click="filter = ''"
					>
						<i class="fa fa-lg fa-times"></i>
					</button>
				</span>
				<input
					type="text"
					v-model="filter"
					class="form-control"
					style="margin-right: 5px"
					:placeholder="$t('labels.search_names')"
				/>
			</form>
			<b-table
				ref="groupTable"
				small
				striped
				hover
				fixed
				:show-empty="true"
				:items="provider"
				:fields="fields"
				:current-page="currentPage"
				:per-page="perPage"
				:filter="filter"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:busy.sync="busy"
				class="text-left"
			>
				<template #cell(twitter_comment.createdDatetime)="{ item: row }">
					<div v-if="row.twitter_comment">
						{{ $d(new Date(row.twitter_comment.createdDatetime), "long") }}
					</div>
				</template>

				<template #cell(twitter_handle)="{ item: row }">
					<a :href="`https://www.twitter.com/${row.twitter_handle}`" target="_blank"
						>@{{ row.twitter_handle }}</a
					>
				</template>

				<template #cell(status)="{ item: row }">
					<i v-if="!following" class="fas fa-lg fa-spinner fa-pulse text-muted" />
					<template v-if="following">
						<i v-if="isFollowing(row)" class="fas fa-check text-success" />
						<i v-if="!isFollowing(row)" class="fas fa-times text-danger" />
					</template>
				</template>
			</b-table>
			<b-pagination
				size="md"
				class="m-1"
				:total-rows="totalRows"
				v-model="currentPage"
				:per-page="perPage"
			></b-pagination>
		</div>
	</main-page>
</template>

<script>
import MemberService from "@/services/MemberService";
import f from "@/utils/Format";

export default {
	name: "MembersList",

	props: ["user"],

	components: {},

	data() {
		return {
			f: f,
			fields: [
				{ key: "twitter_comment.createdDatetime", label: this.$i18n.t("labels.time"), sortable: true },
				{ key: "pixiv_user_name", label: this.$i18n.t("labels.pixiv_name"), sortable: true },
				{ key: "twitter_handle", label: this.$i18n.t("labels.twitter_handle"), sortable: true },
				{
					key: "status",
					label: this.$i18n.t("labels.status"),
					thStyle: "width: 100px;",
					thClass: "text-center",
					tdClass: "text-center",
				},
			],

			currentPage: 1,
			perPage: 20,
			filter: "",
			sortBy: null,
			sortDesc: null,
			busy: null,
			totalRows: 0,

			following: false,
		};
	},

	methods: {
		provider(ctx) {
			return MemberService.listVerifications(ctx).then((r) => {
				this.totalRows = r.data.totalRows;
				this.rows = r.data.rows;
				return this.rows;
			});
		},
	},
};
</script>

<style scoped>
.container {
	padding-top: 50px;
	justify-content: start;
}
input {
	background-color: #222222;
	color: white !important;
}
input:focus {
	background-color: #222222;
	color: white !important;
}
</style>
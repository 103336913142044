<template>
	<div class="subheader-container">
		<div class="subheader-line"></div>
		<div class="subheader-bg">
			<div class="subheader">
				<div class="youtube-link-container ml-3">
					<a
						class="youtube-link"
						target="_blank"
						href="https://www.youtube.com/channel/UC7YXqPO3eUnxbJ6rN0z2z1Q"
					>
						<img class="youtube-icon mr-3" src="@/assets/images/YouTube_Logo_2017.svg" />
						<div class="nowrap"><span class="hide-xs">DELUTAYA </span>Δ.</div>
					</a>
				</div>
				<div class="hide-sm vertical-divider mx-4" style="height: 30px"></div>
				<a class="hide-sm subheader-link link-hover" @click="showFanboxDetails">{{ $t("labels.fanbox") }}</a>
				<a class="hide-sm subheader-link link-hover" @click="showTwitterDetails">{{ $t("labels.twitter") }}</a>
				<a class="hide-sm subheader-link link-hover" @click="showDiscordDetails">{{ $t("labels.discord") }}</a>
				<a v-if="!socialsOnly" class="hide-sm subheader-link link-hover" @click="goToSoundboard">{{
					$t("labels.soundboard")
				}}</a>
				<a
					v-if="user && user.is_admin && !socialsOnly"
					class="hide-sm subheader-link link-hover"
					@click="goToSubmit"
					>{{ $t("labels.post") }}</a
				>
				<div class="hide-sm flex" />
				<div class="dropdown">
					<a
						class="subheader-links-icon subheader-icon link-expand m-right"
						data-toggle="dropdown"
						data-pjax-click-state="anchor-empty"
						aria-expanded="false"
					>
						<i class="far fa-link fa-2x" />
						<div class="mt-1 upper fit-more-text">{{ $t("labels.links") }}</div>
					</a>
					<div class="dropdown-menu dropdown-menu-right mt-2 py-2 animate fadeIn text-md">
						<a class="dropdown-item text-lg" @click="showFanboxDetails">
							<pixiv-icon size="20" class="fa-fw mr-2" />{{ $t("labels.fanbox") }}
						</a>
						<a class="dropdown-item text-lg" @click="showTwitterDetails">
							<i class="fab fa-fw fa-twitter mr-2" />{{ $t("labels.twitter") }}
						</a>
						<a class="dropdown-item text-lg" @click="showDiscordDetails">
							<i class="fab fa-fw fa-discord mr-2" />{{ $t("labels.discord") }}
						</a>
						<a v-if="!socialsOnly" class="dropdown-item text-lg" @click="goToSoundboard">
							<i class="fas fa-fw fa-comment mr-2" />{{ $t("labels.soundboard") }}
						</a>
						<a
							v-if="user && user.is_admin && !socialsOnly"
							class="dropdown-item text-lg"
							@click="goToSubmit"
						>
							<i class="fas fa-fw fa-comment mr-2" />{{ $t("labels.post") }}
						</a>
					</div>
				</div>
				<div class="dropdown">
					<a
						class="subheader-icon link-expand"
						data-toggle="dropdown"
						data-pjax-click-state="anchor-empty"
						aria-expanded="false"
					>
						<i class="far fa-sun fa-2x" />
						<div class="mt-1 upper fit-more-text">{{ $t("labels.theme") }}</div>
					</a>
					<theme-dropdown
						:user="user"
						:size="16"
						:refreshBG="refreshBG"
						:refreshTL="refreshTL"
						:refreshOrder="refreshOrder"
						:hidePostOptions="socialsOnly"
					/>
				</div>
				<template v-if="!socialsOnly">
					<div class="dropdown">
						<a
							class="subheader-icon link-expand m-left"
							data-toggle="dropdown"
							data-pjax-click-state="anchor-empty"
							aria-expanded="false"
							id="userDropdownButton"
						>
							<i class="far fa-user fa-2x" />
							<div class="mt-1 upper fit-more-text">{{ $t("labels.user") }}</div>
						</a>
						<user-dropdown :user="user" :size="16" :refreshUser="refreshUser" />
					</div>
					<div v-if="user" class="dropdown">
						<a
							class="subheader-icon link-expand m-left"
							data-toggle="dropdown"
							data-pjax-click-state="anchor-empty"
							aria-expanded="false"
						>
							<i class="far fa-bars fa-2x" />
							<div class="mt-1 upper fit-more-text">{{ $t("labels.nav") }}</div>
						</a>
						<div v-if="awaitingPosts > 0" class="posts-badge badge badge-info">{{ awaitingPosts }}</div>
						<member-nav-dropdown :user="user" :size="16" :awaitingPosts="awaitingPosts" />
					</div>
				</template>
				<div class="mr-3" />
			</div>
		</div>

		<b-modal id="fanboxDetails" header-bg-variant="success" size="lg" hideFooter>
			<template #modal-header>
				<div class="large-icon-modal-header">
					<div class="d-flex flex-column">
						<pixiv-icon :size="108" />
						<div>{{ $t("platforms.fanbox") }}</div>
					</div>
				</div>
			</template>
			<div class="large-details">
				<p>{{ $t("guides.fanbox_1") }}</p>
				<p>{{ $t("guides.fanbox_2") }}</p>
				<p>{{ $t("guides.fanbox_3") }}</p>
				<div class="details-link-container">
					<a class="btn btn-lg btn-success details-link" target="_blank" href="https://deluta.fanbox.cc">
						deluta.fanbox.cc
					</a>
				</div>
				<p class="mt-3">{{ $t("guides.fanbox_4") }}</p>
			</div>
		</b-modal>

		<b-modal id="twitterDetails" header-bg-variant="primary" hideFooter>
			<template #modal-header>
				<div class="large-icon-modal-header">
					<div class="d-flex flex-column">
						<i class="fab fa-twitter fa-3x"></i>
						<div>{{ $t("platforms.twitter") }}</div>
					</div>
				</div>
			</template>
			<div class="large-details">
				<p>{{ $t("guides.twitter_1") }}</p>
				<div class="details-link-container">
					<a class="btn btn-lg btn-primary details-link" target="_blank" href="https://twitter.com/mgmgoi4">
						@mgmgoi4
					</a>
				</div>
				<hr class="my-4" />
				<p>{{ $t("guides.twitter_2") }}</p>
				<div class="details-link-container">
					<a
						class="btn btn-lg btn-primary details-link"
						target="_blank"
						href="https://twitter.com/DELUTAYA_FANBOX"
					>
						@DELUTAYA_FANBOX
					</a>
				</div>
				<p class="mt-3">{{ $t("guides.twitter_3") }}</p>
			</div>
		</b-modal>

		<b-modal id="discordDetails" header-bg-variant="info" hideFooter>
			<template #modal-header>
				<div class="large-icon-modal-header">
					<div class="d-flex flex-column">
						<i class="fab fa-discord fa-3x"></i>
						<div>{{ $t("platforms.discord") }}</div>
					</div>
				</div>
			</template>
			<div class="large-details">
				<p>{{ $t("guides.discord_1") }}</p>
				<div class="details-link-container">
					<a class="btn btn-lg btn-info details-link" target="_blank" href="https://discord.gg/dcJbmHHqJs"
						>DELUTAYΔ Fans Server</a
					>
				</div>
				<p class="mt-4">{{ $t("guides.discord_2") }}</p>
			</div>
		</b-modal>

		<b-modal id="fanboxConnect" size="lg" hideFooter class="gradient-header">
			<template #modal-header>
				<div class="large-icon-modal-header">
					<div class="d-flex flex-row justify-content-center align-items-center">
						<div class="d-flex flex-column">
							<i class="fab fa-twitter fa-3x"></i>
							<div>{{ $t("platforms.twitter") }}</div>
						</div>
						<i class="fas fa-plus fa-lg mx-4" />
						<div class="d-flex flex-column">
							<pixiv-icon :size="108" />
							<div>{{ $t("platforms.fanbox") }}</div>
						</div>
					</div>
				</div>
			</template>
			<div class="large-details">
				<div class="details-link-container py-0">
					<h4 class="bg-success p-3 my-3">{{ $t("guides.fanbox_connect_1") }}</h4>
				</div>
				<p class="mt-3">{{ $t("guides.fanbox_connect_2") }}</p>
				<div class="details-link-container py-0 mt-5">
					<h4 class="bg-success p-3 my-3">{{ $t("guides.fanbox_connect_3") }}</h4>
				</div>
				<p class="mt-3">{{ $t("guides.fanbox_connect_4") }}</p>
				<div class="details-link-container">
					<a
						class="btn btn-lg btn-success details-link"
						style="min-width: 200px"
						target="_blank"
						href="https://deluta.fanbox.cc/posts/1595549"
					>
						{{ $t("guides.fanbox_connect_5") }}
					</a>
				</div>
				<p class="mt-3">{{ $t("guides.fanbox_connect_6") }}</p>
				<p class="text-muted mb-0" style="font-size: 16px">{{ $t("guides.fanbox_connect_7") }}</p>
				<p class="text-muted" style="font-size: 16px">{{ $t("guides.fanbox_connect_8") }}</p>
			</div>
		</b-modal>
	</div>
</template>

<script>
import PostService from "@/services/PostService";
import UserDropdown from "@/components/UserDropdown";
import ThemeDropdown from "@/components/ThemeDropdown";
import MemberNavDropdown from "@/components/MemberNavDropdown";

export default {
	name: "NavBar",

	props: ["user", "refreshBG", "refreshTL", "refreshUser", "refreshOrder", "socialsOnly"],

	components: { UserDropdown, ThemeDropdown, MemberNavDropdown },

	data() {
		return {
			awaitingPosts: 0,
			pinging: false,
			pingTimer: null,
		};
	},
	created() {
		this.loadData();
	},

	destroyed() {
		if (this.pingTimer) {
			clearTimeout(this.pingTimer);
		}
	},

	watch: {
		pingNewPosts() {
			this.loadData();
		},
	},

	computed: {
		pingNewPosts() {
			return this.user && this.user.is_admin;
		},
	},

	methods: {
		loadData() {
			if (this.pingNewPosts && !this.pinging) {
				let checkPosts = () => {
					PostService.getPostCount()
						.then((r) => {
							this.awaitingPosts = r.data.awaiting;
						})
						.catch((e) => {
							console.error(e);
						});

					this.pingTimer = setTimeout(checkPosts, 60000);
				};

				this.pinging = true;
				checkPosts();
			}
		},

		showFanboxDetails() {
			this.$bvModal.show("fanboxDetails");
		},

		showTwitterDetails() {
			this.$bvModal.show("twitterDetails");
		},

		showDiscordDetails() {
			this.$bvModal.show("discordDetails");
		},

		goToSubmit() {
			this.$router.push("/submit");
		},

		goToSoundboard() {
			this.$router.push("/soundboard");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

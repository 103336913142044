<template>
	<div ref="card" class="wall-card d-flex flex-column">
		<div
			class="bg-accent"
			:style="{ transform: `rotate(${rotation}turn)`, 'animation-delay': `${rotation * -100000}ms` }"
		>
			△
		</div>
		<div class="d-flex flex-row align-items-center mt-1">
			<template v-if="!isBlank && post.user_type != 'text'">
				<div v-if="post.user_type == 'pixiv'" class="nowrap" style="font-size: 18px">
					<pixiv-icon size="20" class="fa-fw mr-2" />{{ post.user_name }}
				</div>
				<div v-if="post.user_type == 'discord'" class="nowrap" style="font-size: 18px">
					<i class="fab fa-fw fa-discord mr-1" />{{ post.user_name }}
					<span class="text-half-muted">#{{ post.ext }}</span>
				</div>
				<div v-if="post.user_type == 'twitter'" class="nowrap" style="font-size: 18px">
					<i class="fab fa-fw fa-twitter mr-1" />@{{ post.user_name }}
				</div>
				<div v-if="post.user_type == 'youtube'" class="nowrap" style="font-size: 18px">
					<i class="fab fa-fw fa-youtube mr-1" />{{ post.user_name }}
				</div>
			</template>
			<div v-if="post.members_only" class="ml-auto text-muted mr-1">
				<i class="fas fa-eye-slash mr-1" />{{ $t("labels.members_only") }}
			</div>
		</div>
		<template v-if="!isBlank && post.user_type == 'text'">
			<div style="font-size: 18px; opacity: 0.5">{{ post.user_name }}</div>
		</template>
		<template v-if="!isBlank">
			<div v-if="post.image_url" class="card mt-2 p-2">
				<div
					class="post-img-container expandable"
					:style="{ 'padding-top': `calc(${post.image_height} / ${post.image_width} * 100%)` }"
					@click="showImageLightbox(post)"
				>
					<div class="loading-container d-flex flex-column justify-content-center align-items-center">
						<div class="text-muted" style="font-size: 18px">Loading...</div>
					</div>
					<img :src="post.thumb_url || post.image_url" :id="`img-${post._id}`" class="post-img" />
				</div>
			</div>
			<div v-if="post.video_url" class="card mt-2 p-2">
				<div
					class="post-img-container expandable"
					style="padding-top: 75%"
					@click="showVideoLightbox(getEmbedURL(post.video_url))"
				>
					<div class="loading-container d-flex flex-column justify-content-center align-items-center">
						<div class="text-muted" style="font-size: 18px">Loading...</div>
					</div>
					<img :src="getThumbnailURL(post.video_url)" class="post-img" />
				</div>
			</div>
			<div v-if="post.message" class="message-container">
				<div class="message-inner-container" :style="{ width: post.translation ? '200%' : '100%' }">
					<div
						ref="message"
						class="card mt-2 p-3 justify-content-center pre-line"
						:class="{ 'show-message': !post.showTranslated, 'hide-message': post.showTranslated }"
						v-html="post.message"
					></div>
					<div
						ref="translation"
						v-if="post.translation"
						class="card mt-2 p-3 justify-content-center pre-line"
						:class="{ 'show-message': post.showTranslated, 'hide-message': !post.showTranslated }"
						v-html="post.translation.text"
					></div>
				</div>
			</div>
		</template>
		<template v-if="isBlank">
			<div class="card mt-2 p-2">
				<div class="post-img-container clickable" style="padding-top: 75%">
					<div class="loading-container d-flex flex-column justify-content-center align-items-center">
						<div class="mx-4 text-muted text-center" style="font-size: 18px">
							{{ $t("messages.hidden_members_only_post_1") }}
						</div>
						<div class="mx-4 text-muted text-center mt-3" style="font-size: 18px">
							<button
								class="btn btn-outline-secondary mr-2"
								style="font-size: 18px"
								@click.stop="goToSignIn"
							>
								{{ $t("messages.sign_in") }}
							</button>
							{{ $t("messages.hidden_members_only_post_2") }}
						</div>
					</div>
				</div>
			</div>
		</template>
		<div class="d-flex flex-row align-items-center w-100">
			<div
				v-if="post.translation"
				style="margin-left: 2px; margin-bottom: -10px; outline: none"
				v-tippy="{ hideOnClick: false, placement: 'right', arrow: true, offset: '-1,0' }"
				:title="TLTooltip(post)"
				@click="toggleTranslation"
			>
				<i
					class="fa-2x fa-language click-anim clickable"
					:class="{ far: !post.showTranslated, fas: post.showTranslated }"
				/>
			</div>
			<div
				v-else-if="post.message"
				class="half-tl-icon"
				v-tippy="{ hideOnClick: false, placement: 'right', arrow: true }"
				:title="TLTooltip(post)"
			>
				<i class="fas fa-2x fa-language" />
			</div>
			<div class="ml-auto text-muted mt-2" style="font-size: 12px">
				{{ DateTime.fromISO(post.created_at).toLocaleString(DateTime.DATETIME_FULL) }}
			</div>
		</div>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";
// import Store from "@/utils/Store";
import Img from "@/utils/Img";
import { DateTime } from "luxon";
import * as basicLightbox from "basiclightbox";
import $ from "jquery";

export default {
	name: "WallPost",

	props: ["post"],

	data() {
		return {
			DateTime: DateTime,
			rotation: Math.random(),
		};
	},
	created() {
		this.$set(this.post, "showTranslated", false);
	},

	mounted() {},

	computed: {
		isBlank() {
			return this.post.members_only && this.post.user_name == "";
		},
	},

	methods: {
		logout() {
			AuthService.logout(this);
		},

		toggleTranslation() {
			this.post.showTranslated = !this.post.showTranslated;
		},

		getEmbedURL(url) {
			let youtubeID = this.getYoutubeID(url);
			if (youtubeID) {
				return `https://www.youtube.com/embed/${youtubeID}`;
			}
		},

		getThumbnailURL(url) {
			let youtubeID = this.getYoutubeID(url);
			if (youtubeID) {
				return `https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`;
			}
		},

		getYoutubeID(url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			let match = url.match(regExp);

			return match && match[2].length === 11 ? match[2] : null;
		},

		showImageLightbox(post) {
			const instance = basicLightbox.create(`
				<img src="${post.image_url}" width="800" height="600">
			`);

			instance.show();
		},

		// Experiment with cloning images into base64, didn't work
		getURL(post) {
			if (post.image_url.substring(0, 10) == "data:image") {
				return post.image_url;
			}
			if (!Img.isSameDomain(post.image_url)) {
				return post.image_url;
			}
			let img = document.getElementById(`img-${post._id}`);
			if (!img) {
				return post.image_url;
			}
			let dataURL = Img.getBase64Image(img);
			if (!dataURL) {
				return post.image_url;
			}

			return dataURL;
		},

		showVideoLightbox(url) {
			console.log("showVideoLightbox", url);
			const instance = basicLightbox.create(`
				<iframe src="${url}" width="1120" height="730" frameborder="0"></iframe>
			`);

			instance.show();
		},

		goToSignIn() {
			// Store.set(this, "returnTo", "wall");
			// this.$router.push("/login");

			$("#userDropdownButton").dropdown("toggle");
		},

		TLTooltip(post) {
			let origLanguage = "Unknown";
			if (post.language) {
				origLanguage = this.$i18n.t("languages." + post.language);
			}

			let t_origLang = this.$i18n.t("labels.original_language");
			let t_tlBy = this.$i18n.t("labels.translation_by");
			let t_toggle = this.$i18n.t("labels.click_to_toggle_translation");

			if (!post.translation) {
				return `<div>${t_origLang}: <span class="text-primary">${origLanguage}</span></div>`;
			}
			if (post.showTranslated) {
				let mtlBy = post.translation.mtl_by;
				if (post.translation && post.translation.is_mtl) {
					return `<div class="text-left"><div>${t_tlBy}: <span class="text-primary">${mtlBy}</span></div><small class="text-muted">${t_toggle}</small></div>`;
				} else {
					return `<div class="text-left"><div>${t_tlBy}: ${post.user_name}</div><small class="text-muted">${t_toggle}</small></div>`;
				}
			} else {
				return `<div class="text-left"><div>${t_origLang}: <span class="text-primary">${origLanguage}</span></div><small class="text-muted">${t_toggle}</small></div>`;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
.hamburger-menu {
	border: 4px solid black;
	border-radius: 0.5rem;
	opacity: 0.75;
}
.hamburger-icon {
	height: 40px;
}
.logo {
	margin-top: -8px;
	margin-bottom: -2px;
}
.logo-img {
	height: 80px;
}
.title {
	font-size: 72px;
	font-family: "Blender Pro";
	color: black;
	margin-top: -16px;
	margin-bottom: -16px;
	line-height: 0.8;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}
.transition-color {
	transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.message-container {
	max-width: 358px;
	overflow-x: hidden;
	margin-right: -358px;
	width: 100%;
}

.message-inner-container {
	width: 200%;
	display: flex;
	flex-direction: row;
}

.message-inner-container > div {
	width: 100%;
	transition: opacity 0.5s ease-in-out;
}

.show-message {
	order: 1;
	opacity: 1;
}

.hide-message {
	order: 2;
	opacity: 0;
}

.post-img-container {
	height: 0;
	position: relative;
	width: 100%;
	background-color: rgba(255, 0, 255, 0.02);
	overflow: hidden;
	/* padding-top should be applied manually to make this work */
}

.post-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.half-tl-icon {
	margin-left: 2px;
	margin-bottom: -10px;
	outline: none;
	width: 19px;
	overflow: hidden;
	border-radius: 50%;
	height: 25px;
	margin-top: -3px;
}

.half-tl-icon > i {
	margin-top: -1px;
	margin-left: -17px;
}
</style>

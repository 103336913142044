<template>
	<div
		class="dropdown-menu dropdown-menu-right pt-0 mt-2 animate fadeIn text-md"
		:style="{ 'font-size': size + 'px' }"
	>
		<div class="row no-gutters b-b mb-2"></div>
		<div class="dropdown-user disabled d-flex flex-row align-items-center">
			<div
				class="d-flex flex-column align-items-center mt-1"
				:class="{ 'mr-3': user, 'w-100': !user }"
				:style="{ 'min-width': size * 4 + 'px' }"
			>
				<template v-if="user">
					<template v-if="user.is_idol">
						<i class="fas fa-2x fa-users"></i>
						<div class="mt-1">{{ $t("labels.idol") }}</div>
					</template>
					<template v-if="!user.is_idol && user.is_admin">
						<i class="fas fa-2x fa-user-shield"></i>
						<div class="mt-1">{{ $t("labels.admin") }}</div>
					</template>
					<template v-if="!user.is_idol && !user.is_admin && user.pixiv_user_id">
						<i class="fas fa-2x fa-user-check"></i>
						<div class="mt-1">{{ $t("labels.member") }}</div>
					</template>
					<template v-if="!user.is_idol && !user.is_admin && !user.pixiv_user_id">
						<i class="fas fa-2x fa-user"></i>
						<div class="mt-1">{{ $t("labels.fan") }}</div>
					</template>
				</template>
				<template v-else>
					<div class="text-center">
						<i class="fas fa-2x fa-triangle"></i>
						<div class="mt-1">{{ $t("labels.guest") }}</div>
					</div>
				</template>
			</div>
			<div class="flex" v-if="user">
				<template>
					<div v-if="user.pixiv_user_id" class="nowrap">
						<pixiv-icon size="1em" class="mr-1 fa-fw" />{{ user.pixiv_user_name }}
					</div>
					<div v-if="user.discord_user_id" class="nowrap">
						<i class="fab fa-fw fa-discord mr-1" />{{ user.discord_handle
						}}<span class="text-half-muted">#{{ user.discord_discriminator }}</span>
					</div>
					<div v-if="user.twitter_user_id" class="nowrap">
						<i class="fab fa-fw fa-twitter mr-1" />@{{ user.twitter_handle }}
					</div>
					<div v-if="user.google_user_id" class="nowrap">
						<i class="fab fa-fw fa-youtube mr-1" />{{ user.youtube_name }}
					</div>
					<!-- <div v-if="user.steam_user_id" class="nowrap">
											<i class="fab fa-fw fa-steam mr-1" />{{ user.steam_name }}
										</div> -->
				</template>
			</div>
		</div>
		<div
			v-if="!(user && user.pixiv_user_id && user.twitter_user_id && user.discord_user_id)"
			class="dropdown-divider"
		></div>
		<a v-if="user && !user.pixiv_user_id" class="dropdown-item" @click="openFanboxConnectModal" disabled>
			<div class="d-flex flex-row align-items-center">
				<div class="flex d-flex flex-column">
					<div>{{ $t("labels.connect_to_fanbox") }}</div>
					<small class="text-muted">{{ $t("messages.you_must_connect_to_fanbox_externally") }}</small>
				</div>
				<i class="ml-3 fas fa-lg fa-question text-muted" />
			</div>
		</a>
		<a
			v-if="!(user && user.discord_user_id)"
			class="dropdown-item"
			@click="openInWindow('/v1/auth/discord?provider=discord')"
		>
			<template v-if="user"><i class="fab fa-discord mr-2"/>{{ $t("labels.connect_to_discord") }}</template>
			<template v-else><i class="fab fa-discord mr-2"/>{{ $t("labels.sign_in_with_discord") }}</template>
		</a>
		<a
			v-if="!(user && user.twitter_user_id)"
			class="dropdown-item"
			@click="openInWindow('/v1/auth/twitter?provider=twitter')"
		>
			<template v-if="user"><i class="fab fa-twitter mr-2"/>{{ $t("labels.connect_to_twitter") }}</template>
			<template v-else><i class="fab fa-twitter mr-2"/>{{ $t("labels.sign_in_with_twitter") }}</template>
		</a>
		<!-- <a
			v-if="!(user && user.google_user_id)"
			class="dropdown-item text-muted"
			@click="openInWindow('/v1/auth/google?provider=google')"
		>
			<template v-if="user">{{ $t("labels.connect_to_youtube") }}</template>
			<template v-else>{{ $t("labels.sign_in_with_youtube") }}</template>
			<div class="text-muted" style="font-size: 0.8rem">( {{ $t("labels.not_yet_available") }} )</div>
		</a> -->
		<template v-if="user">
			<div class="dropdown-divider"></div>
			<a class="dropdown-item" @click="logout">{{ $t("labels.sign_out") }}</a>
		</template>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";
import Store from "@/utils/Store";
import Toast from "@/utils/Toast";

export default {
	name: "UserDropdown",

	props: {
		user: {},
		nav: {
			type: Boolean,
			default: false,
		},
		size: {
			type: Number,
			default: 16,
		},
		refreshUser: {
			type: Function,
			default: null,
		},
	},

	data() {
		return {};
	},

	created() {
		window.addEventListener("message", this.loginReturn);
	},

	destroyed() {
		window.removeEventListener("message", this.loginReturn);
	},

	computed: {},

	methods: {
		logout() {
			AuthService.logout(this);
		},

		goToSignIn() {
			Store.set(this, "returnTo", "wall");
			this.$router.push("/login");
		},

		loginReturn(message) {
			if (message && message.data && message.data.type == "loginReturn") {
				console.log("Refresh user", message.data.hash);
				if (this.refreshUser) {
					if (this.user) {
						Toast.info(this.$i18n.t("messages.you_connected_an_account"));
					} else {
						Toast.info(this.$i18n.t("messages.you_logged_in"));
					}
					this.refreshUser();
				}
			}
		},

		openInWindow(url) {
			window.open(url, "newwindow", "width=600,height=700");
		},

		openFanboxConnectModal() {
			this.$bvModal.show("fanboxConnect");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
	<main-page :user="user">
		<div class="container d-flex flex-column h-100">
			<h3>{{ $t("labels.posts") }}</h3>
			<div class="d-flex flex-row flex-wrap w-100 mb-2">
				<div class="d-flex flex-row align-items-center mr-3">
					<label class="text-white mb-0 mr-2" style="font-weight: bold" data-toggle="dropdown">
						{{ $t("labels.approved") }}:
						<span class="mx-1" :class="{ [approved.class]: true }">{{ approved.label }}</span>
						<i class="ml-1 fas fa-lg fa-caret-down" />
					</label>
					<div class="dropdown-menu dropdown-menu-left pt-0 mt-2 animate fadeIn text-md">
						<a
							v-for="o in yesNoOptions"
							:key="o.value"
							class="dropdown-item"
							:class="{ active: approved === o }"
							@click="approved = o"
							>{{ o.label }}</a
						>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mr-3">
					<label class="text-white mb-0 mr-2" style="font-weight: bold" data-toggle="dropdown">
						{{ $t("labels.rejected") }}:
						<span class="mx-1" :class="{ [banned.class]: true }">{{ banned.label }}</span>
						<i class="ml-1 fas fa-lg fa-caret-down" />
					</label>
					<div class="dropdown-menu dropdown-menu-left pt-0 mt-2 animate fadeIn text-md">
						<a
							v-for="o in yesNoOptions"
							:key="o.value"
							class="dropdown-item"
							:class="{ active: banned === o }"
							@click="banned = o"
							>{{ o.label }}</a
						>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mr-3">
					<label class="text-white mb-0 mr-2" style="font-weight: bold" data-toggle="dropdown">
						{{ $t("labels.members_only") }}:
						<span class="mx-1" :class="{ [membersOnly.class]: true }">{{ membersOnly.label }}</span>
						<i class="ml-1 fas fa-lg fa-caret-down" />
					</label>
					<div class="dropdown-menu dropdown-menu-left pt-0 mt-2 animate fadeIn text-md">
						<a
							v-for="o in yesNoOptions"
							:key="o.value"
							class="dropdown-item"
							:class="{ active: membersOnly === o }"
							@click="membersOnly = o"
							>{{ o.label }}</a
						>
					</div>
				</div>

				<form class="flex input-group my-2 mr-3" style="min-width: 100px">
					<span class="input-group-btn">
						<button
							v-if="!filter"
							type="button"
							class="btn no-border no-bg no-shadow"
							style="cursor: default; padding-left: 5px; width: 35px"
						>
							<i class="fa fa-lg fa-search"></i>
						</button>
						<button
							v-if="filter"
							type="button"
							class="btn no-border no-bg no-shadow"
							style="padding-left: 5px; width: 35px"
							@click="filter = ''"
						>
							<i class="fa fa-lg fa-times"></i>
						</button>
					</span>
					<input
						type="text"
						v-model="filter"
						class="form-control"
						style="margin-right: 5px"
						:placeholder="$t('labels.search_messages')"
					/>
				</form>
				<div class="d-flex flex-row align-items-center">
					<button
						class="mr-1 btn pagination page-link"
						@click="currentPage = 1"
						:disabled="currentPage <= 1"
						:class="{ disabled: currentPage <= 1 }"
						style="padding-left: 9.5px; padding-right: 9.25px"
					>
						<i class="fas fa-angle-double-left" />
					</button>
					<button
						class="btn pagination page-link"
						@click="currentPage = currentPage > 1 ? currentPage - 1 : currentPage"
						:disabled="currentPage <= 1"
						:class="{ disabled: currentPage <= 1 }"
					>
						<i class="fas fa-lg fa-caret-left" />
					</button>
					<span class="index-numbers d-flex flex-row justify-content-around">
						<template v-if="totalRows > 0">
							<span> </span>{{ currentPage }}<span class="text-muted">of</span>{{ totalRows }}
							<span> </span>
						</template>
						<span v-else class="text-muted">{{ $t("labels.none") }}</span>
					</span>
					<button
						class="btn pagination page-link"
						@click="currentPage = currentPage < totalRows ? currentPage + 1 : totalRows"
						:disabled="currentPage >= totalRows"
						:class="{ disabled: currentPage >= totalRows }"
					>
						<i class="fas fa-lg fa-caret-right" />
					</button>
					<button
						class="ml-1 btn pagination page-link"
						@click="currentPage = totalRows"
						:disabled="currentPage >= totalRows"
						:class="{ disabled: currentPage >= totalRows }"
						style="padding-left: 9.5px; padding-right: 9.25px"
					>
						<i class="fas fa-angle-double-right" />
					</button>
				</div>
			</div>
			<b-table
				ref="table"
				striped
				fixed
				:show-empty="true"
				:items="provider"
				:fields="fields"
				:current-page="currentPage"
				:per-page="perPage"
				:filter="filter"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:busy.sync="busy"
				class="text-left flex"
			>
				<template #cell(name)="{ item: row }">
					<template v-if="row.user">
						<template v-if="row.preferred_handle">
							<div v-if="row.preferred_handle == 'pixiv' && row.user.pixiv_user_id" class="nowrap">
								<pixiv-icon size="20" class="fa-fw mr-2" />{{ row.user.pixiv_user_name }}
							</div>
							<div v-if="row.preferred_handle == 'discord' && row.user.discord_user_id" class="nowrap">
								<i class="fab fa-fw fa-discord mr-1" />{{ row.user.discord_handle
								}}<span class="text-half-muted">#{{ row.user.discord_discriminator }}</span>
							</div>
							<div v-if="row.preferred_handle == 'twitter' && row.user.twitter_user_id" class="nowrap">
								<i class="fab fa-fw fa-twitter mr-1" />@{{ row.user.twitter_handle }}
							</div>
							<div v-if="row.preferred_handle == 'youtube' && row.user.google_user_id" class="nowrap">
								<i class="fab fa-fw fa-youtube mr-1" />{{ row.user.youtube_name }}
							</div>
						</template>
						<template v-else>
							<div v-if="row.user.pixiv_user_id" class="nowrap">
								<pixiv-icon size="20" class="fa-fw mr-2" />{{ row.user.pixiv_user_name }}
							</div>
							<div v-else-if="row.user.discord_user_id" class="nowrap">
								<i class="fab fa-fw fa-discord mr-1" />{{ row.user.discord_handle
								}}<span class="text-half-muted">#{{ row.user.discord_discriminator }}</span>
							</div>
							<div v-else-if="row.user.twitter_user_id" class="nowrap">
								<i class="fab fa-fw fa-twitter mr-1" />@{{ row.user.twitter_handle }}
							</div>
							<div v-else-if="row.user.google_user_id" class="nowrap">
								<i class="fab fa-fw fa-youtube mr-1" />{{ row.user.youtube_name }}
							</div>
							<div v-else class="nowrap">( Error )</div>
						</template>
					</template>
					<template v-else>
						<div><i class="fas fa-fw fa-user-secret mr-1" />{{ row.user_name }}</div>
						<small class="text-muted">IP: {{ row.submitter_ip || "No IP" }}</small>
					</template>
				</template>
				<template #cell(request_mtl)="{ item: row }">
					<i v-if="row.request_mtl && row.translation" class="fa fa-lg fa-check text-success" />
					<i v-if="!(row.request_mtl && row.translation)" class="fa fa-lg fa-times-circle text-very-muted" />
				</template>
				<template #cell(personal_translation)="{ item: row }">
					<i v-if="!row.request_mtl && row.translation" class="fa fa-lg fa-check text-success" />
					<i v-if="row.request_mtl || !row.translation" class="fa fa-lg fa-times-circle text-very-muted" />
				</template>
				<template #cell(members_only)="{ item: row }">
					<i v-if="row.members_only" class="fa fa-lg fa-check text-success" />
					<i v-if="!row.members_only" class="fa fa-lg fa-times-circle text-very-muted" />
				</template>
				<template #cell(banned)="{ item: row }">
					<i v-if="row.banned" class="fa fa-lg fa-check text-success" />
					<i v-if="!row.banned" class="fa fa-lg fa-times-circle text-very-muted" />
				</template>
				<template #cell(approved)="{ item: row }">
					<i v-if="row.approved" class="fa fa-lg fa-check text-success" />
					<i v-if="!row.approved" class="fa fa-lg fa-times-circle text-very-muted" />
				</template>
				<template #row-details="{ item: row }">
					<div class="d-flex flex-row">
						<div
							v-if="row.image_url"
							class="flex card mx-2 p-2 text-center d-flex flex-row justify-content-center"
						>
							<img :src="row.image_url" style="max-height: 400px" />
						</div>
						<div
							v-if="row.video_url"
							class="flex card mx-2 p-2 text-center d-flex flex-row justify-content-center"
						>
							<iframe :src="getEmbedURL(row.video_url)" style="width: 100%" frameborder="0"></iframe>
						</div>
						<div
							class="flex d-flex"
							:class="{
								'flex-row': !(row.image_url || row.video_url),
								'flex-column': row.image_url || row.video_url,
							}"
						>
							<div class="flex d-flex flex-column mx-2">
								<div class="d-flex flex-row">
									<label class="flex ml-1">{{ $t("labels.message") }}</label>
									<div class="text-success mr-1" style="font-weight: 600">{{ row.language }}</div>
								</div>
								<div v-if="row.message" class="flex card p-2 pre-line" v-html="row.message"></div>
								<div
									v-if="!row.message"
									class="flex card p-2 d-flex flex-column justify-content-center align-items-center"
								>
									<h3 class="text-muted mb-0">({{ $t("labels.none") }})</h3>
								</div>
							</div>
							<div
								class="flex d-flex flex-column mx-2"
								:class="{ 'mt-2': row.image_url || row.video_url }"
							>
								<div class="d-flex flex-row">
									<label class="flex ml-1">{{ $t("labels.translation") }}</label>
									<div v-if="row.translation" class="text-success mr-1" style="font-weight: 600">
										{{ row.translation.language }}
									</div>
								</div>
								<div
									v-if="row.translation"
									class="flex card p-2 pre-line"
									v-html="row.translation.text"
								></div>
								<div
									v-if="!row.translation"
									class="flex card p-2 d-flex flex-column justify-content-center align-items-center"
								>
									<h3 class="text-muted mb-0">({{ $t("labels.none") }})</h3>
								</div>
							</div>
						</div>
						<div class="d-flex flex-column mx-3 mt-0 mb-1" style="min-width: 130px">
							<div class="mb-1 text-muted">
								{{ DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_FULL) }}
							</div>
							<button v-if="!row.approved" class="btn btn-success mt-2" @click="approve(row)">
								{{ $t("labels.approve") }}
							</button>
							<button v-if="row.approved" class="btn btn-outline-secondary mt-2" disabled>
								{{ $t("labels.approved") }}
								<i v-if="row.approved" class="fa fa-lg fa-check text-success ml-2" />
							</button>
							<button v-if="!row.banned" class="btn btn-danger mt-2" @click="ban(row)">
								{{ $t("labels.reject") }}
							</button>
							<button v-if="row.banned" class="btn btn-warning mt-2" @click="unban(row)">
								{{ $t("labels.unreject") }}
							</button>
							<button
								v-if="row.message && row.translation"
								@click="openTLModal(row)"
								class="btn btn-primary mt-2"
							>
								{{ $t("labels.edit_translation") }}
							</button>
							<button
								v-if="row.message && !row.translation"
								@click="openTLModal(row)"
								class="btn btn-primary mt-2"
							>
								{{ $t("labels.add_translation") }}
							</button>
						</div>
					</div>
				</template>
			</b-table>
		</div>

		<b-modal
			v-if="tlPost"
			id="tlModal"
			:title="tlPost.translation ? $t('labels.edit_translation') : $t('labels.add_translation')"
			header-bg-variant="primary"
		>
			<div class="d-flex flex-row mr-1">
				<label class="flex">
					{{ $t("labels.translation") }}
					<character-count
						v-if="!(tlPost.image_url || tlPost.video_url)"
						:value="newTL"
						:limit="1120"
						style="font-size: 12px"
					/>
					<character-count
						v-if="tlPost.image_url || tlPost.video_url"
						:value="newTL"
						:limit="280"
						style="font-size: 12px"
					/>
				</label>
				<language-picker class="mr-1" :value="'JA'" noPick />
			</div>
			<textarea v-if="!(tlPost.image_url || tlPost.video_url)" rows="11" class="form-control" v-model="newTL" />
			<textarea v-if="tlPost.image_url || tlPost.video_url" rows="4" class="form-control" v-model="newTL" />
			<template #modal-footer="{ _, cancel }">
				<button class="btn btn-secondary" @click="cancel">{{ $t("labels.cancel") }}</button>
				<button v-if="!newTL" class="ml-auto btn btn-outline-success" disabled>
					{{ $t("labels.add_translation") }}
				</button>
				<button v-if="newTL" class="ml-auto btn btn-success" @click="addTranslation">
					{{ $t("labels.add_translation") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="redirectModal" header-bg-variant="secondary" hideFooter @hide="redirectingTo = ''">
			<template #modal-header>
				<div class="large-icon-modal-header">
					<div class="d-flex flex-column">
						<i class="fas fa-link fa-2x my-3"></i>
						<div>{{ $t("labels.link_redirect") }}</div>
					</div>
				</div>
			</template>
			<div class="large-details">
				<p>{{ $t("labels.you_are_being_redirected") }}</p>
				<div class="details-link-container">
					<a class="btn btn-lg btn-secondary details-link" target="_blank" :href="redirectingTo">{{ redirectingTo }}</a>
				</div>
				<p class="mt-4">{{ $t("labels.redirect_warning") }}</p>
			</div>
		</b-modal>
	</main-page>
</template>

<script>
import PostService from "@/services/PostService";
import CharacterCount from "@/components/CharacterCount";
import LanguagePicker from "@/components/LanguagePicker";
import Toast from "@/utils/Toast";
import f from "@/utils/Format";
import { DateTime } from "luxon";
import _ from "lodash";

export default {
	name: "PostList",

	props: ["user"],

	components: { CharacterCount, LanguagePicker },

	data() {
		let yesNoOptions = [
			{ label: this.$i18n.t("labels.yes"), class: "text-success", value: true },
			{ label: this.$i18n.t("labels.no"), class: "text-danger", value: false },
			{ label: this.$i18n.t("labels.both"), class: "text-muted", value: null },
		];

		return {
			f: f,
			DateTime: DateTime,
			fields: [
				{ key: "name", label: this.$i18n.t("labels.name"), sortable: true },
				{
					key: "request_mtl",
					label: this.$i18n.t("labels.deepl_translation"),
					class: "text-center",
					sortable: true,
				},
				{
					key: "personal_translation",
					label: this.$i18n.t("labels.personal_translation"),
					class: "text-center",
					sortable: true,
				},
				{
					key: "members_only",
					label: this.$i18n.t("labels.members_only"),
					class: "text-center",
					sortable: true,
				},
				{ key: "banned", label: this.$i18n.t("labels.rejected"), class: "text-center", sortable: true },
				{ key: "approved", label: this.$i18n.t("labels.approved"), class: "text-center", sortable: true },
			],

			currentPage: 1,
			perPage: 1,
			filter: "",
			sortBy: null,
			sortDesc: null,
			busy: null,
			totalRows: 0,

			yesNoOptions: yesNoOptions,

			tlPost: {},
			newTL: null,

			approved: yesNoOptions[1],
			banned: yesNoOptions[1],
			membersOnly: yesNoOptions[2],

			following: false,

			redirectingTo: "",
			showRedirectModal: false
		};
	},

	created() {
		this.refreshTable = () => {
			this.$refs.table.refresh();
		};
		this.redirect = (e) => {
			if(!e) return
			if (e.target.tagName === 'A') {
				let href = e.target.href;
				console.log("[" + href + "]")
				if(!href) return
				if(this.redirectingTo) return

				this.redirectingTo = href
				this.$bvModal.show("redirectModal")
				e.preventDefault();
			}
		}
		document.addEventListener("click", this.redirect)
	},

	destroyed() {
		document.removeEventListener("click", this.redirect)
	},

	watch: {
		approved() {
			this.refreshTable();
		},
		banned() {
			this.refreshTable();
		},
		membersOnly() {
			this.refreshTable();
		},
	},

	methods: {
		provider(ctx) {
			ctx.approved = this.approved.value;
			ctx.banned = this.banned.value;
			ctx.members_only = this.membersOnly.value;
			return PostService.listPostsMod(ctx, 1).then((r) => {
				this.totalRows = r.data.totalRows;
				if (this.currentPage > this.totalRows) {
					this.currentPage = this.totalRows;
				}
				if (this.totalRows > 0 && this.currentPage == 0) {
					this.currentPage = 1;
				}
				this.rows = r.data.rows;
				_.each(this.rows, (r) => {
					this.$set(r, "_showDetails", true);
					r.translation = this.getTL(r);
				});
				return this.rows;
			});
		},

		getTL(post) {
			if (!(post.translations && post.translations.length > 0)) {
				return null;
			}

			post.translations = _.orderBy(post.translations, "created_at", "desc");
			return post.translations[0];
		},

		getYoutubeID(url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			let match = url.match(regExp);

			return match && match[2].length === 11 ? match[2] : null;
		},

		getEmbedURL(url) {
			let youtubeID = this.getYoutubeID(url);
			if (youtubeID) {
				return `https://www.youtube.com/embed/${youtubeID}`;
			}
		},

		approve(post) {
			PostService.approvePost(post._id)
				.then(() => {
					Toast.info(this.$i18n.t("messages.post_approved"));
					this.refreshTable();
				})
				.catch((e) => {
					console.log({ error: e });
					Toast.error("Failed to approve post", e);
				});
		},

		ban(post) {
			PostService.banPost(post._id)
				.then(() => {
					Toast.info(this.$i18n.t("messages.post_rejected"));
					this.refreshTable();
				})
				.catch((e) => {
					console.log({ error: e });
					Toast.error("Failed to reject post", e);
				});
		},

		unban(post) {
			PostService.unbanPost(post._id)
				.then(() => {
					Toast.info(this.$i18n.t("messages.post_unrejected"));
					this.refreshTable();
				})
				.catch((e) => {
					console.log({ error: e });
					Toast.error("Failed to un-reject post", e);
				});
		},

		openTLModal(post) {
			this.tlPost = post;
			this.newTL = "";
			console.log("openTLModal", this.tlPost);
			if (post.translation) {
				this.newTL = post.translation.text;
			}
			this.$bvModal.show("tlModal");
		},

		addTranslation() {
			PostService.addTranslation(this.tlPost._id, {
				language: "JA",
				text: this.newTL,
				is_edit: this.tlPost.translation ? true : false,
			})
				.then(() => {
					if (this.tlPost.translation) {
						Toast.info(this.$i18n.t("messages.translation_edited"));
					} else {
						Toast.info(this.$i18n.t("messages.translation_added"));
					}
					this.refreshTable();
					this.$bvModal.hide("tlModal");
				})
				.catch((e) => {
					console.log(e);
					Toast.error("Failed to save translation", e);
				});
		},
	},
};
</script>

<style scoped>
.card {
	background-color: rgba(40, 40, 40, 0.5);
}
.container {
	padding-top: 50px;
	justify-content: start;
}
input {
	background-color: #222222;
	color: white !important;
}
input:focus {
	background-color: #222222;
	color: white !important;
}
.index-numbers {
	color: white;
	font-weight: bold;
	font-size: 0.9rem;
	min-width: 90px;
	text-align: center;
}
</style>
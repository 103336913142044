<template>
  <div style="overflow: auto" class="large-scroll-bars">
    <div
      class="canvas"
      @mousemove="heatmapHover"
      @click="heatmapClick"
      ref="container"
      :style="{ top: `${topOffset}px`, left: `${leftOffset - 2}px` }"
    >
      <img
        class="corkboard-bg"
        src="@/assets/images/birthday2023/bd2023-corkboard-bg.png"
        :style="{
          top: `-${topOffset}px`,
          left: `-${leftOffset}px`,
        }"
      />
      <template v-for="(def, i) in defs">
        <img
          v-if="!def.full_url"
          :key="i"
          :src="def.img_url"
          class="canvas-element"
          :style="{
            top: `${def.top}px`,
            left: `${def.left}px`,
            width: `${def.width}px`,
          }"
        />
        <img
          v-if="def.full_url"
          :key="i"
          :src="def.img_url"
          class="canvas-element"
          :style="{
            top: `${def.top}px`,
            left: `${def.left}px`,
            width: `${def.width}px`,
          }"
        />
      </template>
      <canvas
        width="3840"
        height="2160"
        class="heatmap"
        ref="heatmap"
        style="z-index: 5"
      ></canvas>
    </div>

    <div class="back-button-container">
      <button class="ui-button" @click="goBack">
        &lt;&nbsp;{{ $t("labels.back") }}
      </button>
    </div>

    <div
      class="lightbox-overlay"
      :class="{ show: showLightbox }"
      @click="closeModals"
    ></div>

    <div class="lightbox" :class="{ show: showLightbox }">
      <loading
        v-if="showLightbox && !showLightboxContent"
        class="lightbox-loading"
        color="rgba(255 255 255 / 100%)"
        font="PixelMPlus10"
      />
      <img
        v-if="lightboxImage"
        :src="lightboxImage"
        class="lightbox-image"
        :class="{ show: showLightboxContent }"
        @load="lightboxImageLoaded"
      />
      <div
        v-if="lightboxDetails"
        class="lightbox-details"
        :class="{ show: showLightboxContent }"
        v-html="lightboxDetails"
      ></div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Birthday/Loading";
var heatmap = require("@/assets/images/birthday2023/heatmap.png");

export default {
  name: "Canvas",

  props: ["shift", "src", "horizontal", "vertical", "user"],

  components: { Loading },

  data() {
    return {
      topOffset: 125,
      leftOffset: 147,
      defs: [
        {
          artist: "Uchi",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Uchi.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Uchi_full.webp",
          artist_url: "",
          top: 1487,
          left: 3144,
          width: 461,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtC___caEAIDAtU.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtC___caEAIDAtU_full.webp",
          artist_url: "",
          top: 1575,
          left: 2912,
          width: 306,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtP6-MwaMAEk6ai.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtP6-MwaMAEk6ai_full.webp",
          artist_url: "",
          top: 1495,
          left: 3504,
          width: 244,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fq8_swaaQAEMPcu.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fq8_swaaQAEMPcu_full.webp",
          artist_url: "",
          top: 0,
          left: 3319,
          width: 198,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyAPpbJaUAEfV-5.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyAPpbJaUAEfV-5_full.webp",
          artist_url: "",
          top: 12,
          left: 2435,
          width: 345,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FtxLp99acAAcfsC.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FtxLp99acAAcfsC_full.webp",
          artist_url: "",
          top: 0,
          left: 1815,
          width: 307,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FV1B46yaMAA_iue.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FV1B46yaMAA_iue_full.webp",
          artist_url: "",
          top: 0,
          left: 2187,
          width: 204,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-9.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-9_full.webp",
          artist_url: "",
          top: 0,
          left: 1647,
          width: 183,
        },
        {
          artist: "Earthenspire",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F2GfY4FbkAAiSOH.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F2GfY4FbkAAiSOH_full.webp",
          artist_url: "",
          top: 0,
          left: 1996,
          width: 221,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FlULsQIacAMzux-.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FlULsQIacAMzux-_full.webp",
          artist_url: "",
          top: 58,
          left: 1767,
          width: 302,
        },
        {
          artist: "Slimpaints2",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_@SlimPaints2.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_@SlimPaints2_full.webp",
          artist_url: "",
          top: 81,
          left: 2300,
          width: 177,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeNB4B8VUAEoWUF.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeNB4B8VUAEoWUF_full.webp",
          artist_url: "",
          top: 359,
          left: 2296,
          width: 243,
        },
        {
          artist: "Erindora",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Erindora.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Erindora_full.webp",
          artist_url: "",
          top: 896,
          left: 108,
          width: 489,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F2uhRA9asAQlYji.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F2uhRA9asAQlYji_full.webp",
          artist_url: "",
          top: 911,
          left: 0,
          width: 168,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FfWhj0WXkAEx9pN.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FfWhj0WXkAEx9pN_full.webp",
          artist_url: "",
          top: 143,
          left: 1972,
          width: 206,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeelzeMaEAIHFpS.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeelzeMaEAIHFpS_full.webp",
          artist_url: "",
          top: 193,
          left: 1786,
          width: 238,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_mgmgoi4-1511591943721025537-20220406_032900-img1.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_mgmgoi4-1511591943721025537-20220406_032900-img1_full.webp",
          artist_url: "",
          top: 182,
          left: 1683,
          width: 184,
        },
        {
          artist: "Samku",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Sanku.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Sanku_full.webp",
          artist_url: "",
          top: 693,
          left: 3072,
          width: 236,
        },
        {
          artist: "Shes",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Shes2671.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Shes2671_full.webp",
          artist_url: "",
          top: 459,
          left: 1809,
          width: 403,
        },
        {
          artist: "Soultron_",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Soultron_.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Soultron__full.webp",
          artist_url: "",
          top: 1480,
          left: 2032,
          width: 442,
        },
        {
          artist: "ThesisDude",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_ThesisDude.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_ThesisDude_full.webp",
          artist_url: "",
          top: 117,
          left: 652,
          width: 647,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F1OPAakaUAA1p3W.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F1OPAakaUAA1p3W_full.webp",
          artist_url: "",
          top: 1379,
          left: 416,
          width: 396,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FfZwse6VUAAbXfL.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FfZwse6VUAAbXfL_full.webp",
          artist_url: "",
          top: 1513,
          left: 652,
          width: 460,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fu_hYKQaQAEdQfV.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fu_hYKQaQAEdQfV_full.webp",
          artist_url: "",
          top: 95,
          left: 2138,
          width: 166,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FodWkUEWYAAKP8z.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FodWkUEWYAAKP8z_full.webp",
          artist_url: "",
          top: 205,
          left: 2123,
          width: 145,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F1Uo_35akAI9NFR.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F1Uo_35akAI9NFR_full.webp",
          artist_url: "",
          top: 315,
          left: 2074,
          width: 287,
        },
        {
          artist: "Lunarstar",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delutaya_Birthday_Map_Submission_W_Text.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delutaya_Birthday_Map_Submission_W_Text_full.webp",
          artist_url: "",
          top: 457,
          left: 2182,
          width: 367,
        },
        {
          artist: "Padmon",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_DeluMeme.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_DeluMeme_full.webp",
          artist_url: "",
          top: 289,
          left: 1206,
          width: 682,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGRjI_agAAFboF.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGRjI_agAAFboF_full.webp",
          artist_url: "",
          top: 302,
          left: 1820,
          width: 284,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdllcNkXgAIuVsu.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdllcNkXgAIuVsu_full.webp",
          artist_url: "",
          top: 105,
          left: 1516,
          width: 239,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGYS8_acAIGw3T.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGYS8_acAIGw3T_full.webp",
          artist_url: "",
          top: 229,
          left: 1481,
          width: 248,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F15COHsWIAAURrL.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F15COHsWIAAURrL_full.webp",
          artist_url: "",
          top: 580,
          left: 2829,
          width: 320,
        },
        {
          artist: "▽entel",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_meme.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_meme_full.webp",
          artist_url: "",
          top: 1753,
          left: 2202,
          width: 440,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeKLBZxUAAEQQXc.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeKLBZxUAAEQQXc_full.webp",
          artist_url: "",
          top: 1436,
          left: 1436,
          width: 361,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_firefox_FWCipLhPp0.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_firefox_FWCipLhPp0_full.webp",
          artist_url: "",
          top: 0,
          left: 1266,
          width: 238,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard__sPCT2Bu-F8-HD.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard__sPCT2Bu-F8-HD_full.webp",
          artist_url: "",
          top: 22,
          left: 1407,
          width: 312,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fe4Xnw_aUAAUF5V.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fe4Xnw_aUAAUF5V_full.webp",
          artist_url: "",
          top: 622,
          left: 607,
          width: 243,
        },
        {
          artist: "Naz_Zren",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_birthday_DELUTAYA.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_birthday_DELUTAYA_full.webp",
          artist_url: "",
          top: 640,
          left: 1025,
          width: 334,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Ftqd5KVWwAEhyt5.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Ftqd5KVWwAEhyt5_full.webp",
          artist_url: "",
          top: 1309,
          left: 1762,
          width: 334,
        },
        {
          artist: "Bok",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_SPOILER_IMG_1113.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_SPOILER_IMG_1113_full.webp",
          artist_url: "",
          top: 901,
          left: 408,
          width: 424,
        },
        {
          artist: "ckw0326",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Ckw_latte-art.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Ckw_latte-art_full.webp",
          artist_url: "",
          top: 1017,
          left: 731,
          width: 464,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F197nyMWYAA4b0w.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F197nyMWYAA4b0w_full.webp",
          artist_url: "",
          top: 876,
          left: 813,
          width: 245,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGRp3naEAMXF6b.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGRp3naEAMXF6b_full.webp",
          artist_url: "",
          top: 735,
          left: 801,
          width: 268,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Flop7LdWIAIK3Pz.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Flop7LdWIAIK3Pz_full.webp",
          artist_url: "",
          top: 1661,
          left: 1038,
          width: 427,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FkG6Nb6aAAELf1w.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FkG6Nb6aAAELf1w_full.webp",
          artist_url: "",
          top: 1797,
          left: 1406,
          width: 282,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Signature.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Signature_full.webp",
          artist_url: "",
          top: 1792,
          left: 3536,
          width: 304,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F0SB-dYWcAAOgbI.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F0SB-dYWcAAOgbI_full.webp",
          artist_url: "",
          top: 1792,
          left: 3540,
          width: 300,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtHVHDEaIAkrvdA.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtHVHDEaIAkrvdA_full.webp",
          artist_url: "",
          top: 1643,
          left: 3554,
          width: 286,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvhY1X6XsAAp39Z.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvhY1X6XsAAp39Z_full.webp",
          artist_url: "",
          top: 1584,
          left: 184,
          width: 455,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_image3.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_image3_full.webp",
          artist_url: "",
          top: 1427,
          left: 2122,
          width: 133,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Group-1.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Group-1_full.webp",
          artist_url: "",
          top: 638,
          left: 1240,
          width: 1343,
        },
        {
          artist: "Aya",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Aya.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Aya_full.webp",
          artist_url: "",
          top: 1227,
          left: 1110,
          width: 503,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu_cake_full.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu_cake_full.webp",
          artist_url: "",
          top: 1108,
          left: 2454,
          width: 373,
        },
        {
          artist: "YINA",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_gym_taya.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_gym_taya_full.webp",
          artist_url: "",
          top: 1881,
          left: 2590,
          width: 354,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_received_546029733993190.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_received_546029733993190_full.webp",
          artist_url: "",
          top: 1083,
          left: 0,
          width: 245,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_I-love-you-in-every-universe.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_I-love-you-in-every-universe_full.webp",
          artist_url: "",
          top: 0,
          left: 3645,
          width: 195,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdPjII0agAUH134.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdPjII0agAUH134_full.webp",
          artist_url: "",
          top: 1644,
          left: 1956,
          width: 131,
        },
        {
          artist: "Mitwun",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Z0uUPTDS.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Z0uUPTDS_full.webp",
          artist_url: "",
          top: 1290,
          left: 1581,
          width: 218,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FoXw73HX0AUZ2XI.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FoXw73HX0AUZ2XI_full.webp",
          artist_url: "",
          top: 1517,
          left: 2380,
          width: 258,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvhM0fwXsAAo_MY.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvhM0fwXsAAo_MY_full.webp",
          artist_url: "",
          top: 1598,
          left: 2441,
          width: 301,
        },
        {
          artist: "M0xXi3_W",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_@M0xXi3_W.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_@M0xXi3_W_full.webp",
          artist_url: "",
          top: 1324,
          left: 339,
          width: 213,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGbhZraIAEhY2m.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdGbhZraIAEhY2m_full.webp",
          artist_url: "",
          top: 1362,
          left: 2405,
          width: 380,
        },
        {
          artist: "YINA",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Yina.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Yina_full.webp",
          artist_url: "",
          top: 1094,
          left: 1124,
          width: 187,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FfOmrwvaEAI4E-I.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FfOmrwvaEAI4E-I_full.webp",
          artist_url: "",
          top: 225,
          left: 3332,
          width: 275,
        },
        {
          artist: "daniverse456",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu_the_frog.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu_the_frog_full.webp",
          artist_url: "",
          top: 299,
          left: 3526,
          width: 314,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fe7UGuqWYAAvaxO.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fe7UGuqWYAAvaxO_full.webp",
          artist_url: "",
          top: 1062,
          left: 2746,
          width: 391,
        },
        {
          artist: "RaRa",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu2023_q.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu2023_q_full.webp",
          artist_url: "",
          top: 147,
          left: 2466,
          width: 402,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FcA1I8waQAAiK_-.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FcA1I8waQAAiK_-_full.webp",
          artist_url: "",
          top: 772,
          left: 584,
          width: 282,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdqZgxJagAU6x3c.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdqZgxJagAU6x3c_full.webp",
          artist_url: "",
          top: 586,
          left: 1135,
          width: 120,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F0p0gQ4acAAgssK.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_F0p0gQ4acAAgssK_full.webp",
          artist_url: "",
          top: 0,
          left: 3226,
          width: 164,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FqRYrxXagAAJWv5.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FqRYrxXagAAJWv5_full.webp",
          artist_url: "",
          top: 117,
          left: 2435,
          width: 133,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-12.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-12_full.webp",
          artist_url: "",
          top: 55,
          left: 3216,
          width: 291,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_QoO6P8CEMk1nvdn8ggAAAAASUVORK5CYII.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_QoO6P8CEMk1nvdn8ggAAAAASUVORK5CYII_full.webp",
          artist_url: "",
          top: 14,
          left: 3033,
          width: 241,
        },
        {
          artist: "Kukie",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delutaya-Bday.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delutaya-Bday_full.webp",
          artist_url: "",
          top: 591,
          left: 2503,
          width: 461,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-7.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-7_full.webp",
          artist_url: "",
          top: 223,
          left: 3002,
          width: 220,
        },
        {
          artist: "Shiro🔺💚",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_ae86.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_ae86_full.webp",
          artist_url: "",
          top: 320,
          left: 2828,
          width: 400,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_image2.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_image2_full.webp",
          artist_url: "",
          top: 0,
          left: 2903,
          width: 173,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FyhRdKYWwAYjG2D.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FyhRdKYWwAYjG2D_full.webp",
          artist_url: "",
          top: 1746,
          left: 2601,
          width: 332,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FwtGk2kWwAEwOzI.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FwtGk2kWwAEwOzI_full.webp",
          artist_url: "",
          top: 1664,
          left: 2873,
          width: 373,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FwaiqEWaYAEQCJk.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FwaiqEWaYAEQCJk_full.webp",
          artist_url: "",
          top: 1564,
          left: 2655,
          width: 367,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtBEATqaIAA7a9m.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtBEATqaIAA7a9m_full.webp",
          artist_url: "",
          top: 1517,
          left: 2594,
          width: 182,
        },
        {
          artist: "カレイ２６",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_delu_bd.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_delu_bd_full.webp",
          artist_url: "",
          top: 1237,
          left: 2722,
          width: 597,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FzkA8DiaIAA7yh6.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FzkA8DiaIAA7yh6_full.webp",
          artist_url: "",
          top: 369,
          left: 739,
          width: 243,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FzTZJf2aQAE71gb.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FzTZJf2aQAE71gb_full.webp",
          artist_url: "",
          top: 1776,
          left: 464,
          width: 547,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-15.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-15_full.webp",
          artist_url: "",
          top: 442,
          left: 633,
          width: 217,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FoIZf5aaIAYKv0r.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FoIZf5aaIAYKv0r_full.webp",
          artist_url: "",
          top: 0,
          left: 1004,
          width: 283,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvCtM4lWAAMQjhT.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvCtM4lWAAMQjhT_full.webp",
          artist_url: "",
          top: 0,
          left: 817,
          width: 245,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_DnlvvN5U4AAVJTR.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_DnlvvN5U4AAVJTR_full.webp",
          artist_url: "",
          top: 540,
          left: 0,
          width: 275,
        },
        {
          artist: "Swee🔺💚",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu_Bday_2023.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delu_Bday_2023_full.webp",
          artist_url: "",
          top: 432,
          left: 210,
          width: 480,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FkwJ3c_aAAE55Td.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FkwJ3c_aAAE55Td_full.webp",
          artist_url: "",
          top: 128,
          left: 2756,
          width: 343,
        },
        {
          artist: "Noroiko",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delustar.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Delustar_full.webp",
          artist_url: "",
          top: 273,
          left: 2738,
          width: 160,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeCVBi1agAAjwiU.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FeCVBi1agAAjwiU_full.webp",
          artist_url: "",
          top: 506,
          left: 1145,
          width: 110,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FendnK0XgAAQl3T.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FendnK0XgAAQl3T_full.webp",
          artist_url: "",
          top: 942,
          left: 2475,
          width: 192,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fez8cT2WYAYxjQt.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fez8cT2WYAYxjQt_full.webp",
          artist_url: "",
          top: 974,
          left: 2628,
          width: 151,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgBwoxhWQAMWPgW.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgBwoxhWQAMWPgW_full.webp",
          artist_url: "",
          top: 407,
          left: 3336,
          width: 268,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__Fu7Fa4-akAABdxC.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__Fu7Fa4-akAABdxC_full.webp",
          artist_url: "",
          top: 1473,
          left: 1094,
          width: 410,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgS-pv-UYAU2zej.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgS-pv-UYAU2zej_full.webp",
          artist_url: "",
          top: 172,
          left: 1274,
          width: 283,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgTD_mSacAADBSI.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgTD_mSacAADBSI_full.webp",
          artist_url: "",
          top: 960,
          left: 2736,
          width: 213,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fx7Q2cWaUAAP_uF.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fx7Q2cWaUAAP_uF_full.webp",
          artist_url: "",
          top: 224,
          left: 1661,
          width: 99,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Koala.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Koala_full.webp",
          artist_url: "",
          top: 127,
          left: 1678,
          width: 116,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FupWRg5XoAIg-Fk.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FupWRg5XoAIg-Fk_full.webp",
          artist_url: "",
          top: 1584,
          left: 1405,
          width: 407,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FmmNJP6XoAELDN6.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FmmNJP6XoAELDN6_full.webp",
          artist_url: "",
          top: 1482,
          left: 1764,
          width: 377,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgS-XcAUUAI-etv.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgS-XcAUUAI-etv_full.webp",
          artist_url: "",
          top: 1751,
          left: 1857,
          width: 381,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_1.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_1_full.webp",
          artist_url: "",
          top: 1625,
          left: 1701,
          width: 297,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvSKrOOaUAAXsuI.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvSKrOOaUAAXsuI_full.webp",
          artist_url: "",
          top: 1918,
          left: 1860,
          width: 400,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FhnEEACXkAEYzVs.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FhnEEACXkAEYzVs_full.webp",
          artist_url: "",
          top: 1867,
          left: 1615,
          width: 292,
        },
        {
          artist: "Kukie",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Kukie-1.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Kukie-1_full.webp",
          artist_url: "",
          top: 1416,
          left: 2345,
          width: 133,
        },
        {
          artist: "Kukie",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Kukie-2.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Kukie-2_full.webp",
          artist_url: "",
          top: 424,
          left: 1821,
          width: 114,
        },
        {
          artist: "twindark (アキト)",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Twindark.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Twindark_full.webp",
          artist_url: "",
          top: 886,
          left: 746,
          width: 116,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-14.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-14_full.webp",
          artist_url: "",
          top: 977,
          left: 2892,
          width: 245,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fip2ynDXoAE1cps.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fip2ynDXoAE1cps_full.webp",
          artist_url: "",
          top: 1149,
          left: 3083,
          width: 177,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FiUr40AWAAARI1F.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FiUr40AWAAARI1F_full.webp",
          artist_url: "",
          top: 758,
          left: 2900,
          width: 184,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyhQDwFacAERTgB.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyhQDwFacAERTgB_full.webp",
          artist_url: "",
          top: 902,
          left: 2916,
          width: 171,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyIXVEPaEAAv_PV.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyIXVEPaEAAv_PV_full.webp",
          artist_url: "",
          top: 1365,
          left: 3640,
          width: 200,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_E6Ha_whVIAsVo9z.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_E6Ha_whVIAsVo9z_full.webp",
          artist_url: "",
          top: 1345,
          left: 3383,
          width: 293,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Jinx.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Jinx_full.webp",
          artist_url: "",
          top: 161,
          left: 1501,
          width: 109,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown5.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown5_full.webp",
          artist_url: "",
          top: 1505,
          left: 3651,
          width: 189,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Supa.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Supa_full.webp",
          artist_url: "",
          top: 615,
          left: 1300,
          width: 256,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(2).webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(2)_full.webp",
          artist_url: "",
          top: 546,
          left: 3289,
          width: 302,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FwIbpT9aEAEjY-3.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FwIbpT9aEAEjY-3_full.webp",
          artist_url: "",
          top: 625,
          left: 3547,
          width: 293,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FuTbGkQaYAct_NM.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FuTbGkQaYAct_NM_full.webp",
          artist_url: "",
          top: 1880,
          left: 2848,
          width: 326,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FmWuQ3kakAMXL9k.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FmWuQ3kakAMXL9k_full.webp",
          artist_url: "",
          top: 553,
          left: 3133,
          width: 209,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FoRJ_ZLWYAAsfeC.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FoRJ_ZLWYAAsfeC_full.webp",
          artist_url: "",
          top: 277,
          left: 146,
          width: 360,
        },
        {
          artist: "daniverse456",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_daniverse456.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_daniverse456_full.webp",
          artist_url: "",
          top: 564,
          left: 2475,
          width: 118,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FmCcA7dagAAE1oI.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FmCcA7dagAAE1oI_full.webp",
          artist_url: "",
          top: 1801,
          left: 0,
          width: 540,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvdIL95XsAQnq80.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FvdIL95XsAQnq80_full.webp",
          artist_url: "",
          top: 214,
          left: 2218,
          width: 124,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-8.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-8_full.webp",
          artist_url: "",
          top: 436,
          left: 3194,
          width: 243,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown-3.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown-3_full.webp",
          artist_url: "",
          top: 241,
          left: 3174,
          width: 235,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown_full.webp",
          artist_url: "",
          top: 60,
          left: 1211,
          width: 244,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unnamed.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unnamed_full.webp",
          artist_url: "",
          top: 1161,
          left: 3280,
          width: 325,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FyRfprFWcAIHIbc.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FyRfprFWcAIHIbc_full.webp",
          artist_url: "",
          top: 1283,
          left: 3242,
          width: 170,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FlIk1GIXkAIU_ry.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FlIk1GIXkAIU_ry_full.webp",
          artist_url: "",
          top: 1175,
          left: 3205,
          width: 147,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(1).webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(1)_full.webp",
          artist_url: "",
          top: 700,
          left: 3288,
          width: 303,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault_full.webp",
          artist_url: "",
          top: 857,
          left: 3284,
          width: 318,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_9VymM87w8Xg-HD.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_9VymM87w8Xg-HD_full.webp",
          artist_url: "",
          top: 1013,
          left: 3250,
          width: 303,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_yHDfABECKzc-HD.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_yHDfABECKzc-HD_full.webp",
          artist_url: "",
          top: 922,
          left: 3509,
          width: 304,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(3).webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(3)_full.webp",
          artist_url: "",
          top: 1083,
          left: 3512,
          width: 299,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(4).webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_maxresdefault-(4)_full.webp",
          artist_url: "",
          top: 773,
          left: 3528,
          width: 303,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgpQmoUUUAAqlP4.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgpQmoUUUAAqlP4_full.webp",
          artist_url: "",
          top: 1517,
          left: 3108,
          width: 220,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-13.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-13_full.webp",
          artist_url: "",
          top: 1221,
          left: 3550,
          width: 268,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-16.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Layer-16_full.webp",
          artist_url: "",
          top: 0,
          left: 1787,
          width: 171,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FvLPSPwaUAUxOsZ.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FvLPSPwaUAUxOsZ_full.webp",
          artist_url: "",
          top: 55,
          left: 2655,
          width: 147,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__Fu__35CaUAEN_pI.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__Fu__35CaUAEN_pI_full.webp",
          artist_url: "",
          top: 0,
          left: 2762,
          width: 198,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FwpW9pmakAAz2dA.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FwpW9pmakAAz2dA_full.webp",
          artist_url: "",
          top: 0,
          left: 2333,
          width: 180,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyIXVETagAArlfn.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_AnyConv.com__FyIXVETagAArlfn_full.webp",
          artist_url: "",
          top: 706,
          left: 0,
          width: 275,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_E743rnTVUAArvpF.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_E743rnTVUAArvpF_full.webp",
          artist_url: "",
          top: 0,
          left: 0,
          width: 211,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FzL2oNTagAABaLn.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FzL2oNTagAABaLn_full.webp",
          artist_url: "",
          top: 492,
          left: 2114,
          width: 143,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fe103CQUAAAvVlt.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fe103CQUAAAvVlt_full.webp",
          artist_url: "",
          top: 0,
          left: 131,
          width: 515,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdF_7BDaAAQDiXC.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FdF_7BDaAAQDiXC_full.webp",
          artist_url: "",
          top: 1904,
          left: 1074,
          width: 416,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgiZ1KtVIAE4cqg.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgiZ1KtVIAE4cqg_full.webp",
          artist_url: "",
          top: 0,
          left: 522,
          width: 335,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgM6R4jUoAABjne.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FgM6R4jUoAABjne_full.webp",
          artist_url: "",
          top: 248,
          left: 0,
          width: 189,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FkGxmXAVUAAyQLk.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FkGxmXAVUAAyQLk_full.webp",
          artist_url: "",
          top: 0,
          left: 3426,
          width: 284,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FMStsqbaUAgDNEM.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FMStsqbaUAgDNEM_full.webp",
          artist_url: "",
          top: 172,
          left: 139,
          width: 172,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FnQxqa4aAAAM78n.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FnQxqa4aAAAM78n_full.webp",
          artist_url: "",
          top: 203,
          left: 359,
          width: 367,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fptm5xhaAAA7hDa.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fptm5xhaAAA7hDa_full.webp",
          artist_url: "",
          top: 484,
          left: 3569,
          width: 271,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtwqVoGaUAEP4UV.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FtwqVoGaUAEP4UV_full.webp",
          artist_url: "",
          top: 0,
          left: 2663,
          width: 142,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FyTH2V3aMAEbp4T.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FyTH2V3aMAEbp4T_full.webp",
          artist_url: "",
          top: 14,
          left: 2468,
          width: 101,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fz_yJNhaIAAq3ep.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Fz_yJNhaIAAq3ep_full.webp",
          artist_url: "",
          top: 0,
          left: 2592,
          width: 119,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FznmtV4WwAEEjBY.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_FznmtV4WwAEEjBY_full.webp",
          artist_url: "",
          top: 0,
          left: 2500,
          width: 132,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_DELU-BIRTHDAY-PSD-(1).webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_DELU-BIRTHDAY-PSD-(1)_full.webp",
          artist_url: "",
          top: 427,
          left: 1,
          width: 193,
        },
        {
          artist: "Hyde",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Zebraneko.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Zebraneko_full.webp",
          artist_url: "",
          top: 1823,
          left: 853,
          width: 367,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown6.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown6_full.webp",
          artist_url: "",
          top: 0,
          left: 1477,
          width: 196,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown3.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_unknown3_full.webp",
          artist_url: "",
          top: 1551,
          left: 0,
          width: 271,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_R18.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_R18_full.webp",
          artist_url: "",
          top: 2070,
          left: 2892,
          width: 392,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Frogtaya.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Frogtaya_full.webp",
          artist_url: "",
          top: 1394,
          left: 2033,
          width: 130,
        },
        {
          artist: "",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Skarmbild_2022-09-21_154136.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Skarmbild_2022-09-21_154136_full.webp",
          artist_url: "",
          top: 405,
          left: 473,
          width: 184,
        },
        {
          artist: "Guchico",
          img_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Guchico.webp",
          full_url:
            "https://files.delutaya.club/file/delucanvas/Pinboard_Guchico_full.webp",
          artist_url: "",
          top: 820,
          left: 2427,
          width: 169,
        },
      ],
      elements: [],
      mouseoverLayer: null,
      prevMouseoverLayer: null,
      scaleFactor: 1,

      showLightbox: false,
      lightboxImage: null,
      lightboxDetails: null,
      showLightboxContent: false,
    };
  },

  created() {
    window.addEventListener("resize", this.doScale);
    this.doScale();
  },

  destroyed() {
    window.removeEventListener("resize", this.doScale);
  },

  mounted() {
    this.loadHeatmap();
    this.mapElements();
  },

  methods: {
    loadHeatmap() {
      var ctx = this.$refs.heatmap.getContext("2d");
      var img = new Image();
      img.onload = function () {
        console.log("do load", ctx, img);
        ctx.drawImage(img, 0, 0);
      };
      img.src = heatmap;
      console.log("set src to", heatmap);
    },

    mapElements() {
      this.elements = this.$refs.container.querySelectorAll(".canvas-element");
    },

    heatmapHover(e) {
      let pixel = this.$refs.heatmap
        .getContext("2d")
        .getImageData(
          e.offsetX - this.leftOffset,
          e.offsetY - this.topOffset,
          1,
          1
        ).data;
      if (pixel[3] == 0) {
        this.mouseoverLayer = null;
      } else {
        let layerNum = this.colorToLayer(pixel);
        this.mouseoverLayer = layerNum;
      }
      if (this.mouseoverLayer != this.prevMouseoverLayer) {
        this.changeHovers(this.prevMouseoverLayer, this.mouseoverLayer);
        this.prevMouseoverLayer = this.mouseoverLayer;
      }
    },

    changeHovers(outEl, inEl) {
      console.log("Change hover layer", outEl, inEl);
      if (outEl != null) {
        this.elements[outEl].classList.remove("hover");
      }
      if (inEl != null) {
        this.elements[inEl].classList.add("hover");
        this.$refs.container.classList.add("hovering");
      } else {
        this.$refs.container.classList.remove("hovering");
      }
    },

    heatmapClick(e) {
      let pixel = this.$refs.heatmap
        .getContext("2d")
        .getImageData(
          e.offsetX - this.leftOffset,
          e.offsetY - this.topOffset,
          1,
          1
        ).data;
      if (pixel[3] == 0) {
        this.mouseoverLayer = null;
      } else {
        let layerNum = this.colorToLayer(pixel);
        this.expandLayer(layerNum);
      }
    },

    expandLayer(index) {
      let def = this.defs[index];
      console.log("Layer def", def);
      if (def.full_url) {
        let details = "";
        if (def.artist) {
          details = `<div>
    <div>Artist: ${def.artist}</div>
    <a href="${def.artist_url}">${def.artist_url}</a>
</div>`;
        }
        this.loadLightboxImage(def.full_url, details);
      }
    },

    colorToLayer(pixel) {
      let r = pixel[0];
      r = (r + 1) / 32 - 1;
      let g = pixel[1];
      g = (g + 1) / 32 - 1;
      let b = pixel[2];
      b = (b + 1) / 32 - 1;
      return r * 64 + g * 8 + b;
    },

    doScale() {
      let vw = window.innerWidth - 10;
      if (vw < 1410) {
        this.scaleFactor = vw / 1410;
      } else {
        this.scaleFactor = 1;
      }
    },

    loadLightboxImage(url, details) {
      this.lightboxImage = url;
      this.lightboxDetails = details;
      this.showLightboxContent = false;
      this.$nextTick(() => {
        this.showLightbox = true;
      });
    },

    lightboxImageLoaded(e) {
      console.log("LOADED IMAGE", e);
      setTimeout(() => {
        this.showLightboxContent = true;
      }, 100);
    },

    closeModals() {
      this.showLightbox = false;
      this.lightboxImage = null;
      this.lightboxDetails = null;
      this.showLightboxContent = false;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "PixelMPlus10";
  src: url("/fonts/PixelMplus10-Regular.ttf");
}

.canvas {
  position: absolute;
  top: 0px;
  transform-origin: left top;
}
.canvas-element {
  position: absolute;
  pointer-events: none;
  transition: filter 0.25s ease-in-out, transform 0.5s ease-in-out,
    scale 0.25s ease-in-out;
}
.heatmap {
  width: 3840px;
  height: 2160px;
  /* display: none; */
}
.canvas.hovering {
  cursor: pointer;
}
.canvas.hovering:hover .canvas-element:not(.hover) {
  /* filter: brightness(50%); */
}
.canvas-element.hover {
  filter: drop-shadow(1px 1px 6px rgba(255, 0, 255, 1))
    drop-shadow(-1px -1px 6px rgba(0, 255, 0, 1));
  scale: 1.03;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.25s;
  backdrop-filter: blur(6px);
  pointer-events: none;
  z-index: 30;
}

.lightbox-overlay.show {
  opacity: 1;
  pointer-events: all;
  cursor: zoom-out;
}

.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 31;
  pointer-events: none;
  padding: 60px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lightbox.show {
  display: flex;
  pointer-events: none;
}

.lightbox-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.lightbox-image {
  opacity: 0;
  transition: opacity 0.45s;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  filter: drop-shadow(0px 6px 15px rgba(0 0 0 / 50%));
}
.lightbox-image.show {
  opacity: 1;
  pointer-events: all;
}

.lightbox-details {
  opacity: 0;
  transition: opacity 0.45s;
  border: 4px solid rgba(255 255 255 / 75%);
  color: rgba(255 255 255 / 75%);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
  filter: drop-shadow(0px 0px 2px rgba(255 255 255 / 62%))
    drop-shadow(0px 0px 4px rgba(255 255 255 / 37%));
  padding: 10px 16px;
  font-family: PixelMPlus10;
  font-size: 20px;
  margin-top: 20px;
}
.lightbox-details.show {
  opacity: 1;
  pointer-events: all;
}

.back-button-container {
  position: fixed;
  top: 30px;
  right: 30px;
}

.ui-button {
  padding: 0rem 1rem;
  font-family: PixelMPlus10;
  font-size: 24px;
  border: 2px solid rgba(255 255 255 / 75%);
  color: rgba(255 255 255 / 75%);
  background-color: transparent;
  transition: all 0.15s;
  filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
    drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
  text-shadow: 0px 0px 1px rgba(255 255 255 / 75%),
    0px 0px 2px rgba(255 255 255 / 50%);
  backdrop-filter: brightness(0.65);
}
.ui-button:hover {
  filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 50%))
    drop-shadow(0px 0px 6px rgba(255 255 255 / 20%));
  border: 2px solid rgba(255 255 255 / 40%);
}

.corkboard-bg {
  position: absolute;
  height: 2412px;
  width: 4129px;
}
</style>
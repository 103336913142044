<template>
	<birthday-page fillBackground hideHeader class="purple-scrollbar">
		<loading class="loading-center" :class="{ show: loading }" color="rgba(0, 0, 0, 0.7)" />
		<div
			class="fullscreen"
			:class="{ show: !loading }"
			:style="{
				left: hOffset ? `${-1 * hOffset + normalizedOffsetX}px` : '-35px',
				// left: hOffset ? `${orienting ? -2 * (horizontalShift) * hOffset - 35 : -1 * hOffset}px` : '-35px',
				top: vOffset ? `${-1 * vOffset}px` : '-17px',
			}"
		>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="0"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Background"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-70"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Back_balloon"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-60"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Long_Ribbon"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-50"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Balloon"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-40"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Flare"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-30"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Ruki"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-20"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Rose"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-10"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Black_Cat"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="-5"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Delu_Mama"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="15"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Delutaya"
				@loaded="loadedImg"
			/>
			<!-- <parallax-layer class="splash-image" :style="{width: splashWidth ? `${splashWidth}px` : '', height: splashHeight ? `${splashHeight}px` : ''}" :shift="30" :horizontal="horizontalShift" :vertical="verticalShift" :src="Dodge" @loaded="loadedImg" /> -->
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="25"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Small_Ribbon"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="45"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Cake"
				@loaded="loadedImg"
			/>
			<parallax-layer
				class="splash-image"
				:style="{
					width: splashWidth ? `${splashWidth}px` : '',
					height: splashHeight ? `${splashHeight}px` : '',
				}"
				:shift="60"
				:horizontal="horizontalShift"
				:vertical="verticalShift"
				:src="Flower"
				@loaded="loadedImg"
			/>
			<img class="signature" :src="Signature" @loaded="loadedImg" />
		</div>
		<!-- <div style="position: absolute; top: 0; right: 100px; text-align: left;">
			<div>iBeta: {{iBeta}}</div>
			<div>iGamma: {{iGamma}}</div>
			<div>cBeta: {{cBeta}}</div>
			<div>cGamma: {{cGamma}}</div>
			<div>horizontalShift: {{horizontalShift}}</div>
			<div>verticalShift: {{verticalShift}}</div>
		</div> -->
		<div :class="{ show: !loading }" class="click-reminder">
			<div v-if="!orienting && !reminderGone" :class="{ 'fade-out': !showReminder }">
				{{ $t("labels.click_to_enter") }}
			</div>
			<div v-if="orienting && !reminderGone" :class="{ 'fade-out': !showReminder }">
				{{ $t("labels.touch_to_enter") }}
			</div>
			<div v-if="orienting && !reminderGone" class="mt-5" :class="{ 'fade-out': !showReminder }">
				{{ $t("labels.drag_to_see_more") }}
			</div>
		</div>
		<div
			v-if="!loading"
			ref="overlay"
			class="click-overlay clickable"
			@click="goToWall"
			@mousemove="calcShift"
		></div>
	</birthday-page>
</template>

<script>
import Theme from "@/utils/Theme";
import ParallaxLayer from "@/components/Birthday/ParallaxLayer";
import Loading from "@/components/Birthday/Loading";

export default {
	name: "Splash",

	props: [],

	components: { Loading, ParallaxLayer },

	data() {
		return {
			theme: null,

			horizontalShift: 0.5,
			verticalShift: 0.5,
			viewportWidth: null,
			viewportHeight: null,
			splashHeight: null,
			splashWidth: null,
			hOffset: null,
			vOffset: null,
			orienting: false,

			iBeta: null,
			iGamma: null,
			cBeta: null,
			cGamma: null,

			loading: true,
			loadedCount: 0,

			showReminder: true,
			reminderGone: false,

			offsetX: 0,
			touchStartX: 0,
			touchOffsetX: 0,

			Background: "https://files.delutaya.club/file/deluparty/Background.png",
			Back_balloon: "https://files.delutaya.club/file/deluparty/Back_balloon.png",
			Long_Ribbon: "https://files.delutaya.club/file/deluparty/Long_Ribbon.png",
			Balloon: "https://files.delutaya.club/file/deluparty/Balloon.png",
			Flare: "https://files.delutaya.club/file/deluparty/Flare.png",
			Ruki: "https://files.delutaya.club/file/deluparty/Ruki.png",
			Rose: "https://files.delutaya.club/file/deluparty/Rose.png",
			Black_Cat: "https://files.delutaya.club/file/deluparty/Black_Cat.png",
			Delu_Mama: "https://files.delutaya.club/file/deluparty/Delu_Mama.png",
			Delutaya: "https://files.delutaya.club/file/deluparty/Delutaya.png",
			Dodge: "https://files.delutaya.club/file/deluparty/Dodge.png",
			Cake: "https://files.delutaya.club/file/deluparty/Cake.png",
			Flower: "https://files.delutaya.club/file/deluparty/Flower.png",
			Small_Ribbon: "https://files.delutaya.club/file/deluparty/Small_Ribbon.png",
			Signature: "https://files.delutaya.club/file/deluparty/Signature.png",
		};
	},

	created() {
		this.theme = Theme.getCurrent();
		console.log("THEME", this.theme);
		console.log("splash", this.theme.splash);

		window.addEventListener("resize", this.calcViewport);
		window.addEventListener("deviceorientation", this.calcShiftOrientation, true);
		window.addEventListener("touchstart", this.touchStart);
		window.addEventListener("touchmove", this.touchMove);
	},

	destroyed() {
		window.removeEventListener("resize", this.calcViewport);
		window.removeEventListener("deviceorientation", this.calcShiftOrientation, true);
		window.removeEventListener("touchstart", this.touchStart);
		window.removeEventListener("touchmove", this.touchMove);
	},

	computed: {
		normalizedOffsetX() {
			let n = this.offsetX + this.touchOffsetX;
			return n;
		},
	},

	mounted() {
		this.calcViewport();
	},

	methods: {
		goToWall() {
			console.log("CLICKED");
			this.$router.push("/birthday2021");
		},

		calcShift(event) {
			let el = this.$refs.overlay;
			this.horizontalShift = event.clientX / el.offsetWidth;
			this.verticalShift = event.clientY / el.offsetHeight;
		},

		calcShiftOrientation(event) {
			if (this.iBeta == null) {
				this.iBeta = event.beta;
			}
			if (this.iGamma == null) {
				this.iGamma = event.gamma;
			}

			this.cGamma = event.gamma;
			this.cBeta = event.beta;

			console.log("orientation", event);
			this.horizontalShift = (event.gamma - this.iGamma) / 40 + 0.5;
			if (this.horizontalShift < 0) this.horizontalShift = 0;
			if (this.horizontalShift > 1) this.horizontalShift = 1;
			this.verticalShift = (event.beta - this.iBeta) / 40 + 0.5;
			if (this.verticalShift < 0) this.verticalShift = 0;
			if (this.verticalShift > 1) this.verticalShift = 1;
			if (event.gamma != null) {
				this.orienting = true;
			}
		},

		calcViewport() {
			this.viewportWidth = window.innerWidth;
			this.viewportHeight = window.innerHeight;
			console.log("window resize", this.viewportWidth, this.viewportHeight);

			const srcAspectRatio = 2.1;
			if (this.viewportWidth > this.viewportHeight * srcAspectRatio) {
				this.splashWidth = this.viewportWidth + 70;
				this.splashHeight = null;
				this.hOffset = 0;
				this.vOffset = (this.splashWidth / 2.1 - this.viewportHeight) / 2;
			} else {
				this.splashWidth = null;
				this.splashHeight = this.viewportHeight + 34;
				this.hOffset = (this.splashHeight * 2.1 - this.viewportWidth) * 0.4;
				this.vOffset = 0;
			}
		},

		loadedImg() {
			this.loadedCount++;
			console.log("loaded count", this.loadedCount);
			if (this.loadedCount >= 13) {
				this.loading = false;
				setTimeout(() => {
					this.showReminder = false;
				}, 2000);
				setTimeout(() => {
					this.reminderGone = true;
				}, 4000);
			}
		},

		touchStart(e) {
			if (e.touches.length != 1) return;
			console.log("touch start", e);
			this.offsetX = this.offsetX + this.touchOffsetX;
			this.touchOffsetX = 0;
			this.touchStartX = e.touches[0].clientX;
		},

		touchMove(e) {
			if (e.touches.length != 1) return;
			console.log("touch move", e);
			this.touchOffsetX = e.touches[0].clientX - this.touchStartX;
		},
	},
};
</script>

<style scoped>
.fullscreen {
	position: absolute;
	bottom: 0;
	right: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity 1s ease-in-out;
}
.fullscreen.show {
	opacity: 1;
}
.click-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.signature {
	position: absolute;
	right: 0;
	bottom: 0;
}
.loading-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.01;
	transition: opacity 0.5s ease-in-out;
}
.loading-center.show {
	opacity: 1;
}

.click-reminder {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 50;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 36px;
	font-family: seto;
	text-shadow: 2px 2px #000077;
	line-height: 36px;
	opacity: 0.01;
	transition: opacity 0.5s ease-in-out;
}
.click-reminder.show {
	opacity: 1;
}

.click-reminder > div {
	transition: opacity 2s ease-in-out;
}
.click-reminder > div.fade-out {
	opacity: 0;
}
</style>
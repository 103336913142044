<template>
	<span
		v-show="count > 0"
		:class="{ 'text-muted': count < limit, 'text-warning': count == limit, 'text-danger': count > limit }"
	>
		({{ count }}/{{ limit }})
	</span>
</template>

<script>
export default {
	name: "CharacterCount",

	props: ["value", "limit"],

	data() {
		return {};
	},
	created() {},
	computed: {
		count() {
			if (!this.value) {
				return 0;
			}
			return this.value.length;
		},
	},

	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pixiv-icon {
	fill: currentColor;
	margin-bottom: 2px;
}
</style>

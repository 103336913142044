var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('birthday-page',{staticClass:"purple-scrollbar",attrs:{"fillBackground":"","hideHeader":""}},[_c('loading',{staticClass:"loading-center",class:{ show: _vm.loading },attrs:{"color":"rgba(0, 0, 0, 0.7)"}}),_c('div',{staticClass:"fullscreen",class:{ show: !_vm.loading },style:({
			left: _vm.hOffset ? ((-1 * _vm.hOffset + _vm.normalizedOffsetX) + "px") : '-35px',
			// left: hOffset ? `${orienting ? -2 * (horizontalShift) * hOffset - 35 : -1 * hOffset}px` : '-35px',
			top: _vm.vOffset ? ((-1 * _vm.vOffset) + "px") : '-17px',
		})},[_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":0,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Background},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-70,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Back_balloon},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-60,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Long_Ribbon},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-50,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Balloon},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-40,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Flare},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-30,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Ruki},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-20,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Rose},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-10,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Black_Cat},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":-5,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Delu_Mama},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":15,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Delutaya},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":25,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Small_Ribbon},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":45,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Cake},on:{"loaded":_vm.loadedImg}}),_c('parallax-layer',{staticClass:"splash-image",style:({
				width: _vm.splashWidth ? (_vm.splashWidth + "px") : '',
				height: _vm.splashHeight ? (_vm.splashHeight + "px") : '',
			}),attrs:{"shift":60,"horizontal":_vm.horizontalShift,"vertical":_vm.verticalShift,"src":_vm.Flower},on:{"loaded":_vm.loadedImg}}),_c('img',{staticClass:"signature",attrs:{"src":_vm.Signature},on:{"loaded":_vm.loadedImg}})],1),_c('div',{staticClass:"click-reminder",class:{ show: !_vm.loading }},[(!_vm.orienting && !_vm.reminderGone)?_c('div',{class:{ 'fade-out': !_vm.showReminder }},[_vm._v(" "+_vm._s(_vm.$t("labels.click_to_enter"))+" ")]):_vm._e(),(_vm.orienting && !_vm.reminderGone)?_c('div',{class:{ 'fade-out': !_vm.showReminder }},[_vm._v(" "+_vm._s(_vm.$t("labels.touch_to_enter"))+" ")]):_vm._e(),(_vm.orienting && !_vm.reminderGone)?_c('div',{staticClass:"mt-5",class:{ 'fade-out': !_vm.showReminder }},[_vm._v(" "+_vm._s(_vm.$t("labels.drag_to_see_more"))+" ")]):_vm._e()]),(!_vm.loading)?_c('div',{ref:"overlay",staticClass:"click-overlay clickable",on:{"click":_vm.goToWall,"mousemove":_vm.calcShift}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div class="container justify-content-center pb-5">
		<div class="delu-text-large title-text">{{ $t("labels.privacy_policy") }}</div>
		<!-- <img src="@/assets/triangle.png" class="triangle-image" /> -->
		<div class="profile-card mt-3 p-4" style="width: initial; max-width: 60%">
			<h1>Privacy Policy for Delutaya Club</h1>

			<p>
				At Delutaya Club, accessible from delutaya.club, one of our main priorities is the privacy of our
				visitors. This Privacy Policy document contains types of information that is collected and recorded by
				Delutaya Club and how we use it.
			</p>

			<p>
				If you have additional questions or require more information about our Privacy Policy, do not hesitate
				to contact us.
			</p>

			<p>
				This Privacy Policy applies only to our online activities and is valid for visitors to our website with
				regards to the information that they shared and/or collect in Delutaya Club. This policy is not
				applicable to any information collected offline or via channels other than this website.
			</p>

			<h2>Consent</h2>

			<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

			<h2>Information we collect</h2>

			<p>
				One of the main purposes of Delutaya Club is to connect a single person to multiple different social media accounts, so that your identity can
				be verified while using different services. For this purpose, Delutaya Club collects and stores only the following information:

				<ul>
					<li>Your username/handle on the service</li>
					<li>Your internal user ID</li>
					<li>Your profile picture</li>
					<li>Your email address</li>
					<li>In some cases, information about your account access permissions, or other members you have followed, subscribed to, or joined the membership of on the service</li>
				</ul>

				Other personally identifying information is not collected or stored.
			</p>
			<p>
				If you contact us directly, we may receive and store any additional personal
				information you choose to provide as part of the communication.
			</p>

			<h2>How we use your information</h2>

			<p>We use the information we collect in various ways, including to:</p>

			<ul>
				<li>Provide, operate, and maintain our website</li>
				<li>Improve, personalize, and expand our website</li>
				<li>Understand and analyze how you use our website</li>
				<li>Identify you on any platform which you have opted in to connect to the service, and cross-reference your identity on any other such platform</li>
			</ul>

			<h2>Log Files</h2>

			<p>
				Delutaya Club follows a standard procedure of using log files. These files log visitors when they visit
				websites. All hosting companies do this and a part of hosting services' analytics. The information
				collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider
				(ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not
				linked to any information that is personally identifiable. The purpose of the information is for
				analyzing trends, administering the site, tracking users' movement on the website, and gathering
				demographic information.
			</p>

			<p>
				Furthermore, none of this information will ever be provided or sold to any third party except in cases where it is necessary for the core operation of the website.
			</p>

			<h2>Cookies and Web Beacons</h2>

			<p>
				Like any other website, Delutaya Club uses 'cookies'. These cookies are used to store information
				including visitors' preferences, and the pages on the website that the visitor accessed or visited. The
				information is used to optimize the users' experience by customizing our web page content based on
				visitors' browser type and/or other information.
			</p>

			<p>
				For more general information on cookies, please read
				<a href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies" from Cookie Consent</a>.
			</p>

			<h2>Advertising Partners Privacy Policies</h2>

			<p>Delutaya Club does not use any advertisement services, nor provide any of your information to any advertisement services. If this policy is ever changed, you will be notified and be asked to agree to a new version of this privacy policy.</p>

			<h2>Third Party Privacy Policies</h2>

			<p>
				Delutaya Club's Privacy Policy does not apply to other social media platforms or websites. Thus, we are advising
				you to consult the respective Privacy Policies of these third-party ad servers for more detailed
				information. It may include their practices and instructions about how to opt-out of certain options.
			</p>

			<p>
				You can choose to disable cookies through your individual browser options. To know more detailed
				information about cookie management with specific web browsers, it can be found at the browsers'
				respective websites.
			</p>

			<h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

			<p>Under the CCPA, among other rights, California consumers have the right to:</p>
			<p>
				Request that a business that collects a consumer's personal data disclose the categories and specific
				pieces of personal data that a business has collected about consumers.
			</p>
			<p>Request that a business delete any personal data about the consumer that a business has collected.</p>
			<p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
			<p>
				If you make a request, we have one month to respond to you. If you would like to exercise any of these
				rights, please contact us.
			</p>

			<h2>GDPR Data Protection Rights</h2>

			<p>
				We would like to make sure you are fully aware of all of your data protection rights. Every user is
				entitled to the following:
			</p>
			<p>
				The right to access – You have the right to request copies of your personal data. We may charge you a
				small fee for this service.
			</p>
			<p>
				The right to rectification – You have the right to request that we correct any information you believe
				is inaccurate. You also have the right to request that we complete the information you believe is
				incomplete.
			</p>
			<p>
				The right to erasure – You have the right to request that we erase your personal data, under certain
				conditions.
			</p>
			<p>
				The right to restrict processing – You have the right to request that we restrict the processing of your
				personal data, under certain conditions.
			</p>
			<p>
				The right to object to processing – You have the right to object to our processing of your personal
				data, under certain conditions.
			</p>
			<p>
				The right to data portability – You have the right to request that we transfer the data that we have
				collected to another organization, or directly to you, under certain conditions.
			</p>
			<p>
				If you make a request, we have one month to respond to you. If you would like to exercise any of these
				rights, please contact us.
			</p>

			<h2>Children's Information</h2>

			<p>
				Another part of our priority is adding protection for children while using the internet. We encourage
				parents and guardians to observe, participate in, and/or monitor and guide their online activity.
			</p>

			<p>
				Delutaya Club does not knowingly collect any Personal Identifiable Information from children under the
				age of 13. If you think that your child provided this kind of information on our website, we strongly
				encourage you to contact us immediately and we will do our best efforts to promptly remove such
				information from our records.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "Error",

	props: [],

	components: {},

	data() {
		return {};
	},

	created() {},

	methods: {},
};
</script>

<style scoped>
.title-text {
	color: #fff;
	text-align: center;
	font-family: "lato", sans-serif;
	font-weight: 300;
	font-size: 50px;
	letter-spacing: 10px;
	padding-left: 10px;

	background: -webkit-linear-gradient(white, #016922);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.delu-text-large {
	color: rgb(79, 211, 89);
	text-shadow: 0 0 5px rgb(79, 211, 89);
	font-size: 42px;
}
.error-block {
	overflow-x: auto;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}
</style>
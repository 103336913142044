import axios from "axios";

//FileService
export default {
	getRepeatMessages() {
		return axios.get(`/v1/repeat_messages`)
	},

	saveRepeatMessage(repeatMessage) {
		return axios.post(`/v1/repeat_message`, repeatMessage)
	},

	deleteRepeatMessage(id) {
		return axios.delete(`/v1/repeat_message/${id}`)
	},

	getDiscordChannelName(id) {
		return axios.get(`/v1/discord_channel_name/${id}`)
	}
};
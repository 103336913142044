<template>
	<main-page :user="user" :refreshUser="fullRefresh">
		<div class="container d-flex flex-column">
			<h1 v-if="loading" class="mt-5"><i class="fas fa-spinner fa-pulse text-very-muted" /></h1>
			<div v-else-if="noAccess" class="mt-5">
				<h1 class="mt-5">{{ $t("labels.no_access") }}</h1>
				<h5 class="mt-4 header-muted">{{ $t("messages.admin_page_1") }}</h5>
				<h5 class="header-muted">{{ $t("messages.admin_page_2") }}</h5>
			</div>
			<template v-else>
				<h3 class="mb-3">{{ $t("labels.files") }}</h3>
				<b-table
					ref="table"
					striped
					stacked="md"
					:show-empty="true"
					:items="files"
					:fields="fields"
					class="text-left flex"
					style="font-size: 1rem"
					:empty-text="$t('messages.there_are_no_records_to_display')"
				>
					<template #cell(filename)="{ item: row }">
						<div class="d-flex flex-row align-items-center">
							<i class="fas fa-lg mr-2" :class="{ [Format.mimeTypeIcon(row.mime_type)]: true }" />
							<span style="word-break: break-word">{{ row.filename }}</span>
						</div>
					</template>
					<template #cell(size)="{ item: row }">
						{{ Format.fileSize(row.size) }}
					</template>
					<template #cell(created_at)="{ item: row }">
						{{ $d(new Date(row.created_at), "long") }}
					</template>
					<template #cell(link)="{ item: row }">
						<div :id="`link-${row.id}`" @click="copyURL(row)" class="link-box">
							<pixiv-icon
								v-if="row.members_only"
								:size="16"
								class="mr-1 text-danger"
								v-tippy
								:title="$t('labels.members_only')"
							/>
							<i
								v-if="row.discord_only"
								class="fab fa-discord text-danger mr-1"
								v-tippy
								:title="getDiscordOnlyTooltip(row)"
							/>
							{{ host + row.path }}
						</div>
					</template>
					<template #cell(actions)="{ item: row }">
						<div class="d-flex flex-row">
							<button
								v-tippy
								:title="$t('labels.edit')"
								@click="editRow(row)"
								class="ml-auto btn btn-icon btn-success"
							>
								<i class="fa fa-edit" />
							</button>
							<button
								v-tippy
								:title="$t('labels.download')"
								@click="downloadRow(row)"
								class="ml-2 btn btn-icon btn-info"
							>
								<i class="fa fa-download" />
							</button>
							<button
								v-tippy
								:title="$t('labels.delete')"
								@click="confirmDeleteRow(row)"
								class="ml-2 btn btn-icon btn-danger"
							>
								<i class="fa fa-trash" />
							</button>
						</div>
					</template>
				</b-table>
				<div class="below-table d-flex flex-row align-items-center mb-3">
					<div class="text-muted">{{ $t("messages.drag_and_drop_or_click") }}</div>
					<button @click="newRow" class="ml-3 btn btn-success">
						<i class="fa fa-plus mr-2" />{{ $t("labels.upload_file") }}
					</button>
				</div>
			</template>
		</div>

		<b-modal
			id="editModal"
			:title="$t('labels.file_details')"
			header-bg-variant="primary"
			body-bg-variant="dark"
			footer-bg-variant="dark"
			no-close-on-esc
			no-close-on-backdrop
			hide-header-close
		>
			<div v-if="editingRow" class="px-3 pt-3 row" style="font-size: 1rem">
				<!-- File -->
				<template v-if="editingRow.filename">
					<div class="col-12">
						<label class="text-muted">{{ $t("labels.filename") }}</label>
					</div>
					<div class="col-12">
						<i class="fas fa-lg mr-2" :class="{ [Format.mimeTypeIcon(editingRow.mime_type)]: true }" />
						<span>{{ editingRow.filename }}</span>
					</div>
				</template>
				<template v-else>
					<div class="col-8 d-flex flex-row align-items-center">
						<input ref="fileSelect" class="d-none" type="file" @change="selectFile" />
						<template v-if="!editingRow.file">
							<i class="text-muted far fa-lg mr-2" :class="{ [Format.mimeTypeIcon()]: true }" />
							<div class="text-muted">{{ $t("labels.no_file_chosen") }}</div>
						</template>
						<div v-if="editingRow.file" class="d-flex flex-row align-items-center file-display py-1 px-2">
							<i class="fas fa-lg mr-2" :class="{ [Format.mimeTypeIcon(editingRow.file.type)]: true }" />
							<div>{{ editingRow.file.name }}</div>
						</div>
					</div>
					<div class="col-4">
						<button @click="clickFileInput" class="btn btn-primary nowrap" :disabled="uploading.inProgress">
							{{ $t("labels.choose_file") }}
						</button>
					</div>
				</template>
				<div class="col-12"><hr class="w-100" /></div>
				<!-- Link -->
				<div class="col-12">
					<label class="text-muted">{{ $t("labels.link") }}</label>
				</div>
				<div class="col-12">
					<div class="d-flex flex-row align-items-center">
						<div class="nowrap">{{ host }}</div>
						<input
							class="form-control dark-control"
							style="margin-left: 6px; padding-left: 4px"
							:style="{ ['margin-left']: uploading.inProgress ? '-4px' : '6px' }"
							v-model="editingRow.path"
							:disabled="uploading.inProgress"
						/>
					</div>
				</div>
				<div class="col-12"><hr class="w-100" /></div>
				<!-- Members only -->
				<div class="col-5 d-flex flex-column justify-content-end">
					<b-form-checkbox
						v-model="editingRow.members_only"
						name="check-button"
						button
						:button-variant="editingRow.members_only ? 'primary' : ''"
						class="mr-3 mb-3"
						:disabled="uploading.inProgress"
					>
						<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
							<i
								class="fa"
								:class="{
									'fa-check': editingRow.members_only,
									'fa-times-circle': !editingRow.members_only,
									'text-very-muted': !editingRow.members_only,
								}"
								style="min-width: 15px"
							/>
							<span class="ml-2 text-left nowrap">{{ $t("labels.members_only") }}</span>
						</div>
					</b-form-checkbox>
				</div>
				<div class="col-7">
					<div v-if="editingRow.members_only" class="mb-2">
						<div class="text-muted" style="font-size: 0.9rem">{{ $t("labels.download_members_only") }}</div>
					</div>
				</div>
				<div v-if="editingRow.members_only || editingRow.discord_only" class="col-12">
					<hr class="w-100 mt-0" />
				</div>
				<!-- Discord only -->
				<div class="col-5 d-flex flex-column justify-content-end">
					<b-form-checkbox
						v-model="editingRow.discord_only"
						name="check-button"
						button
						:button-variant="editingRow.discord_only ? 'primary' : ''"
						class="mr-3 mb-3"
						:disabled="uploading.inProgress"
					>
						<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
							<i
								class="fa"
								:class="{
									'fa-check': editingRow.members_only,
									'fa-times-circle': !editingRow.members_only,
									'text-very-muted': !editingRow.members_only,
								}"
								style="min-width: 15px"
							/>
							<span class="ml-2 text-left nowrap">{{ $t("labels.discord_only") }}</span>
						</div>
					</b-form-checkbox>
				</div>
				<div class="col-7">
					<div v-if="editingRow.discord_only" class="mb-2">
						<div class="text-muted" style="font-size: 0.9rem">{{ $t("labels.download_discord_only") }}</div>
					</div>
				</div>
				<!-- Discord snapshot -->
				<template v-if="editingRow.discord_only">
					<div class="col-5 d-flex flex-column justify-content-end">
						<b-form-checkbox
							v-model="editingRow.discord_only_snapshot"
							:value="false"
							:unchecked-value="true"
							name="check-button"
							button
							:button-variant="editingRow.discord_only_snapshot ? '' : 'primary'"
							class="mr-3 mb-3"
							:disabled="uploading.inProgress"
						>
							<div
								class="d-flex flex-row align-items-center"
								style="margin-left: -4px; margin-right: -4px"
							>
								<i
									class="fa"
									:class="{
										'fa-check': !editingRow.discord_only_snapshot,
										'fa-times-circle': editingRow.discord_only_snapshot,
										'text-very-muted': editingRow.discord_only_snapshot,
									}"
									style="min-width: 15px"
								/>
								<span class="ml-2 text-left nowrap">{{ $t("labels.join_to_access") }}</span>
							</div>
						</b-form-checkbox>
					</div>
					<div class="col-7">
						<template v-if="editingRow.discord_only_snapshot">
							<div v-if="!editingRow.discord_only_at" class="mb-2">
								<div class="text-muted" style="font-size: 0.9rem">
									{{ $t("labels.discord_do_snapshot") }}
								</div>
							</div>
							<div v-if="editingRow.discord_only_at" class="mb-2">
								<div class="text-muted" style="font-size: 0.9rem">
									{{
										$t("labels.discord_has_snapshot", {
											time: $d(new Date(editingRow.discord_only_at), "long"),
										})
									}}
								</div>
							</div>
						</template>
						<div v-else class="mb-2">
							<div class="text-muted" style="font-size: 0.9rem">
								{{ $t("labels.discord_dont_snapshot") }}
							</div>
						</div>
					</div>
				</template>
				<!-- Limit downloads -->
				<!-- <div class="col-5 d-flex flex-column justify-content-end">
					<b-form-checkbox
						v-model="editingRow.limit_downloads"
						name="check-button"
						button
						:button-variant="editingRow.limit_downloads ? 'primary' : ''"
						class="mr-3 mb-3"
						:disabled="uploading.inProgress"
					>
						<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
							<i
								class="fa"
								:class="{
									'fa-check': editingRow.limit_downloads,
									'fa-times-circle': !editingRow.limit_downloads,
									'text-very-muted': !editingRow.limit_downloads,
								}"
								style="min-width: 15px"
							/>
							<span class="ml-2 text-left">{{ $t("labels.limit_downloads") }}</span>
						</div>
					</b-form-checkbox>
				</div>
				<div class="col-7">
					<div v-if="editingRow.limit_downloads" class="mb-3">
						<label class="text-muted">{{ $t("labels.maximum_downloads") }}</label>
						<input
							class="form-control"
							type="number"
							v-model="editingRow.max_downloads"
							:disabled="uploading.inProgress"
						/>
					</div>
				</div> -->
				<!-- Expire -->
				<!-- <div class="col-5 d-flex flex-column justify-content-end">
					<b-form-checkbox
						v-model="editingRow.expire"
						name="check-button"
						button
						:button-variant="editingRow.expire ? 'primary' : ''"
						class="mr-3"
						:disabled="uploading.inProgress"
					>
						<div class="d-flex flex-row align-items-center" style="margin-left: -4px; margin-right: -4px">
							<i
								class="fa"
								:class="{
									'fa-check': editingRow.expire,
									'fa-times-circle': !editingRow.expire,
									'text-very-muted': !editingRow.expire,
								}"
								style="min-width: 15px"
							/>
							<span class="ml-2 text-left">{{ $t("labels.expire") }}</span>
						</div>
					</b-form-checkbox>
				</div>
				<div class="col-7">
					<div v-if="editingRow.expire">
						<label class="text-muted">{{ $t("labels.expiration_date") }}</label>
						<b-form-datepicker
							v-model="editingRow.expiration_date"
							:locale="$i18n.locale"
							:disabled="uploading.inProgress"
						></b-form-datepicker>
					</div>
				</div> -->
			</div>
			<template v-if="editingRow" #modal-footer="{ _, cancel }">
				<button v-if="!(uploading.inProgress || saving)" class="btn btn-secondary" @click="cancel">
					{{ $t("labels.cancel") }}
				</button>
				<button
					v-if="!uploading.inProgress && !saving"
					@click="saveRow(editingRow)"
					class="ml-auto btn"
					:class="{ 'btn-success': canSaveRow(editingRow), 'btn-secondary': !canSaveRow(editingRow) }"
					:disabled="!canSaveRow(editingRow)"
				>
					{{ $t("labels.save") }}
				</button>
				<button v-if="!uploading.inProgress && saving" class="ml-auto btn btn-success" disabled="true">
					<i class="fas fa-spinner fa-pulse mr-1" />{{ $t("labels.saving") }}
				</button>
				<b-progress v-if="uploading.inProgress" class="ml-auto" :max="uploading.total" animated>
					<b-progress-bar :value="uploading.loaded">
						<span v-if="uploading.loaded != uploading.total">{{ $t("labels.uploading") }}</span>
						<span v-else>{{ $t("labels.saving") }}</span>
					</b-progress-bar>
				</b-progress>
			</template>
		</b-modal>

		<b-modal
			id="deleteModal"
			:title="$t('labels.delete_file')"
			header-bg-variant="danger"
			body-bg-variant="dark"
			footer-bg-variant="dark"
			no-close-on-esc
			no-close-on-backdrop
			hide-header-close
		>
			<div v-if="deletingRow" class="px-3 pt-3 row" style="font-size: 1rem">
				<div class="col-12 form-group">
					<h5>{{ $t("messages.confirm_delete") }}</h5>
				</div>

				<div class="row darken shadow m-2 py-3 px-2">
					<div class="col-12">
						<label class="text-muted">{{ $t("labels.filename") }}</label>
					</div>
					<div class="col-12 form-group">
						<i class="fas fa-lg mr-2" :class="{ [Format.mimeTypeIcon(deletingRow.mime_type)]: true }" />
						<span>{{ deletingRow.filename }}</span>
					</div>

					<div class="col-12">
						<label class="text-muted">{{ $t("labels.link") }}</label>
					</div>
					<div class="col-12">
						<span>{{ host + deletingRow.path }}</span>
					</div>
				</div>
			</div>
			<template v-if="deletingRow" #modal-footer="{ _, cancel }">
				<button v-if="!deleting" class="btn btn-secondary" @click="cancel">{{ $t("labels.cancel") }}</button>
				<button v-if="!deleting" @click="deleteRow(deletingRow)" class="ml-auto btn btn-danger">
					{{ $t("labels.delete") }}
				</button>
				<button v-if="deleting" class="ml-auto btn btn-danger" disabled>
					<i class="fas fa-spinner fa-pulse mr-1" />{{ $t("labels.deleting") }}
				</button>
			</template>
		</b-modal>

		<div
			v-if="user"
			ref="uploadOverlay"
			class="upload-drag-overlay d-flex flex-column align-items-center justify-content-center p-5"
		>
			<h3><i class="far fa-file-plus mr-2" />Upload 1 file</h3>
		</div>
	</main-page>
</template>


<script>
import Toast from "@/utils/Toast";
import Format from "@/utils/Format";
import _ from "lodash";

import FileService from "@/services/FileService";

export default {
	name: "FileUpload",

	props: ["user"],

	components: {},

	data() {
		return {
			Toast: Toast,
			Format: Format,

			loading: true,
			noAccess: false,
			fields: [
				{
					key: "filename",
					label: this.$i18n.t("labels.name"),
					class: "v-mid",
					thClass: "nowrap",
					sortable: true,
				},
				{
					key: "size",
					label: this.$i18n.t("labels.size"),
					class: "v-mid",
					thClass: "nowrap",
					sortable: true,
				},
				{
					key: "created_at",
					label: this.$i18n.t("labels.date_added"),
					class: "v-mid",
					thClass: "nowrap",
					sortable: true,
				},
				{
					key: "link",
					label: this.$i18n.t("labels.link"),
					class: "v-mid",
					thClass: "nowrap",
					sortable: true,
				},
				{
					key: "actions",
					label: " ",
					class: "v-mid",
					thClass: "nowrap",
					sortable: true,
				},
			],
			files: [],

			host: "",
			editingRow: null,
			deletingRow: null,
			uploading: {
				inProgress: false,
				loaded: 100,
				total: 100,
			},
			saving: false,
			deleting: false,
		};
	},

	created() {
		if (!this.user) {
			this.loading = false;
			this.noAccess = true;
			return;
		}
		this.host = `${window.location.protocol}//${window.location.host}/Δ/`;
		this.loadRows();
	},

	mounted() {
		window.addEventListener("dragenter", this.windowDragEnter);

		const allowDrag = (e) => {
			e.dataTransfer.dropEffect = "upload";
			e.preventDefault();
		};
		const dragLeave = () => {
			this.$refs.uploadOverlay.classList.remove("show");
		};
		this.$refs.uploadOverlay.addEventListener("dragenter", allowDrag);
		this.$refs.uploadOverlay.addEventListener("dragover", allowDrag);
		this.$refs.uploadOverlay.addEventListener("dragleave", dragLeave);
		this.$refs.uploadOverlay.addEventListener("drop", this.fileDrop);
	},

	destroyed() {
		window.removeEventListener("dragenter", this.windowDragEnter);
	},

	methods: {
		upload() {
			this.uploading = true;
			this.saveFile()
				.then(() => {
					this.uploading = false;
					this.uploaded = true;
				})
				.catch((e) => {
					console.error(e);
					Toast.info("Failed to save file", e);
				});
		},

		loadRows() {
			this.loading = true;
			FileService.getFileLinks()
				.then((r) => {
					this.loading = false;
					this.files = r.data;
				})
				.catch((e) => {
					console.error(e);
					Toast.error("Failed to get file links", e);
				});
		},

		newRow() {
			let newRow = {
				file: null,
				path: null,
				members_only: false,
				discord_only: false,
				discord_only_snapshot: true,
				limit_downloads: false,
				max_downloads: null,
				expire: false,
				expiration_date: null,
			};

			this.editRow(newRow);
		},

		newRowWithFile(file) {
			let newRow = {
				file: file,
				path: null,
				members_only: false,
				discord_only: false,
				discord_only_snapshot: true,
				limit_downloads: false,
				max_downloads: null,
				expire: false,
				expiration_date: null,
			};

			this.editRow(newRow);
		},

		editRow(row) {
			this.editingRow = _.cloneDeep(row);
			this.$bvModal.show("editModal");
		},

		saveRow(row) {
			if (!row.id) {
				let uploadProgress = (event) => {
					this.uploading.loaded = event.loaded;
					this.uploading.total = event.total;
				};

				if (!row.discord_only) {
					row.discord_only_snapshot = false;
				}

				this.uploading.inProgress = true;
				this.uploading.loaded = 0;
				FileService.uploadFileWithPath(row.file, row.path, uploadProgress)
					.then((r) => {
						console.log("uploadFileData", r.data);

						row.filename = row.file.name;
						row.url = r.data.url;
						row.mime_type = r.data.mime_type;
						row.size = r.data.size;
						row.b2_filename = r.data.b2_filename;
						row.b2_id = r.data.b2_id;
						delete row.file;

						FileService.saveFileLink(row)
							.then(() => {
								this.uploading.inProgress = false;
								Toast.info(this.$i18n.t("messages.uploaded_file"));
								this.$bvModal.hide("editModal");
								this.loadRows();
							})
							.catch((e) => {
								this.uploading.inProgress = false;
								console.error(e);
								Toast.error("Failed to save link", e);
							});
					})
					.catch((e) => {
						this.uploading.inProgress = false;
						console.error(e);
						Toast.error("Failed to upload file", e);
					});
			} else {
				this.saving = true;
				FileService.saveFileLink(row)
					.then(() => {
						this.saving = false;
						Toast.info(this.$i18n.t("messages.saved_file_details"));
						this.$bvModal.hide("editModal");
						this.loadRows();
					})
					.catch((e) => {
						this.saving = false;
						console.error(e);
						Toast.error("Failed to save link", e);
					});
			}
		},

		downloadRow(row) {
			FileService.downloadLink(row, this.user);
		},

		copyURL(row) {
			let el = document.getElementById(`link-${row.id}`);
			if (!el) {
				console.error("Failed to find link box", row.id);
				return;
			}

			clearTimeout(el.copied);
			clearTimeout(el.fading);

			el.classList.remove("fading");
			el.classList.add("copied");
			el.copied = setTimeout(() => {
				el.classList.add("fading");
			}, 500);
			el.fading = setTimeout(() => {
				el.classList.remove("copied");
				el.classList.remove("fading");
			}, 1000);

			let url = this.host + row.path;
			Toast.success(this.$i18n.t("messages.copied_link"), url);
			navigator.clipboard.writeText(url);
		},

		clickFileInput() {
			let el = this.$refs.fileSelect;
			if (!el) {
				console.error("Failed to find file select element");
				return;
			}

			el.click();
		},

		selectFile(event) {
			if (!this.editingRow) {
				console.error("File selected while not editing row");
				return;
			}

			let files = event.target.files;
			if (files.length == 0) {
				console.error("Selected 0 files");
				return;
			}

			this.editingRow.file = files[0];
			console.log("FILE", this.editingRow.file);
		},

		canSaveRow(row) {
			if (!(row.id || row.file)) return false;
			if (!row.path) return false;
			if (row.limit_downloads && !(row.max_downloads && row.max_downloads > 0)) return false;
			if (row.expire && !row.expiration_date) return false;

			return true;
		},

		confirmDeleteRow(row) {
			this.deletingRow = row;
			this.$bvModal.show("deleteModal");
		},

		deleteRow(row) {
			this.deleting = true;
			FileService.deleteFileLink(row.id)
				.then(() => {
					Toast.info(this.$i18n.t("messages.file_deleted"));
					this.$bvModal.hide("deleteModal");
					this.deleting = false;
					this.loadRows();
				})
				.catch((e) => {
					console.error(e);
					Toast.error("Failed to delete file", e);
					this.deleting = false;
				});
		},

		windowDragEnter() {
			this.$refs.uploadOverlay.classList.add("show");
		},

		fileDrop(event) {
			event.preventDefault();
			this.$refs.uploadOverlay.classList.remove("show");
			console.log("File drop", event);
			let file = null;
			if (event.dataTransfer.items) {
				// Use DataTransferItemList interface to access the file(s)
				if (event.dataTransfer.items[0].kind === "file") {
					file = event.dataTransfer.items[0].getAsFile();
				}
			} else {
				// Use DataTransfer interface to access the file(s)
				file = event.dataTransfer.files[0];
			}
			if (!file) return;

			this.newRowWithFile(file);
		},

		getDiscordOnlyTooltip(row) {
			let tooltip = this.$i18n.t("labels.discord_only");
			if (row.discord_only_at) {
				console.log("CALC", row.discord_only_at);
				tooltip += `<br /><div class="text-xxs text-muted>(${this.$i18n.d(
					new Date(row.discord_only_at),
					"long"
				)})</div>`;
			}
			return tooltip;
		},

		fullRefresh() {
			this.$router.go();
		},
	},
};
</script>

<style scoped>
.container {
	padding-top: 24px;
	justify-content: start;
}
.btn-icon {
	padding: 0.375rem 0.5rem;
	width: 35px;
}
.link-box {
	display: block;
	cursor: pointer;
	padding: 4px 8px;
	border-radius: 6px;
	border: 1px solid white;
	overflow-wrap: break-word;
}
.copied {
	border-color: #77b300;
	color: #77b300;
}
.fading {
	transition: border-color 1000ms, color 1000ms;
	border-color: white;
	color: white;
}
hr {
	border-color: rgb(255 255 255 / 20%);
}
.below-table {
	padding: 0.75rem 0.75rem;
	border-radius: 8px;
	color: white;
	background-color: rgb(255, 255, 255, 10%);
}
.file-display {
	border: 2px solid rgb(255 255 255 / 25%);
	border-radius: 6px;
	word-break: break-word;
}
.progress {
	height: 35px;
	width: 100%;
	background-color: rgb(255, 255, 255, 10%);
}
.darken {
	background-color: rgb(0 0 0 / 20%);
	border-radius: 12px;
}

.upload-drag-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transition: opacity 0.25s;
	backdrop-filter: blur(6px);
	pointer-events: none;
	z-index: 20;
	color: white;
}

.upload-drag-overlay.show {
	opacity: 1;
	pointer-events: all;
}

.header-muted {
	opacity: 0.5;
}
</style>
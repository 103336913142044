import axios from "axios";
import Store from "@/utils/Store"

//AuthService
export default {
	auth: null,
	getAuthPromise: null,
	queuedRefresh: null,

	//Gets the logged in user, pass allowCache = true to prevent doing a server call when it's not necessary
	getAuth(allowCache) {
		if (!this.getAuthPromise || !allowCache || this.queuedRefresh) {
			this.queuedRefresh = null;

			this.getAuthPromise = axios.get("/v1/auth")
		}

		return this.getAuthPromise;
	},

	logout(vue) {
		return axios.post("/v1/logout").then(() => {
			this.forceRefresh()

			if (vue && vue.$route && vue.$route.meta.access) {
				window.location.href = `/`
			} else {
				window.location.reload()
			}
		})
	},

	getUserNoDistrict() {
		return axios.get(`/v1/me_no_district`)
	},

	forceRefresh() {
		this.queuedRefresh = true;
	},

	vue: null,

	init(vue) {
		this.vue = vue
	},

	getThemeSettings(user) {
		if (user) {
			return user.theme_settings
		} else {
			let theme = Store.getDefault(this.vue, "theme", {
				theme_name: "purple",
				stars_off: false,
				do_translate: false,
				language: '',
			})
			return theme
		}
	},

	setThemeSettings(user, themeSettings) {
		if (user) {
			user.theme_settings = themeSettings
			return this.setTheme(themeSettings).catch(() => {
				console.error("Failed to set theme")
			})
		} else {
			Store.set(this.vue, "theme", themeSettings)
		}
	},

	setTheme(theme) {
		return axios.post(`/v1/theme`, theme)
	}
}
<template>
	<div class="profile-card hoverable d-flex flex-row align-items-center" @mouseenter="emitIcon">
		<h2 class="flex mb-0"><i class="fab fa-fw fa-twitter mr-2" />{{ $t("platforms.twitter") }}</h2>
		<div v-if="user.twitter_user_id" class="text-right">
			<div class="text-success"><i class="fas fa-check mr-1" />{{ $t("labels.verified") }}</div>
			<div class="text-half-muted">@{{ user.twitter_handle }}</div>
		</div>
		<div v-else class="my-1">
			<a
				id="twitter-button"
				href="/v1/auth/twitter?provider=twitter"
				class="btn-connect btn-lg btn-social btn-twitter"
			>
				{{ $t("labels.connect") }}
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "TwitterCard",

	props: ["user"],

	components: {},

	data() {
		return {};
	},

	created() {},

	methods: {
		emitIcon() {
			if (this.user.twitter_icon_url) {
				this.$emit("icon", this.user.twitter_icon_url);
			} else {
				console.error("No Twitter icon URL");
			}
		},
	},
};
</script>

<style scoped>
</style>
<template>
	<div>
		<div
			class="language-display"
			:class="{ pickable: !noPick }"
			data-toggle="dropdown"
			data-pjax-click-state="anchor-empty"
			aria-expanded="false"
		>
			<template v-if="!sLang">
				{{ $t("labels.language:") }} <span class="text-danger">{{ $t("labels.choose") }}</span>
			</template>
			<template v-else>
				{{ $t("labels.language:") }}
				<span class="text-success _800" :class="{ 'text-success': !noPick }">{{ sLang.id }}</span>
			</template>
			<i v-if="!noPick" class="ml-1 fas fa-lg fa-caret-down" />
		</div>
		<div v-if="!noPick" class="dropdown-menu dropdown-menu-right pt-0 mt-2 animate fadeIn text-md">
			<a
				v-for="lang in languageOptions"
				:key="lang.id"
				class="dropdown-item"
				:class="{ active: lang.id == (sLang && sLang.id) }"
				@click="pickLanguage(lang)"
				>{{ lang.label }}</a
			>
		</div>
	</div>
</template>

<script>
import _ from "lodash";

export default {
	name: "LanguagePicker",

	props: {
		value: {
			type: String,
			default: "",
		},
		noPick: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		this.languageOptions = [
			{ label: this.$t("languages.JA"), id: "JA" },
			{ label: this.$t("languages.EN"), id: "EN" },
			{ label: this.$t("languages.ZH"), id: "ZH" },
			{ label: this.$t("languages.DE"), id: "DE" },
			{ label: this.$t("languages.ES"), id: "ES" },
			{ label: this.$t("languages.FR"), id: "FR" },
			{ label: this.$t("languages.IT"), id: "IT" },
			{ label: this.$t("languages.NL"), id: "NL" },
			{ label: this.$t("languages.PL"), id: "PL" },
			{ label: this.$t("languages.PT-PT"), id: "PT-PT" },
			{ label: this.$t("languages.PT-BR"), id: "PT-BR" },
			{ label: this.$t("languages.RU"), id: "RU" },
		];
		return {};
	},
	created() {},
	computed: {
		sLang() {
			return _.find(this.languageOptions, { id: this.value });
		},
	},

	methods: {
		pickLanguage(lang) {
			this.$emit("input", lang.id);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pickable {
	cursor: pointer;
}
.language-display {
}
</style>

<template>
	<div class="dropdown-menu dropdown-menu-right mt-2 animate fadeIn text-md" :style="{ 'font-size': size + 'px' }">
		<!-- <div class="disabled ml-3">{{ $t("labels.theme") }}:</div>
		<a class="dropdown-item active">{{ $t("labels.light") }}</a>
		<a class="dropdown-item">{{ $t("labels.dark") }}</a>
		<div class="dropdown-divider"></div> -->
		<div class="disabled ml-3">{{ $t("labels.language") }}:</div>
		<a class="dropdown-item" :class="{ active: themeSettings.language == 'ja' }" @click="setLanguage('ja')">{{
			$t("languages.JA")
		}}</a>
		<a class="dropdown-item" :class="{ active: themeSettings.language == 'en' }" @click="setLanguage('en')">{{
			$t("languages.EN")
		}}</a>
		<a class="dropdown-item" :class="{ active: themeSettings.language == 'zh' }" @click="setLanguage('zh')">{{
			$t("languages.ZH")
		}}</a>
		<a class="dropdown-item" :class="{ active: themeSettings.language == '' }" @click="setLanguage('')">{{
			$t("labels.browser_default")
		}}</a>
		<div class="dropdown-divider"></div>
		<div class="disabled ml-3">{{ $t("labels.stars") }}:</div>
		<a class="dropdown-item" :class="{ active: !themeSettings.stars_off }" @click="setStarsOff(false)">{{
			$t("labels.on")
		}}</a>
		<a class="dropdown-item" :class="{ active: themeSettings.stars_off }" @click="setStarsOff(true)">{{
			$t("labels.off")
		}}</a>
		<template v-if="!hidePostOptions">
			<div class="dropdown-divider"></div>
			<div class="disabled ml-3">{{ $t("labels.default_post_translation") }}:</div>
			<a class="dropdown-item" :class="{ active: !themeSettings.do_translate }" @click="setTranslate(false)">{{
				$t("labels.original_language")
			}}</a>
			<a class="dropdown-item" :class="{ active: themeSettings.do_translate }" @click="setTranslate(true)">{{
				$t("labels.translate_to_japanese")
			}}</a>
			<div class="dropdown-divider"></div>
			<div class="disabled ml-3">{{ $t("labels.post_order") }}:</div>
			<a class="dropdown-item" :class="{ active: !themeSettings.posts_in_order }" @click="setOrder(false)">{{
				$t("labels.unread_first_then_random")
			}}</a>
			<a class="dropdown-item" :class="{ active: themeSettings.posts_in_order }" @click="setOrder(true)">{{
				$t("labels.newest_to_oldest")
			}}</a>
		</template>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";
// import Store from "@/utils/Store";

export default {
	name: "ThemeDropdown",

	props: {
		user: {},
		size: {
			type: Number,
			default: 16,
		},
		refreshBG: {},
		refreshTL: {},
		refreshOrder: {},
		hidePostOptions: {},
	},

	data() {
		return {
			themeSettings: null,
		};
	},
	created() {
		this.themeSettings = AuthService.getThemeSettings(this.user);
	},
	computed: {},
	watch: {
		user() {
			this.themeSettings = AuthService.getThemeSettings(this.user);
			if (this.refreshBG) {
				this.refreshBG();
			}
			if (this.refreshTL) {
				this.refreshTL();
			}
		},
	},

	methods: {
		setStarsOff(off) {
			this.themeSettings.stars_off = off;
			AuthService.setThemeSettings(this.user, this.themeSettings);
			if (this.refreshBG) {
				this.refreshBG();
			}
		},

		setTranslate(doTL) {
			this.themeSettings.do_translate = doTL;
			AuthService.setThemeSettings(this.user, this.themeSettings);
			if (this.refreshTL) {
				this.refreshTL();
			}
		},

		setOrder(inOrder) {
			console.log("SET ORDER", inOrder);
			this.themeSettings.posts_in_order = inOrder;
			AuthService.setThemeSettings(this.user, this.themeSettings);
			if (this.refreshOrder) {
				console.log("DO REFRESH");
				this.refreshOrder();
			}
		},

		setLanguage(lang) {
			this.themeSettings.language = lang;
			AuthService.setThemeSettings(this.user, this.themeSettings);
			if (lang == "") {
				lang = window.navigator.language;
				if (lang == "en-US") {
					lang = "en";
				}
			}
			this.$i18n.locale = lang;
			this.$forceUpdate();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

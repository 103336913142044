<template>
	<div class="img-container" style="min-height: 80px">
		<div class="text-center h-100">
			<img :id="imgID" v-if="!uploading" ref="img" :src="value" />
			<loading v-if="uploading" type="page" />
		</div>
		<div class="img-overlay d-flex flex-column">
			<template v-if="!newImageFile">
				<div v-if="value && !isDataURL" class="link-text flex scroll-y round-scroll mb-2">{{ value }}</div>
				<div v-if="!value" class="link-text flex scroll-y round-scroll mb-2">Upload Image</div>
				<div class="align-self-end mb-1 mr-1 d-flex flex-row">
					<input ref="img-select" class="d-none" type="file" accept="image/*" @change="selectImage" />
					<button
						v-if="value && clearable"
						class="btn btn-sm btn-icon btn-subtle text-white mr-0"
						v-tippy
						title="Remove Image"
						@click="removeImage"
					>
						<i class="text-white far fa-lg fa-times"></i>
					</button>
					<button
						class="btn btn-sm btn-icon btn-subtle text-white"
						v-tippy
						title="Select File"
						@click="clickImageSelect"
					>
						<i class="text-white far fa-lg fa-file"></i>
					</button>
				</div>
			</template>
			<template v-if="newImageFile">
				<div class="link-text flex scroll-y round-scroll mb-2">
					<div>New Upload:</div>
					<div class="mt-2">{{ newImageFile.name }}</div>
				</div>
				<div class="align-self-end mb-1 mr-1">
					<button
						class="btn btn-sm btn-icon btn-subtle text-white"
						v-tippy
						title="Cancel"
						@click="removeNewImage"
					>
						<i class="text-white far fa-lg fa-times"></i>
					</button>
				</div>
			</template>
		</div>
	</div>
</template>

<style scoped>
img {
	display: static;
	max-width: 100%;
	max-height: 100%;
	height: auto;
}
.img-overlay {
	position: absolute;
	top: 0;
	bottom: -1px;
	left: 0;
	right: 0;
	opacity: 0;
	padding: 0.5rem;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.35s;
}
.img-container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
}
.img-container:hover .img-overlay {
	opacity: 1;
}
.img-container:hover img {
	filter: blur(2px) saturate(50%);
}
.link-text {
	padding: 0.25rem;
	color: white;
	line-height: 1rem;
}
</style>

<script>
import FileService from "@/services/FileService";
import Toast from "@/utils/Toast";

export default {
	name: "PickableImage",
	props: {
		value: {},
		previewTo: {},
		file: {},
		clearable: {
			type: Boolean,
			default: false,
		},
		immediate: {
			type: Boolean,
			default: false,
		},
		imgID: {},
		fileType: {
			type: String,
			default: "",
		},
	},

	data() {
		return {
			newImageFile: this.file,
			oldImageURL: null,
			uploading: false,
		};
	},
	created() {
		this.oldImageURL = this.value;
	},

	watch: {
		newImageFile() {
			this.$emit("update:file", this.newImageFile);
			if (this.newImageFile) {
				this.$emit("update:commit", () => {
					return new Promise((resolve, reject) => {
						console.log("PROMISE INNER", this.newImageFile);
						if (this.newImageFile) {
							console.log("DO UPLOAD");
							return FileService.uploadFile(this.newImageFile, this.fileType)
								.then((r) => {
									console.log("RETURN", r);
									//This input won't work if the picker is in a modal, since the whole
									//component will be deleted by the time this callback is processed
									//Need to use the synced "file" and save it manually instead
									if (this.fileType == "image") {
										this.$emit("input", r.data.url);
										this.$emit("update:thumbUrl", r.data.thumb_url);
										this.$emit("update:width", r.data.w);
										this.$emit("update:height", r.data.h);
									} else {
										this.$emit("input", r.data);
									}
									resolve(r);
								})
								.catch((e) => {
									console.log("ERROR", { error: e });
									reject(e);
								});
						} else {
							resolve();
						}
					});
				});
			} else {
				this.$emit("update:commit", null);
			}
		},

		value() {
			if (!this.isDataURL) {
				console.log("value doesn't start with data:, null new image");
				this.newImageFile = null;
			}
		},
	},

	computed: {
		isDataURL() {
			return typeof this.value == "string" && this.value.substring(0, 5) == "data:";
		},
	},

	methods: {
		clickImageSelect() {
			this.$refs["img-select"].click();
		},

		selectImage(event) {
			console.log("Selected file", event.target.files);
			if (event.target.files && event.target.files[0]) {
				if (event.target.files[0].size > 5242880) {
					Toast.error(this.$i18n.t("messages.invalid_file"), this.$i18n.t("messages.file_over_5mb"));
					return;
				}

				if (this.immediate) {
					this.uploading = true;
					return FileService.uploadFile(event.target.files[0], this.fileType)
						.then((r) => {
							this.uploading = false;
							if (this.fileType == "image") {
								this.$emit("input", r.data.url);
								this.$emit("update:thumbUrl", r.data.thumb_url);
								this.$emit("update:width", r.data.w);
								this.$emit("update:height", r.data.h);
							} else {
								this.$emit("input", r.data);
							}
							this.$emit("uploadSucceeded");
						})
						.catch((e) => {
							this.uploading = false;
							console.log(e);
							this.$emit("uploadFailed");
						});
				} else {
					let img = this.$refs["img"];
					if (img) {
						let reader = new FileReader();

						reader.onload = (e) => {
							this.$emit("input", e.target.result);
						};

						reader.readAsDataURL(event.target.files[0]);
						this.$set(this, "newImageFile", event.target.files[0]);
					} else {
						Toast.error("Failed to preview image");
					}
				}
			} else {
				Toast.error("Error processing file");
			}
		},

		removeNewImage() {
			this.$emit("input", this.oldImageURL);
		},

		removeImage() {
			this.$emit("input", "");
			this.$emit("cleared");
		},
	},
};
</script>

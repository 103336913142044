<template>
	<div class="profile-card hoverable" @mouseenter="emitIcon">
		<div class="d-flex flex-row align-items-center">
			<h2 class="flex mb-0"><i class="fab fa-fw fa-youtube mr-2" />{{ $t("platforms.youtube") }}</h2>
			<div v-if="user.google_user_id" class="text-right">
				<div class="text-success"><i class="fas fa-check mr-1" />{{ $t("labels.verified") }}</div>
				<div class="text-half-muted">{{ user.youtube_name }}</div>
			</div>
			<div v-else class="my-1">
				<a
					id="youtube-button"
					href="/v1/auth/google?provider=google"
					class="btn-connect btn-lg btn-social btn-youtube"
				>
					{{ $t("labels.connect") }}
				</a>
			</div>
		</div>
		<hr class="my-2" />
		<div class="d-flex flex-row align-items-center">
			<h2 class="flex mb-0" style="font-size: 18px">Membership</h2>
			<div class="text-right">
				<div class="text-success" v-if="user.is_member"><i class="fas fa-check fa-lg mr-1" /></div>
				<div class="text-danger" v-if="!user.is_member"><i class="fas fa-times fa-lg mr-1" /></div>
			</div>
		</div>
	</div>
</template>

<script>
import Toast from "@/utils/Toast";
export default {
	name: "YoutubeCard",

	props: ["user"],

	components: {},

	data() {
		return {
			Toast: Toast,
		};
	},

	created() {},

	methods: {
		emitIcon() {
			if (this.user.youtube_icon_url) {
				this.$emit("icon", this.user.youtube_icon_url);
			} else {
				console.error("No Youtube icon URL");
			}
		},
	},
};
</script>

<style scoped>
</style>
import axios from "axios"

export default {
	getStars() {
		return axios.get(`/v1/skybox/stars`)
	},

	saveStar(star) {
		return axios.post(`/v1/skybox/star`, star)
	},

	deleteStar(id) {
		return axios.delete(`/v1/skybox/star/${id}`)
	}
}
<template>
	<main-page :user="user" :refreshUser="loadUser">
		<div class="container d-flex flex-row justify-content-center" style="max-width: 100vw; margin-bottom: 80px">
			<div class="flex general-card mt-5 p-4">
				<div class="delu-text-large title-text mb-5">{{ $t("labels.submit_a_post") }}</div>
				<div class="de-pad">
					<b-tabs v-model="tabIndex" justified>
						<b-tab class="re-pad" :title="$t('labels.message')" active>
							<post-submission-form
								type="text"
								:user="user"
								:events="events"
								v-model="post"
								@submit="submit"
							/>
						</b-tab>
						<b-tab class="re-pad" :title="$t('labels.image')">
							<post-submission-form
								type="image"
								:user="user"
								:events="events"
								v-model="post"
								@submit="submit"
								:commit.sync="saveFile"
							/>
						</b-tab>
						<b-tab class="re-pad" :title="$t('labels.video')">
							<post-submission-form
								type="video"
								:user="user"
								:events="events"
								v-model="post"
								@submit="submit"
							/>
						</b-tab>
					</b-tabs>
				</div>
				<div class="d-flex flex-row">
					<a class="btn btn-primary" @click="goToWall">{{ $t("labels.back") }}</a>
					<button
						v-if="!submitting"
						class="ml-auto btn"
						:class="{ 'btn-success': canSubmit, 'btn-outline-success': !canSubmit }"
						@click="submit"
						:disabled="!canSubmit"
					>
						{{ $t("labels.submit") }}
					</button>
					<button v-if="submitting" class="ml-auto btn btn-outline-success" disabled>
						<template v-if="uploading">{{ $t("labels.uploading") }}</template>
						<template v-else>{{ $t("labels.submitting") }}</template>
						<i class="ml-2 fas fa-spinner fa-spin" />
					</button>
				</div>
			</div>
			<div class="vertical-balance-spacer"></div>
		</div>

		<button @click="goToBirthday" class="btn btn-birthday mt-4" style="position: fixed; bottom: 12px; right: 12px">
			{{ $t("labels.go_to_birthday_page") }}
		</button>

		<b-modal id="anonModal" :title="$t('labels.post_anonymously')" header-bg-variant="primary">
			<p class="m-3" style="font-size: 18px" v-html="$t('messages.post_anonymously')"></p>
			<template #modal-footer="{ ok, cancel }">
				<button class="btn btn-secondary" @click="cancel">{{ $t("labels.back") }}</button>
				<button class="ml-auto btn btn-success" @click.stop="goToSignIn">
					{{ $t("labels.sign_in") }}
				</button>
				<button class="btn btn-outline-primary ml-3" @click="confirmAnon(ok)">
					{{ $t("labels.post_anonymously") }}
				</button>
			</template>
		</b-modal>
	</main-page>
</template>

<script>
import PostSubmissionForm from "@/components/PostSubmissionForm";

import AuthService from "@/services/AuthService";
import PostService from "@/services/PostService";
import Toast from "@/utils/Toast";
import $ from "jquery";

var events = [{ text: "🎉 Birthday 2021! 🎉", value: 1 }];

export default {
	name: "Submit",

	props: [],

	components: { PostSubmissionForm },

	computed: {
		canSubmit() {
			let messageOverLimit =
				this.post.message &&
				((this.tabIndex == 0 && this.post.message.length > 1120) ||
					(this.tabIndex != 0 && this.post.message.length > 280));
			let tlOverLimit =
				this.post.translation &&
				((this.tabIndex == 0 && this.post.translation.length > 1120) ||
					(this.tabIndex != 0 && this.post.translation.length > 280));
			let completeMessage = this.tabIndex == 0 && this.post.message && !messageOverLimit;
			let completeImage = this.tabIndex == 1 && this.post.image_url;
			let completeVideo = this.tabIndex == 2 && this.post.video_url && this.post.youtubeID;
			let validMessage =
				this.post.message &&
				!messageOverLimit &&
				this.post.language &&
				(this.post.language == "JP" || this.post.request_mtl || (this.post.translation && !tlOverLimit));
			let validMessageIfExists = !(this.tabIndex == 0 || this.post.includeMessage) || validMessage;
			return (
				(this.post.user_name || this.user) &&
				this.post.event_id != null &&
				(completeMessage || completeImage || completeVideo) &&
				validMessageIfExists
			);
		},
	},

	data() {
		return {
			user: null,
			tabIndex: 0,
			events: events,
			submitting: false,
			uploading: false,
			saveFile: null,

			post: {
				event_id: 1,
				user_name: null,
				preferred_handle: null,
				post_type: null,
				message: null,
				image_url: null,
				video_url: null,
				request_mtl: true,
				request_translation: null,
				members_only: null,

				// Fields that don't match the final object structure, but are flattened here to make manipulation easier
				includeMessage: false,
				translation: null,
				doAnon: true,
			},
		};
	},

	created() {
		this.loadUser();
	},

	watch: {},

	methods: {
		loadUser() {
			AuthService.getAuth()
				.then((resp) => {
					this.post.doAnon = false;
					this.post.user_name = "";
					this.user = resp.data.base_user;

					if (this.user.pixiv_user_id) {
						this.post.preferred_handle = "pixiv";
					} else if (this.user.discord_user_id) {
						this.post.preferred_handle = "discord";
					} else if (this.user.twitter_user_id) {
						this.post.preferred_handle = "twitter";
					} else if (this.user.google_user_id) {
						this.post.preferred_handle = "youtube";
					}
				})
				.catch((e) => {
					console.log("Not logged in", e);
					// Not authenticated, do nothing
				});
		},

		submit() {
			this.submitting = true;
			if (this.saveFile) {
				this.uploading = true;
				this.saveFile()
					.then(() => {
						this.uploading = false;
						this.doSubmit();
					})
					.catch((e) => {
						this.submitting = false;
						this.uploading = false;
						console.error(e);
						Toast.info("Failed to save file", e);
					});
			} else {
				this.doSubmit();
			}
		},

		doSubmit() {
			return PostService.submitPostSwitch(this.user, this.post)
				.then(() => {
					this.submitting = false;
					if (this.user && this.user.pixiv_user_id) {
						Toast.info(
							this.$i18n.t("messages.post_submitted"),
							this.$i18n.t("messages.thank_you_for_your_contribution")
						);
					} else {
						Toast.infoSticky(
							this.$i18n.t("messages.post_awaiting_approval"),
							this.$i18n.t("messages.your_post_will_appear")
						);
					}
					this.$router.push("/birthday2021");
				})
				.catch((e) => {
					this.submitting = false;
					console.error(e);
					Toast.error(this.$i18n.t("messages.failed_to_submit_post"), e);
				});
		},

		confirmAnon(ok) {
			this.post.doAnon = true;
			ok();
		},

		goToSignIn() {
			// this.$router.push("/login");

			this.$bvModal.hide("anonModal");
			this.$nextTick(() => {
				$("#userDropdownButton").dropdown("toggle");
			});
		},

		goToWall() {
			this.$router.push("/");
		},

		goToBirthday() {
			this.$router.push("/birthday2021");
		},
	},
};
</script>

<style scoped>
.general-card {
	max-width: 670px;
}
.de-pad {
	margin-top: -1.5rem;
	margin-left: -1.5rem;
	margin-right: -1.5rem;
}
.re-pad {
	margin: 1.5rem;
}
.error-block {
	overflow-x: auto;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}
</style>
<template>
	<main-page hideHeader>
		<div class="container text-white" style="min-height: 100vh">
			<label class="mt-3">{{ $t("labels.upload_image") }}</label>
			<pickable-image
				class="side-img card p-2 shadow-sm"
				v-model="image_url"
				:thumbUrl.sync="thumb_url"
				:width.sync="image_width"
				:height.sync="image_height"
				style="min-height: 150px; width: 200px"
				:commit.sync="saveFile"
				imgID="upload-img"
				fileType="image"
			/>
			<button v-if="!uploaded && !uploading && image_url" class="btn btn-success mt-3" @click="upload">
				Upload
			</button>
			<button v-if="uploading" disabled class="btn btn-secondary mt-3">Uploading...</button>
			<div class="mt-3" v-if="uploaded">
				<label>Image URL</label>
				<input class="form-control" readonly :value="image_url" />
				<label class="mt-2">Thumbnail URL</label>
				<input class="form-control" readonly :value="thumb_url" />
				<button class="btn btn-primary mt-3" @click="another">Another</button>
			</div>
		</div>
	</main-page>
</template>


<script>
import Toast from "@/utils/Toast";
import PickableImage from "@/components/PickableImage";

export default {
	name: "ImageUpload",

	props: [],

	components: { PickableImage },

	data() {
		return {
			Toast: Toast,

			uploaded: false,
			uploading: false,
			image_url: null,
			thumb_url: null,
			image_width: null,
			image_height: null,
			saveFile: null,
		};
	},

	created() {},

	methods: {
		upload() {
			this.uploading = true;
			this.saveFile()
				.then(() => {
					this.uploading = false;
					this.uploaded = true;
				})
				.catch((e) => {
					console.error(e);
					Toast.info("Failed to save file", e);
				});
		},

		another() {
			this.uploaded = false;
			this.uploading = false;
			this.image_url = null;
			this.thumb_url = null;
			this.image_width = null;
			this.image_height = null;
		},
	},
};
</script>

<style scoped>
</style>
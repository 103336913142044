export default {
	normalizeFilename(name) {
		let parts = name.split(".")
		if (parts.length > 1) {
			parts.pop()
		}
		name = parts.join(".")

		name = name.replaceAll("_", " ")

		let words = name.split(" ")
		for (let i in words) {
			if (words[i].length > 0) {
				words[i] = words[i][0].toUpperCase() + words[i].substr(1);
			}
		}

		name = words.join(" ")
		return name
	},

	mimeTypeIcon(mimeType) {
		if(!mimeType) {
			return "fa-file"
		}
		mimeType = mimeType.split(";")[0]
		switch (mimeType) {
			case "audio/aac":
				return "fa-file-audio";
			case "video/x-msvideo":
				return "fa-file-video";
			case "image/bmp":
				return "fa-file-image";
			case "application/x-bzip":
				return "fa-file-archive";
			case "application/x-bzip2":
				return "fa-file-archive";
			case "text/csv":
				return "fa-file-excel";
			case "application/msword":
				return "fa-file-word";
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				return "fa-file-word";
			case "image/gif":
				return "fa-file-image";
			case "text/html":
				return "fa-file-code";
			case "image/jpeg":
				return "fa-file-image";
			case "video/mpeg":
				return "fa-file-video";
			case "application/vnd.oasis.opendocument.presentation":
				return "fa-file-powerpoint";
			case "application/vnd.oasis.opendocument.spreadsheet":
				return "fa-file-excel";
			case "application/vnd.oasis.opendocument.text":
				return "fa-file-word";
			case "audio/ogg":
				return "fa-file-audio";
			case "video/ogg":
				return "fa-file-video";
			case "image/png":
				return "fa-file-image";
			case "application/pdf":
				return "fa-file-pdf";
			case "application/vnd.ms-powerpoint":
				return "fa-file-powerpoint";
			case "application/vnd.ms-excel":
				return "fa-file-excel";
			case "application/vnd.ms-word":
				return "fa-file-excel";
			case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
				return "fa-file-powerpoint";
			case "application/x-rar-compressed":
				return "fa-file-archive";
			case "application/rtf":
				return "fa-file-alt";
			case "image/svg+xml":
				return "fa-file-image";
			case "application/x-shockwave-flash":
				return "fa-file-video";
			case "image/tiff":
				return "fa-file-image";
			case "text/plain":
				return "fa-file-alt";
			case "audio/wav":
				return "fa-file-audio";
			case "audio/webm":
				return "fa-file-audio";
			case "video/webm":
				return "fa-file-video";
			case "video/mp4":
				return "fa-file-video";
			case "image/webp":
				return "fa-file-image";
			case "application/zip":
				return "fa-file-archive";
			case "application/x-7z-compressed":
				return "fa-file-archive";
			default:
				return "fa-file";
		}
	},

	fileSize(bytes, si=false, dp=1) {
		const thresh = si ? 1000 : 1024;
	
		if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
		}
	
		const units = si 
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
		: ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let u = -1;
		const r = 10**dp;
	
		do {
		bytes /= thresh;
		++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
	
	
		return bytes.toFixed(dp) + ' ' + units[u];
	}
}
<template>
	<div>
		<spacebox
			style="width: 100%; height: 100%"
			@clickImage="loadLightboxImage"
			@clickVideo="loadLightboxVideo"
			@starsLoaded="starsLoaded"
		></spacebox>

		<div
			ref="button"
			class="back-button-container fadeable d-flex flex-row justify-content-end align-items-center"
		>
			<div v-if="starsLoading" class="ui-text mr-3" style="opacity: 0.75">
				{{ $t("labels.loading")
				}}<i class="fas fa-spinner fa-pulse ml-1" />
			</div>
			<button class="ui-button" @click="goBack">
				&lt;&nbsp;{{ $t("labels.back") }}
			</button>
		</div>

		<div
			class="lightbox-overlay"
			:class="{ show: showLightbox || showCredits }"
			@click="closeModals"
		></div>

		<div class="lightbox" :class="{ show: showLightbox }">
			<loading
				v-if="showLightbox && !showLightboxContent"
				class="lightbox-loading"
			/>
			<img
				v-if="lightboxImage"
				:src="lightboxImage"
				class="lightbox-image"
				:class="{ show: showLightboxContent }"
				@load="lightboxImageLoaded"
			/>
			<iframe
				v-if="lightboxVideo"
				:src="lightboxVideo"
				class="lightbox-video"
				:class="{ show: showLightboxContent }"
				@load="lightboxVideoLoaded"
				width="1120"
				height="730"
				frameborder="0"
			></iframe>
			<div
				v-if="lightboxDetails"
				class="lightbox-details"
				:class="{ show: showLightboxContent }"
				v-html="lightboxDetails"
			></div>
		</div>

		<b-modal
			id="redirectModal"
			header-bg-variant="secondary"
			hideFooter
			@hide="redirectingTo = ''"
		>
			<template #modal-header>
				<div class="large-icon-modal-header">
					<div class="d-flex flex-column">
						<i class="fas fa-link fa-2x my-3"></i>
						<div>{{ $t("labels.link_redirect") }}</div>
					</div>
				</div>
			</template>
			<div class="large-details">
				<p>{{ $t("labels.you_are_being_redirected") }}</p>
				<div class="details-link-container">
					<a
						class="btn btn-lg btn-secondary details-link"
						target="_blank"
						:href="redirectingTo"
						>{{ redirectingTo }}</a
					>
				</div>
				<p class="mt-4">{{ $t("labels.redirect_warning") }}</p>
			</div>
		</b-modal>
	</div>
</template>

<script>
import Theme from "@/utils/Theme";
// import _ from "lodash";

import Spacebox from "@/components/Birthday/Spacebox";
// import DeluCanvas from "@/components/Birthday/Canvas";
// import Loading from "@/components/Birthday/Loading";

export default {
	name: "BirthdayHome",

	props: [],

	components: { Spacebox },

	computed: {
		lang() {
			return this.$i18n.locale;
		},
	},

	data() {
		return {
			loading: true,
			starsLoading: true,

			theme: Theme.getCurrent(),
			user: null,

			refreshBG: null,

			redirectingTo: "",

			showLightbox: false,
			showMenu: false,
			showCredits: false,
			lightboxImage: null,
			lightboxVideo: null,
			lightboxDetails: null,
			showLightboxContent: false,
		};
	},

	created() {
		this.loadData();
		this.redirect = (e) => {
			if (!e) return;
			if (e.target.tagName === "A") {
				let href = e.target.href;
				if (!href) return;
				if (this.redirectingTo) return;

				this.redirectingTo = href;
				this.$bvModal.show("redirectModal");
				e.preventDefault();
			}
		};
		document.addEventListener("click", this.redirect);
	},

	destroyed() {},

	mounted() {
		this.$refs["button"].classList.add("fade-in");
	},

	unmounted() {},

	watch: {},

	methods: {
		loadData() {
			// do nothing
		},

		loadLightboxImage(url, details) {
			this.lightboxImage = url;
			this.lightboxDetails = details;
			this.lightboxVideo = null;
			this.showLightboxContent = false;
			this.$nextTick(() => {
				this.showLightbox = true;
			});
		},

		loadLightboxVideo(url) {
			this.lightboxImage = null;
			this.lightboxDetails = null;
			this.lightboxVideo = url;
			this.showLightboxContent = false;
			this.$nextTick(() => {
				this.showLightbox = true;
			});
		},

		lightboxImageLoaded(e) {
			console.log("LOADED IMAGE", e);
			setTimeout(() => {
				this.showLightboxContent = true;
			}, 100);
		},

		lightboxVideoLoaded(e) {
			console.log("LOADED IMAGE", e);
			setTimeout(() => {
				this.showLightboxContent = true;
			}, 100);
		},

		starsLoaded() {
			this.starsLoading = false;
		},

		closeModals() {
			this.showLightbox = false;
			this.lightboxImage = null;
			this.lightboxVideo = null;
			this.showLightboxContent = false;
		},

		goBack() {
			this.$router.go(-1);
			setTimeout(() => {
				document.location.reload();
			}, 1000);
		},
	},
};
</script>

<style scoped>
.fadeable {
	opacity: 0;
	transition: opacity 1s ease-in;
}
.fadeable.fade-in {
	opacity: 1;
}

.content-box {
	background-color: black;
	position: relative;
	margin-top: 2rem;
	width: 80%;
	max-width: 800px;
	padding: 1rem;
	overflow: hidden;
}
.content-box-bg {
	position: absolute;
	width: 400px;
	top: -80px;
	left: -80px;
	opacity: 0.15;
}

.green-border {
	border-top: 3px solid rgb(10, 208, 10);
}

.header-center {
	width: 80%;
	max-width: 800px;
	display: flex;
}

.header-icon > img {
	height: 64px;
	width: 64px;
	border-radius: 50%;
}
.header-icon {
	padding: 6px;
	border: 3px solid rgb(10, 208, 10);
	border-radius: 50%;
}
.header-top {
	position: relative;
	border-bottom: 3px solid rgb(10, 208, 10);
	font-weight: 900;
	font-size: 40px;
	font-family: tahoma;
	text-transform: capitalize;
	line-height: 48px;
}
.header-bottom {
	font-size: 16px;
	font-weight: 500;
	opacity: 0.75;
}

.left-header {
	text-align: left;
	align-self: left;
	padding-left: 4px;
}
.left-header .header-icon {
	position: absolute;
	right: calc(100% - 2px);
}
.left-header .header-top {
	margin-left: -20px;
	padding-left: 20px;
}
.left-header .header-bottom {
	padding-left: 2px;
}

.lightbox-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.25s;
	backdrop-filter: blur(6px);
	pointer-events: none;
	z-index: 30;
}

.lightbox-overlay.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	z-index: 31;
	pointer-events: none;
	padding: 60px 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.lightbox.show {
	display: flex;
	pointer-events: none;
}

.lightbox-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.5;
}

.lightbox-image {
	opacity: 0;
	transition: opacity 0.45s;
	max-width: 100%;
	max-height: 100%;
	height: auto;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	background-color: #000;
}
.lightbox-image.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox-video {
	opacity: 0;
	transition: opacity 0.45s;
	max-width: 100%;
	max-height: 100%;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	background-color: #000;
}
.lightbox-video.show {
	opacity: 1;
	pointer-events: all;
}

.lightbox-details {
	opacity: 0;
	transition: opacity 0.45s;
	background: linear-gradient(
		to right,
		rgb(253, 242, 222),
		rgb(253, 242, 222) 60px,
		rgb(223, 212, 192) 100%
	);
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
	padding: 10px 16px;
	font-size: 24px;
	font-family: seto;
	font-weight: 800;
	margin-top: 20px;
}
.lightbox-details.show {
	opacity: 1;
	pointer-events: all;
}

.back-button-container {
	position: fixed;
	top: 30px;
	right: 30px;
}

.ui-button {
	padding: 0rem 1rem;
	font-family: PixelMPlus10;
	font-size: 24px;
	border: 2px solid rgba(255 255 255 / 75%);
	color: rgba(255 255 255 / 75%);
	background-color: transparent;
	transition: all 0.15s;
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 75%))
		drop-shadow(0px 0px 6px rgba(255 255 255 / 50%));
	text-shadow: 0px 0px 1px rgba(255 255 255 / 75%),
		0px 0px 2px rgba(255 255 255 / 50%);
	backdrop-filter: brightness(0.65);
}
.ui-button:hover {
	filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 50%))
		drop-shadow(0px 0px 6px rgba(255 255 255 / 20%));
	border: 2px solid rgba(255 255 255 / 40%);
}
.ui-container {
	position: absolute;
	bottom: 60px;
	font-family: PixelMPlus10;
	font-size: 36px;
	color: rgba(255 255 255 / 75%);
	background-color: rgba(0 0 30 / 75%);
	max-width: 390px;
	max-height: 50%;
	z-index: 2;
	display: flex;
	flex-direction: column;
}
.ui-text {
	padding: 0rem 1rem;
	font-family: PixelMPlus10;
	font-size: 24px;
	color: rgba(255 255 255 / 75%);
	background-color: transparent;
	transition: all 0.15s;
	text-shadow: 0px 0px 1px rgba(255 255 255 / 75%),
		0px 0px 2px rgba(255 255 255 / 50%);
}
</style>

<template>
  <div id="app">
    <router-view :key="$route.fullPath" class="foreground" />
  </div>
</template>

<script>
import "@/assets/css/stars.css";

export default {};
</script>

<style>
#app {
  font-family: "lato", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.background {
  height: 100%;
  background: radial-gradient(
    ellipse at bottom,
    #19252f 0%,
    #121b25 40%,
    #000000 100%
  );
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.background-scene {
  height: 100%;
  background: radial-gradient(
    ellipse at bottom,
    #19252f 0%,
    #121b25 40%,
    #000000 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform-origin: 0 0;
}
.foreground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
</style>
